// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.daikin.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    version: '1.000.0',
    production: false,
    errMsg: 'Failed to complete. Please try again.',
    partner: true,
    config: {
        "stage": "prod",
        "appName": "CPM",
        "haystackUrl": "https://silo.daikin.service.75f.io/",
        "tagsUrl": "https://silo.daikin.service.75f.io/v2/sitePointTags",
        "cpmUrl": "https://chiller-plant.daikin.service.75f.io/",
        "alertsUrl": "https://alerts.daikin.service.75f.io/",
        "pasUrl": `https://portfolio-analytics.daikin.service.75f.io`,
        "auth": {
            "caretakerUrl": "https://caretaker.daikin.service.75f.io/api/v1",
            "gatekeeperUrl": " https://gatekeeper.daikin.service.75f.io/api/v1"
        }
    }
};