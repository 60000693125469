<div id="loader" class="col-12 loader" style="display:none ">
  <div><img  src="../../../../assets/images/loader.gif"></div>
</div>
<cpm-top-bar [ngClass]="showTopBar ? 'showTopBar': 'hideTopBar'" [themeClass]="toggleChecked ? 'dark-theme': 'light-theme'" [selectedTabIndex]="selectedTabIndex" [isBuilderDataChanged]="showBtnData.showSaveDraftBtn"  [loginUserTypeData]="loginUserTypeData"  [disableMatTab] = 'disableMatTab' [toggleChecked] = 'toggleChecked' [toggleTheme]="toggleTheme" [isPublished]="isPublished" (masterViewThemePreference)="themeChanged($event)" (sharedPlantData)="sharedPlantData($event)" (isBuildingSelected)="isBuildingSelectedChange($event)" (noPlantSelected)="isNoPlantSelected($event)" (plantBuilderView)="plantBuilderView($event)"  (masterViewPreference)="masterViewPreferenceChanged($event)"></cpm-top-bar>
<div  id="noPlantSelected" class="col-12 no-building" style="display:'' ">
  <div><em class="fas fa-building  fa-5x" ></em></div><br/>
  <div>No Central Plant Dashboard Exist. <span *ngIf="isFac"> Kindly connect 75F Professional Services Team </span></div>
</div>
<div class="draw-flow-poc" id="canvas" style="display:none" >
  <div [ngClass]="toggleChecked ? 'dark-theme': 'light-theme'" id="wrapper" class="wrapper" >    
    <div id="parentDrawFlow">
      <div id="drawflow" class="hideConfigs"
        (dragenter)="onDragEnter($event)"
        (dragleave)="onDragLeave($event)"
        (dragover)="onDragOver($event)"
        (dragend)="onDragEnd($event)"
        (drop)="onDrop($event)">
        <div class="legends">
          <div><div class="onLegend legendColor"></div><p>ON</p></div>
          <div><div class="offLegend legendColor"></div><p>OFF</p></div>
          <div><div class="severeLegend legendColor"></div><p>SEVERE ALERT</p></div>
          <div><div class="moderateLegend legendColor"></div><p>MODERATE ALERT</p></div>
          <div><div class="lowLegend legendColor"></div><p>LOW ALERT</p></div>
        </div>

        <div [ngClass]="{'displayNone':showBtnData.showExitPreviewBtn, 'full-screen': isFullScreen}" class="flex-row-reverse plant-button-container">
          <div *ngIf="(showBtnData.showPublishBtn && activeDraftState) && editor.editor_mode =='edit' && !showBtnData.showSaveDraftBtn" [matTooltip]="generatePublishTooltipText()" matTooltipClass="publishBtnTooltipText"  class="btn btn-submit m-l-5" (click)="publishPlantClicked()">PUBLISH</div>          
          <div *ngIf="showBtnData.showDeleteDraftBtn  && editor.editor_mode =='edit'" class="btn btn-submit m-l-5" (click)="deleteDraftBtnClicked()">DELETE DRAFT</div>
          <div *ngIf="showBtnData.showSaveDraftBtn && editor.editor_mode =='edit'" class="btn btn-submit m-l-5" (click)="saveDraftBtnClicked()">SAVE DRAFT</div>
          <div *ngIf="showBtnData.showPreviewBtn && editor.editor_mode =='edit'" class="btn btn-submit m-l-5" (click)="plantPreview()">PREVIEW</div>
        </div>
        <div  [ngClass]="{'displayNone':!showBtnData.showExitPreviewBtn}" class="flex-row-reverse plant-button-container">
          <div *ngIf="showBtnData.showExitPreviewBtn" class="btn btn-submit m-l-5" (click)="exitplantPreview()">EXIT PREVIEW</div>
        </div>

        <div class="flex-row-reverse plant-button-container">
          <div *ngIf="showBtnData.showEditPlantBtn  && (view == 'My Plant' || view == 'My Plant Preview') && (!isFac || (isFac && isBuilderUser)) && !isFullScreen && !loginUserTypeData.isPublic" class="btn btn-submit m-l-5" (click)="editClicked()">EDIT PLANT</div>
          <div *ngIf="showBtnData.showExportBtn  && view == 'My Plant'" class="btn btn-submit m-l-5" (click)="exportPlant()">EXPORT</div>
        </div>

          <div *ngIf="showDiscardPopUp" class="discardPopUp">
            <div (click) = "onDiscardPopUpCrossClick()" class="discardPopUpCross"><i class="fa fa-times" aria-hidden="true"></i></div>
          <div class="showDiscardPopUp-heading">Can't Edit this page </div>
           <div class="showDiscardPopUp-description m-b-2">
            This page can't be edited now because <span>{{selectedPlantData?.lastModifiedBy?.userEmail}}</span> has it open for editing.<br>
           </div>
           <div class="showDiscardPopUp-description m-b-7">
            Ask <span>{{selectedPlantData?.lastModifiedBy?.userEmail}}</span> to publish the page or edit the page and discard changes.
           </div>
           <div class="showDiscardPopUp-lastPublished">Last Published:<br>
            <span>{{selectedPlantData?.lastModifiedBy?.userEmail}}</span> on  <span>{{selectedPlantData?.lastModifiedBy?.dateTime | date: 'MMM d, y | h:mm a'}}</span>
           </div>
           <button class="discard-btn m-t-18" [disabled]="disableDiscardBtn" (click)="onDiscardClick()"> DISCARD CHANGES</button>
        </div>

        <div *ngIf="view == 'My Plant'" class="btn-expand" [ngClass]="{'full-screen': isFullScreen}" (click)="onFullScreen()"><em class="fa fa-expand"></em></div>
        <div id="zoomBox" class="bar-zoom">
          <button  matTooltip="Zoom In" matTooltipPosition="left" matTooltipClass="actionTooltip" (click)="onZoomIn()" class="zoom-in-btn"><em class="fa fa-magnifying-glass-plus"></em></button>
          <div class="btn-divider"></div>
          <button  matTooltip="Fit to Screen" matTooltipPosition="left" matTooltipClass="actionTooltip" class="fit-to-screen-btn" (click)="fitToScreen()">
            <img class="fit-to-screen-opacity  fit-to-screen-icon" src="/assets/images/fitToScreen.svg">
            <img class="fit-to-screen-active fit-to-screen-icon" src="/assets/images/fitToScreen_active.svg">
          </button>
          <div class="btn-divider"></div>
          <button matTooltip="Zoom Out" matTooltipPosition="left"  matTooltipClass="actionTooltip"  (click)="onZoomOut()" class="zoom-out-btn"><em class="fa fa-magnifying-glass-minus"></em></button>
        </div>
      </div>
    </div>
    <div id="bottom-bar" *ngIf="!isFullScreen"   [ngClass]="{'bottombarMinHeight': (hidePanels && isPublished)}" class="builder-bottom-container">
      <app-cpm-info-bar *ngIf="isBuildingSelected || showInfoBar" [editor_mode]="editor.editor_mode" (bottomBarSaved)="bottomBarSaved($event)" [plantId]="plantChangedVal" [loginUserTypeData]="loginUserTypeData" [buildings]="selectedBuilding" [themeClass]="toggleChecked"></app-cpm-info-bar>
    <div class="btn-hideShowEquips" *ngIf="hidePanels && isPublished">
      <div class="showEquipBarBtn" (click)="toggleEquipBar()">
        {{ hideEquipBar ? "HIDE EQUIPMENTS" : "SHOW EQUIPMENTS" }}
      </div>
    </div>
    <ng-container *ngIf="!nodes?.length && !hideEquipListLoader">
      <div class="text-center"><img  src="/assets/images/loader.gif" alt="'loader'"></div>
    </ng-container>
    <div class="equipment-bottom-bar" *ngIf="hideEquipBar && hidePanels" [ngClass]="toggleChecked ? 'dark-theme': 'light-theme'">      

      <ng-container *ngIf="hidePanels && isPublished">
        <ng-container *ngFor="let node of nodes">
          <div class="equip-class" >
            <div [tooltip] = "buildTooltip(node)"  placement="top" class="buildEquipments drag-drawflow {{ getNodeClass(node.class) }}" draggable="true" (dragstart)="onDragStart($event)" >
              <p class="hiddenName">{{node.name}}</p>
            </div>
            <div class="equipName" matTooltip="{{node.name}}" matTooltipPosition="above">{{ trimName(node.name) }}</div>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </div>
</div>
</div>