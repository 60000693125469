<div class="modal-container">
    <div class="modal-header">
        <div class="header-div">
            <div class="empty-div"></div>
            <div class="header-center-text">
                <mat-select [(value)]="selectedEquipName" (selectionChange)="onClickViewDropdownChange($event.value)">
                    <mat-option *ngFor="let equip of equipList" [value]="equip.id">
                        {{equip.dropDownName}}
                    </mat-option>
                </mat-select>
            </div>
            

            <div class="calendar-div">
                <div class="export-div">
                    <button mat-button class="export-button" (click)="export()">EXPORT</button>
                </div>
                <input type="text" id="equip-date-range" class="equip-modal-datepicker" readonly ngxDaterangepickerMd
                    [linkedCalendars]="true" [drops]="'down'" [minDate]="minDate" [maxDate]="maxDate" [opens]="'left'"
                    [locale]="locale" [(ngModel)]="selected" (change)="setDateRange($event)"
                    [alwaysShowCalendars]="true" [ranges]="ranges" [autoApply]="true" [showClearButton]="true"
                    [showRangeLabelOnInput]="false" [closeOnAutoApply]="true" />
                <i matSuffix class="fa fa-calendar color-primary" aria-hidden="true"></i>
            </div>
        </div>
    </div>
    <div class="modal-content" #clickedViewModal id="clicked-view-modal">
        <div class="click-container">
            <div class="equip-predefined-params d-flex justify-content-between"
                *ngIf="selectedEquipData.class != 'coolingTowerSingleFan' && selectedEquipData.class != 'coolingTowerTwoFan' && selectedEquipData.class != 'coolingTowerFourFan' ">
                <div *ngIf="isLoading" class="left-side-params-loader" id="left-side-params-loader">
                    <ngx-skeleton-loader animation="progress" [theme]="responsiveStylingObj.loaderStyling.leftTop" count="1"></ngx-skeleton-loader>
                </div>
                <div *ngIf="!isLoading" class="left-side-params" [ngClass]="predefinedParameters?.leftPosition ? predefinedParameters?.leftPosition : ''">
                    <div class="param-container" *ngIf="predefinedParameters?.enable?.pointList[0].point">
                        <span [ngClass]="predefinedParameters?.enable?.icon"></span>
                        <div class="enable_div">
                            <span [ngClass]="{'point-event-none': loginUserTypeData.isVofm}">{{predefinedParameters?.enable?.pointList[0].name}}
                                <mat-select matTooltip="{{showEnableTooltip(predefinedParameters?.enable)}}"  [matTooltipPosition]="'above'" matTooltipClass="enable-tooltip"  [ngClass]="{'sharedMatDropDown': loginUserTypeData.isPublic}" [disabled]="loginUserTypeData.isPublic" class="equip-running-state" [(ngModel)]="predefinedParameters?.enable?.pointList[0].pointValue"
                                    (selectionChange)="onWritablePointsDropdownChange($event, predefinedParameters?.enable?.pointList[0].point,'predefined')">
                                    <mat-option *ngFor="let template of equipRunningStates" [value]="template.value">
                                        {{template.name}}
                                    </mat-option>
                                </mat-select>
                            </span>
                        </div>
                    </div> 

                    <div class="param-container" *ngIf="runHoursParameterVal">
                        <span class="border_right-1" *ngIf="runHoursParameterVal && predefinedParameters?.enable?.pointList[0].point"></span>
                        <span [ngClass]="predefinedParameters?.runHours?.icon"></span>
                        <div class="run_hrs" [ngClass]="{'coolingTowerSingleFan': data?.class == 'coolingTowerSingleFan'}" *ngIf="runHoursParameterVal">
                            <span>{{predefinedParameters?.runHours?.pointList[0].name}}<span class="chiller-run-hrs p-l-5">{{runHoursParameterVal}}</span></span>
                        </div>
                    </div>
                </div>

                <div *ngIf="isLoading" class="right-side-params-loader" >
                    <ngx-skeleton-loader animation="progress" [theme]="responsiveStylingObj.loaderStyling.rightTop" count="1"></ngx-skeleton-loader>
                </div>

                <div *ngIf="!isLoading" [ngClass]="predefinedParameters?.rightPosition" class="right-side-params">
                    <div class="param-container" *ngIf="powerParameterVal">
                        <span [ngClass]="predefinedParameters?.power?.icon"></span>
                        <div class="power_div" [ngClass]="{'coolingTowerSingleFan': data?.class == 'coolingTowerSingleFan'}">
                            <span>{{predefinedParameters?.power?.pointList[0].name}}<span class="power p-l-5">{{powerParameterVal}}</span></span>
                        </div>                        
                    </div>

                    <div class="param-container" *ngIf="energyConsumedParameterVal">
                        <span class="border_right-1" *ngIf="energyConsumedParameterVal"></span>
                        <span [ngClass]="predefinedParameters?.energy?.icon"></span>
                        <div class="energy_div"
                            [ngClass]="{'coolingTowerSingleFan': selectedEquipData?.class == 'coolingTowerSingleFan'}">
                            <span>{{predefinedParameters?.energy?.pointList[0].name}}<span class="energy_consumed p-l-5">{{energyConsumedParameterVal}}</span></span>
                        </div>
                    </div>

                </div>
            </div>
            <div *ngIf="(selectedEquipData.class == 'coolingTowerSingleFan' || selectedEquipData.class == 'coolingTowerTwoFan' || selectedEquipData.class == 'coolingTowerFourFan')"
                class="equip-predefined-params">
                <div *ngIf="isLoading" class="left-side-params-loader" id="left-side-params-loader">
                    <ngx-skeleton-loader animation="progress" [theme]="responsiveStylingObj.loaderStyling.leftTop" count="1"></ngx-skeleton-loader>
                </div>
                <div *ngIf="!isLoading" class="left-side-params" [ngClass]="{'ct1': selectedEquipData.class == 'coolingTowerSingleFan' || selectedEquipData.class == 'coolingTowerTwoFan' || selectedEquipData.class == 'coolingTowerFourFan'}">
                    <div class="row w-100 align-items-center justify-content-end">
                        
                        <div class="col-6 p-r-0" *ngIf="predefinedParameters?.enable?.pointsToDisplayCount">
                            <div *ngFor="let item of predefinedParameters?.enable?.pointList; index as i"
                                class="predefined-enable-top">
                                <div class="d-flex" *ngIf="item?.point">
                                    <span [ngClass]="predefinedParameters?.enable?.icon"></span>
                                    <span class="enable_div">
                                        <span [ngClass]="{'point-event-none': loginUserTypeData.isVofm}">{{predefinedParameters?.enable?.pointList[i].name}}
                                            <mat-select matTooltip="{{showCTEnableTooltip(item.point)}}"  [matTooltipPosition]="'above'" matTooltipClass="enable-tooltip" [ngClass]="{'sharedMatDropDown': loginUserTypeData.isPublic}" [disabled]="loginUserTypeData.isPublic" class="equip-running-state p-l-5"
                                                [(ngModel)]="item.point?.writableData?.[0].val"
                                                (selectionChange)="onWritablePointsDropdownChange($event, item.point,'predefined')">
                                                <mat-option *ngFor="let template of item?.point?.options"
                                                    [value]="template?.value">
                                                    {{template?.name}}
                                                </mat-option>
                                            </mat-select>
                                        </span>
                                    </span>
                                </div>
                            </div>
                        </div>


                        <div class="col-6 p-l-0" *ngIf="predefinedParameters?.runHours?.pointsToDisplayCount">
                            <div *ngFor="let item of predefinedParameters?.runHours?.pointList; index as i">
                                <div class="d-flex preDefinedPointerTop" *ngIf="item.pointValueToDisplay">
                                    <span class="border_right" *ngIf="predefinedParameters?.runHours?.pointsToDisplayCount && 
                                        predefinedParameters?.enable?.pointsToDisplayCount">
                                    </span>
                                    <div [ngClass]="predefinedParameters?.runHours?.icon"></div>
                                    <div class="run_hrs"
                                        [ngClass]="{'coolingTowerSingleFan': data?.class == 'coolingTowerSingleFan'}">
                                        <span>{{predefinedParameters?.runHours?.pointList[i].name}}
                                            <span
                                                class="chiller-run-hrs p-l-5">{{item.pointValueToDisplay}}</span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div *ngIf="isLoading" class="right-side-params-loader" >
                    <ngx-skeleton-loader animation="progress" [theme]="responsiveStylingObj.loaderStyling.rightTop" count="1"></ngx-skeleton-loader>
                </div>

                <div *ngIf="!isLoading" [ngClass]="predefinedParameters?.rightPosition" class="right-side-params1">
                    <div class="row w-100">
                        
                        <div class="col-6 p-r-0" *ngIf="predefinedParameters?.power?.pointsToDisplayCount">
                            <div *ngFor="let item of predefinedParameters?.power?.pointList; index as i">
                                <div class="d-flex preDefinedPointerTop" *ngIf="item.pointValueToDisplay">
                                    <div [ngClass]="predefinedParameters?.power?.icon"></div>
                                    <div class="power_div"
                                        [ngClass]="{'coolingTowerSingleFan': data?.class == 'coolingTowerSingleFan'}">
                                        <span>{{predefinedParameters?.power?.pointList[i].name}}
                                            <span
                                                class="power p-l-5">{{item.pointValueToDisplay}}
                                            </span>
                                            </span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-6 p-l-0 p-r-0" *ngIf="predefinedParameters?.energy?.pointsToDisplayCount">
                            <div *ngFor="let item of predefinedParameters?.energy?.pointList; index as i">
                                <div class="d-flex preDefinedPointerTop" *ngIf="item.pointValueToDisplay">
                                    <span class="border_right" *ngIf="predefinedParameters?.energy?.pointsToDisplayCount && 
                                    predefinedParameters?.power?.pointsToDisplayCount"></span>
                                    <div [ngClass]="predefinedParameters?.energy?.icon"></div>
                                    <div class="energy_div"
                                        [ngClass]="{'coolingTowerSingleFan': data?.class == 'coolingTowerSingleFan'}">
                                        <span>{{predefinedParameters?.energy?.pointList[i].name}}
                                            <span
                                                class="energy_consumed p-l-5">{{item.pointValueToDisplay}}</span>
                                            </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="equip-header" [ngStyle]="{'top': equipTitleTop}" [ngClass]="selectedEquipData?.class + '-equip-header'">
                <p class="equip-header-name">
                    {{selectedEquipData.displayName}}
                </p>
                <p class="equip-header-details">
                    {{selectedEquipData.equipData.brand}}
                    {{selectedEquipData.equipData.model}}<span *ngIf="selectedEquipData.equipData.capacity">- {{selectedEquipData.equipData.capacity}} </span>
                </p>
            </div>
            <div class="clicked-view-content-container">
                <div *ngIf="isLoading" class="left-side-content-loader" id="left-side-content">
                    <ngx-skeleton-loader animation="progress" [theme]="responsiveStylingObj.loaderStyling.centerLeft" count="1"></ngx-skeleton-loader>
                </div>
                <div [hidden]="isLoading" class="left-side-content"  [ngClass]="{'ct-fourFan': selectedEquipData?.class == 'coolingTowerFourFan','ct-twoFan': selectedEquipData?.class == 'coolingTowerTwoFan'}"  id="left-side-content">
                    <div class="left-side-container" [ngStyle]="{'top':leftContainerTop}">
                        <div id="bar-Chart-content"></div>
                        <div [ngClass]="getPointListHeight(clickPointList)">
                            <div class="pointlist-container" [ngStyle]="{'max-height': pointListHeight}">
                                <div *ngFor="let equipData of clickPointList;index as i">
                                    <div  class="d-flex m-t-10" *ngIf="checkCustomPoint(equipData)">
                                        <div [title]="equipData?.toolTipName" class="point-name mr-auto">
                                            {{equipData?.pointName}}
                                        </div>
                                        <!-- Custom point writable drop down -->
                                        <div *ngIf="equipData?.writable != undefined && equipData?.options?.length">
                                            <mat-select [ngClass]="{'sharedMatDropDown': loginUserTypeData?.isPublic || loginUserTypeData?.isVofm}" [disabled]="loginUserTypeData?.isPublic || loginUserTypeData?.isVofm"
                                                class="custom-point-dropdown" [(ngModel)]="equipData?.writableData?.[0].val"
                                                (selectionChange)="onWritablePointsDropdownChange($event,equipData,'custom')">
                                                <mat-option *ngFor="let option of equipData.options" [value]="option.value">
                                                    {{option.name}}
                                                </mat-option>
                                            </mat-select>
                                        </div>
                                        <!-- Custom point readonly Data -->
                                        <div *ngIf="(equipData?.writable == undefined && equipData?.data.length) || equipData?.options?.length == 0" class="point-value">
                                            {{equipData?.pointValue}} {{equipData.unit}}
                                        </div>
                                        <!-- Custom point no data -->
                                        <div *ngIf="equipData?.writable == undefined && !equipData?.data.length" class="point-value">
                                        NA
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div *ngIf="isLoading" class="middle-side-content-loader" id="middle-side-content-loader">
                    <ngx-skeleton-loader animation="progress" [theme]="responsiveStylingObj.loaderStyling.centerMiddle" count="1"></ngx-skeleton-loader>
                </div>


                <div [hidden]="isLoading"  class="middle-side-content" id="middle-side-content">
                    <div class="radialGraph" [ngStyle]="{'margin-top': circularWidgetPosition}" [ngClass]="selectedEquipData?.class + '_graph_div'" id="graph-wrapper">
                        <div id="svg-div" class="circular-svg-div" style="position: relative;">
                            <span class="circular-top-todaydate-text"
                            [ngClass]="{'circular-top-todaydate-text-CT2':  selectedEquipData.class == 'coolingTowerTwoFan', 'circular-top-todaydate-text-CT4': selectedEquipData.class == 'coolingTowerFourFan'}"
                            id="Time-12-pm"></span>
                        <span class="circular-right-todaydate-text" id="Time-6-pm"></span>
                        <span class="circular-bottom-todaydate-text" id="Time-12-am"></span>
                        <span class="circular-left-todaydate-text"
                            [ngClass]="{'circular-left-todaydate-text-CT4': selectedEquipData.class == 'coolingTowerFourFan', 'circular-left-todaydate-text-CT2':  selectedEquipData.class == 'coolingTowerTwoFan'}"
                            id="Time-6-am"></span>
                        <svg id="graphId-chart" class="graph-container">
                            <circle id="energy-consumption-chart" r="180" stroke="#666666" stroke-width="0.5" fill="#333333"
                                fill-opacity="0.6" />
                            <circle id="status-chart" r="175" stroke="#666666" stroke-width="0.5" fill="#333333"
                                fill-opacity="0.6" />
                            <circle id="alert-chart" r="170" stroke="#666666" stroke-width="0.5" fill="#333333"
                                fill-opacity="0.6" />
                            <circle id="chiller-load-chart" r="165" stroke="#666666"
                                stroke-width="0.5" fill="#333333" fill-opacity="0.6" />
                            <line id="vertical-line" x1="225px" y1="0" x2="225px" y2="100%" stroke="#666666" stroke-width="1" />
                            <line id="horizontal-line" x1="0" y1="200px" x2="100%" y2="200px" stroke="#666666" stroke-width="1" />
                            <image id="center-img" height="200" width="200" y="150" x="150"></image>
                        </svg>
                        </div>
                        <svg class="display_date_time" id="svg-date-time">
                            <text id="text-1" font-size="11px" fill="#999999">12PM</text>
                            <text id="text-2" font-size="11px" fill="#999999">6PM</text>
                            <text id="text-3" font-size="11px" fill="#999999">12AM</text>
                            <text id="text-4" font-size="11px" fill="#999999">6AM</text>
                        </svg>
                    </div>
                    <div class="equip-command-div" id="command-div" [ngClass]="commandHeight">
                        <div id="command-point-{{i}}" *ngFor="let item of predefinedParameters?.command?.pointList; index as i">
                            <span [ngClass]="{'point-event-none': loginUserTypeData.isVofm}">{{
                                predefinedParameters?.command?.pointList[i].name}}
                                <mat-select [ngClass]="{'sharedMatDropDown': loginUserTypeData.isPublic}" [disabled]="loginUserTypeData.isPublic" [(value)]="item.pointValue"
                                    (selectionChange)="onWritablePointsDropdownChange($event, item.point, 'predefined')">
                                    <mat-option [value]="'1'"> ON</mat-option>
                                    <mat-option [value]="'0'"> OFF</mat-option>
                                </mat-select>
                            </span>
                        </div>
                    </div>
                </div>

                <div *ngIf="isLoading && equipImageDetails?.url" class="right-side-content-loader" id="right-side-content-loader">
                    <ngx-skeleton-loader animation="progress" [theme]="responsiveStylingObj?.loaderStyling?.centerRight" count="1"></ngx-skeleton-loader>
                </div>

                <div [hidden]="isLoading" class="right-side-content" id="right-side-content">
                    <ng-container
                        *ngIf="(selectedEquipData.class == 'pump1Vertical_forward' || selectedEquipData.class == 'pump1Vertical_backward' || selectedEquipData.class == 'coolingTowerSingleFan' || selectedEquipData.class == 'coolingTowerFourFan' || selectedEquipData.class == 'coolingTowerTwoFan') && !isLoading">
                        <circular-graph-widget [responsiveStylingObj]="responsiveStylingObj" [loginUserTypeData]="loginUserTypeData" [type]="isEquipChilledOrHotOrCondenser" [userInfo]="data.userInfo" [startDate]="formatStartDate" [endDate]="formatEndDate"
                            [selectedEquipData]="selectedEquipData"></circular-graph-widget>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</div>