import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatOption } from '@angular/material/core';


@Component({
  selector: 'site-selector',
  templateUrl: './site-selector.component.html',
  styleUrls: ['./site-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SiteSelectorComponent),
      multi: true
    }
  ]
})
export class SiteSelectorComponent implements OnInit, OnChanges {
  @ViewChild('allSelected') private allSelected: MatOption;
  @ViewChild('siteSearchInput') siteSearchInputElement: ElementRef;

  @Input() scope: Array<any> = [];
  @Input() selectorKeys: { parent: string, child: string } = { parent: 'sites', child: 'floors' };

  @Input() preSelectedScope?: Array<any> = [];
  @Input() defaultSelection = 'none';
  @Input() selectionDisabled = false;

  @Input() placeholder = 'Dashboard Scope';

  @Output() scopeSelectionChangeEvent: EventEmitter<any> = new EventEmitter();

  scopeSelectorForm: FormGroup;
  scopeSelectedList: Array<string> = [];

  allScopeSelected = false;

  scopeApplicable = [];

  availableChildrenLength = 0;

  filteredScope: Array<any>;
  searchUserForm: FormGroup;
  constructor(private fb: FormBuilder, private cd: ChangeDetectorRef) {
    this.scopeSelectorForm = this.fb.group({
      scopeSelection: new FormControl('')
    });

    this.searchUserForm = this.fb.group({
      userType: new FormControl('')
    });
  }



  siteSelectToggle() {
    if (this.allSelected.selected) {
      this.allSelected.deselect();
      this.getsiteData();
      return false;
    }
    if (this.scopeSelectorForm.controls.scopeSelection.value.length == this.filteredScope.length){
      this.allSelected.select();
    }

    this.getsiteData();
  }

  toggleAllSelection() {
    if (this.allSelected.selected) {
      this.scopeSelectorForm.controls.scopeSelection
        .patchValue([...this.filteredScope.map(item => item.id), 0]);
    } else {
      this.scopeSelectorForm.controls.scopeSelection.patchValue([]);
    }

    this.getsiteData();
  }


  getsiteData() {
    let selectedValue = this.scopeSelectorForm.controls.scopeSelection.value;
    const selectedSiteArr = this.filteredScope.filter((site) => {
      return selectedValue.some((siteId) => {
        return siteId === site.id;
      });
    });

    if ((this.filteredScope.length > 1)  && (selectedSiteArr.length == this.scope.length)) {
      this.allScopeSelected = true;
    } else {
      this.allScopeSelected = false;
    }

    this.scopeSelectedList = selectedSiteArr.map(site => site.dis)

    selectedValue =  selectedValue.filter((site) => {
      return  site !== 0 ;
    });

    this.scopeSelectionChangeEvent.emit(selectedValue);

  }

  ngOnChanges(changes: SimpleChanges) {

    if (changes.selectionDisabled) {
      if (changes.selectionDisabled.currentValue === true) {
        this.scopeSelectorForm.get('scopeSelection').disable();
      } else {
        this.scopeSelectorForm.get('scopeSelection').enable();
      }
    }

    if (this.scope && this.scope.length) {
      this.scopeApplicable = this.scope;
    }
    this.preSelectedScope = this.preSelectedScope || [];

    if (this.preSelectedScope.length === 0) {

      if (this.defaultSelection === 'all') {
        // If no preselected scope is present, then by default we choose everything
        this.allSitesSelectChange(true);
      } else {
        this.emptyOutSelection();
      }

    } else {
      // this.mapPreselectedScope();
    }
  }


  ngOnInit(): void {
    if (this.scope && this.scope.length) {
      this.filteredScope = [...this.scope];
    }
  }


  openedChange(isOpen) {
    this.siteSearchInputElement.nativeElement.value = '';
    if (isOpen && this.filteredScope.length > 4) {
      this.siteSearchInputElement.nativeElement.focus();
    }
  }


  // The total number of children across sites / orgs
  // calcTotalChildrenLength() {
  //   this.availableChildrenLength = 0;
  //   this.scopeApplicable.map(parent => {
  //     this.availableChildrenLength += parent[this.selectorKeys.child].length;
  //   });
  // }


  markChecked(arr, val) {
    if (!arr || (Array.isArray(arr) && !arr.length)) {
      return [];
    }
    return arr.filter((_filterItem) => _filterItem)
      .map((_item) => {
        _item['checked'] = val;
        return _item;
      });
  }


  emptyOutSelection() {
    this.scopeSelectorForm.controls.scopeSelection.patchValue([]);
    if (this.scopeApplicable && this.scopeApplicable.length) {
      this.scopeApplicable = this.scopeApplicable.map(parent => {
        parent['checked'] = false;
      });
    }

    this.filteredScope = this.scopeApplicable;
  }


  // All sites have been selected
  allSitesSelectChange(allSelection, event?) {
    if (event) {
      event.stopPropagation();
    }

    this.allScopeSelected = allSelection;
    if (allSelection) {
      this.scopeApplicable = this.scopeApplicable.map(parent => {
        parent['checked'] = allSelection;
        return parent;
      });
    } else {
      this.scopeApplicable = this.scopeApplicable.map(parent => {
        parent['checked'] = allSelection;
        return parent;
      });
      this.scopeSelectorForm.controls.scopeSelection.patchValue([]);
    }

    this.scopeSelectionMade();
  }


  onSiteSelectionChange(event?) {

    this.scopeApplicable = this.scopeApplicable.map(parent => {
      if (parent.id === event.source.value) {
        parent['checked'] = event.source.checked;
      }
      return parent;
    });


    this.scopeSelectionMade();
  }

  // sending the selection out of the component - after restructuring.
  scopeSelectionMade(fromPreselected = false) {
    const finalScopeSelections = [];
    const userSelectionsMade = this.scopeSelectorForm.controls.scopeSelection.value;


    this.scopeSelectedList = [];
    this.scopeApplicable.map(parent => {
      if (parent['checked']) {
        this.scopeSelectedList.push(parent.dis);
        finalScopeSelections.push(parent);
      }
    });



    if (userSelectionsMade == 'all') {
      this.scopeSelectorForm.controls.scopeSelection.patchValue([...this.scopeSelectorForm.controls.scopeSelection.value, 'all']);
      this.allScopeSelected = true;
    } else {
      this.allScopeSelected = false;
      this.scopeSelectorForm.controls.scopeSelection.setValue(finalScopeSelections.map(site => site.id));

    }


    const emittedValue = {
      finalScopeSelections
    };
    if (!fromPreselected) {
      this.scopeSelectionChangeEvent.emit(emittedValue);
    }
    this.cd.detectChanges();
  }

  handleInput(event, value): void {
    event.stopPropagation();
    this.filteredScope = this.filteredScope.map((parent: any) => {
      if (this.selectorKeys.child == 'sites') {
        if (value && value.trim().length > 0) {
          value = value.trim();
          const filteredChildren = parent[this.selectorKeys.child].filter(child => {
            return child?.dis?.toLowerCase().includes(value.toLowerCase());
          });
          parent.hide = filteredChildren.length === 0 && !parent?.dis?.toLowerCase().includes(value?.toLowerCase());
        } else {
          parent.hide = false;
        }
      }
      return parent;
    });
  }


  clearSearch() {
    this.filteredScope = [...this.scope];
    this.filteredScope = this.filteredScope.map((parent: any) => {
      parent.hide = false;
      return parent;
    });
  }

  checkSelectAll(siteSearchlength){
    return this.scope && this.scope.length > 1 && siteSearchlength === 0 ? true: false;
  }

}
