import {
  AfterViewInit,
  Component,
  HostListener,
  OnInit,
  ViewChild,
} from '@angular/core';
import { NodeElement } from '../../models/node.model';
import { UserService } from '@/cpm/shared/services/user.service';
import { LocalStorageService } from '@/cpm/shared/services/localStorage.service';
import { Drawflow } from '../../../../assets/js/drawflow.min.js';
import { MatDialog } from '@angular/material/dialog';
import { EquipConfigModelComponent } from '../equip-config-model/equip-config-model.component';
import { preConfiguredNodes, ElementsToBeConfiguredNodes } from '@/shared/constants/show-equipments';
import { AlertService } from '@/shared/services/alert.service';
import { ObjectUtil } from '@/shared/utils/object-util';
import { StorageService } from 'src/app/shared/services/storage.service';
import { CPMTopBarComponent } from '../cpm-top-bar/cpm-top-bar.component';
import { StorageKeys } from '@/shared/enums/StorageKeys.enum';
import { CPMMasterViewEquipModalComponent } from '../master-view-equip-modal/master-view-equip-modal.component';
import { ApiService } from '@/shared/services/api.service';
import { ConfirmationPopupComponent } from '../../../shared/components/dialogs/confirmation-popup/confirmation-popup.component';
import _ from 'lodash';
import { catchError, forkJoin, map, Observable, of, Subject, takeUntil } from 'rxjs';
declare const d3: any;
import * as dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { DomSanitizer } from '@angular/platform-browser';
import { HelperMethodsService } from '@/cpm/shared/services/helper-methods.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ToastMessageComponent } from '../toast-message/toast-message.component';
import { ExportModalComponent } from '@/shared/components/export-modal/export-modal.component';
import { defaultClickViewPoint } from 'src/app/shared/constants/default-click-point';
import { CpmService } from '@/shared/services/cpm.service';
dayjs.extend(utc);
dayjs.extend(timezone);

@Component({
  selector: 'cpm-plant-builder',
  templateUrl: './cpm-plant-builder.component.html',
  styleUrls: ['./cpm-plant-builder.component.scss']
})

export class CPMPlantBuilderComponent implements OnInit, AfterViewInit {
  nodes: NodeElement[] = [];
  tempNodes: NodeElement[] = [];
  userPreferenceData: any = {
    "userId": "",
    "heatmapPreferences": {
      "cpmTheme": "light",
      "cpmMasterViewPreference": "valueView"
    }
  }
  nodesDrawn: any[] = [];
  masterViewEquipModalOpened = false;
  masterViewEquipModalStyle = {};
  selectedItem!: NodeElement;
  editor!: any;
  toggleTheme = 'light';
  toggleChecked = false;
  groupNodeDeletedData;

  locked: boolean = false;

  lastMousePositionEv: any;

  drawFlowHtmlElement!: HTMLElement;
  selectedItemInChart: any;
  isFullScreen = false;
  hidePanels: boolean = true;
  hideEquipBar: boolean = true;
  showTopBar: boolean = true;
  today = new Date();
  selectedItemID: any;
  selectedItemNode: any;
  selectedItemNodeInputId: any;
  selectedItemNodeOutputId: any;
  selectedItemNodeIsSensor: boolean;
  deletedItemNode: any;
  selectedItemNodeInputPort: any;
  selectedItemNodeOutputPort: any;
  mousePositionEv: any;
  isBuildingSelected = false;
  selectedBuilding: any = [];
  noPlantSelected = true;
  isCollide: boolean = false;
  duplicateNodesArr = [];
  selectedPlantData: any;
  isEquipDialogOpen: boolean = false;
  enablePublishButton: boolean = false;
  callDrawCustomPathsRecursive: boolean = false;

  isPublished: boolean = true;
  cpmView: string = "";
  plantConfigList: any;
  view: any;
  showBtnData = {
    showEditPlantBtn: false,
    showSaveDraftBtn: false,
    showDeleteDraftBtn: false,
    showPublishBtn: false,
    showExitPreviewBtn: false,
    showPreviewBtn: false,
    showExportBtn: false
  }
  jsonData = []
  @ViewChild(CPMTopBarComponent) CPMTopBarComponent: CPMTopBarComponent;
  onHoverEquipBarNode: any;
  tooltipVariation = {
    interactiveDebounce: 100,
    trigger: 'mouseenter'
  };
  onHoverToolTipPosition = 'top';
  tempParentEquipData: any;
  deletedNodeData: any;
  createdParentEquipId: any;
  shiftPressed: boolean = false;
  showDiscardPopUp: boolean = false;
  selectedTabIndex: number = 0;
  disableMatTab: boolean = true;
  isFac: boolean = false;
  isBuilderUser: boolean = false;
  groupDeletionClicked = false;
  disableDiscardBtn = false;
  pipeRefs: any = [];
  private unsubscribe: Subject<void> = new Subject();
  userPersonalInfo = {};
  equipPipeSequence = {
    "waterCooledChiller": ["condenserWaterSupplyHeaderPipe", "condenserWaterReturnHeaderPipe", "chilledWaterReturnHeaderPipe", "chilledWaterSupplyHeaderPipe"],
    "airCooledChiller": ["chilledWaterReturnHeaderPipe", "chilledWaterSupplyHeaderPipe"],
    "coolingTowerSingleFan": ["condenserWaterSupplyHeaderPipe", "condenserWaterReturnHeaderPipe"],
    "coolingTowerTwoFan": ["condenserWaterSupplyHeaderPipe", "condenserWaterReturnHeaderPipe", "condenserWaterReturnHeaderPipe"],
    "coolingTowerFourFan": ["condenserWaterSupplyHeaderPipe", "condenserWaterReturnHeaderPipe", "condenserWaterReturnHeaderPipe", "condenserWaterReturnHeaderPipe", "condenserWaterReturnHeaderPipe"],
    "building2Pipe": ["chilledWaterReturnHeaderPipe", "chilledWaterSupplyHeaderPipe"],
    "building2PipeHot": ["hotWaterReturnHeaderPipe", "hotWaterSupplyHeaderPipe"],
    "dualTempBuilding2Pipe": ["dualTempReturnHeaderPipe", "dualTempSupplyHeaderPipe"],
    "steamBoiler": ["hotWaterReturnHeaderPipe", "hotWaterSupplyHeaderPipe"],
    "condenserBoiler": ["hotWaterReturnHeaderPipe", "hotWaterSupplyHeaderPipe"],
    "building4Pipe": ["chilledWaterReturnHeaderPipe", "chilledWaterSupplyHeaderPipe", "hotWaterReturnHeaderPipe", "hotWaterSupplyHeaderPipe"]
  };
  refreshFor5MinsMasterPointsData: any;
  timer = 5;
  plantChangedVal: any = '';
  masterViewPreference: any = 'valueView';
  isEquipConnected: boolean;
  showInfoBar: boolean = false;
  hoverTags = {
    "waterCooledChiller": {
      "chiller": {
        'leaving': ["chilled", "leaving", "water", "temp", "sensor"],
        'entering': ["chilled", "water", "temp", "entering", "sensor"],
        'setPoint': ["chilled", "water", "sp"]
      },
      "condensor": {
        'leaving': ["condenser", "leaving", "water", "temp", "sensor"],
        'entering': ["condenser", "water", "temp", "entering", , "sensor"],
        'setPoint': ["condenser", "water", "sp"]
      }
    },
    "airCooledChiller": {
      "chiller": {
        'leaving': ["chilled", "leaving", "water", "temp", "sensor"],
        'entering': ["chilled", "water", "temp", "entering", "sensor"],
        'setPoint': ["chilled", "water", "sp"]
      }
    },
    "steamBoiler": {
      "boiler": {
        'leaving': ["hot", "leaving", "sensor", "temp", "water",],
        'entering': ["hot", "entering", "sensor", "temp", "water"],
        'setPoint': ["hot", "water", "sp"]
      }
    },
    'condenserBoiler': {
      "boiler": {
        'leaving': ["hot", "leaving", "temp", "water", "sensor"],
        'entering': ["hot", "entering", "temp", "water", "sensor"],
        'setPoint': ["hot", "water", "sp"]
      }
    }
  }
  isAlertPopUpShown: boolean = false;
  isStatusPopUpShown: boolean = false;
  loginUserTypeData: any;
  ctrlKeyPressed: boolean = false;
  activeDraftState: boolean = false;
  displayErrorOnPublish: string = 'One or more connections are incomplete. Please complete all connections to publish.';
  siteName: string = '';
  disablePublishBtnIfAnyEquipsDeleted: boolean = false;
  dualPlantEquipStatusArr = [];
  hideEquipListLoader = false;
  isGroupNodeDelete: boolean;
  equipConfiguration:any;
  pumpCatogoryData:any;

  constructor(private userService: UserService,
    public localService: LocalStorageService,
    private dialog: MatDialog,
    public storageService: StorageService,
    public apiService: ApiService,
    public cpmService:CpmService,
    private dom: DomSanitizer,
    private toastMessage: MatSnackBar,
    public alertService: AlertService,
    private helperMethodsService: HelperMethodsService) { }

  private initializeList(tempEquipData) {
    tempEquipData.forEach((data, i) => {
      if (data.equipType) {
        let nodesData = preConfiguredNodes[data.equipType];
        if (nodesData != undefined) {
          this.confiqureEquips(data, nodesData, i)
        }
      }
    });

    this.nodes = this.nodes.map((_node, i) => {
      _node['sortOrder'] = i;
      return _node;
    }).sort((a, b) => a.sortOrder - b.sortOrder);
    this.getUserDetails();
  }

  removeDuplicatesInArrayOfObject(arr, key): any {
    return [...new Map(arr.map(item => [item[key], item])).values()]
  }

  confiqureEquips(data, nodesData, i) {
    this.nodes.push({
      id: i + 1,
      equipId: data.equipId,
      name: data.equipName,
      class: nodesData.name,
      inputs: nodesData.inputs,
      outputs: nodesData.outputs,
      displayName: nodesData.displayName,
      img: nodesData.img,
      margin: nodesData.margin,
      width: nodesData.width,
      height: nodesData.height,
      label: nodesData.label ? true : false,
      cutAwayImg: nodesData.cutAwayImg,
      static: nodesData.static,
      subEquips: data.equipData.subEquips,
      equipData: data.equipData,
      tags: data.tags,
      pointLimit: nodesData.pointLimit
    });

  }


  @HostListener('window:keydown', ['$event'])
  keyDownEvent(event: KeyboardEvent) {
    if (!this.shiftPressed && event.key == 'Shift') {
      let groupElement = document.getElementsByClassName("GROUP");
      for (let i = 0; i < groupElement.length; i++) {
        groupElement[i]['style']['z-index'] = '1200';
        groupElement[i]['style']['visibility'] = 'visible';
      }
      this.shiftPressed = true;
      this.editor.shiftPressed = true;
    } else if (event.key == 'Control' || event.key == 'Meta') {
      this.ctrlKeyPressed = true;
    }
  }

  @HostListener('window:keyup', ['$event'])
  keyUpEvent(event: KeyboardEvent) {
    if (event.key == 'Shift') {
      let groupElement = document.getElementsByClassName("GROUP");
      for (let i = 0; i < groupElement.length; i++) {
        groupElement[i]['style']['z-index'] = '1000';
        groupElement[i]['style']['visibility'] = 'hidden';
      }
      this.shiftPressed = false;
      this.editor.shiftPressed = false;
    } else if (event.key == 'Control' || event.key == 'Meta') {
      this.ctrlKeyPressed = false;
    }
  }

  onHoverEquipBar(node, event) {
    let offset = event.target.offsetLeft;
    this.onHoverToolTipPosition = 'top-start'
    let scrollPosition = document.querySelector('.equipment-bottom-bar').scrollLeft
    if (offset - scrollPosition > 1100 && offset - scrollPosition < 1500) {
      this.onHoverToolTipPosition = 'left-start'
      setTimeout(() => {
        const el: any = <HTMLElement>document.getElementsByClassName('onHoverEquipBar')[0]
        el.classList.add('rightToolTip1')
      }, 150);
    } else if (offset - scrollPosition > 1500 && offset - scrollPosition < 1530) {
      this.onHoverToolTipPosition = 'left-start'
      setTimeout(() => {
        const el: any = <HTMLElement>document.getElementsByClassName('onHoverEquipBar')[0]
        el.classList.add('rightToolTip2')
      }, 150);
    }
  }

  buildTooltip(onHoverEquipBarNode) {
    let innerHTML = '';
    if (onHoverEquipBarNode?.equipData?.subEquips?.length > 0 && onHoverEquipBarNode?.class != 'btuMeter' && !onHoverEquipBarNode?.class.includes('pump1Vertical')) {
      innerHTML = `<div class="onHoverEquipBar">
    <p class="onHoverEquipBar-name">${onHoverEquipBarNode?.displayName}</p>`

      innerHTML += `<p class="onHoverEquipBar-childHeading">CHILD EQUIPS</p>`

      if (this.isHeaderPipe(onHoverEquipBarNode?.class) || this.isBypassPipe(onHoverEquipBarNode?.class)) {
        onHoverEquipBarNode?.equipData?.subEquips?.forEach(subEquip => {
          if (subEquip?.equips?.length > 0) {
            subEquip?.equips?.forEach(point => {
              innerHTML += `<p class="onHoverEquipBar-child2Para"><span class="onHoverEquipBar-child2 onHoverEquipBar-headerpipe"> @ ${point?.shortName}</span></p>`
            });
          }
        });
      } else {
        onHoverEquipBarNode?.equipData?.subEquips?.forEach(subEquip => {
          innerHTML += `<p class="onHoverEquipBar-child1">@ ${subEquip?.subEquipsName}</p> `

          if (subEquip?.equips?.length > 0) {
            subEquip?.equips?.forEach(point => {
              innerHTML += `<p class="onHoverEquipBar-child2Para"> <span class="onHoverEquipBar-L">L</span> <span class="onHoverEquipBar-child2 onHoverEquipBar-child2-equip"> @ ${point?.shortName}</span></p>`
            });
          }
        });
      }
      innerHTML += `</div>`
    }
    return innerHTML;
  }

  trimName(name) {
    return (name.length > 30 ? name.slice(0, 30) + '...' : name).toLowerCase();
  }

  setImgPath(path) {
    return '/assets/images/cpmEquips/' + path;
  }

  getNodeClass(className) {
    if (className.includes('pump1Vertical_forward') || className.includes('pump1Vertical_backward')) {
      className = 'pump1Vertical';
    }
    return className + '_dragEquip';
  }

  createChillerPoint(pointData) {
    var createCompressorPoint = document.createElement("div");
    createCompressorPoint.classList.add('d-flex');
    createCompressorPoint.classList.add('m-t-10');
    createCompressorPoint.classList.add("compressorPointStyle");

    let compressorPointName = document.createElement('div');
    compressorPointName.classList.add("compressorPointText");
    compressorPointName.classList.add('mr-auto');
    //Display shortDis if shortDis is availble else display Dis value  
    compressorPointName.innerHTML = pointData?.shortDis ? pointData?.shortDis : pointData?.dis;
    createCompressorPoint.appendChild(compressorPointName);

    let compressorPointValue = document.createElement('div');
    compressorPointValue.classList.add("compressorPointValue");
    const pointVal = pointData.energyConsumedValue?.length ? pointData.energyConsumedValue : pointData.pointValue.length ? pointData.pointValue : 'NA';
    compressorPointValue.innerHTML = `${pointVal} ${pointVal != 'NA' && pointData?.unit != undefined ? pointData.unit : ''}`;

    createCompressorPoint.appendChild(compressorPointValue);
    return createCompressorPoint;
  }

  bulletChartIsLeaveTemp(data, pointList, widgetType) {
    let isPointSelected;
    if (widgetType == 'condenser') {
      isPointSelected = { ...pointList.find(pointData => this.isPointListTagsAreSame(this.hoverTags.waterCooledChiller.condensor.leaving, pointData.tags)) };
    } else if (widgetType == 'evaporator') {
      isPointSelected = { ...pointList.find(pointData => this.isPointListTagsAreSame(this.hoverTags.waterCooledChiller.chiller.leaving, pointData.tags)) };
    } if (widgetType == 'boiler') {
      isPointSelected = { ...pointList.find(pointData => this.isPointListTagsAreSame(this.hoverTags[data.class].boiler.leaving, pointData.tags)) };
    }

    if (isPointSelected?.data?.length) {
      return true;
    } else {
      return false;
    }
  }

  bulletChartIsEnterTemp(data, pointList, widgetType) {
    let isPointSelected;
    if (widgetType == 'condenser') {
      isPointSelected = { ...pointList.find(pointData => this.isPointListTagsAreSame(this.hoverTags[data.class].condensor.entering, pointData.tags)) };
    } else if (widgetType == 'evaporator') {
      isPointSelected = { ...pointList.find(pointData => this.isPointListTagsAreSame(this.hoverTags[data.class].chiller.entering, pointData.tags)) };
    } if (widgetType == 'boiler') {
      isPointSelected = { ...pointList.find(pointData => this.isPointListTagsAreSame(this.hoverTags[data.class].boiler.entering, pointData.tags)) };
    }

    if (isPointSelected?.data?.length) {
      return true;
    } else {
      return false;
    }
  }

  bulletChartIsSetPointTemp(data, pointList, widgetType) {
    let isPointSelected;
    if (widgetType == 'condenser') {
      isPointSelected = { ...pointList.find(pointData => this.isPointListTagsAreSame(this.hoverTags[data.class].condensor.setPoint, pointData.tags)) };
    } else if (widgetType == 'evaporator') {
      isPointSelected = { ...pointList.find(pointData => this.isPointListTagsAreSame(this.hoverTags[data.class].chiller.setPoint, pointData.tags)) };
    } if (widgetType == 'boiler') {
      isPointSelected = { ...pointList.find(pointData => this.isPointListTagsAreSame(this.hoverTags[data.class].boiler.setPoint, pointData.tags)) };
    }

    if (isPointSelected?.data?.length) {
      return true;
    } else {
      return false;
    }
  }

  getBulletWidgetData(data, pointList, key, widgetType) {

    let convertValue, minValue, maxValue, convertedMaxValue, widgetHeader, outerLine1, outerline2, maxNumber, minNumber;
    let bulletleaveTemp: any = {}
    let bulletEnterTemp: any = {}
    let bulletSetPointTemp: any = {}
    pointList.forEach(pointData => {

      if (widgetType == 'condenser') {
        if (this.hoverTags[data.class].condensor.leaving.every(value => pointData.tags.includes(value))) {
          bulletleaveTemp = pointData;
        }
        if (this.hoverTags[data.class].condensor.entering.every(value => pointData.tags.includes(value))) {
          bulletEnterTemp = pointData;
        }
        if (this.hoverTags[data.class].condensor.setPoint.every(value => pointData.tags.includes(value))) {
          bulletSetPointTemp = pointData;
        }

        widgetHeader = 'CONDENSER WATER';
        outerLine1 = +3;
        outerline2 = -3;
      }

      if (widgetType == 'evaporator') {
        if (this.hoverTags[data.class].chiller.leaving.every(value => pointData.tags.includes(value))) {
          bulletleaveTemp = pointData;
        }
        if (this.hoverTags[data.class].chiller.entering.every(value => pointData.tags.includes(value))) {
          bulletEnterTemp = pointData;
        }
        if (this.hoverTags[data.class].chiller.setPoint.every(value => pointData.tags.includes(value))) {
          bulletSetPointTemp = pointData;
        }

        widgetHeader = 'CHILLED WATER';
        outerLine1 = -3;
        outerline2 = +3;
      }


      if (widgetType == 'boiler') {
        if (this.hoverTags[data.class].boiler.leaving.every(value => pointData.tags.includes(value))) {
          bulletleaveTemp = pointData;
        }
        if (this.hoverTags[data.class].boiler.entering.every(value => pointData.tags.includes(value))) {
          bulletEnterTemp = pointData;
        }
        if (this.hoverTags[data.class].boiler.setPoint.every(value => pointData.tags.includes(value))) {
          bulletSetPointTemp = pointData;
        }

        widgetHeader = 'HOT WATER';
        outerLine1 = -3;
        outerline2 = +3;
      }
    });

    if (Object.keys(bulletleaveTemp).length && bulletleaveTemp.data.length) {
      let valueArr = bulletleaveTemp.data.map(point => {
        const val = point?.val ? (Number(point?.val?.split(" ")[0]).toFixed(2)) : null;
        return val;
      });
      minNumber = Math.min.apply(Math, valueArr);
      maxNumber = Math.max.apply(Math, valueArr);
      convertValue = minNumber;
      convertedMaxValue = maxNumber - minNumber;
      minValue = `${minNumber} ${bulletleaveTemp.unit}`;
      maxValue = `${maxNumber} ${bulletleaveTemp.unit}`;
    } else if (Object.keys(bulletEnterTemp).length && bulletEnterTemp.data.length) {
      let valueArr = bulletEnterTemp.data.map(point => {
        const val = point?.val ? (Number(point?.val?.split(" ")[0]).toFixed(2)) : null;
        return val;
      });
      minNumber = Math.min.apply(Math, valueArr);
      maxNumber = Math.max.apply(Math, valueArr);
      convertValue = minNumber;
      convertedMaxValue = maxNumber - minNumber;
      minValue = `${minNumber} ${bulletEnterTemp.unit}`;
      maxValue = `${maxNumber} ${bulletEnterTemp.unit}`;
    }

    switch (key) {
      case 'widgetHeader':
        return widgetHeader;
      case "leaveTemp":
        if (this.bulletChartIsLeaveTemp(data, pointList, widgetType)) {
          let unit = bulletleaveTemp.unit;
          bulletleaveTemp = bulletleaveTemp.data[bulletleaveTemp.data.length - 1].val;
          return bulletleaveTemp?.split(' ')[0] ? Number(bulletleaveTemp.split(" ")[0]).toFixed(2) + unit : 'NA' + unit;
        } else {
          return "";
        }
      case "enterTemp":
        if (this.bulletChartIsEnterTemp(data, pointList, widgetType)) {
          let unit = bulletEnterTemp.unit;
          bulletEnterTemp = bulletEnterTemp.data[bulletEnterTemp.data.length - 1].val;
          return bulletEnterTemp?.split(' ')[0] ? Number(bulletEnterTemp.split(" ")[0]).toFixed(2) + unit : 'NA' + unit;
        } else {
          return "";
        }
      case "setPoint":
        if (this.bulletChartIsSetPointTemp(data, pointList, widgetType)) {
          let unit = bulletSetPointTemp.unit;
          bulletSetPointTemp = bulletSetPointTemp.data[bulletSetPointTemp.data.length - 1].val;
          return bulletSetPointTemp?.split(' ')[0] ? Number(bulletSetPointTemp.split(" ")[0]).toFixed(2) + unit : 'NA' + unit;
        } else {
          return "";
        }
      case "getData":
        let convertedLeaveTemp;
        let convertedEnterTemp;
        let convertedSetPointTemp;

        if (this.bulletChartIsLeaveTemp(data, pointList, widgetType)) {
          convertedLeaveTemp = Number(bulletleaveTemp.data[bulletleaveTemp.data.length - 1].val?.split(' ')[0]) - convertValue;
        } else {
          convertedLeaveTemp = convertValue;
        }

        if (this.bulletChartIsEnterTemp(data, pointList, widgetType)) {
          convertedEnterTemp = Number(bulletEnterTemp.data[bulletEnterTemp.data.length - 1].val?.split(' ')[0]) - convertValue;;
        } else {
          convertedEnterTemp = convertValue;
        }

        if (this.bulletChartIsSetPointTemp(data, pointList, widgetType)) {
          convertedSetPointTemp = Number(bulletSetPointTemp.data[bulletSetPointTemp.data.length - 1].val?.split(' ')[0]) - convertValue;
        } else {
          convertedSetPointTemp = convertValue;
        }

        let ranges, setPointMarkers, deltaLine1, deltaLine2, deltaJoinLine, deltaFillData;

        if (this.bulletChartIsLeaveTemp(data, pointList, widgetType) && this.bulletChartIsEnterTemp(data, pointList, widgetType)) {
          ranges = [convertedLeaveTemp, convertedEnterTemp, convertedMaxValue];
          deltaLine1 = [convertedLeaveTemp];
          deltaLine2 = [convertedEnterTemp];
          deltaJoinLine = [{
            x1: convertedLeaveTemp + outerLine1,
            x2: convertedEnterTemp + outerline2
          }];
          deltaFillData = [{
            x1: convertedLeaveTemp,
            x2: convertedEnterTemp
          }]
        } else if (!this.bulletChartIsLeaveTemp(data, pointList, widgetType) && this.bulletChartIsEnterTemp(data, pointList, widgetType)) {
          ranges = [convertedEnterTemp, convertedMaxValue];
          deltaLine1 = [];
          deltaLine2 = [];
          deltaJoinLine = [];
          deltaFillData = [];
        } else if (this.bulletChartIsLeaveTemp(data, pointList, widgetType) && !this.bulletChartIsEnterTemp(data, pointList, widgetType)) {
          ranges = [convertedLeaveTemp, convertedMaxValue];
          deltaLine1 = [];
          deltaLine2 = [];
          deltaJoinLine = [];
          deltaFillData = [];
        } else if (!this.bulletChartIsLeaveTemp(data, pointList, widgetType) && !this.bulletChartIsEnterTemp(data, pointList, widgetType) && this.bulletChartIsSetPointTemp(data, pointList, widgetType)) {
          ranges = [0, convertedMaxValue];
          deltaLine1 = [];
          deltaLine2 = [];
          deltaJoinLine = [];
          deltaFillData = [];
        }

        if (this.bulletChartIsSetPointTemp(data, pointList, widgetType)) {
          setPointMarkers = [convertedSetPointTemp];
        } else {
          setPointMarkers = [];
        }
        let bulletChartObj = [
          {
            "ranges": ranges,
            "markers": setPointMarkers,
            "minValue": minValue,
            "maxValue": maxValue,
            "line1": deltaLine1,
            "line2": deltaLine2,
            "joinLine": deltaJoinLine,
            "polygon": deltaFillData
          }
        ];
        return bulletChartObj;

      case "delta":
        if (this.bulletChartIsLeaveTemp(data, pointList, widgetType) && this.bulletChartIsEnterTemp(data, pointList, widgetType)) {
          let unit = bulletEnterTemp.unit;
          return Math.abs(Number(bulletleaveTemp.data[bulletleaveTemp.data.length - 1].val?.split(' ')[0]) - Number(bulletEnterTemp.data[bulletEnterTemp.data.length - 1].val?.split(' ')[0])).toFixed(2) + unit;
        } else {
          return "";
        }
      default:
        return "";
    }

  }

  isPointListTagsAreSame(arr1, arr2) {
    return arr1.every(value => arr2.includes(value));
  }


  checkCondenserWidget(dataNode, pointList) {
    let equip = dataNode.childData.class;
    let leavePointTags = this.hoverTags[equip].condensor.leaving;
    let enterPointTags = this.hoverTags[equip].condensor.entering;
    let checkPointTags = this.hoverTags[equip].condensor.setPoint;

    let checkLeavePoint: boolean, checkEnterPoint: boolean, checkPointList: boolean = false;
    pointList.forEach(point => {
      if (this.isPointListTagsAreSame(leavePointTags, point.tags) && point?.data?.length) {
        checkLeavePoint = true;
      }
      if (this.isPointListTagsAreSame(enterPointTags, point.tags) && point?.data?.length) {
        checkEnterPoint = true;
      }
      if (this.isPointListTagsAreSame(checkPointTags, point.tags) && point?.data?.length) {
        checkPointList = true;
      }
    });


    let allWidgetPointCheck = [
      checkLeavePoint,
      checkEnterPoint
    ];

    return allWidgetPointCheck.includes(true);
  }

  checkEvaporatorWidget(dataNode, pointList) {

    let equip = dataNode.childData.class;
    let leavePointTags = this.hoverTags[equip].chiller.leaving;
    let enterPointTags = this.hoverTags[equip].chiller.entering;
    let checkPointTags = this.hoverTags[equip].chiller.setPoint;

    let checkLeavePoint: boolean = false;
    let checkEnterPoint: boolean = false;
    let checkPointList: boolean = false;
    pointList.forEach(point => {
      if (this.isPointListTagsAreSame(leavePointTags, point.tags) && point?.data?.length) {
        checkLeavePoint = true;
      }
      if (this.isPointListTagsAreSame(enterPointTags, point.tags) && point?.data?.length) {
        checkEnterPoint = true;
      }
      if (this.isPointListTagsAreSame(checkPointTags, point.tags) && point?.data?.length) {
        checkPointList = true;
      }
    });

    let allWidgetPointCheck = [
      checkLeavePoint,
      checkEnterPoint
    ];

    return allWidgetPointCheck.includes(true);
  }

  generateBulletChart(dataNode, pointList, widgetType, clickState = false, hoverChiller) {
    const bulletChartWidget = document.createElement('div');
    bulletChartWidget.setAttribute('id', 'bulletChartWidget' + "-" + widgetType + "-" + clickState);
    bulletChartWidget.classList.add("bulletChartWidget");
    if (this.bulletChartIsEnterTemp(dataNode, pointList, widgetType) && this.bulletChartIsLeaveTemp(dataNode, pointList, widgetType)) {
      bulletChartWidget.classList.add(widgetType + '-bulletChart');
    } else if (!this.bulletChartIsEnterTemp(dataNode, pointList, widgetType) && this.bulletChartIsLeaveTemp(dataNode, pointList, widgetType)) {
      bulletChartWidget.classList.add(widgetType + '-leaveTempbulletChart');
    } else if (this.bulletChartIsEnterTemp(dataNode, pointList, widgetType) && !this.bulletChartIsLeaveTemp(dataNode, pointList, widgetType)) {
      bulletChartWidget.classList.add(widgetType + '-enterTempbulletChart');
    } else if (!this.bulletChartIsEnterTemp(dataNode, pointList, widgetType) && !this.bulletChartIsLeaveTemp(dataNode, pointList, widgetType) && this.getBulletWidgetData(dataNode, pointList, 'setPoint', widgetType)) {
      bulletChartWidget.classList.add(widgetType + '-setpointTempbulletChart');
    }

    const bulletChartPointLabelContent = document.createElement('div');
    bulletChartPointLabelContent.classList.add("row");
    bulletChartWidget.appendChild(bulletChartPointLabelContent);

    const bulletChartPointLabelLeftContent = document.createElement('div');
    bulletChartPointLabelLeftContent.classList.add("col-3");
    bulletChartPointLabelLeftContent.classList.add("left-align");

    bulletChartPointLabelContent.appendChild(bulletChartPointLabelLeftContent);

    if (this.bulletChartIsLeaveTemp(dataNode, pointList, widgetType)) {
      const leftPointContent = document.createElement('div');

      const leftIconContent = document.createElement('i');
      leftIconContent.classList.add("fa");
      leftIconContent.classList.add("fa-square");
      leftIconContent.classList.add("leavingColor");
      leftPointContent.appendChild(leftIconContent);

      const leftLabelTextContent = document.createElement('span');
      leftLabelTextContent.classList.add("bulletValueType");
      leftLabelTextContent.classList.add("p-l-15");
      leftLabelTextContent.innerHTML = "Leaving";
      leftPointContent.appendChild(leftLabelTextContent);

      const leftLabelValueContent = document.createElement('div');
      leftLabelValueContent.classList.add("bulletTempLabel");
      leftLabelValueContent.classList.add("leavingColor");
      leftLabelValueContent.innerHTML = `${this.getBulletWidgetData(dataNode, pointList, 'leaveTemp', widgetType)}`;
      leftPointContent.appendChild(leftLabelValueContent);

      bulletChartPointLabelLeftContent.appendChild(leftPointContent);
    }

    if (!this.bulletChartIsLeaveTemp(dataNode, pointList, widgetType)) {
      const leftPointContent = document.createElement('div');

      const leftIconContent = document.createElement('i');
      leftIconContent.classList.add("fa");
      leftIconContent.classList.add("fa-square");
      leftIconContent.classList.add("bulletChartNoPointColor");
      leftPointContent.appendChild(leftIconContent);

      const leftLabelTextContent = document.createElement('span');
      leftLabelTextContent.classList.add("bulletValueType");
      leftLabelTextContent.classList.add("p-l-15");
      if (hoverChiller) {
        leftLabelTextContent.innerHTML = `<img class="bulletChartNoPointRect1" src='/assets/images/bulletchartNoPointRectangle.png'></img>`;
      } else {
        leftLabelTextContent.innerHTML = `<img class="bulletChartNoPointRect" src='/assets/images/bulletchartNoPointRectangle.png'></img>`;
      }
      leftPointContent.appendChild(leftLabelTextContent);

      const leftLabelValueContent = document.createElement('div');
      leftLabelValueContent.classList.add("bulletTempLabel");
      leftLabelValueContent.classList.add("bulletChartNoPointColor");
      leftLabelValueContent.innerHTML = "NA";
      leftPointContent.appendChild(leftLabelValueContent);

      bulletChartPointLabelLeftContent.appendChild(leftPointContent);
    }



    const bulletChartPointLabelCenterContent = document.createElement('div');
    bulletChartPointLabelCenterContent.classList.add("col-6");
    bulletChartPointLabelCenterContent.classList.add("center-align");

    bulletChartPointLabelContent.appendChild(bulletChartPointLabelCenterContent);

    if (this.bulletChartIsSetPointTemp(dataNode, pointList, widgetType)) {
      let bulletChartHeaderTitle = document.createElement('div');
      bulletChartHeaderTitle.classList.add("bulletChartPointName")
      bulletChartHeaderTitle.innerHTML = this.getBulletWidgetData(dataNode, pointList, 'widgetHeader', widgetType);

      bulletChartPointLabelCenterContent.appendChild(bulletChartHeaderTitle);
    }

    if (!this.bulletChartIsSetPointTemp(dataNode, pointList, widgetType)) {
      let bulletChartHeaderTitle = document.createElement('div');
      bulletChartHeaderTitle.classList.add("bulletChartSetPointNoDataTitle")
      bulletChartPointLabelCenterContent.appendChild(bulletChartHeaderTitle);
    }

    if (this.bulletChartIsSetPointTemp(dataNode, pointList, widgetType)) {
      const setPointLabelContent = document.createElement('div');
      setPointLabelContent.classList.add("bulletChartSetPointText")
      setPointLabelContent.innerHTML = `SETPOINT <span>${this.getBulletWidgetData(dataNode, pointList, 'setPoint', widgetType)}</span>`;

      bulletChartPointLabelCenterContent.appendChild(setPointLabelContent);
    }

    if (!this.bulletChartIsSetPointTemp(dataNode, pointList, widgetType)) {
      const setPointLabelContent = document.createElement('div');
      setPointLabelContent.classList.add("bulletChartSetPointNoData")
      bulletChartPointLabelCenterContent.appendChild(setPointLabelContent);
    }

    const bulletChartPointLabelRightContent = document.createElement('div');
    bulletChartPointLabelRightContent.classList.add("col-3");
    bulletChartPointLabelRightContent.classList.add("right-align");

    bulletChartPointLabelContent.appendChild(bulletChartPointLabelRightContent);

    if (this.bulletChartIsEnterTemp(dataNode, pointList, widgetType)) {
      const rightPointContent = document.createElement('div');
      rightPointContent.innerHTML = `<div><span class="bulletValueType">Entering</span>
                                       <i class="fa fa-square enterColor" aria-hidden="true"></i>    
                                    </div>
                                    <div class="bulletTempLabel enterColor">
                                      ${this.getBulletWidgetData(dataNode, pointList, 'enterTemp', widgetType)} 
                                    </div>`

      bulletChartPointLabelRightContent.appendChild(rightPointContent);
    }

    if (!this.bulletChartIsEnterTemp(dataNode, pointList, widgetType)) {
      const rightPointContent = document.createElement('div');
      rightPointContent.innerHTML = `<div><span class="bulletValueType p-r-10">
                                    <img class="${hoverChiller ? 'bulletChartNoPointRect1' : 'bulletChartNoPointRect'}" src='/assets/images/bulletchartNoPointRectangle.png'>
                                    </span>
                                         <i class="fa fa-square bulletChartNoPointColor" aria-hidden="true"></i>    
                                      </div>
                                      <div class="bulletTempLabel bulletChartNoPointColor">
                                        NA
                                      </div>`

      bulletChartPointLabelRightContent.appendChild(rightPointContent);
    }

    const bulletChartContent = document.createElement('div');
    bulletChartContent.classList.add("bulletChartWidget");

    let bulletChartData = this.getBulletWidgetData(dataNode, pointList, 'getData', widgetType);
    if (hoverChiller) {
      var bulletChartmargin = { top: 5, right: 20, bottom: 20, left: 5 },
        width = 570 - bulletChartmargin.left - bulletChartmargin.right,
        height = 70 - bulletChartmargin.top - bulletChartmargin.bottom;
    } else {
      var bulletChartmargin = { top: 5, right: 20, bottom: 20, left: 20 },
        width = 820 - bulletChartmargin.left - bulletChartmargin.right,
        height = 90 - bulletChartmargin.top - bulletChartmargin.bottom;
    }

    let bulletChart = d3.bullet(380, 30, false, hoverChiller)
      .width(width)
      .height(height);
    let bulletChartSvg = document.createElement("div");
    if (hoverChiller) {
      bulletChartSvg.classList.add("bulletChartHeight1");
    } else {
      bulletChartSvg.classList.add("bulletChartHeight");
    }
    d3.select(bulletChartSvg)
      .data(bulletChartData)
      .append("svg")
      .attr("class", "bullet")
      .attr("id", widgetType + "-" + clickState)
      .attr("width", width)
      .attr("height", height)
      .append("g")
      .attr("class", "bulletChartrect")
      .attr("transform", "translate(" + bulletChartmargin.left + "," + (bulletChartmargin.top + 10) + ")")
      .call(bulletChart);

    bulletChartContent.appendChild(bulletChartSvg);

    if (this.bulletChartIsLeaveTemp(dataNode, pointList, widgetType) && this.bulletChartIsEnterTemp(dataNode, pointList, widgetType)) {
      const bulletChartDelta = document.createElement('div');
      bulletChartDelta.classList.add("bulletDeltaText");
      if (hoverChiller) {
        bulletChartDelta.classList.add("m-l-115");
      } else {
        bulletChartDelta.classList.add("center-align");
      }

      bulletChartDelta.innerHTML = `DELTA  <span class="deltaText"><span class="triangleImg">Δ</span> ${this.getBulletWidgetData(dataNode, pointList, 'delta', widgetType)}</span>`
      bulletChartContent.append(bulletChartDelta);
    }

    bulletChartWidget.appendChild(bulletChartContent);
    return bulletChartWidget;
  }

  checkBoilerWidget(dataNode) {

    let equip = dataNode.childData.class;
    let leavePointTags = this.hoverTags[equip].boiler.leaving;
    let enterPointTags = this.hoverTags[equip].boiler.entering;
    let checkPointTags = this.hoverTags[equip].boiler.setPoint;

    let checkLeavePoint = false;
    let checkEnterPoint = false;
    let checkPointList = false;
    dataNode.data.point.forEach(point => {
      if (leavePointTags.every(value => point.tags.includes(value)) && point?.data?.length) {
        checkLeavePoint = true;
      }

      if (enterPointTags.every(value => point.tags.includes(value)) && point?.data?.length) {
        checkEnterPoint = true;
      }
      if (checkPointTags.every(value => point.tags.includes(value)) && point?.data?.length) {
        checkPointList = true;
      }
    });


    let allWidgetPointCheck = [
      checkLeavePoint,
      checkEnterPoint
    ];

    return allWidgetPointCheck.includes(true);
  }

  boilerChillerHover(dataNode, pointList, type) {
    const hoverBoilerContainer = document.createElement('div');

    const hoverBoilerHeader = document.createElement('div');
    hoverBoilerHeader.classList.add("hoverCoolingTowerTitle");
    hoverBoilerHeader.innerHTML = dataNode.displayName;

    hoverBoilerContainer.appendChild(hoverBoilerHeader);
    if (type == 'boiler' && this.checkBoilerWidget(dataNode)) {
      hoverBoilerContainer.appendChild(this.generateBulletChart(dataNode, pointList, 'boiler', false, false));
    }

    if (type == 'airCooledChiller' && this.checkEvaporatorWidget(dataNode, pointList)) {
      hoverBoilerContainer.appendChild(this.generateBulletChart(dataNode, pointList, 'evaporator', false, false));
    }
    if (pointList.length) {
      let hoverBoilerPoints = pointList;
      let setPointLimit = 5;
      let addedPoints = 1;


      for (var i = 0; i < hoverBoilerPoints.length; i++) {
        if ((addedPoints <= setPointLimit)) {
          hoverBoilerContainer.appendChild(this.createChillerPoint(hoverBoilerPoints[i]));
          addedPoints++;
        }
      }
    }

    if (document.getElementById(`hoverBoiler-${dataNode.equipId}`) != null) {
      document.getElementById(`hoverBoiler-${dataNode.equipId}`).innerHTML = '';
      document.getElementById(`hoverBoiler-${dataNode.equipId}`).appendChild(hoverBoilerContainer);
    }
  }

  chillerHover(dataNode, pointList) {
    pointList?.map(point => {
      if (point.tags.includes("compressor")) {
        point.pointType = 'compressor';
      } else if (point.tags.includes("chiller")) {
        point.pointType = 'evaporator';
      } else if (point.tags.includes("condenser")) {
        point.pointType = 'condenser';
      }
    });

    const hoverCompresserHeader = document.createElement('div');
    hoverCompresserHeader.classList.add("hoverChillerTitle");
    hoverCompresserHeader.innerHTML = dataNode.displayName;
    let hoverCompresserContainer = document.createElement('div');

    let masterViewSvg = document.createElement('svg');
    masterViewSvg.classList.add("masterlineSvg");

    let masterViewLine = document.createElement('line');
    masterViewLine.setAttribute("x1", '-2000');
    masterViewLine.setAttribute("y1", '2340');
    masterViewLine.setAttribute("x2", '2000');
    masterViewLine.setAttribute("y2", '4000');
    masterViewLine.setAttribute("stroke", "red");

    let masterViewCircle = document.createElement('circle');
    masterViewCircle.setAttribute("cx", '20');
    masterViewCircle.setAttribute("cy", '40');
    masterViewCircle.setAttribute("r", '5');
    masterViewCircle.setAttribute("fill", "#666");

    masterViewSvg.appendChild(masterViewLine);
    masterViewSvg.appendChild(masterViewCircle);
    hoverCompresserContainer.appendChild(masterViewSvg);
    hoverCompresserContainer.appendChild(hoverCompresserHeader);

    let hoverCompresserDiv = document.createElement('div');
    hoverCompresserDiv.classList.add("row");
    hoverCompresserContainer.appendChild(hoverCompresserDiv);


    let hoverChillerContent = document.createElement('div');
    hoverChillerContent.classList.add("row");
    hoverCompresserDiv.appendChild(hoverChillerContent);

    if (this.checkEvaporatorWidget(dataNode, pointList)) {
      let hoverChillerWidgetLeft = document.createElement('div');
      if (dataNode.class == 'waterCooledChiller' && this.checkCondenserWidget(dataNode, pointList)) {
        hoverChillerWidgetLeft.classList.add("col-6");
      } else {
        hoverChillerWidgetLeft.classList.add("col-12");
      }

      hoverChillerWidgetLeft.classList.add("p-l-0");
      hoverChillerWidgetLeft.classList.add("p-r-28");
      hoverChillerContent.appendChild(hoverChillerWidgetLeft);
      hoverChillerWidgetLeft.appendChild(this.generateBulletChart(dataNode, pointList, 'evaporator', true, true));
    }

    if (dataNode.class == 'waterCooledChiller' && this.checkCondenserWidget(dataNode, pointList)) {
      let hoverChillerWidgetRight = document.createElement('div');
      hoverChillerWidgetRight.classList.add("col-6");
      hoverChillerWidgetRight.classList.add("p-l-28");
      hoverChillerWidgetRight.classList.add("p-r-0");
      hoverChillerContent.appendChild(hoverChillerWidgetRight);
      hoverChillerWidgetRight.appendChild(this.generateBulletChart(dataNode, pointList, 'condenser', true, true));
    }

    let combinedDataPoints = pointList;

    if (combinedDataPoints.length) {
      let setPointLimit = 8;
      let addedPoints = 1;

      let hoverChillerWidgetPoints = document.createElement('div');
      hoverChillerWidgetPoints.classList.add("row");
      // hoverCompresserContainer.appendChild(hoverChillerWidgetPoints);

      for (var i = 0; i < combinedDataPoints.length - 1; i++) {
        if ((combinedDataPoints[i].pointType == 'compressor') && addedPoints <= setPointLimit) {
          let hoverChillerWidgetPointsDiv = document.createElement('div');
          hoverChillerWidgetPointsDiv.classList.add("col-6");
          hoverChillerWidgetPointsDiv.classList.add("compressorPointStyle1");
          hoverChillerWidgetPoints.appendChild(hoverChillerWidgetPointsDiv);
          hoverChillerWidgetPointsDiv.appendChild(this.createChillerPoint(combinedDataPoints[i]));
          addedPoints++;
        }
      }

      for (var i = 0; i < combinedDataPoints.length - 1; i++) {
        if ((combinedDataPoints[i].pointType != 'compressor') && (combinedDataPoints[i].pointType == 'evaporator') && addedPoints <= setPointLimit) {
          let hoverChillerWidgetPointsDiv = document.createElement('div');
          hoverChillerWidgetPointsDiv.classList.add("col-6");
          hoverChillerWidgetPointsDiv.classList.add("compressorPointStyle1");
          // hoverChillerWidgetPointsDiv.classList.add("p-r-30");
          hoverChillerWidgetPoints.appendChild(hoverChillerWidgetPointsDiv);

          hoverChillerWidgetPointsDiv.appendChild(this.createChillerPoint(combinedDataPoints[i]));
          addedPoints++;
        }
      }

      for (var i = 0; i < combinedDataPoints.length - 1; i++) {
        if ((combinedDataPoints[i].pointType != 'compressor') && (combinedDataPoints[i].pointType != 'evaporator') && (combinedDataPoints[i].pointType == 'condenser') && addedPoints <= setPointLimit) {
          let hoverChillerWidgetPointsDiv = document.createElement('div');
          hoverChillerWidgetPointsDiv.classList.add("col-6");
          hoverChillerWidgetPointsDiv.classList.add("compressorPointStyle1");
          // hoverChillerWidgetPointsDiv.classList.add("p-r-30");
          hoverChillerWidgetPoints.appendChild(hoverChillerWidgetPointsDiv);

          hoverChillerWidgetPointsDiv.appendChild(this.createChillerPoint(combinedDataPoints[i]));
          addedPoints++;
        }
      }

      for (var i = 0; i < combinedDataPoints.length - 1; i++) {
        if ((combinedDataPoints[i].pointType != 'compressor') && (combinedDataPoints[i].pointType != 'evaporator') && (combinedDataPoints[i].pointType != 'condenser') && (combinedDataPoints[i].dis != 'Building Schedule') && addedPoints <= setPointLimit) {
          let hoverChillerWidgetPointsDiv = document.createElement('div');
          hoverChillerWidgetPointsDiv.classList.add("col-6");
          hoverChillerWidgetPointsDiv.classList.add("compressorPointStyle1");
          // hoverChillerWidgetPointsDiv.classList.add("p-l-30");
          hoverChillerWidgetPoints.appendChild(hoverChillerWidgetPointsDiv);

          hoverChillerWidgetPointsDiv.appendChild(this.createChillerPoint(combinedDataPoints[i]));
          addedPoints++;
        }
      }


      hoverCompresserContainer.appendChild(hoverChillerWidgetPoints);

      const hoverViewBottomStyle = document.createElement('div');
      hoverViewBottomStyle.classList.add('hoverViewBottomStyle');
      hoverCompresserContainer.appendChild(hoverViewBottomStyle);

      if (document.getElementById(`pointHeightId-${dataNode.equipId}`) != null) {
        document.getElementById(`hoverChiller-${dataNode.equipId}`).innerHTML = '';
        document.getElementById(`hoverChiller-${dataNode.equipId}`).appendChild(hoverCompresserContainer);
      }

      // const hoverViewBottomStyle = document.createElement('div');
      // hoverViewBottomStyle.classList.add('hoverViewBottomStyle');
      // hoverCompresserContainer.appendChild(hoverViewBottomStyle);
      // content.appendChild(hoverCompresserContainer);
    }
  }

  pumAndCTHover(dataNode, pointList) {
    if (typeof pointList != "undefined") {
      let hoverContainer = document.createElement('div');
      const hoverContainerScroll = document.createElement('div');
      hoverContainerScroll.classList.add("hoverCoolingTowerHeight");
      let hoverTitle = dataNode.class == 'coolingTowerFourFan' ? 'COOLING TOWER 4 FAN' : dataNode.class == 'coolingTowerTwoFan' ? 'COOLING TOWER 2 FAN' : 'COOLING TOWER';
      if (dataNode.class.includes('pump1Vertical')) {
        hoverTitle = 'PUMP VERTICAL'
      }
      const hoverHeader = document.createElement('div');
      hoverHeader.classList.add("hoverCoolingTowerTitle");
      hoverHeader.innerHTML = hoverTitle;
      hoverContainer.appendChild(hoverHeader);

      const vfd_speed = ['vfd', 'speed'];
      const vfd_speed_feedback = ['vfd', 'speed', 'sensor'];
      // Single fan Dataset for circular chart.
      let dataset = {};
      // 2 fan and 4 fan Dataset for circular chart.
      let dataSetObjOfArr = {};
      let minValObj = {};
      let maxValObj = {};
      let setPointLimit = 5;
      let addedPoints = 1;
      // Removing the below data points from the list.
      let hoverPoints = pointList;
      for (var i = 0; i < hoverPoints.length; i++) {
        let pointVal = "0";
        if (hoverPoints[i].data.length && hoverPoints[i].data[hoverPoints[i].data.length - 1].val) {
          pointVal = hoverPoints[i].data[hoverPoints[i].data.length - 1].val;
        }
        if (this.isPointListTagsAreSame(vfd_speed_feedback, hoverPoints[i].tags)) {
          const minNumber = hoverPoints[i].minVal;
          const maxNumber = hoverPoints[i].maxVal;
          const unit = hoverPoints?.[i]?.unit;
          dataset['VFD_Speed_Feedback'] = [{ value: parseInt(pointVal), color: '#FFB134', unit }, { value: maxNumber - parseInt(pointVal), color: '', unit }];
          if (dataNode.class.includes('coolingTowerFourFan') || dataNode.class.includes('coolingTowerTwoFan')) {
            minValObj['coolingTower' + hoverPoints[i].cell + 'VFDSpeedFeedback'] = minNumber;
            maxValObj['coolingTower' + hoverPoints[i].cell + 'VFDSpeedFeedback'] = maxNumber;
            dataSetObjOfArr['coolingTowerFan#' + hoverPoints[i].cell + 'VFDSpeedFeedback'] = dataset['VFD_Speed_Feedback'];
          } else {
            minValObj['VFDSpeedFeedback'] = minNumber;
            maxValObj['VFDSpeedFeedback'] = maxNumber;
          }
        } else if (this.isPointListTagsAreSame(vfd_speed, hoverPoints[i].tags)) {
          const minNumber = hoverPoints[i].minVal;
          const maxNumber = hoverPoints[i].maxVal;
          const unit = hoverPoints?.[i]?.unit;
          dataset['VFD_Speed'] = [{ value: parseInt(pointVal), color: '#FF5147', unit }, { value: maxNumber - parseInt(pointVal), color: '', unit }];
          if (dataNode.class.includes('coolingTowerFourFan') || dataNode.class.includes('coolingTowerTwoFan')) {
            minValObj['coolingTower' + hoverPoints[i].cell + 'VFDSpeed'] = minNumber;
            maxValObj['coolingTower' + hoverPoints[i].cell + 'VFDSpeed'] = maxNumber;
            dataSetObjOfArr['coolingTowerFan#' + hoverPoints[i].cell + 'VFDSpeed'] = dataset['VFD_Speed'];
          } else {
            minValObj['VFDSpeed'] = minNumber;
            maxValObj['VFDSpeed'] = maxNumber;
          }
        }
        if (!this.isPointListTagsAreSame(vfd_speed, hoverPoints[i].tags) && addedPoints <= setPointLimit) {
          hoverContainerScroll.appendChild(this.createChillerPoint(hoverPoints[i]));
          addedPoints++;
        }
      }
      let chartData = [[], []];
      let row = document.createElement("div");
      row.classList.add('row');
      hoverContainer.appendChild(row);
      let graphMainDiv = document.createElement("div");
      if (dataNode.class.includes('coolingTowerSingleFan') || dataNode.class.includes('pump1Vertical')) {
        hoverContainer.style.width = '950px';
        chartData = this.setCircularChartForCTSingleFan(dataset, minValObj, maxValObj);
        if ((dataset['VFD_Speed'] && dataset['VFD_Speed'].length) || (dataset['VFD_Speed_Feedback'] && dataset['VFD_Speed_Feedback'].length)) {
          graphMainDiv.classList.add("col-12");
          graphMainDiv.style.display = "flex";
          const unit = dataset['VFD_Speed']?.[0]?.unit || dataset['VFD_Speed_Feedback']?.[0]?.unit;
          row.appendChild(this.createHoverViewChartContent(dataNode.class, dataset, chartData, graphMainDiv, 0, unit));
        }
      } else if (dataNode.class.includes('coolingTowerFourFan') || dataNode.class.includes('coolingTowerTwoFan')) {
        const count = dataNode.class.includes('coolingTowerTwoFan') ? 2 : 4;
        let setWidth = 0;
        for (let i = 1; i <= count; i++) {
          const vfd_speed = dataSetObjOfArr[`Cooling_Tower_Fan_${i}_VFD_Speed`];
          const vfd_speed_feedback = dataSetObjOfArr[`Cooling_Tower_Fan_${i}_VFD_Speed_Feedback`];
          if ((vfd_speed && vfd_speed.length) || (vfd_speed_feedback && vfd_speed_feedback.length)) {
            setWidth++;
          }
        }
        // Setting container conditionally for 2 fan and 4 fan based on points.
        let colClass = 'col-6';
        if (setWidth > 1) {
          hoverContainer.style.width = '1350px';
        } else {
          colClass = 'col-12';
          hoverContainer.style.width = '950px';
        }
        this.setCircularChartForCTTwoAndFourFan(dataNode.class, dataSetObjOfArr, count, row, colClass, minValObj, maxValObj);
      }
      hoverContainer.classList.add("row");
      hoverContainer.appendChild(hoverContainerScroll);
      const hoverViewBottomStyle = document.createElement('div');
      hoverViewBottomStyle.classList.add('hoverViewBottomStyle');
      hoverContainer.appendChild(hoverViewBottomStyle);
      if (document.getElementById(`${dataNode.class}-${dataNode.class.includes("pump1Vertical") ? dataNode.childData.equipData.subEquips.filter(x => x.equip[0] == 'pump1Vertical')[0].equipId : dataNode.equipId}`) != null) {
        document.getElementById(`${dataNode.class}-${dataNode.class.includes("pump1Vertical") ? dataNode.childData.equipData.subEquips.filter(x => x.equip[0] == 'pump1Vertical')[0].equipId : dataNode.equipId}`).innerHTML = hoverContainer.innerHTML;
      }
    }
  }

  removeAlertsLoader(nodeData) {
    let equipId = nodeData.class.includes("pump1Vertical") ? nodeData.childData.equipData.subEquips.filter(x => x.equip[0] == 'pump1Vertical')[0].equipId : nodeData.equipId
    let element = document.getElementById(`loaderAlerts-${equipId}`);
    if (element) {
      element.remove();
    }
  }

  getAlertsHoverEquipStatus(nodeData, alertsMainContainer, noAlerts = false) {
    let getPointByQuery;
    if (this.loginUserTypeData.isPublic) {
      let queryString = this.getTagsByEquipClass(nodeData.class);
      queryString += ` and equipRef==@${this.getEquipIdForMasterView(nodeData)}`;
      getPointByQuery = this.apiService.getSharedAlertStatusONOrOFF(this.loginUserTypeData.linkShareUuid, queryString).pipe(catchError(err => of(err)), map(this.cpmService.stripHaystackTypeMapping));
    } else {
      getPointByQuery = this.getEquipPointByQuery(nodeData).pipe(catchError(err => of(err)), map(this.cpmService.stripHaystackTypeMapping))
    }
    getPointByQuery.subscribe(_pointData => {
      if (this.loginUserTypeData.isPublic) {
        const pointData = _pointData?.[0];
        let ids = pointData?.rows?.length ? pointData?.rows?.map(_pt => _pt.id) : [];
        let pointHasWritableTag = pointData?.rows?.length && pointData?.rows?.[0]?.hasOwnProperty('writable');
        let pontHasHisAndNotWritableTag = pointData?.rows?.length && pointData?.rows?.[0]?.hasOwnProperty('his') && !pointData?.rows?.[0]?.hasOwnProperty('writable');
        this.setAlertsHoverEquipStatusForSharedPlant(pointData, ids, pointHasWritableTag, pontHasHisAndNotWritableTag, nodeData, alertsMainContainer, noAlerts);
      } else {
        const pointData = _pointData;
        let ids = pointData?.rows?.length ? pointData?.rows?.map(_pt => _pt.id) : [];
        let pointHasWritableTag = pointData?.rows?.length && pointData?.rows?.[0]?.hasOwnProperty('writable');
        let pontHasHisAndNotWritableTag = pointData?.rows?.length && pointData?.rows?.[0]?.hasOwnProperty('his') && !pointData?.rows?.[0]?.hasOwnProperty('writable');
        this.setAlertsHoverEquipStatusForPlant(pointHasWritableTag, pontHasHisAndNotWritableTag, ids, nodeData, alertsMainContainer, noAlerts);
      }
    });
  }

  setAlertsHoverEquipStatusForSharedPlant(pointData, ids, pointHasWritableTag, pontHasHisAndNotWritableTag, nodeData, alertsMainContainer, noAlerts) {
    if (pointData?.pointId) {
      ids = [pointData?.pointId];
    }
    // Setting pointHasWritableTag to true only if "writable" tag is present.
    pointHasWritableTag = pointData?.tags?.includes('writable');
    // Setting pointHasWritableTag to true only if "his" tag is present and not "writable" tag.
    pontHasHisAndNotWritableTag = pointData?.tags?.includes('his') && !pointData?.tags?.includes('writable');
    if (pointHasWritableTag) {
      this.apiService.getSharedBulkWritablePointData(ids, this.loginUserTypeData.linkShareUuid).pipe(map(this.cpmService.stripHaystackTypeMapping)).subscribe(_res => {
        let alertData = 'OFF';
        if (_res.rows.length) {
          _res.rows.forEach(_row => {
            if (_row.data?.length && _row.data[0].val == '1') {
              alertData = 'ON';
            }
          });
        }
        // setting "status" property to "ON" or "OFF" based on value.
        nodeData['status'] = alertData;
        // removing the alerts loader from the DOM.
        this.removeAlertsLoader(nodeData);
        // Creating the alerts popup with updated value.
        this.alertsPopUp(nodeData, alertsMainContainer, noAlerts);
      });
    } else if (pontHasHisAndNotWritableTag) {
      this.apiService.getSharedCurrenthisReadMany(ids, this.loginUserTypeData.linkShareUuid).pipe(map(this.cpmService.stripHaystackTypeMapping)).subscribe(_res => {
        let alertData = 'OFF';
        if (_res.rows.length) {
          _res.rows.forEach(_row => {
            if (_row.data?.length && _row.data[0].val == '1') {
              alertData = 'ON';
            }
          });
        }
        // setting "status" property to "ON" or "OFF" based on value.
        nodeData['status'] = alertData;
        // removing the alerts loader from the DOM.
        this.removeAlertsLoader(nodeData);
        // Creating the alerts popup with updated value.
        this.alertsPopUp(nodeData, alertsMainContainer, noAlerts);
      });
    }
  }

  setAlertsHoverEquipStatusForPlant(pointHasWritableTag, pontHasHisAndNotWritableTag, ids, nodeData, alertsMainContainer, noAlerts) {
    if (pointHasWritableTag) {
      this.cpmService.getBulkWritablePointData(ids).pipe(map(this.cpmService.stripHaystackTypeMapping)).subscribe(_res => {
        let alertData = 'OFF';
        if (_res.rows.length) {
          _res.rows.forEach(_row => {
            if (_row.data?.length && _row.data[0].val == '1') {
              alertData = 'ON';
            }
          });
        }
        nodeData['status'] = alertData
        this.removeAlertsLoader(nodeData);
        this.alertsPopUp(nodeData, alertsMainContainer, noAlerts);
      });
    } else if (pontHasHisAndNotWritableTag) {
      this.cpmService.getCurrenthisReadMany(ids).pipe(map(this.cpmService.stripHaystackTypeMapping)).subscribe(_res => {
        let alertData = 'OFF';
        if (_res.rows.length) {
          _res.rows.forEach(_row => {
            if (_row.data?.length && _row.data[0].val == '1') {
              alertData = 'ON';
            }
          });
        }
        nodeData['status'] = alertData
        this.removeAlertsLoader(nodeData);
        this.alertsPopUp(nodeData, alertsMainContainer, noAlerts);
      });
    }
  }

  alertsPopUp(dataNode, alertsMainContainer, noAlerts = false) {
    let equipId = dataNode.class.includes("pump1Vertical") ? dataNode.childData.equipData.subEquips.filter(x => x.equip[0] == 'pump1Vertical')[0].equipId : dataNode.equipId
    let alertsContainerDiv = document.querySelector('.alerts-container');
    if (!alertsContainerDiv) {
      return;
    }
    let alertsHeadingDiv = alertsContainerDiv.querySelector('.alerts-heading');
    if (alertsHeadingDiv) {
      return;
    }
    let alertsContainer = alertsMainContainer.firstChild;
    if (!alertsContainer) {
      return;
    }
    const hoverEquipStatusElement = document.getElementById(`hoverEquipStatus-${equipId}`);

    if (hoverEquipStatusElement) {
      if (dataNode['status'] == 'ON') {
        hoverEquipStatusElement.classList.add("alerts-status");
      } else {
        hoverEquipStatusElement.classList.add("alerts-status-off");
      }
      hoverEquipStatusElement.innerHTML = dataNode['status']
    }

    const alertsHeading = document.createElement('div');
    alertsHeading.classList.add("alerts-heading");
    alertsHeading.innerHTML = `ALERTS(${dataNode.data.alert.length})`;

    alertsContainer.appendChild(alertsHeading);

    var alertContent = document.createElement("div");
    alertContent.classList.add("alerts-content");
    for (var i = 0; i < dataNode.data.alert.length; i++) {
      var alertDiv = document.createElement("div");
      alertDiv.classList.add('d-flex');
      alertDiv.classList.add('alertDiv');

      const alertName = document.createElement("div");
      alertName.classList.add('mr-auto');
      if (dataNode.data.alert[i].mSeverity == 'MODERATE') {
        alertName.classList.add('alert-moderate');
      } else if (dataNode.data.alert[i].mSeverity == 'SEVERE') {
        alertName.classList.add('alert-high');
      } else {
        alertName.classList.add('alert-low');
      }

      alertName.innerHTML = dataNode.data.alert[i].mTitle;

      var timeGenerated;
      timeGenerated = document.createElement("div");
      timeGenerated.classList.add('alert-time-generated');
      const date = new Date(dataNode.data.alert[i].startTime);
      const months = [
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'
      ];

      const month = months[date.getMonth()];
      const day = date.getDate();
      const year = date.getFullYear();
      const hours = date.getHours();
      const minutes = date.getMinutes();

      const period = hours >= 12 ? 'PM' : 'AM';
      const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
      const formattedMinutes = minutes.toString().padStart(2, '0');

      const formattedDate = `${month} ${day}, ${year} | ${formattedHours}:${formattedMinutes} ${period}`;
      timeGenerated.innerHTML = formattedDate;

      alertDiv.appendChild(alertName);
      alertDiv.appendChild(timeGenerated);
      alertContent.appendChild(alertDiv);

      var alertDescription = document.createElement("div");
      alertDescription.classList.add('d-flex');
      alertDescription.classList.add('alertDescription');
      alertDescription.innerHTML = dataNode.data.alert[i].mMessage;

      alertContent.appendChild(alertDescription);

    }
    if (noAlerts) {
      var alertDescription = document.createElement("div");
      alertDescription.classList.add('d-flex');
      alertDescription.classList.add('alertDescription');
      alertDescription.innerHTML = "No Alerts Configured";

      alertContent.appendChild(alertDescription);
    }
    alertsContainer.appendChild(alertContent)
  }

  setCircularChartForCTSingleFan(dataset, minValObj, maxValObj) {
    if ((dataset['VFD_Speed'] && dataset['VFD_Speed'].length) && (dataset['VFD_Speed_Feedback'] && dataset['VFD_Speed_Feedback'].length)) {
      return [dataset['VFD_Speed'], dataset['VFD_Speed_Feedback']];
    } else if ((dataset['VFD_Speed'] && dataset['VFD_Speed'].length) && !(dataset['VFD_Speed_Feedback'] && dataset['VFD_Speed_Feedback'].length)) {
      return [
        dataset['VFD_Speed'],
        [{
          'value': maxValObj['VFDSpeedFeedback'] ? maxValObj['VFDSpeedFeedback'] : maxValObj['VFDSpeed'],
          'color': '#333333'
        }]
      ]
    } else if (!(dataset['VFD_Speed'] && dataset['VFD_Speed'].length) && (dataset['VFD_Speed_Feedback'] && dataset['VFD_Speed_Feedback'].length)) {
      return [
        [{
          'value': maxValObj['VFDSpeed'] ? maxValObj['VFDSpeed'] : maxValObj['VFDSpeedFeedback'],
          'color': '#333333'
        }],
        dataset['VFD_Speed_Feedback']
      ]
    } else {
      return [[], []];
    }
  }


  setCircularChartForCTTwoAndFourFan(dataNodeClass, dataset, arr, row, className, minVal?, maxVal?) {
    for (let i = 1; i <= arr; i++) {
      let minValObjVFDSpeed = minVal[`coolingTower${i}VFDSpeed`];
      let maxValObjVFDSpeed = maxVal[`coolingTower${i}VFDSpeed`];
      let minValObjVFDSpeedFeedback = minVal[`coolingTower${i}VFDSpeedFeedback`];
      let maxValObjVFDSpeedFeedback = maxVal[`coolingTower${i}VFDSpeedFeedback`];

      minValObjVFDSpeed = minValObjVFDSpeed ? minValObjVFDSpeed : (minValObjVFDSpeedFeedback ? minValObjVFDSpeedFeedback : 0);
      maxValObjVFDSpeed = maxValObjVFDSpeed ? maxValObjVFDSpeed : (maxValObjVFDSpeedFeedback ? maxValObjVFDSpeedFeedback : 100);
      minValObjVFDSpeedFeedback = minValObjVFDSpeedFeedback ? minValObjVFDSpeedFeedback : (minValObjVFDSpeed ? minValObjVFDSpeed : 0);
      maxValObjVFDSpeedFeedback = maxValObjVFDSpeedFeedback ? maxValObjVFDSpeedFeedback : (maxValObjVFDSpeed ? maxValObjVFDSpeed : 100);
      const vfd_speed = dataset[`coolingTowerFan#${i}VFDSpeed`];
      const vfd_speed_feedback = dataset[`coolingTowerFan#${i}VFDSpeedFeedback`];
      const unit = vfd_speed?.[0]?.unit || vfd_speed_feedback?.[0]?.unit;
      let chartData = [[], []];
      let dataSetObj = { VFD_Speed: [], VFD_Speed_Feedback: [] };
      if ((vfd_speed && vfd_speed.length) && (vfd_speed_feedback && vfd_speed_feedback.length)) {
        chartData = [vfd_speed, vfd_speed_feedback];
        dataSetObj = { VFD_Speed: vfd_speed, VFD_Speed_Feedback: vfd_speed_feedback };
      } else if ((vfd_speed && vfd_speed.length) && !(vfd_speed_feedback && vfd_speed_feedback.length)) {
        chartData = [
          vfd_speed,
          [{
            'value': maxValObjVFDSpeedFeedback,
            'color': '#333333'
          }, {
            'value': minValObjVFDSpeedFeedback,
            'color': ''
          }]
        ];
        dataSetObj = {
          VFD_Speed: vfd_speed, VFD_Speed_Feedback: [{
            'value': minValObjVFDSpeedFeedback,
            'color': '#333333'
          }, {
            'value': maxValObjVFDSpeedFeedback,
            'color': ''
          }]
        };
      } else if (!(vfd_speed && vfd_speed.length) && (vfd_speed_feedback && vfd_speed_feedback.length)) {
        chartData = [
          [{
            'value': minValObjVFDSpeed,
            'color': '#333333'
          }, {
            'value': maxValObjVFDSpeed,
            'color': ''
          }],
          vfd_speed_feedback,
        ];
        dataSetObj = {
          VFD_Speed: [{
            'value': minValObjVFDSpeed,
            'color': '#333333'
          }, {
            'value': maxValObjVFDSpeed,
            'color': ''
          }], VFD_Speed_Feedback: vfd_speed_feedback
        };
      } else {
        chartData = [[], []];
      }
      if (chartData && chartData.length && ((dataSetObj.VFD_Speed && dataSetObj.VFD_Speed.length) || (dataSetObj.VFD_Speed_Feedback && dataSetObj.VFD_Speed_Feedback.length))) {
        let graphMainDiv = document.createElement("div");
        graphMainDiv.classList.add(className);
        graphMainDiv.style.display = 'flex';
        row.appendChild(this.createHoverViewChartContent(dataNodeClass, dataSetObj, chartData, graphMainDiv, i, unit));
      }
    }
  }

  createHoverViewChartContent(dataNodeClass, dataset, chartData, graphMainDiv, index = 0, unit) {
    let graphDiv = this.generateCircularChart(chartData, unit);
    graphMainDiv.appendChild(graphDiv);
    const graphContent = document.createElement('div');
    graphContent.setAttribute('id', 'graphHeaderText');
    if (index) {
      const headingText = document.createElement('div');
      headingText.classList.add('chartSubText');
      headingText.innerHTML = `FAN ${index}`;
      graphContent.appendChild(headingText);
    }
    if (dataNodeClass == 'coolingTowerSingleFan' || dataNodeClass == 'pump1Vertical') {
      graphContent.classList.add('graphPointHeader');
    } else {
      graphContent.classList.add('graphPointHeaderCTSingle_WaterPump');
    }
    const graphVal = document.createElement('div');
    if ((dataset['VFD_Speed'] && dataset['VFD_Speed'].length) && (dataset['VFD_Speed_Feedback'] && dataset['VFD_Speed_Feedback'].length)) {
      const speedValue = Number(dataset['VFD_Speed'][0]['value']) ? Number(dataset['VFD_Speed'][0]['value']) : 'NA';
      const feedBackValue = Number(dataset['VFD_Speed_Feedback'][0]['value']) ? Number(dataset['VFD_Speed_Feedback'][0]['value']) : 'NA';

      graphVal.innerHTML += `<span class="graphPointValText inner-chart-color">${speedValue} ${speedValue == 'NA' ? '' : dataset['VFD_Speed']?.[0]?.['unit']}</span>`;
      graphVal.innerHTML += `<span class="borderBtwChartValue"></span>`;
      graphVal.innerHTML += `<span class="graphPointValText outer-chart-color">${feedBackValue} ${feedBackValue == 'NA' ? '' : dataset['VFD_Speed_Feedback']?.[0]?.['unit']}</span>`;
      graphContent.innerHTML += 'VFD SPEED | FEEDBACK';
    } else if (dataset['VFD_Speed'] && dataset['VFD_Speed'].length) {
      const value = Number(dataset['VFD_Speed'][0]['value']) ? Number(dataset['VFD_Speed'][0]['value']) : 'NA';
      graphVal.innerHTML += `<span class="graphPointValText inner-chart-color">${value} ${value == 'NA' ? '' : dataset['VFD_Speed']?.[0]?.['unit']}</span>`;
      graphContent.innerHTML += 'VFD SPEED';
    } else if (dataset['VFD_Speed_Feedback'] && dataset['VFD_Speed_Feedback'].length) {
      const value = Number(dataset['VFD_Speed_Feedback'][0]['value']) ? Number(dataset['VFD_Speed_Feedback'][0]['value']) : 'NA';
      graphVal.innerHTML += `<span class="graphPointValText outer-chart-color">${value} ${value == 'NA' ? '' : dataset['VFD_Speed_Feedback']?.[0]?.['unit']}</span>`;
      graphContent.innerHTML += 'VFD FEEDBACK';

    }

    graphContent.appendChild(graphVal);
    graphMainDiv.appendChild(graphContent);

    return graphMainDiv;
  }

  generateCircularChart(dataset, unit) {
    const graphDiv = document.createElement("div");
    graphDiv.setAttribute("id", "circularGraph");
    graphDiv.setAttribute("class", "circularChart");
    const width = 240;
    const height = 300;
    var pie = d3.pie().sort(null);
    var arc = d3.arc();
    const svgElement = d3.select(graphDiv)
      .append('svg')
      .attr("width", width)
      .attr("height", height)
      .append("g")
      .attr("transform", "translate(" + width / 2 + "," + height / 2 + ")");
    // Shadow effect for arc

    var defs = svgElement.append("defs");

    var filter2 = defs.append("filter")
      .attr("id", "glow2");

    filter2.append("feColorMatrix")
      .attr("type", "matrix")
      .attr("values", "0 0 0 0 0  0 0 0 0 0  0 0 0 0 0  0 0 0 0 0");

    filter2.append("feGaussianBlur")
      .attr("stdDeviation", 5)
      .attr("result", "coloredBlur");

    var feMerge2 = filter2.append("feMerge");

    feMerge2.append("feMergeNode")
      .attr("in", "coloredBlur")
    feMerge2.append("feMergeNode")
      .attr("in", "SourceGraphic");


    var filter = defs.append("filter")
      .attr("id", "glow");

    filter.append("feColorMatrix")
      .attr("type", "matrix")
      .attr("values", "1.00 0 0 0 0  0 0.69 0 0 0  0 0 0.20 0 0  0 0 0 1 0");

    filter.append("feGaussianBlur")
      .attr("stdDeviation", 10.5)
      .attr("result", "coloredBlur");

    var feMerge = filter.append("feMerge");

    feMerge.append("feMergeNode")
      .attr("in", "coloredBlur")
    feMerge.append("feMergeNode")
      .attr("in", "SourceGraphic");

    var filter1 = defs.append("filter")
      .attr("id", "glow1")

    filter1.append("feColorMatrix")
      .attr("type", "matrix")
      .attr("values", "1.00 0 0 0 0  0 0.32 0 0 0  0 0 0.28 0 0  0 0 0 1 0");

    filter1.append("feGaussianBlur")
      .attr("stdDeviation", 10.5)
      .attr("result", "coloredBlur");

    var feMerge1 = filter1.append("feMerge");

    feMerge1.append("feMergeNode")
      .attr("in", "coloredBlur")
    feMerge1.append("feMergeNode")
      .attr("in", "SourceGraphic");
    // Append Max Value 
    svgElement.append("text")
      .text(`0 ${unit}`)
      .attr("x", 25)
      .attr("y", -110)
      .attr("font-size", '29px')
      .attr("fill", "#999999");
    // Append Min Value 
    svgElement.append("text")
      .text(`100 ${unit}`)
      .attr("x", -90)
      .attr("y", -110)
      .attr("font-size", '29px')
      .attr("fill", "#999999");
    svgElement.append("line")
      .attr("transform", "translate(40,10)")
      .attr("x1", -40)
      .attr("y1", -140)
      .attr("x2", -40)
      .attr("y2", -10)
      .attr("stroke-width", 2)
      .attr("stroke", "#666666");

    var g = svgElement.selectAll("g")
      .data(function () {
        var values = dataset;
        return values.map(val => val.map(_val => _val.value));
      }).enter().append("g");
    g.selectAll("path")
      .data(function (d, i) {
        return pie(d).map(function (item) {
          const data = dataset[i][0];
          const color = data.color;
          return { data: item, parentIndex: i, color: color };
        });
      }).enter().append("path")
      .style("filter", function (d, i) {
        let glow;
        if (d.color != '#333333') {
          glow = (d.color == '#FFB134') ? "url(#glow)" : "url(#glow1)";
        } else {
          glow = "url(#glow2)";
        }
        if (i == 1) {
          glow = "url(#glow2)"
        }
        return glow;
      })
      .attr("fill", function (d, i) {
        let chartColor = d.color;
        let color = '#333333';
        if (i == 0) {
          color = chartColor;
        }
        return color;
      }).attr("d", function (d, i) {
        return arc.innerRadius(function () {
          var val;
          if (d.color == "#333333") {
            val = 40;
          } else {
            val = i == 0 ? 35 : 40;
          }
          return val + 35 * d.parentIndex;
        }).outerRadius(22 + 35 * (d.parentIndex + 1))(d.data);
      });
    return graphDiv;
  }



  private initDrawFlow(htmlElement: HTMLElement): void {
    var self = this;
    this.editor = new Drawflow(htmlElement);
    this.editor.reroute = true;
    this.editor.reroute_fix_curvature = true;
    this.editor.force_first_input = true;
    this.equipConfiguration = this.editor.equipConfiguration;

    // Path pipe bend  
    this.editor.createCurvature = function (start_pos_x: any, start_pos_y: any, end_pos_x: any, end_pos_y: any, curvature_value: any, type: 'slope', calculateIntersectionPoint) {
      let calculatedLineIntersection;
      calculatedLineIntersection = self.calculateIntersectionPoint(end_pos_x, end_pos_y, end_pos_x + 1000, end_pos_y + 2000 * Math.sin(15 * Math.PI / 180), start_pos_x, start_pos_y, start_pos_x - 1000, start_pos_y + 2000 * Math.sin(15 * Math.PI / 180));
      // if(start_pos_y < end_pos_y){
      // calculatedLineIntersection = self.calculateIntersectionPoint(start_pos_x, start_pos_y, start_pos_x+1000, start_pos_y + 2000 * Math.sin(15*Math.PI/180), end_pos_x, end_pos_y, end_pos_x-1000, end_pos_y + 2000 * Math.sin(14*Math.PI/180));
      // }
      return ' M ' + start_pos_x + ' ' + start_pos_y + ' L ' + calculatedLineIntersection.x + ' ' + calculatedLineIntersection.y + ' L ' + end_pos_x + ' ' + end_pos_y;
    }

    this.editor.start();
    // const dataToImport = //
    // uncomment code for importing data for initial rendering
    // this.editor.import(dataToImport);
    //this.drawCustomPaths();
  }

  isBuildingSelectedChange(event) {
    this.nodes = [];
    this.duplicateNodesArr = [];
    if (event == 'noCanvas') {
      document.getElementById("canvas").style.display = "none";
      return
    }
    this.editor.drawflow.drawflow.Home.data = {};
    this.selectedBuilding = event.buildingData;
    this.isBuildingSelected = this.selectedBuilding.length > 0 ? true : false;
    if (this.isBuildingSelected) {
      localStorage.removeItem(StorageKeys.TEMPPLANTDATA);
      if (event.plantData.isPublished) {
        localStorage.setItem(StorageKeys.TEMPPLANTDATA, JSON.stringify(event.plantData.plantJsonStr));
      }
      document.getElementById("canvas").style.display = "";
      document.getElementById("loader").style.display = "block";
      this.selectedPlantData = event.plantData;
      this.siteName = event.plantData.siteName;
      if (event.isNewPlant) {
        this.selectedTabIndex = 1;
        this.showBtnData.showEditPlantBtn = false;
        this.disableMatTab = false;
        this.hidePanels = true;
        this.isPublished = true;
        this.hideEquipBar = false;
        this.showTopBar = true;
        this.toggleEquipBar();
        this.editor.editor_mode = 'edit';
      } else {
        this.plantBuilderView('My Plant', false)
        this.showBtnData.showEditPlantBtn = true;
        if (event.plantData.status == 'PUBLISHED') {
          this.showBtnData.showExportBtn = true;
        }
        this.disableMatTab = true
        this.hidePanels = false;
        this.isPublished = false;
        this.showTopBar = true;
      }
      this.loadCanvasEquipData(event.plantData, false);
      this.showDiscardPopUp = false;
      this.editor.zoom_refresh();
    } else {
      document.getElementById("loader").style.display = "";
      document.getElementById("canvas").style.display = "none";
    }

  }

  sharedPlantData(plantDataResp) {
    // this.userPreferenceData.heatmapPreferences.cpmMasterViewPreference = 'valueView';
    this.selectedPlantData = plantDataResp;
    this.siteName = plantDataResp.siteName;
    document.getElementById("noPlantSelected").style.display = "none";
    document.getElementById("loader").style.display = "none";
    document.getElementById("canvas").style.display = "";
    this.plantBuilderView('My Plant', false)
    this.showBtnData.showEditPlantBtn = true;
    this.showBtnData.showExportBtn = true;
    this.disableMatTab = true
    this.hidePanels = false;
    this.isPublished = false;
    this.showTopBar = true;

    this.loadCanvasEquipData(plantDataResp, false);
    this.showDiscardPopUp = false;
    this.editor.zoom_refresh();
    this.showInfoBar = true;
    this.plantChangedVal = this.selectedPlantData.chillerPlantId;
  }


  getEquipIdForMasterView(equipData) {
    if (this.isBtuOrPump(equipData.class)) {
      return equipData.childData.equipData.subEquips[0].equipId;
    } else {
      return equipData.pointId != "" ? equipData.pointId : equipData.equipId;
    }
  }

  isBtuOrPump(equipName) {
    return this.equipConfiguration.btuandPumpList.indexOf(equipName) != -1;
  }

  isPump(equipName) {
    return this.equipConfiguration.pumpList.indexOf(equipName) != -1;
  }

  filterEquipListWhichAlreadyPresentOnCanvas(onShowEquipmentsClick) {
    let selectedPlantData = this.selectedPlantData.plantJsonStr;
    if (onShowEquipmentsClick) {
      selectedPlantData = this.editor.drawflow;
    }
    const self = this;
    let equipInCanvas = [];
    // Filtering out the equips which are not in canvas and push to equipInCanvas array.
    Object.keys(selectedPlantData?.drawflow?.Home?.data).forEach(function (key) {
      const equipData = selectedPlantData.drawflow.Home.data[key];
      if (selectedPlantData.drawflow.Home.data[key].childData.class.includes('pump1Vertical') || selectedPlantData.drawflow.Home.data[key].childData.class.includes('btuMeter')) {
        equipInCanvas.push({ key, class: equipData.childData.class, type: selectedPlantData.drawflow.Home.data[key].equipType, equipId: selectedPlantData.drawflow.Home.data[key].childData?.subEquips[0]?.equipId });
      } else {
        if (self.isSensorNode(equipData.class)) {
          equipInCanvas.push({ key, class: equipData.childData.class, type: selectedPlantData.drawflow.Home.data[key].equipType, equipId: selectedPlantData.drawflow.Home.data[key].childData.equipId, subEquipPipeRef: selectedPlantData.drawflow.Home.data[key].groupPipeId, equipPointId: selectedPlantData.drawflow.Home.data[key].pointId });
        } else {
          equipInCanvas.push({ key, class: equipData.childData.class, type: selectedPlantData.drawflow.Home.data[key].equipType, equipId: selectedPlantData.drawflow.Home.data[key].childData.equipId });
        }
      }
    });
    if (self.nodes?.length) {
      // Removing duplicate equips from the nodes array.
      self.nodes = self.removeDuplicatesInArrayOfObject(self.nodes, 'equipId');
      self.CheckEquipsOrSubEquipsDeleted(equipInCanvas)
      self.nodes = self.nodes.filter((node) => !equipInCanvas.map(_data => _data.equipId).includes(node.equipId));
      // Import the modified plantJsondata to drawflow.min.js...
      if (!onShowEquipmentsClick) self.editor.import(self.selectedPlantData.plantJsonStr);
      this.hideEquipListLoader = true;
    } else {
      this.setWarningIconToAllTheEquipsIfEquipListIsEmpty(equipInCanvas);
      self.editor.import(self.selectedPlantData.plantJsonStr);
      this.hideEquipListLoader = true;
    }
  }

  getAlertRingClass(status) {
    switch (status) {
      case "equipon":
        return "equipOnStatus";
      case "low":
        return "alertLow";
      case "moderate":
        return "alertModerate";
      case "severe":
        return "alertSevere";
      default:
        return "equipOffStatus";
    }
  }

  setAlertRingStatusForEquip(equipKey, alert) {
    const alertStatus = ['equipOnStatus', 'alertLow', 'alertModerate', 'alertSevere', 'equipOffStatus'];
    const ele = document.getElementById(`node-${equipKey}`);
    let found = '';
    switch (alert) {
      case 'INTERNAL_SEVERE':
        alert = 'severe';
        break;
      case 'INTERNAL_INFO':
        alert = 'moderate';
        break;
      case 'INTERNAL_LOW':
        alert = 'low';
        break;
    }
    if (ele != null) {
      ele.querySelector('.drawflow_content_node').classList.forEach(_element => {
        if (alertStatus.find(_alert => _alert.indexOf(_element) > -1)) {
          found = _element;
        }
      });
    }
    if (found) {
      ele.querySelector('.drawflow_content_node').classList.replace(found, this.getAlertRingClass(alert.toLowerCase()));
    }
  }

  getEquipPointByQuery(equipData) {
    let queryString = this.getTagsByEquipClass(equipData?.class);
    queryString += ` and equipRef==@${this.getEquipIdForMasterView(equipData)}`;
    return this.cpmService.findByQuery(queryString);
  }

  getTagsByEquipClass(classVal) {
    let buildQuery = 'run and status and sensor';
    switch (classVal) {
      case 'waterCooledChiller': case 'airCooledChiller':
        buildQuery += ' and chiller';
        break;
      case classVal.includes('pump1Vertical'):
        buildQuery += ' and pump';
        break;
      case 'coolingTowerSingleFan': case 'coolingTowerTwoFan': case 'coolingTowerFourFan':
        buildQuery += ' and coolingTower and fan';
        break;
      case 'steamBoiler': case 'condenserBoiler':
        buildQuery += ' and boiler';
        break;
    }
    return buildQuery;
  }

  /**
   * Sets the alert ring status for equipment.
   * 
   * @param equipData - The equipment data.
   * @param equipId - The equipment ID.
   * @param equipKey - The equipment key.
   */
  setAlertRingStatusForEquips(equipData, equipId) {
    let equipKey = equipData.id;
    let included = ["waterCooledChiller", "airCooledChiller", "pump1Vertical", "pump1Vertical_backward", "pump1Vertical_forward", "coolingTowerSingleFan", "coolingTowerTwoFan", "coolingTowerFourFan", "steamBoiler", "condenserBoiler"]
    if (included.indexOf(equipData.class) > -1) {
      const status = "PUBLISHED";
      let alertStatus: any;
      let equipStatusOnOFF: any;

      if (this.loginUserTypeData.isPublic) {
        let queryString = this.getTagsByEquipClass(equipData.class);
        queryString += ` and equipRef==@${this.getEquipIdForMasterView(equipData)}`;
        equipStatusOnOFF = this.apiService.getSharedAlertStatusONOrOFF(this.loginUserTypeData.linkShareUuid, queryString).pipe(catchError(err => of(err)), map(this.cpmService.stripHaystackTypeMapping));
        alertStatus = this.apiService.getSharedAlertStatus(this.loginUserTypeData.linkShareUuid, equipId).pipe(catchError(err => of(err)), map(this.cpmService.stripHaystackTypeMapping));
      } else {
        equipStatusOnOFF = this.getEquipPointByQuery(equipData).pipe(catchError(err => of(err)), map(this.cpmService.stripHaystackTypeMapping));
        alertStatus = this.apiService.getEquipAlertStatus(this.selectedPlantData.chillerPlantId, equipId, status).pipe(catchError(err => of(err)), map(this.cpmService.stripHaystackTypeMapping));
      }

      forkJoin([alertStatus, equipStatusOnOFF]).subscribe((alertnEquipStatusRes: any) => {
        let isAlertRingSet: boolean = false;
        if (alertnEquipStatusRes[0] != '' && alertnEquipStatusRes[0] && Object.keys(alertnEquipStatusRes[0]).length && Object.keys(alertnEquipStatusRes[0].severity)?.length) {
          let alert = _.max(Object.keys(alertnEquipStatusRes[0].severity));
          this.setAlertRingStatusForEquip(equipKey, alert);
          isAlertRingSet = true;
        }

        // Get Equipment Status ON/OFF

        let pointData: any = alertnEquipStatusRes[1];
        let ids = pointData?.rows?.length ? pointData?.rows?.map(_pt => _pt.id) : [];
        let pointHasWritableTag = pointData?.rows?.length && pointData?.rows?.[0]?.hasOwnProperty('writable');
        let pontHasHisAndNotWritableTag = pointData?.rows?.length && pointData?.rows?.[0]?.hasOwnProperty('his') && !pointData?.rows?.[0]?.hasOwnProperty('writable');


        if (this.loginUserTypeData.isPublic) {
          pointData = alertnEquipStatusRes[1][0];
          if (pointData?.pointId) {
            ids = [pointData?.pointId];
          }
          pointHasWritableTag = pointData?.tags?.includes('writable');
          pontHasHisAndNotWritableTag = pointData?.tags?.includes('his') && !pointData?.tags?.includes('writable');
          if (pointHasWritableTag) {
            this.apiService.getSharedBulkWritablePointData(ids, this.loginUserTypeData.linkShareUuid).pipe(map(this.cpmService.stripHaystackTypeMapping)).subscribe(_res => {
              let equipStatus = 'equipoff';
              if (_res.rows.length) {
                _res.rows.forEach(_row => {
                  if (_row.data?.length && _row.data[0].val == '1') {
                    equipStatus = 'equipOn';
                  }
                });
              }
              this.editor.drawflow.drawflow.Home.data[equipKey].equipStatus = equipStatus;
              this.updateEquipStatusImage(equipData, equipKey);  // Method to change the image of the equipment based on the status of the equipment
              if (!isAlertRingSet) {
                this.setAlertRingStatusForEquip(equipKey, equipStatus);
              }
            }, error => {
              this.editor.drawflow.drawflow.Home.data[equipKey].equipStatus = 'equipoff';
            });
          } else if (pontHasHisAndNotWritableTag) {
            this.apiService.getSharedCurrenthisReadMany(ids, this.loginUserTypeData.linkShareUuid).pipe(map(this.cpmService.stripHaystackTypeMapping)).subscribe(_res => {
              let equipStatus = 'equipoff';
              if (_res.rows.length) {
                _res.rows.forEach(_row => {
                  if (_row.data?.length && _row.data[0].val == '1') {
                    equipStatus = 'equipOn';
                  }
                });
              }
              this.editor.drawflow.drawflow.Home.data[equipKey].equipStatus = equipStatus;
              this.updateEquipStatusImage(equipData, equipKey);  // Method to change the image of the equipment based on the status of the equipment
              if (!isAlertRingSet) {
                this.setAlertRingStatusForEquip(equipKey, equipStatus);
              }
            }, error => {
              this.editor.drawflow.drawflow.Home.data[equipKey].equipStatus = 'equipoff';
            });
          } else {
            this.editor.drawflow.drawflow.Home.data[equipKey].equipStatus = 'equipoff';
          }
        } else {
          if (pointHasWritableTag) {
            this.cpmService.getBulkWritablePointData(ids).pipe(map(this.cpmService.stripHaystackTypeMapping)).subscribe(_res => {
              let equipStatus = 'equipoff';
              if (_res.rows.length) {
                _res.rows.forEach(_row => {
                  if (_row.data?.length && _row.data[0].val == '1') {
                    equipStatus = 'equipOn';
                  }
                });
              }

              this.editor.drawflow.drawflow.Home.data[equipKey].equipStatus = equipStatus;
              this.updateEquipStatusImage(equipData, equipKey);  // Method to change the image of the equipment based on the status of the equipment
              if (!isAlertRingSet) {
                this.setAlertRingStatusForEquip(equipKey, equipStatus);
              }
            }, error => {
              this.editor.drawflow.drawflow.Home.data[equipKey].equipStatus = 'equipoff';
            });
          } else if (pontHasHisAndNotWritableTag) {
            this.cpmService.getCurrenthisReadMany(ids).pipe(map(this.cpmService.stripHaystackTypeMapping)).subscribe(_res => {
              let equipStatus = 'equipoff';
              if (_res.rows.length) {
                _res.rows.forEach(_row => {
                  if (_row.data?.length && _row.data[0].val == '1') {
                    equipStatus = 'equipOn';
                  }
                });
              }

              this.editor.drawflow.drawflow.Home.data[equipKey].equipStatus = equipStatus;

              this.updateEquipStatusImage(equipData, equipKey);  // Method to change the image of the equipment based on the status of the equipment
              if (!isAlertRingSet) {
                this.setAlertRingStatusForEquip(equipKey, equipStatus);
              }
            }, error => {
              this.editor.drawflow.drawflow.Home.data[equipKey].equipStatus = 'equipoff';
            });
          } else {
            this.editor.drawflow.drawflow.Home.data[equipKey].equipStatus = 'equipoff';
          }
        }
      });
    }
  }
  

  // Method to change the image of the equipment based on the status of the equipment for mouseover, mouseleave events and also default view
  updateEquipStatusImage(equipData, equipKey) {

    let equipContent = document.getElementById(`drawflow_content_node-${equipData.id}`);
    let equipElement: any = document.getElementById(`node-${equipData.id}`);
    const equipDisplayNames = ['coolingTowerFourFan', 'coolingTowerTwoFan', 'coolingTowerSingleFan', 'airCooledChiller'];
    let equipStatus = this.editor.drawflow.drawflow.Home.data[equipKey].equipStatus; 

    if (equipContent != null) {

      // Change the image of the equipment on mouseover event based on the status of the equipment
      equipContent.addEventListener('mouseover', (event) => {
        equipElement.getElementsByClassName('equipImg')[0].src = equipStatus === 'equipOn'
          ? `/assets/images/cpmEquips/${equipData.class}.gif`
          : `/assets/images/cpmEquips/${equipData.cutAwayImg}`;
        equipElement.style.setProperty('z-index', '1502', 'important');
      });

      // Change the image of the equipment on mouseleave event based on the status of the equipment
      equipContent.addEventListener('mouseleave', () => {
        if (equipStatus === 'equipOn' && equipDisplayNames.includes(equipData?.class)) {
          equipElement.getElementsByClassName('equipImg')[0].src = `/assets/images/cpmEquips/_OnState_${equipData.class}.gif`;
        } else if (equipData.img) {
          equipElement.getElementsByClassName('equipImg')[0].src = `/assets/images/cpmEquips/${equipData.img}`;
        }
        equipElement.style.setProperty('z-index', '1052', 'important');
      });
    }
    
    // Change the image of the equipment based on the status of the equipment when status is equipOn or equipOff
    if (equipDisplayNames.includes(equipData?.class) && equipStatus === 'equipOn') {
      equipElement.getElementsByClassName('equipImg')[0].src = `/assets/images/cpmEquips/_OnState_${equipData.class}.gif`;
    } else {
      equipElement.getElementsByClassName('equipImg')[0].src = `/assets/images/cpmEquips/${equipData.img}`;
    }
  }

  /**
   * Retrieves the master point for the given equipment data.
   * 
   * @param equipData - The equipment data.
   * @returns An Observable that emits the master point.
   */
  getMasterPoint(equipData): Observable<any> {
    let pointList = [];
    equipData.childData.equipData.subEquips.forEach(_equip => {
      _equip.equips.forEach(equipData => {
        pointList.push(equipData.pointId);
      });
    });

    return new Observable(observer => {
      let body = {
        "statusType": "PUBLISHED",
        "isTrend": "true",
        "viewType": "MASTER"
      }

      let equipId = this.getEquipIdForMasterView(equipData);
      pointList.push(equipId);

      if (this.loginUserTypeData.isPublic) {
        this.setAlertRingStatusForEquips(equipData, equipId);
        let configAPI = this.apiService.getSharedConfig(this.loginUserTypeData.linkShareUuid, equipId)
          .pipe(catchError(err => of(err)), map(this.cpmService.stripHaystackTypeMapping));
        let masterViewAPI = this.apiService.getSharedMasterData(this.loginUserTypeData.linkShareUuid, equipId, body).pipe(catchError(err => of(err)), map(this.cpmService.stripHaystackTypeMapping));
        let energyConsumedAPI =  this.apiService.getsharedEnergyConsumed(this.loginUserTypeData.linkShareUuid, equipId, body).pipe(catchError(err => of(err)), map(this.cpmService.stripHaystackTypeMapping));
        forkJoin([configAPI, masterViewAPI, energyConsumedAPI]).subscribe((response: any) => {
          let writablePoint, readablePoint = [];
          if (Object.keys(response[0]).length === 0 || (response[1].status == 400 || response[1] == "")) {

            pointList.forEach(_key => {
              if (document.querySelectorAll(`#pointStatusContainerId-${_key}`).length) {
                let pointContainer: any = document.querySelectorAll(`#pointStatusContainerId-${_key}`);
                pointContainer.forEach(point => point.style.display = 'none');
              }

              if (document.querySelectorAll(`#masterViewSvgId-${_key}`).length) {
                let masterViewSvg: any = document.querySelectorAll(`#masterViewSvgId-${_key}`);
                masterViewSvg.forEach(point => point.style.display = 'none');
              }
            });
            
            let equipId = this.getEquipIdForMasterView(equipData);
            let checkIsLoader = document.getElementById(`pointLoader-${equipId}`);
            if (checkIsLoader != null) {
              document.getElementById(`pointStatusContainerId-${equipId}`).remove();
              document.getElementById(`status-view-masterViewSvgId-${equipId}`).style.display = 'none';
            }
            document.querySelectorAll('pointStatusContainerId-undefined').forEach(_el => _el.remove());
            observer.next(undefined);
            observer.complete();
          } else {
            let readAndWritePointList = [];
            if (response[1]?.readPointsData) {
              readablePoint = response[1]?.readPointsData?.rows.map((_data) => {
                _data.isWritable = false;
                return _data;
              });
            }
            if (response[1]?.hisPointsData) {
              response[1]?.hisPointsData.forEach((_data, i) => {
                readablePoint = readablePoint.concat(_data.rows?.map((pointData) => {
                  if (i == 0) {
                    pointData.isTrend = true;
                  }
                  pointData.isWritable = false;
                  return pointData;
                }));
              });
            }

            if (response[1]?.writablePointsData) {
              writablePoint = response[1]?.writablePointsData?.rows?.map(pointData => {
                pointData.isWritable = true;
                return pointData;
              });
            }

            if (!readablePoint) {
              readablePoint = [];
            }
            readAndWritePointList = readablePoint.concat(writablePoint);

            if(response[2] != '' && response[2].length){
              readAndWritePointList.forEach(pointObj => {
                let energyConsumedPoint =  response[2].filter(energyObj => energyObj.pointId == pointObj.id);
                if (energyConsumedPoint.length) {
                   let energyConsumedValue =  energyConsumedPoint[0].energyConsumedValue.replace(pointObj.unit, "");
                   pointObj.energyConsumedValue = `${ energyConsumedValue != 'NA' ? Math.round(Number(energyConsumedValue) * 100) / 100 : 'NA'} ${energyConsumedValue != 'NA' && pointObj.unit != undefined ? pointObj.unit : ''}`
                }
              });
            }

            let mainSensorPoints = [];

            if (this.isHeaderPipe(equipData.childData.class) || this.isBypassPipe(equipData.childData.class)) {
              equipData?.childData?.equipData?.subEquips.forEach(subEquips => {
                subEquips.equips.forEach(equips => {
                  mainSensorPoints.push(equips.pointId)
                });
              });
            } else {
              equipData?.equipData?.subEquips.forEach(subEquips => {
                subEquips.equips.forEach(equips => {
                  mainSensorPoints.push(equips.pointId)
                });
              });
            }

            const groupedPoints = response[0].pointList?.reduce((acc, obj) => {
              if (obj.master) {
                let key;
                if (this.isHeaderPipe(equipData.childData.class) || this.isBypassPipe(equipData.childData.class)) {
                  key = obj.pointId;
                } else if (this.isPump(equipData.childData.class)) {
                  key = equipData.childData.equipData.subEquips[0].equipId;
                } else if (equipData.childData.class == "btuMeter") {
                  key = equipData.pointId;
                }
                else {
                  if (equipData.equipId == obj.equipRef || !mainSensorPoints.includes(obj.pointId) || obj.pointMetaData.custom) {
                    key = equipData.equipId;
                  } else {
                    key = obj.pointId;
                  }
                }


                if (!acc[key]) {
                  acc[key] = [];
                }
                acc[key].push(obj);
              }
              return acc;
            }, {});

            Object.keys(groupedPoints).forEach(groupKey => {
              groupedPoints[groupKey].forEach(pointData => {
                pointData.pointList = readAndWritePointList.filter(point => point?.id == pointData?.pointId);
              });

              var pointCont = document.createElement("div");
              pointCont.classList.add("pointContainerHeight");
              // Master view Point
              if (groupedPoints[groupKey].length) {
                const pointGroupHeader = document.createElement('div');
                pointGroupHeader.classList.add("pointGroupHeader");
                pointGroupHeader.innerHTML = "Pre-defined";
                if (groupKey == equipData.equipId) {
                  pointCont.appendChild(pointGroupHeader);
                }
                for (var i = 0; i < groupedPoints[groupKey].length; i++) {
                  if (groupedPoints[groupKey][i].master && !groupedPoints[groupKey][i].pointMetaData.custom) {
                    if (groupedPoints[groupKey][i].pointList?.length) {
                      pointCont.appendChild(this.createMasterViewPoint(groupedPoints[groupKey][i].pointList, equipData.pointId));
                    }
                  }
                }

                if (groupedPoints[groupKey].some(obj => obj.pointMetaData.custom === true && obj.master)) {
                  const pointGroupHeaderCustom = document.createElement('div');
                  pointGroupHeaderCustom.classList.add("pointGroupHeader");
                  pointGroupHeaderCustom.innerHTML = "Custom";
                  if (groupKey == equipData.equipId) {
                    pointCont.appendChild(pointGroupHeaderCustom);
                  }
                  for (var i = 0; i < groupedPoints[groupKey].length; i++) {
                    if (groupedPoints[groupKey][i].master && groupedPoints[groupKey][i].pointMetaData.custom) {
                      if (groupedPoints[groupKey][i].pointList?.length) {
                        pointCont.appendChild(this.createMasterViewPoint(groupedPoints[groupKey][i].pointList, equipData.pointId));
                      }
                    }
                  }
                }

                if (document.getElementById(`pointHeightId-${groupKey}`) != null) {
                  document.getElementById(`pointHeightId-${groupKey}`).innerHTML = pointCont.innerHTML;
                  const masterViewContainer = document.getElementById(`pointStatusContainerId-${groupKey}`);
                  if (masterViewContainer) {
                    if (!masterViewContainer.classList.contains('master-view-active-block')) {
                      masterViewContainer.classList.add('master-view-active-block');
                    }
                  }
                  if (this.loginUserTypeData.isPublic) {
                    this.CPMTopBarComponent.statusView(this.userPreferenceData.heatmapPreferences.cpmMasterViewPreference, false);
                  }
                }
              }
            });
            const self = this;
            const selectEleList = document.querySelectorAll('.masterPointSelect');
            selectEleList.forEach((_element, i) => {
              selectEleList[i].addEventListener('change', (event) => {
                const pointId = event.target['id'].split('-pointId-')[1];
                const pointData = readAndWritePointList.find(_r => _r.id == pointId && _r.isWritable);
                if (pointData) {  
                    self.forceOverrideData(pointId, pointData.data, event.target['value']).pipe(map(this.cpmService.stripHaystackTypeMapping)).subscribe((res: any) => {
                      pointData.data = res.rows[0].data;
                    });
                  }
              });
            });

            this.removeEmptyMasterContainer(groupedPoints,equipData);
            observer.next(response);
            observer.complete();
          }
        }, (error) => {
          pointList.forEach(_key => {
            if (document.querySelectorAll(`#pointStatusContainerId-${_key}`).length) {
              let pointContainer: any = document.querySelectorAll(`#pointStatusContainerId-${_key}`);
              pointContainer.forEach(point => point.style.display = 'none');
            }

            if (document.querySelectorAll(`#masterViewSvgId-${_key}`).length) {
              let masterViewSvg: any = document.querySelectorAll(`#masterViewSvgId-${_key}`);
              masterViewSvg.forEach(point => point.style.display = 'none');
            }
          });

          let equipId = this.getEquipIdForMasterView(equipData);
          let checkIsLoader = document.getElementById(`pointLoader-${equipId}`);
          if (checkIsLoader != null) {
            document.getElementById(`pointStatusContainerId-${equipId}`).remove();
            document.getElementById(`status-view-masterViewSvgId-${equipId}`).style.display = 'none';
          }
          observer.next(undefined);
          observer.complete();
        }, () => {
        });
      } else if (!this.loginUserTypeData.isPublic) {
        this.setAlertRingStatusForEquips(equipData, equipId);
        let configAPI = this.apiService.getEquipConfigs({ "plantId": this.selectedPlantData.chillerPlantId, "equipId": equipId, status: 'PUBLISHED' })
          .pipe(catchError(err => of(err)), map(this.cpmService.stripHaystackTypeMapping));
        let masterViewAPI = this.apiService.getMasterPoint(this.selectedPlantData.chillerPlantId, equipId, body).pipe(catchError(err => of(err)), map(this.cpmService.stripHaystackTypeMapping));
        let energyConsumedAPI = this.apiService.getEnergyConsumed(this.selectedPlantData.chillerPlantId, equipId, body).pipe(catchError(err => of(err)), map(this.cpmService.stripHaystackTypeMapping));
        forkJoin([configAPI, masterViewAPI, energyConsumedAPI]).subscribe((response: any) => {
          let writablePoint, readablePoint = [];
          if (Object.keys(response[0]).length === 0 || (response[1].status == 400 || response[1] == "")) {

            pointList.forEach(_key => {
              if (document.querySelectorAll(`#pointStatusContainerId-${_key}`).length) {
                let pointContainer: any = document.querySelectorAll(`#pointStatusContainerId-${_key}`);
                pointContainer.forEach(point => point.style.display = 'none');
              }

              if (document.querySelectorAll(`#masterViewSvgId-${_key}`).length) {
                let masterViewSvg: any = document.querySelectorAll(`#masterViewSvgId-${_key}`);
                masterViewSvg.forEach(point => point.style.display = 'none');
              }
            });

            let equipId = this.getEquipIdForMasterView(equipData);
            let checkIsLoader = document.getElementById(`pointLoader-${equipId}`);
            if (checkIsLoader != null) {
              document.getElementById(`pointStatusContainerId-${equipId}`).remove();
              document.getElementById(`status-view-masterViewSvgId-${equipId}`).style.display = 'none';
            }

            document.querySelectorAll('pointStatusContainerId-undefined').forEach(_el => _el.remove());
            observer.next(undefined);
            observer.complete();
          } else {
            let readAndWritePointList = [];
            if (response[1]?.readPointsData) {
              readablePoint = response[1]?.readPointsData?.rows.map((_data) => {
                _data.isWritable = false;
                return _data;
              });
            }
            if (response[1]?.hisPointsData) {
              response[1]?.hisPointsData.forEach((_data, i) => {
                readablePoint = readablePoint.concat(_data.rows?.map((pointData) => {
                  if (i == 0) {
                    pointData.isTrend = true;
                  }
                  pointData.isWritable = false;
                  return pointData;
                }));
              });
            }

            if (response[1]?.writablePointsData) {
              writablePoint = response[1]?.writablePointsData?.rows?.map(pointData => {
                pointData.isWritable = true;
                return pointData;
              });
            }

            if (!readablePoint) {
              readablePoint = [];
            }
            readAndWritePointList = readablePoint.concat(writablePoint);

            if(response[2] != '' && response[2].length){
              readAndWritePointList.forEach(pointObj => {
                let energyConsumedPoint =  response[2].filter(energyObj => energyObj.pointId == pointObj.id);
                if (energyConsumedPoint.length) {
                   let energyConsumedValue =  energyConsumedPoint[0].energyConsumedValue.replace(pointObj.unit, "");
                   pointObj.energyConsumedValue = `${ energyConsumedValue != 'NA' ? Math.round(Number(energyConsumedValue) * 100) / 100 : 'NA'} ${energyConsumedValue != 'NA' && pointObj.unit != undefined ? pointObj.unit : ''}`
                }
              });
            }
           

            let mainSensorPoints = [];

            if (this.isHeaderPipe(equipData.childData.class) || this.isBypassPipe(equipData.childData.class)) {
              equipData?.childData?.equipData?.subEquips.forEach(subEquips => {
                subEquips.equips.forEach(equips => {
                  mainSensorPoints.push(equips.pointId)
                });
              });
            } else {
              equipData?.equipData?.subEquips.forEach(subEquips => {
                subEquips.equips.forEach(equips => {
                  mainSensorPoints.push(equips.pointId)
                });
              });
            }

            const groupedPoints = response[0].pointList.reduce((acc, obj) => {
              if (obj.master) {
                let key;
                if (this.isHeaderPipe(equipData.childData.class) || this.isBypassPipe(equipData.childData.class)) {
                  key = obj.pointId;
                } else if (this.isPump(equipData.childData.class)) {
                  key = equipData.childData.equipData.subEquips[0].equipId;
                } else if (equipData.childData.class == "btuMeter") {
                  key = equipData.pointId;
                }
                else {
                  if (equipData.equipId == obj.equipRef || !mainSensorPoints.includes(obj.pointId) || obj.pointMetaData.custom) {
                    key = equipData.equipId;
                  } else {
                    key = obj.pointId;
                  }
                }

                if (!acc[key]) {
                  acc[key] = [];
                }
                acc[key].push(obj);
              }
              return acc;
            }, {});

            Object.keys(groupedPoints).forEach(groupKey => {
              groupedPoints[groupKey].forEach(pointData => {
                pointData.pointList = readAndWritePointList.filter(point => point?.id == pointData?.pointId);
              });

              var pointCont = document.createElement("div");
              pointCont.classList.add("pointContainerHeight");
              // Master view Point
              if (groupedPoints[groupKey].length) {
                const pointGroupHeader = document.createElement('div');
                pointGroupHeader.classList.add("pointGroupHeader");
                pointGroupHeader.innerHTML = "Pre-defined";
                if (groupKey == equipData.equipId) {
                  pointCont.appendChild(pointGroupHeader);
                }
                for (var i = 0; i < groupedPoints[groupKey].length; i++) {
                  if (groupedPoints[groupKey][i].master && !groupedPoints[groupKey][i].pointMetaData.custom) {
                    if (groupedPoints[groupKey][i].pointList?.length) {
                      pointCont.appendChild(this.createMasterViewPoint(groupedPoints[groupKey][i].pointList, equipData.pointId));
                    }
                  }
                }

                if (groupedPoints[groupKey].some(obj => obj.pointMetaData.custom === true && obj.master)) {
                  const pointGroupHeaderCustom = document.createElement('div');
                  pointGroupHeaderCustom.classList.add("pointGroupHeader");
                  pointGroupHeaderCustom.innerHTML = "Custom";
                  if (groupKey == equipData.equipId) {
                    pointCont.appendChild(pointGroupHeaderCustom);
                  }
                  for (var i = 0; i < groupedPoints[groupKey].length; i++) {
                    if (groupedPoints[groupKey][i].master && groupedPoints[groupKey][i].pointMetaData.custom) {
                      if (groupedPoints[groupKey][i].pointList?.length) {
                        pointCont.appendChild(this.createMasterViewPoint(groupedPoints[groupKey][i].pointList, equipData.pointId));
                      }
                    }
                  }
                }

                if (document.getElementById(`pointHeightId-${groupKey}`) != null) {
                  document.getElementById(`pointHeightId-${groupKey}`).innerHTML = pointCont.innerHTML;
                  const masterViewContainer = document.getElementById(`pointStatusContainerId-${groupKey}`);
                  if (masterViewContainer) {
                    if (!masterViewContainer.classList.contains('master-view-active-block')) {
                      masterViewContainer.classList.add('master-view-active-block');
                    }
                  }
                  if (!this.loginUserTypeData.isPublic) {
                    this.CPMTopBarComponent.statusView(this.userPreferenceData.heatmapPreferences.cpmMasterViewPreference, false);
                  }
                }
              }
            });
            const self = this;
            const selectEleList = document.querySelectorAll('.masterPointSelect');
            selectEleList.forEach((_element, i) => {
              selectEleList[i].addEventListener('change', (event) => {
                const pointId = event.target['id'].split('-pointId-')[1];
                const pointData = readAndWritePointList.find(_r => _r.id == pointId && _r.isWritable);
                if (pointData) {
                    self.forceOverrideData(pointId, pointData.data, event.target['value']).pipe(map(this.cpmService.stripHaystackTypeMapping)).subscribe((res: any) => {
                      pointData.data = res.rows[0].data;
                   });
                }
              });
            });

            this.removeEmptyMasterContainer(groupedPoints, equipData);
            observer.next(response);
            observer.complete();
          }
        }, (error) => {
          pointList.forEach(_key => {
            if (document.querySelectorAll(`#pointStatusContainerId-${_key}`).length) {
              let pointContainer: any = document.querySelectorAll(`#pointStatusContainerId-${_key}`);
              pointContainer.forEach(point => point.style.display = 'none');
            }

            if (document.querySelectorAll(`#masterViewSvgId-${_key}`).length) {
              let masterViewSvg: any = document.querySelectorAll(`#masterViewSvgId-${_key}`);
              masterViewSvg.forEach(point => point.style.display = 'none');
            }
          });

          let equipId = this.getEquipIdForMasterView(equipData);
          let checkIsLoader = document.getElementById(`pointLoader-${equipId}`);
          if (checkIsLoader != null) {
            document.getElementById(`pointStatusContainerId-${equipId}`).remove();
            document.getElementById(`status-view-masterViewSvgId-${equipId}`).style.display = 'none';
          }

          observer.next(undefined);
          observer.complete();
        }, () => {
        });
      }
    });
  }


  removeEmptyMasterContainer(groupedPoints, dataNode) {
    let equipId = this.getEquipIdForMasterView(dataNode);
    let checkIsLoader = document.getElementById(`pointLoader-${equipId}`);
    if (checkIsLoader != null) {     
      document.getElementById(`pointStatusContainerId-${equipId}`).remove();
      document.getElementById(`status-view-masterViewSvgId-${equipId}`).style.display = 'none';
    }
  }

  formatHoverResponse(response, energyConsumed) {
    // Writable Response.
    let writableResponse = []; 
    let hoverPointList = [];
    if(response != '' && response?.writablePointsData && response?.hisPointsData){
      writableResponse = JSON.parse(JSON.stringify(response?.writablePointsData?.rows));
      hoverPointList = JSON.parse(JSON.stringify(response?.hisPointsData?.rows));

    hoverPointList.map(point => {
      if(energyConsumed.length){
        let energyConsumedPoint =  energyConsumed.filter(energyObj => energyObj.pointId == point.id);
        if (energyConsumedPoint.length) {
          let energyConsumedValue =  energyConsumedPoint[0].energyConsumedValue.replace(point.unit, "");
          point.energyConsumedValue = `${energyConsumedValue != 'NA' ? Math.round(Number(energyConsumedValue) * 100) / 100 : 'NA'}`
        }
      }
 
      point = this.helperMethodsService.formatEnums(point);
      let tags = Object.keys(point);
      if (writableResponse?.length) {
        const found = writableResponse.find(_writableResPoint => ((point.id == _writableResPoint.id) && _writableResPoint?.data?.length));
        if (found) {
          point['writableData'] = found.data;
        }
      }
      const displayName = point?.shortDis ? point.shortDis : point?.dis;
      point['pointName'] = this.addEllipsis(displayName, 30);
      // setting writable value as point value if Writable data is there else latest point data is set as point value 
      point['pointValue'] = point['writableData'] != undefined ? point['writableData'][0].val : (point?.data[point?.data.length - 1])?.val?.split(' ')[0] ? Number((point?.data[point?.data.length - 1])?.val?.split(' ')[0]).toFixed(2) : 'NA'
      if (point.enum) {
        let enumValue = point.enum.find(x => x.value == point['pointValue']);
        if (enumValue != undefined) {
          // Setting Enum value to the point if Enum is available 
          point['pointValue'] = enumValue.name;
        }

      }
      if (point?.enum?.length > 1 && point?.data?.length) {
        let checkData = point['writableData']?.length ? Number(point['writableData'][0].val.split(' ')[0]) : Number(point['pointValue']);
        const found = point?.enum?.find(_r => Number(_r.value) == checkData);
        if (found) {
          point['pointValue'] = found.name;
        }
      }
      point['tags'] = tags;
    });
  }

    return hoverPointList;
  }

  getHoverData(node) {
    return new Observable(observer => {
      const date = new Date();
      let month: any = date.getMonth() + 1;
      month = month < 10 ? `0${month}` : `${month}`;
      let year = date.getFullYear();
      let currentDate = `${year}-${month}-${date.getDate().toString().padStart(2, "0")}`;

      let body = {
        "statusType": "PUBLISHED",
        "isTrend": "false",
        "viewType": "HOVER",
        "startDate": currentDate,
        "endDate": currentDate,
      }
      let equipId = node.class.includes("pump1Vertical") ? node.childData.equipData.subEquips.filter(x => x.equip[0] == 'pump1Vertical')[0].equipId : node.equipId;
      if (this.loginUserTypeData.isPublic) {

        let masterViewAPI = this.apiService.getSharedMasterData(this.loginUserTypeData.linkShareUuid, equipId, body).pipe(catchError(err => of(err)), map(this.cpmService.stripHaystackTypeMapping));
        let energyConsumedAPI =  this.apiService.getsharedEnergyConsumed(this.loginUserTypeData.linkShareUuid, equipId, body).pipe(catchError(err => of(err)), map(this.cpmService.stripHaystackTypeMapping));
        forkJoin([masterViewAPI, energyConsumedAPI]).subscribe((response: any) => {
          if (response[0] == "") {
            observer.next(undefined);
            observer.complete();
          } else {
            let hoverPointList = this.formatHoverResponse(response[0], response[1]);
            observer.next(hoverPointList);
            observer.complete();
          }
        }, (error) => {
          observer.next(undefined);
          observer.complete();
        }, () => {

        });


      } else if (!this.loginUserTypeData.isPublic) {
        let masterViewAPI = this.apiService.getMasterPoint(this.selectedPlantData.chillerPlantId, equipId, body).pipe(catchError(err => of(err)), map(this.cpmService.stripHaystackTypeMapping));
        let energyConsumedAPI = this.apiService.getEnergyConsumed(this.selectedPlantData.chillerPlantId, equipId, body).pipe(catchError(err => of(err)), map(this.cpmService.stripHaystackTypeMapping));
            forkJoin([masterViewAPI, energyConsumedAPI]).subscribe((response: any) => {
          if (response[0] == "") {
            observer.next(undefined);
            observer.complete();
          } else {
            let hoverPointList = this.formatHoverResponse(response[0], response[1]);
            observer.next(hoverPointList);
            observer.complete();
          }
        }, (error) => {

          observer.next(undefined);
          observer.complete();
        }, () => {

        });
      }
    });
  }

  addEllipsis(text, textLimit) {
    return (text.length > textLimit) ? text.slice(0, textLimit - 1) + '&hellip;' : text;
  }

  createMasterViewPoint(pointData, equipId) {
    let masterValueColor = this.getMasterViewColor(Object.keys(pointData[0]))
    var createPoint = document.createElement("div");
    createPoint.classList.add('d-flex');

    const pointName = document.createElement("div");
    pointName.classList.add('mr-auto');
    pointName.classList.add('equipview-pointName');
    if (this.userPreferenceData?.heatmapPreferences?.cpmMasterViewPreference == 'valueView') {
      pointName.style.display = 'none';
    }
    let displayName = '';
    if (pointData?.length) {
      displayName = pointData[0]?.shortDis ? pointData[0]?.shortDis : pointData[0]?.dis;
    }
    pointName.setAttribute("title", displayName);
    pointName.innerHTML = this.addEllipsis(displayName, 25);

    const findTrendPointData = pointData.find(_point => _point.isTrend);
    if (findTrendPointData?.data?.length > 0) {
      var graphDiv = document.createElement("div");
      graphDiv.setAttribute("id", "trendGraph");
      graphDiv.classList.add('trendGraphContainer');
      if (this.userPreferenceData?.heatmapPreferences?.cpmMasterViewPreference != 'trendView') {
        graphDiv.style.display = 'none';
      }
      const width = 100, height = 20;

      let svg = document.createElement("svg");
      d3.select(svg)
        .attr("width", width)
        .attr("height", height)
        .append("g")

      const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const utcOffset = dayjs().tz(tz).utcOffset();
      let trendPointArr = [];

      const filteredArr = pointData[0].data.filter((value, index) => index % 1 === 0);

      filteredArr.forEach(pointValue => {
        let trendPointData = {
          date: pointValue.ts ? pointValue.ts.slice(0, 19) : null,
          pointValue: (pointValue.val != null && pointValue.val.split(" ")[0] != null) ? Math.trunc(Number(pointValue.val.split(" ")[0])) : pointValue.val == null ? null : Math.trunc(Number(pointValue.val))
        }
        if (!trendPointData.date && pointValue.hasOwnProperty('lastModifiedDateTime')) {
          trendPointData.date = pointValue.lastModifiedDateTime.slice(0, 19);
        }
        trendPointArr.push(trendPointData);
      });

      const parseTime = d3.timeParse("%Y-%m-%dT%H:%M:%S")

      trendPointArr.forEach(function (d) {
        d.date = parseTime(d.date)
        d.pointValue = +d.pointValue;
      });

      const xScale = d3.scaleTime().range([0, width])
      const yScale = d3.scaleLinear().range([height, 0])
      xScale.domain(d3.extent(trendPointArr, function (d) {
        return d.date
      }))
      yScale.domain(d3.extent(trendPointArr, function (d) {
        return d.pointValue
      }))

      const valueline = d3.line()
        .curve(d3.curveMonotoneX)
        .x(function (d) {
          return xScale(d.date)
        })
        .y(function (d) {
          return yScale(d.pointValue)
        })
      // glow code
      let filter = d3.select(svg)
        .append("filter")
        .attr("id", "glow")
        .attr("x", "-50%")
        .attr("y", "-50%")
        .attr("width", "200%")
        .attr("height", "200%");

      filter.append("feGaussianBlur")
        .attr("stdDeviation", "4")
        .attr("result", "coloredBlur");

      let feMerge = filter.append("feMerge");
      feMerge.append("feMergeNode").attr("in", "coloredBlur");
      feMerge.append("feMergeNode").attr("in", "SourceGraphic");
      //glow code ends
      let path = document.createElement("path");
      d3.select(path)
        .data([trendPointArr])
        .attr("d", valueline)
        .style("fill", "none")
        .style("stroke", masterValueColor)
        .style("stroke-width", "3")
        .style("filter", "url(#glow)");

      svg.appendChild(path);
      graphDiv.appendChild(svg);
    }

    var pointValue;
    let pointDataWritableVal: any = pointData.find(_point => _point.isWritable);
    const pointDataReadableVal: any = pointData.find(_point => !_point.isWritable && !_point.isTrend);
    if (pointDataWritableVal?.isWritable) {

      let pointdataTransfer = this.helperMethodsService.formatEnums(pointDataWritableVal);
      if (pointdataTransfer.enum) {
        pointDataWritableVal.pointList = pointdataTransfer.enum;
      } else if (pointdataTransfer.options) {
        pointDataWritableVal.pointList = pointdataTransfer.options;
      }
      if (pointDataWritableVal?.pointList?.length) {
        pointValue = document.createElement("div");
        pointValue.classList.add('equipview-pointValue');
        pointValue.classList.add('red');
        let selectPointId = 'selectPointId-' + equipId + "-pointId-" + pointdataTransfer.id;
        var selectPointListDiv = document.createElement("div");
        selectPointListDiv.classList.add("masterdropDownOption");
        var selectPointList = document.createElement("select");
        selectPointList.classList.add("masterPointSelect");
        if (this.loginUserTypeData.isVofm) {
          selectPointListDiv.classList.add("point-event-none");
        }
        if (this.loginUserTypeData.isPublic) {
          selectPointList.classList.add("sharedDropDown");
        }
        selectPointList.id = selectPointId;

        for (var j = 0; j < pointDataWritableVal?.pointList?.length; j++) {
          var option = document.createElement("option");
          option.classList.add("masterPointSelectOption");
          if (pointDataWritableVal.data?.length && pointDataWritableVal.data[0].val.split(' ')[0] == pointDataWritableVal.pointList[j].value) {
            option.setAttribute('selected', 'selected');
          }
          option.value = pointDataWritableVal.pointList[j].value;
          option.text = pointDataWritableVal.pointList[j].name;
          selectPointList.appendChild(option);
        }
        if (pointDataWritableVal.data?.length) {
          selectPointList.value = pointDataWritableVal.data[0].val.split(' ')[0];
        } else {
          selectPointList.value = null;
        }
        selectPointListDiv.appendChild(selectPointList);
        pointValue.appendChild(selectPointListDiv);
      } else {
        pointValue = document.createElement("div");
        pointValue.classList.add('equipview-pointValue');
        pointValue.style.color = masterValueColor + " " + "!important";
        if (pointDataWritableVal?.data.length) {
          pointValue.innerHTML = `${Math.round(Number(pointDataWritableVal?.data[0]?.val?.split(' ')[0]) * 100) / 100} ${pointDataWritableVal.unit != undefined ? pointDataWritableVal.unit : ''}`
        } else {
          pointValue.innerHTML = 'NA';
        }
      }
    } else {
      let pointdataTransfer = this.helperMethodsService.formatEnums(pointDataReadableVal);
      let poinData = "NA";
      if (pointdataTransfer?.enum?.length > 1 && pointDataReadableVal?.data?.length) {
        let found = pointdataTransfer.enum.find(_r => _r.value == pointDataReadableVal?.data[0].val);
        if (found) {
          poinData = found.name;
        }
      } else if (pointDataReadableVal.energyConsumedValue) {
        poinData = pointDataReadableVal.energyConsumedValue;
      } else {
        if (pointDataReadableVal?.data?.length && pointDataReadableVal?.data[0]?.val != undefined) {
          poinData = `${Math.round(Number(pointDataReadableVal?.data[0]?.val?.split(' ')[0]) * 100) / 100} ${pointDataReadableVal.unit != undefined ? pointDataReadableVal.unit : ''}`
        }
      }
      pointValue = document.createElement("div");
      pointValue.classList.add('equipview-pointValue');
      pointValue.style.color = masterValueColor + " " + "!important";
      pointValue.innerHTML = `${poinData}`;
    }

    createPoint.appendChild(pointName);
    if (findTrendPointData?.data?.length > 0 || (pointData?.length && pointData[0]?.data?.length > 0)) {
      if (graphDiv) {
        createPoint.appendChild(graphDiv);
      }
    }
    createPoint.appendChild(pointValue);
    return createPoint;
  }

  setPriorityData(id, priorityArray, val) {
    return {
      ref: id,
      level: String(priorityArray.level),
      who: `web_${this.getUserName()}`,
      duration: '0ms',
      val: val
    };
  }

  forceOverrideData(id, prevData: any, val) {
    const overRideArrayLevel = [];
    let overrideLevel8: any = {};
    overrideLevel8 = this.setPriorityData(id, { level: '10' }, val);
    overRideArrayLevel.push(overrideLevel8);
    // If priority array data is empty then setting the val.
    prevData.map(priorityArray => {
      // If the priority level is less than 10 returning the val as 'N'
      if (priorityArray.level < 10) {
        overrideLevel8 = this.setPriorityData(id, priorityArray, 'N');
        overRideArrayLevel.push(overrideLevel8);
      }
    });
    return this.writeDataToHaystack(overRideArrayLevel, '', prevData);
  }

  writeDataToHaystack(data: any, reason, prevData) {
    return this.cpmService.updateBulkWritablePointData(data, reason)
      .pipe(takeUntil(this.unsubscribe))

  }

  getUserName() {
    if (Object.keys(this.userPersonalInfo)?.length) {
      return this.userPersonalInfo['firstName'] + ' ' + this.userPersonalInfo['lastName'];
    } else {
      return null;
    }
  }

  loadCanvasEquipData(selectedPlantData, isReload) {
    this.editor.drawflow.drawflow.Home.data = {};
    this.editor.import(this.editor.drawflow);
    this.showLoader();
    this.getSiteEquips();
    this.selectedPlantData = selectedPlantData;
    this.checkAnyEquipsOrSubEquipsAreDeleted();
    this.getPlantStatus();
    if (this.loginUserTypeData.isPublic) {
      this.editor.import(selectedPlantData.plantJsonStr);
    }
    this.callDrawCustomPathsRecursive = true;
    this.drawCustomPaths();
  }


  addDragEventsToConnections() {
    let allconnections: any = Array.from(document.getElementsByClassName("connection"));
    allconnections.map(connection => {

      document.querySelector(`.${Array.from(connection.classList).join('.')} path.main-path`).addEventListener("dragenter", event => {
        // highlight potential drop target when the draggable element enters it


        let elementT: any = event.target;
        let groupPipeId = '';
        elementT?.parentElement?.classList.forEach(_class => {
          if (_class.includes("groupPipe-")) {
            groupPipeId = _class;
          }
          if (groupPipeId) {
            let groupPipeElement = document.querySelectorAll(`.${groupPipeId}`);
            groupPipeElement.forEach(pipeElement => {
              if (!pipeElement.classList.contains("orangeClass")) {
                pipeElement.classList.add("orangeClass");
              }
            });
          }
        });





        // let elementT: any = event.target;
        // if (!elementT.classList.contains("orangeClass")) {
        //   elementT.classList.add("orangeClass");
        // }
      });

      document.querySelector(`.${Array.from(connection.classList).join('.')} path.main-path`).addEventListener("dragleave", event => {        // reset background of potential drop target when the draggable leaves it
        // let elementT: any = event.target;
        // if (elementT.classList.contains("orangeClass")) {
        //   elementT.classList.remove("orangeClass");
        // }

        let elementT: any = event.target;
        let groupPipeId = '';
        elementT?.parentElement?.classList.forEach(_class => {
          if (_class.includes("groupPipe-")) {
            groupPipeId = _class;
          }
          if (groupPipeId) {
            let groupPipeElement = document.querySelectorAll(`.${groupPipeId}`);
            groupPipeElement.forEach(pipeElement => {
              if (pipeElement.classList.contains("orangeClass")) {
                pipeElement.classList.remove("orangeClass");
              }
            });
          }
        });
      });

      document.querySelector(`.${Array.from(connection.classList).join('.')} path.main-path`).addEventListener("mouseover", event => {
        let elementT: any = event.target;
        let groupPipeId = '';
        elementT?.parentElement?.classList.forEach(_class => {
          if (_class.includes("groupPipe-")) {
            groupPipeId = _class;
          }
          if (groupPipeId) {
            let groupPipeElement = document.querySelectorAll(`.${groupPipeId}`);
            groupPipeElement.forEach(pipeElement => {
              if (!pipeElement.classList.contains("orangeClass")) {
                pipeElement.classList.add("orangeClass");
              }
            });
          }
        });
      });

      document.querySelector(`.${Array.from(connection.classList).join('.')} path.main-path`).addEventListener("mouseout", event => {
        let elementT: any = event.target;
        let groupPipeId = '';
        elementT?.parentElement?.classList.forEach(_class => {
          if (_class.includes("groupPipe-")) {
            groupPipeId = _class;
          }
          if (groupPipeId) {
            let groupPipeElement = document.querySelectorAll(`.${groupPipeId}`);
            groupPipeElement.forEach(pipeElement => {
              if (pipeElement.classList.contains("orangeClass")) {
                pipeElement.classList.remove("orangeClass");
              }
            });
          }
        });
      });
    });
  }

  isNoPlantSelected(data) {
    this.noPlantSelected = data?.event;
    this.plantChangedVal = data?.name?.chillerPlantId;
    if (!this.noPlantSelected) {
      document.getElementById("noPlantSelected").style.display = "none";
      document.getElementById("loader").style.display = "";
      document.getElementById("canvas").style.display = "none";
    } else {
      document.getElementById("noPlantSelected").style.display = "";
      document.getElementById("loader").style.display = "none";
      document.getElementById("canvas").style.display = "none";
    }
  }

  plantBuilderView(view, reload = true) {
    this.view = view;
    this.showLoader();
    this.setBottomBarHeightConditionally();
    this.cpmView = "savedView";
    this.isPublished = true;
    if (!document.getElementById("drawflow").classList.contains("hideConfigs")) {
      document.getElementById("drawflow").classList.add("hideConfigs");
    }
    if (view == "My Plant") {
      this.editor.editor_mode = 'fixed';
      this.cpmView = "editView";
      this.isPublished = false;
      this.showBtnData = {
        showEditPlantBtn: false,
        showSaveDraftBtn: false,
        showDeleteDraftBtn: false,
        showPublishBtn: false,
        showExitPreviewBtn: false,
        showPreviewBtn: false,
        showExportBtn: false
      }
      this.selectedTabIndex = 0;
      document.getElementById("drawflow").classList.remove("hideConfigs");
      document.getElementById("drawflow").classList.add("hidePipeVertex");
    } else if (view == 'My Plant Preview') {
      this.editor.editor_mode = 'fixed';
      this.cpmView = "editView";
      this.isPublished = false;
      this.showBtnData.showExitPreviewBtn = true;
      document.getElementById("drawflow").classList.remove("hideConfigs");
      document.getElementById("drawflow").classList.add("hidePipeVertex");
    } else {
      this.editor.editor_mode = 'edit';
      document.getElementById("parentDrawFlow").style.height = "calc(100vh - 42px)";
      document.getElementsByClassName('bar-zoom')[0]['style'].bottom = '260px'
      document.getElementById("drawflow").classList.remove("hidePipeVertex");
      this.selectedTabIndex = 1;
      this.getPlantStatus();
    }
    this.onPublishedView();
    if (reload) {
      let status = this.isFac ? this.isBuilderUser ? this.editor.editor_mode == 'edit' ? "DRAFT" : "PUBLISHED": "PUBLISHED" : this.editor.editor_mode == 'edit' ? "DRAFT" : "PUBLISHED";
      this.apiService
        .getPlantData(this.selectedPlantData.chillerPlantId, status)
        .subscribe({
          next: (plantDataResp) => {
            if (!plantDataResp.hasOwnProperty('plantJson')) {
              this.selectedPlantData.plantJsonStr = {
                drawflow: {
                  Home: {
                    data: {}
                  }
                }
              };
            } else {
              this.selectedPlantData.plantJsonStr = JSON.parse(plantDataResp.plantJson);
              if (plantDataResp.status == "PUBLISHED") {
                this.showBtnData.showExportBtn = true;
              }
            }
            if (this.editor.editor_mode == 'edit') {
              this.nodes = [];
              this.duplicateNodesArr = [];
              this.selectedTabIndex = 1;
              this.showDiscardPopUp = false;
              this.showBtnData.showEditPlantBtn = false;
              this.disableMatTab = false;
              this.disableDiscardBtn = false;
            }
            this.storageService.setSelectedPlantData(this.selectedPlantData);
            this.siteName = plantDataResp?.siteName;
          },
          error: () => { },
          complete: () => {
            this.loadCanvasEquipData(this.selectedPlantData, false);
          }
        });
    }
  }

  drawCustomPaths() {
    let allSVGConnections = document.getElementsByClassName("connection");
    if (this.callDrawCustomPathsRecursive) {
      for (let i = 0; i < allSVGConnections.length; i++) {
        let classList = Array.from(allSVGConnections[i].classList)
        let classArray = ["CDWReturnClass", "CDWSupplyClass", "CWReturnClass", "CWSupplyClass", "boilerReturnClass", "boilerSupplyClass", "DTSupplyClass", "DTReturnClass"]
        var found = classList.filter(i => classArray.includes(i));
        if (found.length == 0) {
          const customClass = this.findSVGNode(allSVGConnections[i].classList)
          if (customClass) {
            allSVGConnections[i].classList.add(customClass);
            this.callDrawCustomPathsRecursive = false;
          } else {
            setTimeout(() => {
              this.callDrawCustomPathsRecursive = true;
              this.drawCustomPaths();
            }, 400);

          }
        }
      }

    }

  }

  findSVGNode(listOfClasses: any) {
    let nodeOutClass = "";
    let nodeInClass = "";
    listOfClasses.forEach((element: any) => {
      if (element.indexOf("node_out") > -1) {
        nodeOutClass = element;
      }
      if (element.indexOf("node_in") > -1) {
        nodeInClass = element;
      }
    });
    nodeOutClass = nodeOutClass?.split("node_out_")[1];
    nodeInClass = nodeInClass?.split("node_in_")[1];
    let nodeOutID = nodeOutClass?.split("node-")[1];
    let nodeInID = nodeInClass?.split("node-")[1];
    let findNodeClass: any = document.getElementById(nodeOutClass)?.classList;
    if (findNodeClass) {
      if (findNodeClass.contains("waterCooledChiller") || findNodeClass.contains("airCooledChiller")) {
        if (listOfClasses.contains("output_1")) {
          return "CDWReturnClass";
        } else if (listOfClasses.contains("output_2")) {
          return "CDWSupplyClass";
        } else if (listOfClasses.contains("output_3")) {
          return "CWReturnClass"
        } else if (listOfClasses.contains("output_4")) {
          return "CWSupplyClass"
        }
      } else if (findNodeClass.contains("steamBoiler") || findNodeClass.contains("condenserBoiler")) {
        if (listOfClasses.contains("output_1")) {
          return "boilerReturnClass";
        } else if (listOfClasses.contains("output_2")) {
          return "boilerSupplyClass";
        }
      }
      // sensor valves
      if (findNodeClass.contains("valveActuator") ||
        findNodeClass.contains("temperatureSensor") ||
        findNodeClass.contains("wellTemperature") ||
        findNodeClass.contains("pressureSensor") ||
        findNodeClass.contains("diffPressureSensor") ||
        findNodeClass.contains("flowSensor") ||
        findNodeClass.contains("flowSwitch") ||
        findNodeClass.contains("pump1Vertical") ||
        findNodeClass.contains("btuMeter") ||
        findNodeClass.contains("connectTwo") ||
        findNodeClass.contains("pipeDemarkation")) {
        return this.checkForInputConnection(this.editor.getNodeFromId(nodeOutID));
      }
      // water flow direction 
      // if (findNodeClass.contains("supplyFlowArrow")) {
      //   return "animate-forward";
      // } else if (findNodeClass.contains("returnFlowArrow")) {
      //   return "animate-reverse";
      // }
      // sensor valves
      let equipName = this.editor.getNodeFromId(nodeOutID)?.childData?.class;
      if (this.isHeaderPipe(equipName) || this.isBypassPipe(equipName)) {
        return this.equipConfiguration.headerPipeClassMapping[equipName];
      } else {
        if (findNodeClass.contains("valveActuator") ||
          findNodeClass.contains("temperatureSensor") ||
          findNodeClass.contains("wellTemperature") ||
          findNodeClass.contains("pressureSensor") ||
          findNodeClass.contains("diffPressureSensor") ||
          findNodeClass.contains("flowSensor") ||
          findNodeClass.contains("flowSwitch") ||
          findNodeClass.contains("pump1Vertical") ||
          findNodeClass.contains("btuMeter") ||
          findNodeClass.contains("connectTwo")) {
          return this.checkForInputConnection(this.editor.getNodeFromId(nodeOutID));
        }
      }
    }
    return "";
  }

  restoreTheNodeOnRemoval() {
    const found = this.duplicateNodesArr.find((_node: NodeElement) => _node.equipId === this.groupNodeDeletedData.childData.equipId);
    const preventNodes = [];
    const findNode = preventNodes.find(_r => this.selectedItemNode.class.toString() == _r.toString());

    if (found && !findNode && this.editor.nodeDeleteClicked) {
      this.nodes.push(found);
      this.nodes = this.sortNodes(this.nodes);
    }
  }

  sortNodes(array) {
    return array.sort((a, b) => a.sortOrder - b.sortOrder);
  }


  checkPipeConnection(from) {
    this.checkAnyEquipsOrSubEquipsAreDeleted();
    if (from != 'plantImported') {
      this.showBtnData.showSaveDraftBtn = true;
    }
    this.showBtnData.showExitPreviewBtn = false;
    let plantData = this.editor.drawflow.drawflow.Home.data;
    this.enablePublishButton = true;
    const plantDataArr = [];
    Object.keys(plantData).forEach(key => plantDataArr.push(plantData[key]));

    plantDataArr.forEach(equipData => {
      let equipInput = [];
      let equipOutputs = [];
      Object.keys(equipData.inputs).forEach(key => equipInput.push(equipData.inputs[key]));
      Object.keys(equipData.outputs).forEach(key => equipOutputs.push(equipData.outputs[key]));
      if (equipData.class != "GROUP") {
        if (Object.keys(equipData.inputs).length > 0) {
          equipInput.forEach(input => {
            if (input.connections.length == 0) {
              equipOutputs.forEach(outputdata => {
                if (outputdata.connections.length < 2) {
                  this.enablePublishButton = false;
                  return;
                }
              })
            }
          })
        }

        if (Object.keys(equipData.outputs).length > 0) {
          equipOutputs.forEach(output => {
            if (output.connections.length == 0) {
              equipInput.forEach(input => {
                if (input?.connections?.length < 2) {
                  this.enablePublishButton = false;
                  return;
                }
              })
            }
          })
        }
      }
    });
  }

  getPipeClass(equipName) {
    return this.equipConfiguration.headerPipeClassMapping[equipName];
  }

  /**
   * Creates an L-bend pipe based on the provided pipe type.
   * 
   * @param e - The event object containing the coordinates of the mouse click.
   * @param pipeType - The type of the pipe ('forward' or 'backward').
   */
  createLBendPipe(e, pipeType) {
    this.createdParentEquipId = ""
    let generateUniqueID = this.editor.getUniqueid();
    this.tempParentEquipData = {
      class: 'connectorLbendPipe',
      equipId: generateUniqueID,
      equipData: {
        displayName: 'connectorLbendPipe',
        name: 'connectorLbendPipe',
        subEquips: []
      }
    }
    let nodePosition = { x: e.x, y: e.y };
    let nodeId = this.selectedItemID = this.editor.nodeId;
    this.selectedItem = <NodeElement>{};
    this.selectedItem.displayName = '';
    this.selectedItem.name = 'connectorLbend';
    this.selectedItem.inputs = 1;
    this.selectedItem.outputs = 1;
    this.selectedItem.class = 'connectorLbend';
    this.selectedItem.pipeClass = 'DTSupplyClass';
    this.selectedItem.pipeType = pipeType;

    this.addNodeToDrawBoard(nodePosition.x, nodePosition.y, false, false, false, generateUniqueID);
    this.selectedItemID = this.editor.nodeId;
    let zoomLevel = Number(this.editor.zoom.toFixed(2));
    let secondLBend;
    if (pipeType == 'forward') {
  // set the second L bend Node based on the zoom level
      if (zoomLevel < 0.07) {
        secondLBend = 8;
      } else if (zoomLevel < 0.1) {
        secondLBend = 12;
      } else if (zoomLevel < 0.15) {
        secondLBend = 16;
      } else if (zoomLevel < 0.2) {
        secondLBend = 22;
      } else if (zoomLevel < 0.25) {
        secondLBend = 26;
      } else if (zoomLevel < 0.4) {
        secondLBend = 28;
      } else if (zoomLevel < 0.6) {
        secondLBend = 38;
      } else if (zoomLevel < 0.7) {
        secondLBend = 48;
      } else if (zoomLevel < 0.8) {
        secondLBend = 58;
      } else {
        secondLBend = 68;
      }

      this.addNodeToDrawBoard(nodePosition.x, nodePosition.y + secondLBend, false, true, false, generateUniqueID);
    } else if (pipeType == 'backward') {
      // set the second L bend Node based on the zoom level
      if (zoomLevel < 0.1) {
        secondLBend = 10;
      } else if (zoomLevel < 0.2) {
        secondLBend = 20;
      } else if (zoomLevel < 0.3) {
        secondLBend = 30;
      } else if (zoomLevel < 0.4) {
        secondLBend = 40;
      } else if (zoomLevel < 0.6) {
        secondLBend = 50;
      } else if (zoomLevel < 0.7) {
        secondLBend = 60;
      } else if (zoomLevel < 0.8) {
        secondLBend = 70;
      } else {
        secondLBend = 80;
      }
      let secondLBendY = secondLBend / 10
      this.addNodeToDrawBoard(nodePosition.x + secondLBend, nodePosition.y + secondLBendY, false, true, false, generateUniqueID);
    }
    this.editor.addConnection(nodeId, nodeId + 1, 'output_1', 'input_1', 'DTSupplyClass', false, true, '');
    setTimeout(() => {
      this.selectedItemID = this.editor.nodeId;
      this.addNodeToDrawBoard(nodePosition.x, nodePosition.y, false, false, true, "", generateUniqueID);
    }, 100);
  }

  /**
   * Creates a header pipe based on the provided parameters.
   * 
   * @param e - The event object containing the coordinates of the pipe.
   * @param pipeType - The type of the pipe ('forward' or 'backward').
   */
  createHeaderPipe(e, pipeType) {
    let nodePosition = { x: e.x, y: e.y };
    let nodeId = this.editor.nodeId;
    this.selectedItem = <NodeElement>{};
    this.selectedItem.displayName = '';
    this.selectedItem.name = 'connectTwo';
    this.selectedItem.inputs = 1;
    this.selectedItem.outputs = 1;
    this.selectedItem.class = 'pipeDemarkation';
    this.selectedItem.pipeType = pipeType;
    this.selectedItem.pipeClass = this.getPipeClass(this.tempParentEquipData.class);
    let subEquipId = this.tempParentEquipData.equipData.subEquips[0].equipId;

    if (this.isBypassPipe(this.tempParentEquipData.class)) {
      this.selectedItemID = undefined;
      this.addNodeToDrawBoard(nodePosition.x, nodePosition.y, false, false, false, subEquipId);
    } else {
      this.addNodeToDrawBoard(nodePosition.x, nodePosition.y, false, true, false, subEquipId);
    }

    let filteredData = JSON.parse(JSON.stringify(this.tempParentEquipData));
    filteredData.equipData.subEquips[0].equips.forEach((subEquipObj, subEquipIndex) => {
      if (subEquipObj.hasOwnProperty("master")) {
        if (!subEquipObj.master) {
          delete filteredData.equipData.subEquips[0].equips[subEquipIndex];
        }
      }
    });

    filteredData.equipData.subEquips[0].equips.forEach((subEquipsData, index) => {
      let subEquipName;
      if (subEquipsData.equip[0] == 'byPassLine-connectTwo') {
        subEquipName = subEquipsData.equip[0];
        this.selectedItem = <NodeElement>{};
        this.selectedItem.displayName = 'Connector';
        this.selectedItem.name = 'connectTwo';
        this.selectedItem.inputs = 1;
        this.selectedItem.outputs = 1;
        this.selectedItem.class = 'byPassLine-connectTwo';
      } else {
        subEquipName = `${subEquipsData.equip[0]}_${pipeType}`;
        this.selectedItem = ElementsToBeConfiguredNodes.find(sensorData => {
          return (sensorData.name == subEquipName) || (sensorData.id == subEquipName);
        });
        this.selectedItem.name = subEquipsData?.equip?.[0]?.shortName ? subEquipsData?.equip?.[0]?.shortName : subEquipsData?.equip?.[0]?.pointName
      }
      index = index + 1;

      this.selectedItem.pipeClass = this.getPipeClass(this.tempParentEquipData.class);
      if (pipeType == 'forward') {
        this.addNodeToDrawBoard(nodePosition.x + 70, nodePosition.y + 70, false, false, false, subEquipId, subEquipsData.pointId);
      } else if (pipeType == 'backward') {
        this.addNodeToDrawBoard(nodePosition.x + 70, nodePosition.y + 70, false, false, false, subEquipId, subEquipsData.pointId);
      }
    });

    this.selectedItem = <NodeElement>{};
    this.selectedItem.displayName = '';
    this.selectedItem.name = 'connectTwo';
    this.selectedItem.inputs = 1;
    this.selectedItem.outputs = 1;
    this.selectedItem.class = 'pipeDemarkation';
    this.selectedItem.pipeClass = this.getPipeClass(this.tempParentEquipData.class);

    if (pipeType == 'forward') {
      this.addNodeToDrawBoard(nodePosition.x + 100, nodePosition.y + 70, false, false, false, this.tempParentEquipData.equipData.subEquips[0].equipId);
      for (let i = 0; i <= (this.tempParentEquipData.equipData.subEquips[0].equips.length + 1); i++) {
        const subNodeId = (nodeId + (i + 1));
        const parentNodeId = nodeId + i;
        if (document.getElementById(`node-${subNodeId}`)) {
          document.getElementById(`node-${subNodeId}`).style.left = 'auto';
          document.getElementById(`node-${subNodeId}`).style.top = 'auto';
          const calcLeft = `${(document.getElementById(`node-${parentNodeId}`).getElementsByClassName(`input input_${1}`)[0].getBoundingClientRect().x - document.getElementById(`node-${subNodeId}`).getElementsByClassName(`input input_${1}`)[0].getBoundingClientRect().x) / this.editor.zoom}`;
          const calcTop = `${(document.getElementById(`node-${parentNodeId}`).getElementsByClassName(`input input_${1}`)[0].getBoundingClientRect().y - document.getElementById(`node-${subNodeId}`).getElementsByClassName(`input input_${1}`)[0].getBoundingClientRect().y) / this.editor.zoom}`;
          let offsetLeft;
          let offsetTop;
          if (document.getElementById(`node-${subNodeId}`).getElementsByClassName('equipName')?.length && document.getElementById(`node-${subNodeId}`).getElementsByClassName('equipName')[0].innerHTML == '') {
            let subEquipData = document.getElementById(`node-${subNodeId - 1}`).classList;
            if (subEquipData?.length && ['valveActuator', 'temperatureSensor', 'pressureSensor', 'diffPressureSensor'].some(className => Array.from(subEquipData).some(equipClass => equipClass.includes(className)))) {
              offsetLeft = 120;
              offsetTop = 56;
            } else if (subEquipData?.length && ['threeWayValvemixing', 'threeWayValveDivert'].some(className => Array.from(subEquipData).some(equipClass => equipClass.includes(className)))) {
              offsetLeft = 255;
              offsetTop = 130;
            } else if (subEquipData?.length && Array.from(subEquipData).some(equipClass => equipClass.includes('pipeDemarkation'))) {
              offsetLeft = 240;
              offsetTop = 126;
            } else {
              offsetLeft = 240;
              offsetTop = 110;
            }
            document.getElementById(`node-${subNodeId}`).style.left = (Number(calcLeft) - offsetLeft) + 'px';
            document.getElementById(`node-${subNodeId}`).style.top = (Number(calcTop) + offsetTop) + 'px';
            this.editor.drawflow.drawflow.Home.data[subNodeId].pos_x = (Number(calcLeft) - offsetLeft);
            this.editor.drawflow.drawflow.Home.data[subNodeId].pos_y = (Number(calcTop) + offsetTop);
          } else {
            let subEquipData = document.getElementById(`node-${subNodeId}`).classList;
            if (i == 0) {
              offsetLeft = 150;
              offsetTop = 82;
            } else if (subEquipData?.length && Array.from(subEquipData).some(equipClass => equipClass.includes('valveActuator'))) {
              offsetLeft = 220;
              offsetTop = 110;
            } else if (subEquipData?.length && Array.from(subEquipData).some(equipClass => equipClass.includes('temperatureSensor'))) {
              offsetLeft = 220;
              offsetTop = 116;
            } else if (subEquipData?.length && ['pressureSensor', 'diffPressureSensor'].some(className => Array.from(subEquipData).some(equipClass => equipClass.includes(className)))) {
              offsetLeft = 220;
              offsetTop = 122;
            } else {
              offsetLeft = 220;
              offsetTop = 122;
            }
            document.getElementById(`node-${subNodeId}`).style.left = (Number(calcLeft) - offsetLeft) + 'px';
            document.getElementById(`node-${subNodeId}`).style.top = (Number(calcTop) + offsetTop) + 'px';
            this.editor.drawflow.drawflow.Home.data[subNodeId].pos_x = (Number(calcLeft) - offsetLeft);
            this.editor.drawflow.drawflow.Home.data[subNodeId].pos_y = (Number(calcTop) + offsetTop);
          }
          this.editor.addConnection(parentNodeId, subNodeId, 'output_1', 'input_1', this.getPipeClass(this.tempParentEquipData.class), false, true, subEquipId);
        }
      }
    } else if (pipeType == 'backward') {
      this.addNodeToDrawBoard(nodePosition.x + 100, nodePosition.y + 70, false, false, false, subEquipId);
      for (let i = 0; i <= (this.tempParentEquipData.equipData.subEquips[0].equips.length + 1); i++) {
        const subNodeId = (nodeId + (i + 1));
        const parentNodeId = nodeId + i;
        if (document.getElementById(`node-${subNodeId}`)) {
          document.getElementById(`node-${subNodeId}`).style.left = 'auto';
          document.getElementById(`node-${subNodeId}`).style.top = 'auto';
          const calcLeft = `${(document.getElementById(`node-${parentNodeId}`).getElementsByClassName(`input input_${1}`)[0].getBoundingClientRect().x - document.getElementById(`node-${subNodeId}`).getElementsByClassName(`input input_${1}`)[0].getBoundingClientRect().x) / this.editor.zoom}`;
          const calcTop = `${(document.getElementById(`node-${parentNodeId}`).getElementsByClassName(`input input_${1}`)[0].getBoundingClientRect().y - document.getElementById(`node-${subNodeId}`).getElementsByClassName(`input input_${1}`)[0].getBoundingClientRect().y) / this.editor.zoom}`;
          let offsetLeft;
          let offsetTop;
          if (document.getElementById(`node-${subNodeId}`).getElementsByClassName('equipName')[0].innerHTML == '') {
            let subEquipData = document.getElementById(`node-${subNodeId - 1}`).classList;
            if (subEquipData?.length && ['valveActuator', 'temperatureSensor', 'pressureSensor', 'diffPressureSensor'].some(className => Array.from(subEquipData).some(equipClass => equipClass.includes(className)))) {
              offsetLeft = 140;
              offsetTop = 70;
            } else {
              offsetLeft = 230;
              offsetTop = 115;
            }
            document.getElementById(`node-${subNodeId}`).style.left = (Number(calcLeft) + offsetLeft) + 'px';
            document.getElementById(`node-${subNodeId}`).style.top = (Number(calcTop) + offsetTop) + 'px';
            this.editor.drawflow.drawflow.Home.data[subNodeId].pos_x = (Number(calcLeft) + offsetLeft);
            this.editor.drawflow.drawflow.Home.data[subNodeId].pos_y = (Number(calcTop) + offsetTop);
          } else {
            let subEquipData = document.getElementById(`node-${subNodeId}`).classList;
            if(i==0) {
              offsetLeft = 125;
              offsetTop = 67;
            } else if (subEquipData?.length && Array.from(subEquipData).some(equipClass => equipClass.includes('valveActuator'))) {
              offsetLeft = 240;
              offsetTop = 130;
            } else if (subEquipData?.length && Array.from(subEquipData).some(equipClass => equipClass.includes('temperatureSensor'))) {
              offsetLeft = 250;
              offsetTop = 135;
            } else if (subEquipData?.length && ['pressureSensor', 'diffPressureSensor'].some(className => Array.from(subEquipData).some(equipClass => equipClass.includes(className)))) {
              offsetLeft = 240;
              offsetTop = 130;
            } else {
              offsetLeft = 210;
              offsetTop = 110;
            }
            document.getElementById(`node-${subNodeId}`).style.left = (Number(calcLeft) + offsetLeft) + 'px';
            document.getElementById(`node-${subNodeId}`).style.top = (Number(calcTop) + offsetTop) + 'px';
            this.editor.drawflow.drawflow.Home.data[subNodeId].pos_x = (Number(calcLeft) + offsetLeft);
            this.editor.drawflow.drawflow.Home.data[subNodeId].pos_y = (Number(calcTop) + offsetTop);
          }
          this.editor.addConnection(parentNodeId, subNodeId, 'output_1', 'input_1', this.getPipeClass(this.tempParentEquipData.class), false, true, subEquipId);
        }
      }
    }

    this.tempParentEquipData.equipData.subEquips[0].equips.forEach((subEquipsData) => {
      if (subEquipsData.subEquipType == 'btuMeter') {
        this.selectedItem = ElementsToBeConfiguredNodes.find(sensorData => {
          return sensorData.name == subEquipsData.equips[0].equip[0];
        });
        const btuMeterNodeId = document.getElementsByClassName(`parentEquipId-${this.tempParentEquipData.equipId} btuMeter`)[0].id.slice(5);
        this.addNodeToDrawBoard(nodePosition.x + 70, nodePosition.y + 70, false, false, false, "", this.tempParentEquipData.equipData.subEquips[0].equipId);
        this.editor.addConnection(btuMeterNodeId, this.editor.nodeId - 1, 'output_1', 'input_1', this.getPipeClass(this.tempParentEquipData.class), false, false, '', true);
      }
    });

    if (pipeType == 'forward') {
      const newNodeId = this.editor.nodeId;
      this.selectedItemID = newNodeId;
      this.addNodeToDrawBoard(nodePosition.x - 180, nodePosition.y, false, false, true, "", this.tempParentEquipData.equipId);
    } else if (pipeType == 'backward') {
      const newNodeId = this.editor.nodeId;
      this.selectedItemID = newNodeId;
      this.addNodeToDrawBoard(nodePosition.x, nodePosition.y, false, false, true, "", this.tempParentEquipData.equipId);
    }
  }

  restoreTheBtuMeterAndSubEquip() {
    const found = this.duplicateNodesArr.find((_node: NodeElement) => {
      if (_node.class == 'btuMeter' && _node.equipData['subEquips'][0].equipId == this.deletedNodeData.childData.equipData.subEquips[0].equipId) {
        _node['equipId'] = this.deletedNodeData.childData.equipData.subEquips[0].equipId;
        _node.equipData['subEquips'][0].pipeClass = this.setPipeClassByTags(_node.equipData['subEquips'][0].parentEquipTags);
        return _node['equipId'] === this.deletedNodeData.childData.equipData.subEquips[0].equipId;
      }
    });

    if (found && this.editor.nodeDeleteClicked) {
      const findDuplicateObj = this.nodes.find((_node: NodeElement) => {
        return _node.equipId == this.deletedNodeData.childData.equipData.subEquips[0].equipId;
      });
      if (!findDuplicateObj) {
        this.nodes.push(found);
      }
      this.nodes = this.sortNodes(this.nodes);
    }
    this.deletedNodeData = {};
  }

  restoreThePumpVertical() {
    const found = this.duplicateNodesArr.find((_node: NodeElement) => {
      if ((_node.class.includes('pump1Vertical')) && _node.equipData['subEquips'][0].equipId == this.deletedNodeData.childData.equipData.subEquips[0].equipId) {
        _node['equipId'] = this.deletedNodeData.childData.equipData.subEquips[0].equipId;
        _node.equipData['subEquips'][0].pipeClass = this.setPipeClassByTags(_node.equipData['subEquips'][0].parentEquipTags);
        return _node['equipId'] === this.deletedNodeData.childData.equipData.subEquips[0].equipId;
      }
    });
    if (found && this.editor.nodeDeleteClicked) {
      const findDuplicateObj = this.nodes.find((_node: NodeElement) => {
        return _node.equipId == this.deletedNodeData.childData.equipData.subEquips[0].equipId;
      });
      if (!findDuplicateObj) {
        this.nodes.push(found);
      }
      this.nodes = this.sortNodes(this.nodes);
    }
    this.deletedNodeData = {};
  }

  checkHeaderPipeGroup(equipdata) {
    if (equipdata.class == "GROUP" && this.isHeaderPipe(equipdata.childData.class)) {
      return true;
    } else {
      return false;
    }
  }

  checkBypassPipeGroup(equipdata) {
    if (equipdata.class == "GROUP" && this.isBypassPipe(equipdata.childData.class)) {
      return true;
    } else {
      return false;
    }
  }

  checkSupplyOrReturnPipe(data) {
    if (data.nodePipeClass == "CDWReturnClass" || data.nodePipeClass == "CWSupplyClass" || data.nodePipeClass == "boilerSupplyClass" || data.nodePipeClass == "DTSupplyClass") {
      return 'supply';
    } else {
      return 'return';
    }
  }

  ngAfterViewInit(): void {
    let dragElementHover = null;
    let last_x = 0;
    let last_y = 0;

    this.drawFlowHtmlElement = <HTMLElement>document.getElementById('drawflow');
    this.initDrawFlow(this.drawFlowHtmlElement);

    // Events!
    this.editor.on('nodeCreated', (id: any) => {
      this.selectedItemID = id;
      let nodeData = this.editor.getNodeFromId(id);
      if (nodeData != undefined && !this.isHeaderPipe(this.tempParentEquipData?.class) && nodeData?.class != "GROUP" && !this.isBypassPipe(this.tempParentEquipData?.class) && !this.isConnector(nodeData?.class)) {
        this.createNodesOnEquipDrop();
      }
      this.checkPipeConnection('nodeCreated');
    });

    this.editor.on('savePipeRef', (connection) => {
      // this.savePipeRefsToConfig(connection);
    });

    this.editor.on('nodeGroupRemoved', (id: any) => {
      // if ((this.isParentEquip(this.editor?.deletedNodeData?.childData.class) || this.isHeaderPipe(this.editor?.deletedNodeData?.childData.class))) {
      //   this.deleteEquipAndConfig(this.editor?.deletedNodeData?.equipId);
      // }
      this.groupDeletionClicked = true;
      this.groupNodeDeletedData = ObjectUtil.deepClone(this.editor.deletedNodeData);
      this.editor.deletedNodeData.data.elements.forEach(nodeId => {
        this.editor.removeNodeId(`node-${nodeId}`);
      });

      this.restoreTheNodeOnRemoval();
      this.groupDeletionClicked = false;
      setTimeout(() => {
        this.checkPipeConnection('nodeGroupRemoved');
      }, 1000);
    });

    this.editor.on('nodeRemoved', (id: any) => {
      // Redrawing the connection after node(PUMP, BTU Meter, Connectors) is deleted
      if ((this.editor.deletedNodeData?.childData?.class == 'btuMeter' || this.editor?.deletedNodeData?.class == 'btuMeter')) {
        this.deletedNodeData = ObjectUtil.deepClone(this.editor.deletedNodeData);
        if (this.groupDeletionClicked) {
          const parentNodeData = this.groupNodeDeletedData;
          const pipeClass = this.getPipeClass(parentNodeData.childData.class);
          if (this.editor.drawflow.drawflow.Home.data[parentNodeData.id]?.data?.elements) {
            this.editor.drawflow.drawflow.Home.data[parentNodeData.id].data.elements = this.editor.drawflow.drawflow.Home.data[parentNodeData.id].data.elements.filter(_node => _node != this.deletedNodeData.id);
          }
          if (this.editor.deletedNodeData?.class == 'btuMeter') {
            if (this.editor.drawflow.drawflow.Home.data[parentNodeData?.id]?.childData?.equipData?.subEquips[0]?.equips) {
              this.editor.drawflow.drawflow.Home.data[parentNodeData.id].childData.equipData.subEquips[0].equips = this.editor.drawflow.drawflow.Home.data[parentNodeData.id].childData.equipData.subEquips[0].equips.filter(_subequip => _subequip?.subEquipType != 'btuMeter');
            }
            this.restoreTheBtuMeterAndSubEquip();
            this.onBtuMeterDeletion(pipeClass);
            this.reStorePipeConnectionOnBtuMeterDeletion(id);
          }
          this.groupDeletionClicked = false;
        } else {
          const groupId = document.getElementsByClassName(`parentEquipId-${this.deletedNodeData.equipId} GROUP`)[0]?.id.slice(5);

          let pipeClass;
          if (this.editor.drawflow.drawflow.Home.data[Number(id)]?.nodePipeClass != undefined && this.editor.drawflow.drawflow.Home.data[Number(id)]?.nodePipeClass != '') {
            pipeClass = this.editor.drawflow.drawflow.Home.data[Number(id)]?.nodePipeClass;
          } else {
            pipeClass = this.editor.drawflow.drawflow.Home.data[Number(id)].connectedParentSubEquipPipeClass;
          }
          if (this.editor.drawflow.drawflow.Home.data[groupId]?.data?.elements) {
            this.editor.drawflow.drawflow.Home.data[groupId].data.elements = this.editor.drawflow.drawflow.Home.data[groupId].data.elements.filter(_node => _node != this.deletedNodeData.id);
          }
          if (this.editor.deletedNodeData?.childData.class == 'btuMeter') {
            if (this.editor.drawflow.drawflow.Home.data[groupId]?.childData?.equipData?.subEquips[0]?.equips) {
              this.editor.drawflow.drawflow.Home.data[groupId].childData.equipData.subEquips[0].equips = this.editor.drawflow.drawflow.Home.data[groupId].childData.equipData.subEquips[0].equips.filter(_subequip => _subequip?.subEquipType != 'btuMeter');
            }
            this.restoreTheBtuMeterAndSubEquip();
            this.onBtuMeterDeletion(pipeClass);
            this.reStorePipeConnectionOnBtuMeterDeletion(id);
          }
        }
      }

      if (this.editor?.deletedNodeData?.class.includes('pump1Vertical') || this.editor?.deletedNodeData?.class.includes('byPassLine-connectTwo')) {
        this.deletedNodeData = ObjectUtil.deepClone(this.editor.deletedNodeData);
        const groupId = document.getElementsByClassName(`parentEquipId-${this.deletedNodeData.equipId} GROUP`)[0]?.id.slice(5);
        if (groupId) {
          if (this.editor.drawflow.drawflow.Home.data[groupId]?.childData?.equipData?.subEquips[0]?.equips) {
            this.editor.drawflow.drawflow.Home.data[groupId].childData.equipData.subEquips[0].equips = this.editor.drawflow.drawflow.Home.data[groupId].childData.equipData.subEquips[0].equips.filter(_subequip => _subequip?.subEquipType != 'pump1Vertical');
          }
          if (this.editor.drawflow.drawflow.Home.data[groupId]?.data?.elements) {
            this.editor.drawflow.drawflow.Home.data[groupId].data.elements = this.editor.drawflow.drawflow.Home.data[groupId].data.elements.filter(_node => _node != this.deletedNodeData.id);
          }
        }
        if (this.selectedItemNode) {
          this.selectedItemNodeIsSensor = true;
          this.selectedItemNodeInputId = this.selectedItemNode.inputs.input_1?.connections[0]?.node;
          this.selectedItemNodeInputPort = this.selectedItemNode.inputs.input_1?.connections[0]?.input;
          this.selectedItemNodeOutputId = this.selectedItemNode.outputs.output_1?.connections[0]?.node;
          this.selectedItemNodeOutputPort = this.selectedItemNode.outputs.output_1?.connections[0]?.output;
        }
        this.createConnectionOnSensorDeletion(this.deletedNodeData.nodePipeClass, this.editor.deletedNodeData.groupPipeId);
        this.restoreThePumpVertical();
      } else if (this.editor?.deletedNodeData?.class.includes('subEquip-connectTwo')) {
        let connectedNodeDataArr = [];
        this.editor?.deletedNodeData.inputs.input_1.connections.forEach(nodeData => {
          let connectedNodeData = this.editor.getNodeFromId(String(nodeData.node));
          if (connectedNodeData.id != this.editor?.deletedNodeData.id && (this.isParentEquip(connectedNodeData.class) || connectedNodeData?.groupPipeId == this.editor?.deletedNodeData?.groupPipeId)) {
            connectedNodeData.connectedNodeMapping = nodeData;
            connectedNodeDataArr.push(connectedNodeData);
          }
        });

        this.editor?.deletedNodeData.outputs.output_1.connections.forEach(nodeData => {
          let connectedNodeData = this.editor.getNodeFromId(String(nodeData.node));
          if (connectedNodeData.id != this.editor?.deletedNodeData.id && (this.isParentEquip(connectedNodeData.class) || connectedNodeData?.groupPipeId == this.editor?.deletedNodeData?.groupPipeId)) {
            connectedNodeData.connectedNodeMapping = nodeData;
            connectedNodeDataArr.push(connectedNodeData);
          }
        });

        if (connectedNodeDataArr.length) {
          this.selectedItemNodeIsSensor = true;
          this.selectedItemNodeInputId = connectedNodeDataArr[0].connectedNodeMapping.node;
          this.selectedItemNodeInputPort = connectedNodeDataArr[0].connectedNodeMapping.input != undefined ? connectedNodeDataArr[0].connectedNodeMapping.input : connectedNodeDataArr[0].connectedNodeMapping.output;
          this.selectedItemNodeOutputId = connectedNodeDataArr[1].connectedNodeMapping.node;
          this.selectedItemNodeOutputPort = connectedNodeDataArr[1].connectedNodeMapping.input != undefined ? connectedNodeDataArr[1].connectedNodeMapping.input : connectedNodeDataArr[1].connectedNodeMapping.output;
        }
        this.createConnectionOnSensorDeletion(this.editor?.deletedNodeData?.nodePipeClass, this.editor.deletedNodeData.groupPipeId);
      }
      setTimeout(() => {
        this.checkPipeConnection('nodeRemoved');
      }, 1000);
    });

    // Loading master view points data to equips.
    this.editor.on('loadMasterViewPoints', (selectedPlantData: any) => {
      if (this.loginUserTypeData.isPublic) {
        this.themeChanged(['light', false]);
      }
      //set 5 mins interval for master view point data.
      this.set5minsIntervalRefresh();
      if (this.editor.editor_mode != 'edit') {
        this.loadMasterViewPoints(selectedPlantData);
      }
    });

    this.editor.on('nodeSelected', (id: any) => {
      this.setSelectedNode(id);
    });

    this.editor.on('moduleCreated', (name: any) => {
      this.checkPipeConnection('moduleCreated');
    });

    this.editor.on('moduleChanged', (name: any) => {
      this.checkPipeConnection('moduleChanged');
    });

    this.editor.on('connectionCreated', (connection: any) => {
      let inputConnectionData = this.editor.getNodeFromId(connection.input_id);
      let outputConnectionData = this.editor.getNodeFromId(connection.output_id);

      // When a connection made to heatexchanger remove rotate icon from equip.

      if (inputConnectionData?.class == 'connectTwo' && inputConnectionData?.childData?.class.includes('heatExchanger') && !outputConnectionData?.childData?.class.includes('heatExchanger')) {
        const element = document.getElementsByClassName(`connectTwo parentEquipId-${inputConnectionData.childData.equipId}`);
        if (element.length) {
          Array.from(element).forEach(_nodeEle => {
            const nodeId = _nodeEle.id.toString().split('node-')[1];
            const nodeData = this.editor.getNodeFromId(nodeId);
            if (nodeData?.outputs?.output_1?.connections?.length) {
              if ((<HTMLElement>document.getElementsByClassName(`parentEquipId-${inputConnectionData?.childData?.equipId}`)?.[0].getElementsByClassName('heatExchangerPipeArrowImg_backward')[0])) {
                (<HTMLElement>document.getElementsByClassName(`parentEquipId-${inputConnectionData?.childData?.equipId}`)?.[0].getElementsByClassName('heatExchangerPipeArrowImg_backward')[0]).style.display = 'none';
              } else if ((<HTMLElement>document.getElementsByClassName(`parentEquipId-${inputConnectionData?.childData?.equipId}`)?.[0].getElementsByClassName('heatExchangerPipeArrowImg_forward')[0])) {
                (<HTMLElement>document.getElementsByClassName(`parentEquipId-${inputConnectionData?.childData?.equipId}`)?.[0].getElementsByClassName('heatExchangerPipeArrowImg_forward')[0]).style.display = 'none';
              }
            }
          });
        }
      } else if (outputConnectionData?.class == 'connectTwo' && !inputConnectionData?.childData?.class.includes('heatExchanger') && outputConnectionData?.childData?.class.includes('heatExchanger')) {
        const element = document.getElementsByClassName(`connectTwo parentEquipId-${outputConnectionData.childData.equipId}`);
        if (element.length) {
          Array.from(element).forEach(_nodeEle => {
            const nodeId = _nodeEle.id.toString().split('node-')[1];
            const nodeData = this.editor.getNodeFromId(nodeId);
            if (nodeData?.outputs?.output_1?.connections?.length) {
              if ((<HTMLElement>document.getElementsByClassName(`parentEquipId-${outputConnectionData?.childData?.equipId}`)?.[0].getElementsByClassName('heatExchangerPipeArrowImg_backward')[0])) {
                (<HTMLElement>document.getElementsByClassName(`parentEquipId-${outputConnectionData?.childData?.equipId}`)?.[0].getElementsByClassName('heatExchangerPipeArrowImg_backward')[0]).style.display = 'none';
              } else if ((<HTMLElement>document.getElementsByClassName(`parentEquipId-${outputConnectionData?.childData?.equipId}`)?.[0].getElementsByClassName('heatExchangerPipeArrowImg_forward')[0])) {
                (<HTMLElement>document.getElementsByClassName(`parentEquipId-${outputConnectionData?.childData?.equipId}`)?.[0].getElementsByClassName('heatExchangerPipeArrowImg_forward')[0]).style.display = 'none';
              }
            }
          });
        }
      }

      if ((inputConnectionData.class == "connectTwo" && inputConnectionData.childData.class.includes('heatExchanger')) && (((outputConnectionData.class == "connectTwo" && this.isMainEquip(outputConnectionData.childData.class)) || (outputConnectionData.class == "pipeDemarkation" && this.isHeaderPipe(outputConnectionData.childData.class))))) {
        this.editor.drawflow.drawflow[this.editor.module].data[inputConnectionData.id].connectedParentSubEquipId = outputConnectionData.groupPipeId;
        this.editor.drawflow.drawflow[this.editor.module].data[inputConnectionData.id].connectedParentSubEquipPipeClass = outputConnectionData.nodePipeClass;
        this.editor.drawflow.drawflow[this.editor.module].data[inputConnectionData.id].connectedParentClass = outputConnectionData.childData.class;
        this.editor.drawflow.drawflow[this.editor.module].data[inputConnectionData.id].connectedParentEquipId = outputConnectionData.childData.equipId;
      } else if ((outputConnectionData.class == "connectTwo" && outputConnectionData.childData.class.includes('heatExchanger')) && (((inputConnectionData.class == "connectTwo" && this.isMainEquip(inputConnectionData.childData.class)) || (inputConnectionData.class == "pipeDemarkation" && this.isHeaderPipe(inputConnectionData.childData.class))))) {
        this.editor.drawflow.drawflow[this.editor.module].data[outputConnectionData.id].connectedParentSubEquipId = inputConnectionData.groupPipeId;
        this.editor.drawflow.drawflow[this.editor.module].data[outputConnectionData.id].connectedParentSubEquipPipeClass = inputConnectionData.nodePipeClass;
        this.editor.drawflow.drawflow[this.editor.module].data[outputConnectionData.id].connectedParentClass = inputConnectionData.childData.class;
        this.editor.drawflow.drawflow[this.editor.module].data[outputConnectionData.id].connectedParentEquipId = inputConnectionData.childData.equipId;
      }
      if ((inputConnectionData.class == "connectTwo" && this.isMainEquip(inputConnectionData.childData.class)) && (outputConnectionData.class == "pipeDemarkation" && this.isHeaderPipe(outputConnectionData.childData.class))) {
        this.editor.drawflow.drawflow[this.editor.module].data[outputConnectionData.id].connectedParentSubEquipId = inputConnectionData.groupPipeId;
        this.editor.drawflow.drawflow[this.editor.module].data[outputConnectionData.id].connectedParentSubEquipPipeClass = inputConnectionData.nodePipeClass;
        this.editor.drawflow.drawflow[this.editor.module].data[outputConnectionData.id].connectedParentClass = inputConnectionData.childData.class;
        this.editor.drawflow.drawflow[this.editor.module].data[outputConnectionData.id].connectedParentEquipId = inputConnectionData.childData.equipId;
      } else if ((outputConnectionData.class == "connectTwo" && this.isMainEquip(outputConnectionData.childData.class)) && (inputConnectionData.class == "pipeDemarkation" && this.isHeaderPipe(inputConnectionData.childData.class))) {
        this.editor.drawflow.drawflow[this.editor.module].data[inputConnectionData.id].connectedParentSubEquipId = outputConnectionData.groupPipeId;
        this.editor.drawflow.drawflow[this.editor.module].data[inputConnectionData.id].connectedParentSubEquipPipeClass = outputConnectionData.nodePipeClass;
        this.editor.drawflow.drawflow[this.editor.module].data[inputConnectionData.id].connectedParentClass = outputConnectionData.childData.class;
        this.editor.drawflow.drawflow[this.editor.module].data[inputConnectionData.id].connectedParentEquipId = outputConnectionData.childData.equipId;
      } else if ((inputConnectionData.class == "connectTwo" && outputConnectionData.class == "connectorLbend") || (inputConnectionData.class == "pipeDemarkation" && outputConnectionData.class == "connectorLbend")) {
        this.editor.drawflow.drawflow[this.editor.module].data[outputConnectionData.id].connectedParentSubEquipId = inputConnectionData.groupPipeId;
        this.editor.drawflow.drawflow[this.editor.module].data[outputConnectionData.id].connectedParentSubEquipPipeClass = inputConnectionData.nodePipeClass;
        this.editor.drawflow.drawflow[this.editor.module].data[outputConnectionData.id].connectedParentClass = inputConnectionData.childData.class;
        this.editor.drawflow.drawflow[this.editor.module].data[outputConnectionData.id].connectedParentEquipId = inputConnectionData.childData.equipId;
      } else if ((outputConnectionData.class == "connectTwo" && inputConnectionData.class == "connectorLbend") || (outputConnectionData.class == "pipeDemarkation" && inputConnectionData.class == "connectorLbend")) {
        this.editor.drawflow.drawflow[this.editor.module].data[inputConnectionData.id].connectedParentSubEquipId = outputConnectionData.groupPipeId;
        this.editor.drawflow.drawflow[this.editor.module].data[inputConnectionData.id].connectedParentSubEquipPipeClass = outputConnectionData.nodePipeClass;
        this.editor.drawflow.drawflow[this.editor.module].data[inputConnectionData.id].connectedParentClass = outputConnectionData.childData.class;
        this.editor.drawflow.drawflow[this.editor.module].data[inputConnectionData.id].connectedParentEquipId = outputConnectionData.childData.equipId;
      }



      if (this.isBypassPipe(inputConnectionData.childData.class) || this.isBypassPipe(outputConnectionData.childData.class)) {
        let byePassPipeData, headerPipeData;
        byePassPipeData = this.isBypassPipe(inputConnectionData?.childData?.class) ? inputConnectionData : outputConnectionData;
        headerPipeData = this.isHeaderPipe(inputConnectionData?.childData?.class) ? inputConnectionData : outputConnectionData;
        Object.keys(this.editor.drawflow.drawflow[this.editor.module].data).forEach(ele => {
          if (this.editor.drawflow.drawflow[this.editor.module].data[ele].class === "GROUP" && this.editor.drawflow.drawflow[this.editor.module].data[ele].equipId == byePassPipeData.equipId) {
            if (this.editor.drawflow.drawflow[this.editor.module].data[ele].connectedNodeData?.length) {
              this.editor.drawflow.drawflow[this.editor.module].data[ele].connectedNodeData.push(headerPipeData)
            } else {
              this.editor.drawflow.drawflow[this.editor.module].data[ele].connectedNodeData = [headerPipeData];
            }
            if (this.editor.drawflow.drawflow[this.editor.module].data[ele].byePassConnection1 == undefined) {
              this.editor.drawflow.drawflow[this.editor.module].data[ele].byePassConnection1 = this.checkSupplyOrReturnPipe(headerPipeData);
            } else if (this.editor.drawflow.drawflow[this.editor.module].data[ele].byePassConnection2 == undefined) {
              this.editor.drawflow.drawflow[this.editor.module].data[ele].byePassConnection2 = this.checkSupplyOrReturnPipe(headerPipeData);
            }
          }
        });
      }

      if (inputConnectionData.class == 'pipeDemarkation' || outputConnectionData.class == 'pipeDemarkation') {
        this.pipeArrowIconShowHide(inputConnectionData,outputConnectionData);
      }

      document.querySelector(`.connection.node_in_node-${connection.input_id}.node_out_node-${connection.output_id}.${connection.output_class}.${connection.input_class} path.main-path`).addEventListener("dragenter", event => {
        // highlight potential drop target when the draggable element enters it
        let elementT: any = event.target;
        if (!elementT.classList.contains("orangeClass")) {
          elementT.classList.add("orangeClass");
        }
      });

      document.querySelector(`.connection.node_in_node-${connection.input_id}.node_out_node-${connection.output_id}.${connection.output_class}.${connection.input_class} path.main-path`).addEventListener("dragleave", event => {
        // reset background of potential drop target when the draggable leaves it
        let elementT: any = event.target;
        if (elementT.classList.contains("orangeClass")) {
          elementT.classList.remove("orangeClass");
        }
      });

      document.querySelector(`.connection.node_in_node-${connection.input_id}.node_out_node-${connection.output_id}.${connection.output_class}.${connection.input_class} path.main-path`).addEventListener("mouseover", event => {
        // highlight all group pipe on hover
        let elementT: any = event.target;
        let groupPipeId = '';
        elementT?.parentElement?.classList.forEach(_class => {
          if (_class.includes('groupPipe-')) {
            groupPipeId = _class;
          }
        });
        if (groupPipeId) {
          let groupPipeElement = document.querySelectorAll(`.${groupPipeId}`);
          groupPipeElement.forEach(pipeElement => {
            if (!pipeElement.classList.contains("orangeClass")) {
              pipeElement.classList.add("orangeClass");
            }
          });
        }
      });

      document.querySelector(`.connection.node_in_node-${connection.input_id}.node_out_node-${connection.output_id}.${connection.output_class}.${connection.input_class} path.main-path`).addEventListener("mouseout", event => {
        let elementT: any = event.target;
        let groupPipeId = '';
        elementT?.parentElement?.classList.forEach(_class => {
          if (_class.includes('groupPipe-')) {
            groupPipeId = _class;
          }
        });
        if (groupPipeId) {
          let groupPipeElement = document.querySelectorAll(`.${groupPipeId}`);
          groupPipeElement.forEach(pipeElement => {
            if (pipeElement.classList.contains("orangeClass")) {
              pipeElement.classList.remove("orangeClass");
            }
          });
        }
      });

      this.getConnectionCustomization(connection);
      this.checkPipeConnection('connectionCreated');

      if (inputConnectionData.class == 'connectorLbend' && outputConnectionData.class != 'connectorLbend' || outputConnectionData.class == 'connectorLbend' && inputConnectionData.class != 'connectorLbend') {
        let nodeData: any;
        let pipeClass;
        let connectorLbendData = inputConnectionData.class == 'connectorLbend' ? inputConnectionData : outputConnectionData;
        const groupId = document.getElementsByClassName(`parentEquipId-${connectorLbendData.equipId} GROUP`)[0]?.id.slice(5);
        let groupNodeData = this.editor.drawflow.drawflow[this.editor.module].data[groupId];
        let nodeConnectedData = [];
        groupNodeData?.data?.elements.forEach(nodeIds => {
          nodeData = this.editor.getNodeFromId(String(nodeIds));
          // Add the pipe class to the connectorLbend node.
          if(nodeData?.inputs?.input_1?.connections.length == 1 && nodeData?.outputs?.output_1?.connections.length == 1){
            pipeClass = nodeData.connectedParentSubEquipPipeClass;
            nodeConnectedData.push(nodeData.inputs.input_1.connections.length);
          } else if (nodeData?.inputs?.input_1?.connections.length == 2) {
            pipeClass = nodeData.connectedParentSubEquipPipeClass;
            nodeConnectedData.push(nodeData.inputs.input_1.connections.length);
          } else if (nodeData?.outputs?.output_1?.connections.length == 2) {
            pipeClass = nodeData.connectedParentSubEquipPipeClass;
            nodeConnectedData.push(nodeData.outputs.output_1.connections.length);
          }
        });

        if (nodeConnectedData.length) {
          var els: any = document.querySelector(`#pipeRotateImg_${nodeData?.equipId}`);
          if (els != null) {
            els.style.display = 'none';
          }

          let lbendNodeElement: any = document.querySelectorAll(`.groupPipe-${nodeData?.equipId} path.main-path`);
          lbendNodeElement = Array.from(lbendNodeElement);
          if (lbendNodeElement.length) {
            lbendNodeElement.forEach(element => {
              element.style.stroke = this.equipConfiguration.pipeColorMapping[pipeClass];
            });
          }
        }
      }
    });

    this.editor.on('connectionRemoved', (connection: any) => {
      let inputConnectionData = this.editor.getNodeFromId(connection.input_id);
      let outputConnectionData = this.editor.getNodeFromId(connection.output_id);

      // Restore the rotate icon if all the connections of heatexchanger is removed.
      if (inputConnectionData?.class == 'connectTwo' && inputConnectionData?.childData?.class.includes('heatExchanger') && !outputConnectionData?.childData?.class.includes('heatExchanger')) {
        const element = document.getElementsByClassName(`connectTwo parentEquipId-${inputConnectionData.childData.equipId}`);
        if (element?.length) {
          const emptyArr = [];
          Array.from(element).forEach((_nodeEle, i) => {
            const nodeId = _nodeEle.id.toString().split('node-')[1];
            const nodeData = this.editor.getNodeFromId(nodeId);
            if (nodeData?.outputs?.output_1?.connections?.length) {
              emptyArr.push(i);
            }
            if ((Array.from(element).length - 1) == i && !(emptyArr?.length)) {
              if ((<HTMLElement>document.getElementsByClassName(`parentEquipId-${inputConnectionData?.childData?.equipId}`)?.[0].getElementsByClassName('heatExchangerPipeArrowImg_backward')[0])) {
                (<HTMLElement>document.getElementsByClassName(`parentEquipId-${inputConnectionData?.childData?.equipId}`)?.[0].getElementsByClassName('heatExchangerPipeArrowImg_backward')[0]).style.display = 'block';
              } else if ((<HTMLElement>document.getElementsByClassName(`parentEquipId-${inputConnectionData?.childData?.equipId}`)?.[0].getElementsByClassName('heatExchangerPipeArrowImg_forward')[0])) {
                (<HTMLElement>document.getElementsByClassName(`parentEquipId-${inputConnectionData?.childData?.equipId}`)?.[0].getElementsByClassName('heatExchangerPipeArrowImg_forward')[0]).style.display = 'block';
              }
            }
          });
        }
      } else if (outputConnectionData?.class == 'connectTwo' && !inputConnectionData?.childData?.class.includes('heatExchanger') && outputConnectionData?.childData?.class.includes('heatExchanger')) {
        const element = document.getElementsByClassName(`connectTwo parentEquipId-${outputConnectionData.childData.equipId}`);
        if (element.length) {
          const emptyArr = [];
          Array.from(element).forEach((_nodeEle, i) => {
            const nodeId = _nodeEle.id.toString().split('node-')[1];
            const nodeData = this.editor.getNodeFromId(nodeId);
            if (nodeData?.outputs?.output_1?.connections?.length) {
              emptyArr.push(i);
            }
            if ((Array.from(element).length - 1) == i && !(emptyArr?.length)) {
              if ((<HTMLElement>document.getElementsByClassName(`parentEquipId-${outputConnectionData?.childData?.equipId}`)?.[0]?.getElementsByClassName('heatExchangerPipeArrowImg_backward')[0])) {
                (<HTMLElement>document.getElementsByClassName(`parentEquipId-${outputConnectionData?.childData?.equipId}`)?.[0]?.getElementsByClassName('heatExchangerPipeArrowImg_backward')[0]).style.display = 'block';
              } else if ((<HTMLElement>document.getElementsByClassName(`parentEquipId-${outputConnectionData?.childData?.equipId}`)?.[0]?.getElementsByClassName('heatExchangerPipeArrowImg_forward')[0])) {
                (<HTMLElement>document.getElementsByClassName(`parentEquipId-${outputConnectionData?.childData?.equipId}`)?.[0]?.getElementsByClassName('heatExchangerPipeArrowImg_forward')[0]).style.display = 'block';
              }
            }
          });
        }
      }
      if (inputConnectionData) {
        const warningIcon = document.getElementsByClassName(`${inputConnectionData.childData.equipId}_warning-icon`);
        if (Array.from(warningIcon).length) {
          Array.from(warningIcon).forEach(_ele => { _ele.remove() });
        }
      }

      if (inputConnectionData) {
        const warningIcon = document.getElementsByClassName(`${inputConnectionData.childData.equipId}_warning-icon`);
        if (Array.from(warningIcon).length) {
          Array.from(warningIcon).forEach(_ele => { _ele.remove() });
        }
      }

      if (inputConnectionData) {
        const warningIcon = document.getElementsByClassName(`${inputConnectionData.childData.equipId}_warning-icon`);
        if (Array.from(warningIcon).length) {
          Array.from(warningIcon).forEach(_ele => { _ele.remove() });
        }
      }

      if (inputConnectionData.class == "byPassLine-connectTwo" || outputConnectionData.class == "byPassLine-connectTwo") {
        let connectorData, bypassData;
        connectorData = inputConnectionData.class == "byPassLine-connectTwo" ? inputConnectionData : outputConnectionData;
        bypassData = inputConnectionData.class == "byPassLine-connectTwo" ? outputConnectionData : inputConnectionData;
        let pipeType = this.checkSupplyOrReturnPipe(connectorData);
        Object.keys(this.editor.drawflow.drawflow[this.editor.module].data).forEach(ele => {
          if (this.editor.drawflow.drawflow[this.editor.module].data[ele].class === "GROUP" && this.editor.drawflow.drawflow[this.editor.module].data[ele].equipId == bypassData.equipId) {
            if (this.editor.drawflow.drawflow[this.editor.module].data[ele].connectedNodeData?.length) {
              this.editor.drawflow.drawflow[this.editor.module].data[ele].connectedNodeData = this.editor.drawflow.drawflow[this.editor.module].data[ele].connectedNodeData.filter((nodeObj) => {
                nodeObj.id != connectorData.id;
              })
            }
            if (this.editor.drawflow.drawflow[this.editor.module].data[ele].byePassConnection1 == pipeType) {
              delete this.editor.drawflow.drawflow[this.editor.module].data[ele].byePassConnection1;
            } else if (this.editor.drawflow.drawflow[this.editor.module].data[ele].byePassConnection2 == pipeType) {
              delete this.editor.drawflow.drawflow[this.editor.module].data[ele].byePassConnection2;
            }
          }
        })
      }

      if (this.editor.precanvas.getElementsByClassName("drawflow-delete").length) {
        this.editor.precanvas.getElementsByClassName("drawflow-delete")[0].remove();
      };
      let plantData = this.editor.drawflow.drawflow.Home.data;
      const plantDataArr = [];
      Object.keys(plantData).forEach(key => plantDataArr.push(plantData[key]));
      let inputEquipData = this.editor.getNodeFromId(connection.input_id);
      let outputEquipData = this.editor.getNodeFromId(connection.output_id);
      plantDataArr.forEach(equipData => {
        if (this.isSensorNode(equipData.class) && (equipData.id == inputEquipData.id || equipData.id == outputEquipData.id)) {
          if ((equipData.inputs.input_1.connections.length == 0 && equipData.outputs.output_1.connections.length == 0) ||
            (equipData.class == "btuMeter" && equipData.id == inputEquipData.id) ||
            (equipData.class.includes("pump1Vertical") && equipData.id == inputEquipData.id)) {
            this.editor.removeNodeId('node-' + equipData.id);
          }
        }
      });
      this.checkPipeConnection('connectionRemoved');


      if (inputConnectionData.class == 'connectorLbend' && outputConnectionData.class != 'connectorLbend' || outputConnectionData.class == 'connectorLbend' && inputConnectionData.class != 'connectorLbend') {
        let nodeData: any;
        let pipeClass;
        let connectorLbendData = inputConnectionData.class == 'connectorLbend' ? inputConnectionData : outputConnectionData;
        const groupId = document.getElementsByClassName(`parentEquipId-${connectorLbendData.equipId} GROUP`)[0]?.id.slice(5);
        let groupNodeData = this.editor.drawflow.drawflow[this.editor.module].data[groupId];
        let nodeConnectedData = [];
        groupNodeData?.data?.elements.forEach(nodeIds => {
          nodeData = this.editor.getNodeFromId(String(nodeIds));
          if (nodeData?.inputs?.input_1?.connections?.length == 2) {
            pipeClass = nodeData.connectedParentSubEquipPipeClass;
            nodeConnectedData.push(nodeData.inputs.input_1.connections.length);
          } else if (nodeData?.outputs?.output_1?.connections?.length == 2) {
            pipeClass = nodeData.connectedParentSubEquipPipeClass;
            nodeConnectedData.push(nodeData.outputs.output_1.connections.length);
          }
        });

        if (nodeConnectedData.length == 0) {
          var els: any = document.querySelector(`#pipeRotateImg_${groupNodeData?.equipId}`);
          if (els != null) {
            els.style.display = 'block';
          }

          let lbendNodeElement: any = document.querySelectorAll(`.groupPipe-${nodeData?.equipId} path.main-path`);
          lbendNodeElement = Array.from(lbendNodeElement);
          if (lbendNodeElement.length == 1) {
            lbendNodeElement.forEach(element => {
              element.style.stroke = '#b9b9b9';
            });
          }
        }
      }

      if (inputConnectionData.class == 'pipeDemarkation' || outputConnectionData.class == 'pipeDemarkation') {
        this.pipeArrowIconShowHide(inputConnectionData,outputConnectionData);
      }
    });

    this.editor.on('connectionNotRemoved', (msg: any) => {
      this.showToast('error', msg)
    })

    this.editor.on('connectionStart', (ele: any) => { });

    this.editor.on('mouseMove', (position: any) => {
      let selectednodeClassList;
      if (this.editor.node_selected) {
        selectednodeClassList = [...this.editor.node_selected.classList];
      }
      this.setLastMousePosition(position);
      if (this.editor.node_selected && this.editor.drag && (selectednodeClassList[1] !== "GROUP" && selectednodeClassList[1] !== "connectorLbend")) {
        const nodeId = this.editor.node_selected.id.slice(5);
        const dragNodeInfo = this.editor.getNodeFromId(nodeId);
        const foundDeletedEquip = Object.keys(this.editor.drawflow.drawflow.Home.data).map(_r => {
          if (dragNodeInfo.equipId == this.editor.drawflow.drawflow.Home.data[_r]?.equipId) {
            return this.editor.drawflow.drawflow.Home.data[_r]
          }
        }).filter(_r => _r).find(_subE => _subE.isNodeDeleted);
        if (!foundDeletedEquip) {
          if (nodeId) {
            const btuMeterParentNode = this.editor.getNodeFromId(nodeId);
            if (btuMeterParentNode.childData.class == 'btuMeter' && btuMeterParentNode.class.includes('temperatureSensor') && !btuMeterParentNode?.outputs?.output_1?.connections?.length) {
              document.getElementById(`node-${nodeId}`).style.setProperty('z-index', '1000', 'important')
            }
          }
          const eles = document.elementsFromPoint(position.x, position.y);
          const ele = eles.filter(ele => ele.classList[1] === "GROUP");
          if (ele.length > 0) {
            dragElementHover = ele[0];
            dragElementHover.classList.add("hover-drop");
          } else {
            if (dragElementHover != null) {
              dragElementHover.classList.remove("hover-drop");
              dragElementHover = null;
            }
          }
        }
      } else if (this.editor.node_selected && this.editor.drag && this.editor.node_selected.classList[1] == "GROUP" && this.shiftPressed) {
        const dragNode = this.editor.node_selected.id.slice(5);
        const dragNodeInfo = this.editor.getNodeFromId(dragNode);
        const foundDeletedEquip = Object.keys(this.editor.drawflow.drawflow.Home.data).map(_r => {
          if (dragNodeInfo.equipId == this.editor.drawflow.drawflow.Home.data[_r]?.equipId) {
            return this.editor.drawflow.drawflow.Home.data[_r]
          }
        }).filter(_r => _r).find(_subE => _subE.isNodeDeleted);
        if (!foundDeletedEquip) {
          if (dragNodeInfo?.data?.elements) {
            let parentNodeClientRect = document.getElementById(`node-${Math.min(...dragNodeInfo?.data?.elements)}`).getBoundingClientRect();

            if ((dragNodeInfo.class == "GROUP" && dragNodeInfo.childData.class == "connectorLbendPipe") || (_.inRange(position.x, parentNodeClientRect.left, parentNodeClientRect.right) && _.inRange(position.y, parentNodeClientRect.top, parentNodeClientRect.bottom)) || this.isHeaderPipe(dragNodeInfo.childData.class) || this.isBypassPipe(dragNodeInfo.childData.class) || dragNodeInfo.childData.class.includes('heatExchanger')) {
              const elements = dragNodeInfo.data.elements;
              elements.forEach(eleN => {
                const node = document.getElementById(`node-${eleN}`);
                if (node) {
                  const xnew = (last_x - position.x) * this.editor.precanvas.clientWidth / (this.editor.precanvas.clientWidth * this.editor.zoom);
                  const ynew = (last_y - position.y) * this.editor.precanvas.clientHeight / (this.editor.precanvas.clientHeight * this.editor.zoom);

                  node.style.top = (node.offsetTop - ynew) + "px";
                  node.style.left = (node.offsetLeft - xnew) + "px";

                  this.editor.drawflow.drawflow[this.editor.module].data[eleN].pos_x = (node.offsetLeft - xnew);
                  this.editor.drawflow.drawflow[this.editor.module].data[eleN].pos_y = (node.offsetTop - ynew);
                  this.editor.updateConnectionNodes(`node-${eleN}`);
                }
              });
            }
          }
        }
      }
      last_x = position.x;
      last_y = position.y;
    });

    //Dual Temp -- change pipe color based on the Equip Connected in My Plant Section
    this.editor.on('changeDualTempColor', (plantData: any) => {
      this.changeDualTempColor(plantData);
    });

    this.editor.on('nodeMoved', (data: any) => {
      if (data.connectionStart) {
        const parentNodeData = this.editor.getNodeFromId(data.connectionEnd);
        const btuMeterParentNode = this.editor.getNodeFromId(data.id);
        const nodeData = this.editor.getNodeFromId(data.id);
        document.getElementById(`node-${data.id}`).style.setProperty('z-index', '1100', 'important');
        const selectedPipeConnectionClass = document.getElementsByClassName(`connection node_in_node-${data.connectionEnd} node_out_node-${data.connectionStart}`)[0].classList;
        let groupPipeId = '';
        selectedPipeConnectionClass.forEach(_class => {
          if (_class.includes('groupPipe-')) {
            groupPipeId = _class.split('groupPipe-')[1];
          }
        });

        let connectionStartNodeData = this.editor.getNodeFromId(String(data.connectionStart));
        let connectionEndNodeData = this.editor.getNodeFromId(String(data.connectionEnd));


        if (connectionStartNodeData.class == "connectTwo") {
          this.editor.drawflow.drawflow.Home.data[Number(this.selectedItemID)].parentPipeRef = connectionStartNodeData.groupPipeId;
          this.editor.drawflow.drawflow.Home.data[Number(this.selectedItemID)].connectedParentSubEquipPipeClass = connectionStartNodeData.nodePipeClass;
          this.editor.drawflow.drawflow.Home.data[Number(this.selectedItemID)].connectedParentSubEquipId = connectionStartNodeData.groupPipeId;
          this.editor.drawflow.drawflow.Home.data[Number(this.selectedItemID)].connectedParentEquipId = connectionStartNodeData.childData.equipId;
          this.editor.drawflow.drawflow.Home.data[Number(this.selectedItemID)].connectedParentClass = connectionStartNodeData.childData.class;
        } else if (connectionStartNodeData.class == "pipeDemarkation") {
          this.editor.drawflow.drawflow.Home.data[Number(this.selectedItemID)].parentPipeRef = connectionStartNodeData.connectedParentSubEquipId;
          this.editor.drawflow.drawflow.Home.data[Number(this.selectedItemID)].connectedParentSubEquipPipeClass = connectionStartNodeData.connectedParentSubEquipPipeClass;
          this.editor.drawflow.drawflow.Home.data[Number(this.selectedItemID)].connectedParentSubEquipId = connectionStartNodeData.connectedParentSubEquipId;
          this.editor.drawflow.drawflow.Home.data[Number(this.selectedItemID)].connectedParentEquipId = connectionStartNodeData.connectedParentEquipId;
          this.editor.drawflow.drawflow.Home.data[Number(this.selectedItemID)].connectedParentClass = connectionStartNodeData.connectedParentClass;
        } else if (connectionStartNodeData.class.includes('pump') || connectionStartNodeData.class.includes('btuMeter') || connectionStartNodeData.class.includes('temperatureSensor')) {
          this.editor.drawflow.drawflow.Home.data[Number(this.selectedItemID)].parentPipeRef = connectionStartNodeData.connectedParentSubEquipId;
          this.editor.drawflow.drawflow.Home.data[Number(this.selectedItemID)].connectedParentSubEquipPipeClass = connectionStartNodeData.nodePipeClass;
          this.editor.drawflow.drawflow.Home.data[Number(this.selectedItemID)].connectedParentSubEquipId = connectionStartNodeData.groupPipeId;
          this.editor.drawflow.drawflow.Home.data[Number(this.selectedItemID)].connectedParentEquipId = connectionStartNodeData.childData.equipId;
          this.editor.drawflow.drawflow.Home.data[Number(this.selectedItemID)].connectedParentClass = connectionStartNodeData.connectedParentClass;
        }

        if (this.editor.drawflow.drawflow.Home.data[Number(this.selectedItemID)]?.parentPipeRef == undefined) {
          if (connectionEndNodeData.class == "connectTwo") {
            this.editor.drawflow.drawflow.Home.data[Number(this.selectedItemID)].parentPipeRef = connectionEndNodeData.groupPipeId;
            this.editor.drawflow.drawflow.Home.data[Number(this.selectedItemID)].connectedParentSubEquipPipeClass = connectionEndNodeData.nodePipeClass;
            this.editor.drawflow.drawflow.Home.data[Number(this.selectedItemID)].connectedParentSubEquipId = connectionEndNodeData.groupPipeId;
            this.editor.drawflow.drawflow.Home.data[Number(this.selectedItemID)].connectedParentEquipId = connectionEndNodeData.childData.equipId;
            this.editor.drawflow.drawflow.Home.data[Number(this.selectedItemID)].connectedParentClass = connectionEndNodeData.childData.class;
          } else if (connectionStartNodeData.class == "pipeDemarkation") {
            this.editor.drawflow.drawflow.Home.data[Number(this.selectedItemID)].parentPipeRef = connectionEndNodeData.connectedParentSubEquipId;
            this.editor.drawflow.drawflow.Home.data[Number(this.selectedItemID)].connectedParentSubEquipPipeClass = connectionEndNodeData.connectedParentSubEquipPipeClass;
            this.editor.drawflow.drawflow.Home.data[Number(this.selectedItemID)].connectedParentSubEquipId = connectionEndNodeData.connectedParentSubEquipId;
            this.editor.drawflow.drawflow.Home.data[Number(this.selectedItemID)].connectedParentEquipId = connectionEndNodeData.connectedParentEquipId;
            this.editor.drawflow.drawflow.Home.data[Number(this.selectedItemID)].connectedParentClass = connectionEndNodeData.connectedParentClass;
          } else if (connectionEndNodeData.class.includes('pump') || connectionEndNodeData.class.includes('btuMeter') || connectionEndNodeData.class.includes('temperatureSensor')) {
            this.editor.drawflow.drawflow.Home.data[Number(this.selectedItemID)].parentPipeRef = connectionEndNodeData.connectedParentSubEquipId;
            this.editor.drawflow.drawflow.Home.data[Number(this.selectedItemID)].connectedParentSubEquipPipeClass = connectionEndNodeData.nodePipeClass;
            this.editor.drawflow.drawflow.Home.data[Number(this.selectedItemID)].connectedParentSubEquipId = connectionEndNodeData.groupPipeId;
            this.editor.drawflow.drawflow.Home.data[Number(this.selectedItemID)].connectedParentEquipId = connectionEndNodeData.childData.equipId;
            this.editor.drawflow.drawflow.Home.data[Number(this.selectedItemID)].connectedParentClass = connectionEndNodeData.connectedParentClass;
          }
        }



        if (parentNodeData.equipId && btuMeterParentNode.equipId) {
          if (document.getElementsByClassName(`parentEquipId-${parentNodeData.equipId} GROUP`)?.length) {
            const groupNodeId = document.getElementsByClassName(`parentEquipId-${parentNodeData.equipId} GROUP`)[0].id.slice(5);
            this.editor.drawflow.drawflow.Home.data[groupNodeId].data.elements.push(data.id)
          }
        }
        let validConnection = false;
        let pipeClass = '';
        selectedPipeConnectionClass.forEach(_class => {
          if (nodeData.childData.equipData.subEquips[0].pipeClass.includes(_class)) {
            validConnection = true;
            pipeClass = _class;
          }
        });
        if (validConnection) {
          if (selectedPipeConnectionClass.contains("connector-pipe")) {
            pipeClass = pipeClass + " connector-pipe";
          }
          this.editor.removeSingleConnection(data.connectionStart, data.connectionEnd, selectedPipeConnectionClass[3], selectedPipeConnectionClass[4]);
          const parentNodeData = this.editor.getNodeFromId(data.connectionEnd);
          this.updateTempSensorPosition(data, data.connectionEnd, data.id, parentNodeData.class);
          this.editor.addConnection(Number(data.connectionStart), Number(data.id), selectedPipeConnectionClass[3], 'input_1', pipeClass, false, true, groupPipeId, false); // A(Equip_connection_out) -> B(selected_input)

          this.editor.addConnection(Number(data.id), Number(data.connectionEnd), 'output_1', selectedPipeConnectionClass[4], pipeClass, false, true, groupPipeId, false); // B(selected_ouput) -> A (Equip_connection_in)
          const extraClassArray = pipeClass.split(' ');
          for (let i = 0; i < extraClassArray.length; i++) {
            document.getElementById(`node-${data.id}`).getElementsByClassName('drawflow_content_node')[0].classList.add(extraClassArray[i]);
          }

          this.editor.updateConnectionNodes(`node-${data.id}`);
          this.editor.drawflow.drawflow.Home.data[data.id].groupPipeId = groupPipeId;
          let groupPipeElement = document.querySelectorAll(`.groupPipe-${groupPipeId}`);
          groupPipeElement.forEach(pipeElement => {
            if (pipeElement.classList.contains("orangeClass")) {
              pipeElement.classList.remove("orangeClass");
            }
          });
        }
      } else {
        this.checkPipeConnection('nodeMoved');
        this.updateGroupPosition(data.id);
      }
    });

    this.editor.on('zoom', (zoom: any) => {
      let z1 = 64 * zoom;
      let z2 = 128 * zoom;

      let svglineStroke = this.toggleTheme == 'light' ? '#ccc' : '#333';
      let svgBackground = this.toggleTheme == 'light' ? '#fff' : '#181818';

      let rotateL1, rotateL2;
      if (Number(zoom) <= 0.2) {
        rotateL1 = 64;
        rotateL2 = 27.5;
      } 
      if (Number(zoom) > 0.2 && Number(zoom) <= 0.3) {
        rotateL1 = 64;
        rotateL2 = 27.5;
      } else if (Number(zoom) > 0.3 && Number(zoom) <= 0.4) {
        rotateL1 = 64;
        rotateL2 = 27;
      } else if (Number(zoom) > 0.4 && Number(zoom) <= 0.8) {
        rotateL1 = 63;
        rotateL2 = 27;
      } else if (Number(zoom) >= 0.8) {
        rotateL1 = 63.5;
        rotateL2 = 27;
      }

      let svgData = `<svg xmlns="http://www.w3.org/2000/svg" class="light">
        <style type="text/css">
          line { stroke: ${svglineStroke}; }  
          svg  { background: ${svgBackground}; }
        </style>
        <defs>
            <pattern id="grid" patternUnits="userSpaceOnUse" width="${z1}" height="${z1}" patternTransform="rotate(${rotateL1})">
                <line x1="${z1}" y1="0" x2="${z1}" y2="${z2}" />
            </pattern>
        </defs>
        <defs>
            <pattern id="grid1" patternUnits = "userSpaceOnUse" width = "${z1}" height = "${z1}" patternTransform = "rotate(${rotateL2})" >
               <line x1="0" y1 = "${z1}" x2 = "${z2}" y2 = "${z1}" />
            </pattern>
        </defs>
        <rect width="100%" height="100%" fill="url(#grid)" />
        <rect width="100%" height="100%" fill="url(#grid1)" />
       </svg>`

      let encodedSVG = this.encodeSVG(svgData);
      document.getElementById("drawflow").setAttribute(`style`, `background-image: url("data:image/svg+xml,${encodedSVG}");`);
    });

    this.editor.on('translate', (position: any) => { });

    this.editor.on('addReroute', (id: any) => {
      //this.addSplitNode(id);
    });

    this.editor.on('removeReroute', (id: any) => {
    });

    this.editor.on('createSplitNode', (event: any) => {
      if (this.ctrlKeyPressed && event.target.parentElement.nodeName === "svg") {
        this.addSplitNode(event);
      } else if (event.target.parentElement.id == 'parentDrawFlow' || event.target.parentElement.id == 'drawflow') {
        this.createLBendPipe(event, 'forward');
      }
    });

    this.editor.on('masterPointDropDownAdded', (id: any) => {
    });

    this.editor.on("clickEndFixed", (e) => {

      if (e.target.id.includes("equipCollapseIcon-")) {
        let id = e.target.id.slice(18);
        document.getElementById("pointStatusContainerId-" + id).style.display = "none";
        document.getElementById("equipCollapseIcon-" + id).style.display = "none";
        document.getElementById("equipExpandIcon-" + id).style.display = "block";
        document.getElementById("equipExpandCont-" + id).style.display = "block";
        // document.getElementById("masterViewSvgId-"+id).style.display = "none";
      }

      if (e.target.id.includes("equipExpandIcon-")) {
        let id = e.target.id.slice(16);
        document.getElementById("equipExpandCont-" + id).style.display = "none";
        document.getElementById("equipExpandIcon-" + id).style.display = "none";
        document.getElementById("pointStatusContainerId-" + id).style.display = "block";
        document.getElementById("equipCollapseIcon-" + id).style.display = "block";
        // document.getElementById("masterViewSvgId-"+id).style.display = "block";
      }

    })

    // My Plant Hover 
    this.editor.on("equipHoverAlertEnter", (nodeData) => {
      if (this.isStatusPopUpShown) {
        return;
      }
      let elems;
      elems = document.querySelectorAll(`.parentEquipId-${nodeData.equipId},#alertsContainer-${nodeData.equipId}`);

      if (elems) {
        for (var i = 0; i < elems?.length; i++) {
          elems[i]['style']['z-index'] = 1550;
        }
      }
      let equipId = nodeData.class.includes("pump1Vertical") ? nodeData.childData.equipData.subEquips.filter(x => x.equip[0] == 'pump1Vertical')[0].equipId : nodeData.equipId
      let alertsMainContainer: any = document.getElementById(`alertsContainer-${equipId}`)
      const alertsContainerDiv = alertsMainContainer.querySelector('.alerts-container');
      if (alertsContainerDiv) {
        return;
      }
      const alertsContainer = document.createElement('div');
      alertsContainer.classList.add("alerts-container");
      var equipDiv = document.createElement("div");
      equipDiv.classList.add('d-flex');

      const equipTitle = document.createElement('div');
      equipTitle.classList.add("alerts-equipName");
      equipTitle.innerHTML = nodeData.displayName;

      const equipStatus = document.createElement('div');
      equipStatus.setAttribute("id", `hoverEquipStatus-${equipId}`);

      const loaderDiv = document.createElement('div');
      loaderDiv.classList.add('hoverLoaderDiv');
      loaderDiv.setAttribute("id", `loaderAlerts-${equipId}`);
      const loader = document.createElement('img');
      loader.src = "/assets/images/loader.gif";
      loader.classList.add('hoverLoader');
      loaderDiv.appendChild(loader);


      equipDiv.appendChild(equipTitle);
      equipDiv.appendChild(equipStatus);
      alertsContainer.appendChild(equipDiv);
      alertsContainer.appendChild(loaderDiv);  // add the loader div
      alertsMainContainer.appendChild(alertsContainer);

      alertsMainContainer.classList.add("display-block-important");
      let body = {
        "statusType": "DRAFT",
        "isTrend": "false",
        "viewType": "MASTER"
      }

      this.isAlertPopUpShown = true;

      if (!this.loginUserTypeData.isPublic) {
        this.apiService.getAllAlerts(this.selectedPlantData.chillerPlantId, equipId, body).pipe(catchError(err => of(err)), map(this.cpmService.stripHaystackTypeMapping)).subscribe((res) => {
          if (res.hasOwnProperty("data")) {
            res.data.sort((a, b) => b.startTime - a.startTime);
            nodeData.data['alert'] = []
            nodeData.data['alert'] = res.data;
            this.getAlertsHoverEquipStatus(nodeData, alertsMainContainer, false)
          } else {
            nodeData.data['alert'] = []
            this.getAlertsHoverEquipStatus(nodeData, alertsMainContainer, true)
          }
        })
      } else if (this.loginUserTypeData.isPublic) {
        this.apiService.getSharedAllAlerts(this.loginUserTypeData.linkShareUuid, equipId, body).pipe(catchError(err => of(err)), map(this.cpmService.stripHaystackTypeMapping)).subscribe((res) => {
          if (res.hasOwnProperty("data")) {
            res.data.sort((a, b) => b.startTime - a.startTime);
            nodeData.data['alert'] = []
            nodeData.data['alert'] = res.data;
            this.getAlertsHoverEquipStatus(nodeData, alertsMainContainer, false)
          } else {
            nodeData.data['alert'] = []
            this.getAlertsHoverEquipStatus(nodeData, alertsMainContainer, true)
          }
        })
      }

    })
    this.editor.on("equipHoverEnter", (nodeData) => {
      if (this.isAlertPopUpShown) {
        return
      }
      this.isStatusPopUpShown = true
      let statusContainer: any = document.getElementById(this.getHoverClass(nodeData));
      if (statusContainer) {
        statusContainer.classList.add("display-block-important");
        let elems = document.querySelectorAll('.waterCooledChiller,.airCooledChiller, .pump1Vertical, .coolingTowerSingleFan, .coolingTowerTwoFan, .coolingTowerFourFan, .steamBoiler, .condenserBoiler, .alerts-outer-container');
        // for (var i = 0; i < elems.length; i++) {
        //   elems[i]['style']['z-index'] = 1500;
        // }

        this.getHoverData(nodeData).subscribe((pointList) => {
          if (nodeData.childData.class == "waterCooledChiller") {
            if (pointList == undefined) {
              document.getElementById(`hoverChiller-${nodeData.childData.equipId}`).style.setProperty('display', 'none', 'important');
            } else {
              this.chillerHover(nodeData, pointList);
            }
          } else if (nodeData.childData.class == "airCooledChiller") {
            if (pointList == undefined) {
              document.getElementById(`hoverBoiler-${nodeData.equipId}`).style.setProperty('display', 'none', 'important');
            } else {
              nodeData.data.point = pointList;
              this.boilerChillerHover(nodeData, pointList, 'airCooledChiller');
            }
          } else if (nodeData.childData.class == "steamBoiler" || nodeData.childData.class == "condenserBoiler") {
            if (pointList == undefined) {
              document.getElementById(`hoverBoiler-${nodeData.childData.equipId}`).style.setProperty('display', 'none', 'important');
            } else {
              nodeData.data.point = pointList;
              this.boilerChillerHover(nodeData, pointList, 'boiler');
            }

          } else if (nodeData.class.includes('pump1Vertical') || nodeData.class.includes('coolingTowerSingleFan') || nodeData.class.includes('coolingTowerFourFan') || nodeData.class.includes('coolingTowerTwoFan')) {
            if (pointList == undefined) {
              const element = document.getElementById(`${nodeData.class}-${nodeData.class.includes("pump1Vertical") ? nodeData.childData.equipData.subEquips.filter(x => x.equip[0] == 'pump1Vertical')[0].equipId : nodeData.equipId}`)
              if (element) {
                element.style.setProperty('display', 'none', 'important');
              }
            } else {
              this.pumAndCTHover(nodeData, pointList);
            }
          }
        });
      }
    });

    // My Plant Hover Leave
    this.editor.on("equipHoverLeave", (node) => {
      this.isAlertPopUpShown = false
      this.isStatusPopUpShown = false
      let equipId = node.class.includes("pump1Vertical") ? node.childData.equipData.subEquips.filter(x => x.equip[0] == 'pump1Vertical')[0].equipId : node.equipId
      const alertsContainer = document.getElementById(`alertsContainer-${equipId}`)
      let firstChild = alertsContainer.firstChild;
      if (firstChild) {
        alertsContainer.removeChild(firstChild);
      }
      alertsContainer.classList.remove("display-block-important");
      const loaderDiv = document.createElement('div');
      loaderDiv.classList.add('hoverLoaderDiv');
      const loader = document.createElement('img');
      loader.src = "/assets/images/loader.gif";
      loader.classList.add('hoverLoader');
      loaderDiv.appendChild(loader);
      if (node.class == "waterCooledChiller") {
        document.getElementById(`hoverChiller-${equipId}`).innerHTML = '';
        document.getElementById(`hoverChiller-${equipId}`).appendChild(loaderDiv);
      } else if (node.class == "steamBoiler" || node.class == "condenserBoiler" || node.class == "airCooledChiller") {
        document.getElementById(`hoverBoiler-${equipId}`).innerHTML = '';
        document.getElementById(`hoverBoiler-${equipId}`).appendChild(loaderDiv);
      } else if (node.class.includes('pump1Vertical_backward') || node.class.includes('pump1Vertical_forward')) {
        document.getElementById(`${node.class}-${equipId}`).innerHTML = '';
        document.getElementById(`${node.class}-${equipId}`).appendChild(loaderDiv);
      } else if (node.class.includes('coolingTowerSingleFan') || node.class.includes('coolingTowerFourFan') || node.class.includes('coolingTowerTwoFan')) {
        document.getElementById(`${node.class}-${equipId}`).innerHTML = '';
        document.getElementById(`${node.class}-${equipId}`).appendChild(loaderDiv);
      }

      let statusContainer: any = document.getElementById(this.getHoverClass(node));
      if (statusContainer) {
        statusContainer.classList.remove("display-block-important");
        let elems = document.querySelectorAll('.waterCooledChiller,.airCooledChiller, .pump1Vertical_forward, .pump1Vertical_backward, .coolingTowerSingleFan, .coolingTowerTwoFan, .coolingTowerFourFan, .steamBoiler, .condenserBoiler, .alerts-outer-container');
        for (var i = 0; i < elems.length; i++) {
          elems[i]['style']['z-index'] = 1049;
        }
      }
    });

    let startData, endData;
    this.editor.on("clickEnd", (e) => {
      startData = {
        'x': e.x,
        'y': e.y
      }
    })

    this.editor.on("equipClick", (e) => {
      let id;
      endData = {
        'x': e.x,
        'y': e.y
      }
      if (e.target.classList.value.includes('heatExchangerPipeArrowImg')) {
        const nodeDataId = e.target.offsetParent.id.slice(5);
        const nodeData = this.editor.getParentNodeData(nodeDataId);
        const groupNodeId = document.getElementsByClassName(`parentEquipId-${nodeData.equipId} GROUP`)[0].id.slice(5);
        this.editor.removeNodeId(`node-${groupNodeId}`);
        const foundItem = ObjectUtil.deepCopy(this.duplicateNodesArr.find(_equip => _equip.equipId == nodeData.equipId));
        foundItem.class = "heatExchanger_backward";
        foundItem.img = "Heat_Exchanger_backward.png";
        if (e.target.classList.value.includes('heatExchangerPipeArrowImg_backward')) {
          foundItem.class = "heatExchanger_forward";
          foundItem.img = "Heat_Exchanger_forward.png";
        }
        this.selectedItem = foundItem;
        this.tempParentEquipData = this.selectedItem;
        this.addNodeToDrawBoard(e.clientX, e.clientY);
      }
      // On click of headerPipe rotate icon.
      if (e.target.classList.value == 'pipeArrowImg_forward' || e.target.classList.value == 'pipeArrowImg_backward') {
        const nodeDataId = e.target.offsetParent.id.slice(5);
        const nodeData = JSON.parse(JSON.stringify(this.editor.getParentNodeData(nodeDataId)));
        const totalNodesLength = nodeData.data.elements;
        if (e.target.classList.value == 'pipeArrowImg_forward') {
          if (this.isHeaderPipe(nodeData.childData.class)) {
            this.tempParentEquipData = JSON.parse(JSON.stringify(nodeData.childData));
            this.editor.removeNodeId(`node-${nodeData.id}`);
            this.createHeaderPipe(e, 'backward');
          } else if (nodeData.childData.class == "connectorLbendPipe") {
            this.tempParentEquipData = JSON.parse(JSON.stringify(nodeData.childData));
            this.editor.removeNodeId(`node-${nodeData.id}`);
            this.createLBendPipe(e, 'backward');
          }
        }
        if (e.target.classList.value == 'pipeArrowImg_backward') {
          if (this.isHeaderPipe(nodeData.childData.class)) {
            this.tempParentEquipData = JSON.parse(JSON.stringify(nodeData.childData));
            this.editor.removeNodeId(`node-${nodeData.id}`);
            this.createHeaderPipe(e, 'forward');
          } else if (nodeData.childData.class == "connectorLbendPipe") {
            this.tempParentEquipData = JSON.parse(JSON.stringify(nodeData.childData));
            this.editor.removeNodeId(`node-${nodeData.id}`);
            this.createLBendPipe(e, 'forward');
          }
        }
      }

      if (e.target?.offsetParent?.id?.includes("node")) {
        id = e.target.offsetParent.id.slice(5);
      }

      let isHeaderPipeConfig = false;
      if (e.target?.parentElement?.classList[1]?.includes("node_in_node-") && e.target?.parentElement?.classList[2]?.includes("node_out_node-")) {
        let nodeInId = e.target?.parentElement?.classList[1].slice(13);
        let nodeOutId = e.target?.parentElement?.classList[2].slice(14);
        if (this.editor.getNodeFromId(nodeInId).equipId == this.editor.getNodeFromId(nodeOutId).equipId) {
          if (this.isHeaderPipe(this.editor.getNodeFromId(nodeInId).childData.class) || (this.isBypassPipe(this.editor.getNodeFromId(nodeInId).childData.class))) {
            id = nodeInId;
            isHeaderPipeConfig = true;
          } else if (this.isHeaderPipe(this.editor.getNodeFromId(nodeOutId).childData.class) || (this.isBypassPipe(this.editor.getNodeFromId(nodeOutId).childData.class))) {
            id = nodeOutId;
            isHeaderPipeConfig = true;
          }
        }
      }

      if (id != undefined) {
        endData.id = id;
      }
      setTimeout(() => {
        if (e.button == 0 && startData?.x == endData?.x && startData?.y == endData?.y) {
          if (endData?.id != undefined) {
            if (e.target.classList[0] != 'output' && e.target.classList[0] != 'input') {
              let nodeData = this.editor.getNodeFromId(endData.id);
              if (this.isParentEquip(nodeData.class) && !this.ctrlKeyPressed) {
                nodeData.isSensorNode = false;
                this.equipClicked(nodeData);
              }
              if (((isHeaderPipeConfig && (this.isHeaderPipe(nodeData.childData.class) || this.isBypassPipe(nodeData.childData.class))) || nodeData.class.includes('btuMeter')) && !this.ctrlKeyPressed) {
                nodeData.isSensorNode = true;
                this.equipClicked(nodeData);
              }
            }
          }
        }
        if (this.editor.editor_mode == 'fixed') {
          if (endData.id != undefined) {
            let nodeData = this.editor.getNodeFromId(endData.id);
            let included = ["waterCooledChiller", "airCooledChiller", "pump1Vertical_forward", 'pump1Vertical_backward', "coolingTowerSingleFan", "coolingTowerTwoFan", "coolingTowerFourFan", "steamBoiler", "condenserBoiler"]
            if (included.includes(nodeData.class) && e.target.className.includes("equipImg") && !nodeData?.equipDeleted) {
              this.onMasterViewEquipClicked(nodeData);
            }
          }
        }
      }, 10);
    });

    this.editor.on("hideLoader", (e) => {
      this.hideLoader();
    })

    this.editor.on("plantImported", (plantJson) => {
      this.checkPipeConnection('plantImported');
      this.addDragEventsToConnections();
      this.fitToScreen();
    })

  }

  getHoverClass(dataNode) {
    let ans = '';
    if (dataNode.class == 'waterCooledChiller') {
      ans = 'hoverChiller-' + dataNode.equipId;
    } else if (dataNode.class.includes('condenserBoiler') || dataNode.class.includes('steamBoiler') || dataNode.class == 'airCooledChiller') {
      ans = 'hoverBoiler-' + dataNode.equipId;
    } else if (dataNode.class.includes('pump1Vertical') || dataNode.class.includes('coolingTowerSingleFan') || dataNode.class.includes('coolingTowerFourFan') || dataNode.class.includes('coolingTowerTwoFan')) {
      ans = dataNode.class + "-" + (dataNode.class.includes("pump1Vertical") ? dataNode.childData.equipData.subEquips.filter(x => x.equip[0] == 'pump1Vertical')[0].equipId : dataNode.equipId);
    }
    return ans;
  }

  updateTempSensorPosition(data, nodeId, tempSensorId, parentEquipClass) {
    let calcLeft: any;
    let calcTop: any;
    if (parentEquipClass == 'coolingTowerTwoFan' || parentEquipClass == 'coolingTowerSingleFan' || parentEquipClass == 'coolingTowerFourFan' || parentEquipClass == 'building2PipeHot' || parentEquipClass == 'building2Pipe' || parentEquipClass == 'dualTempBuilding2Pipe') {
      calcLeft = Number(document.getElementById(`node-${nodeId}`).style.left.split('px')[0]) - 100;
      calcTop = Number(document.getElementById(`node-${nodeId}`).style.top.split('px')[0]) + 450;
    }
    document.getElementById(`node-${tempSensorId}`).style.left = (Number(calcLeft)) + 'px';
    document.getElementById(`node-${tempSensorId}`).style.top = (Number(calcTop)) + 'px';

  }

  reStorePipeConnectionOnBtuMeterDeletion(id) {
    const btuMeterTempSensorNodeId = this.editor.drawflow.drawflow.Home.data[id]?.childData?.equipData?.subEquips[0]?.childNodeId;
    const data = this.editor.getNodeFromId(btuMeterTempSensorNodeId);
    if (data) {
      this.selectedItemNode = data;
    }
    if (this.selectedItemNode) {
      this.selectedItemNodeIsSensor = true;
      this.selectedItemNodeInputId = this.selectedItemNode.inputs.input_1?.connections[0]?.node;
      this.selectedItemNodeInputPort = this.selectedItemNode.inputs.input_1?.connections[0]?.input;
      this.selectedItemNodeOutputId = this.selectedItemNode.outputs.output_1?.connections[0]?.node;
      this.selectedItemNodeOutputPort = this.selectedItemNode.outputs.output_1?.connections[0]?.output;
      const classArray = ["CDWReturnClass", "CDWSupplyClass", "CWReturnClass", "CWSupplyClass", "boilerReturnClass", "boilerSupplyClass", "DTSupplyClass", "DTReturnClass"];
      let btuMeterChildPipeClass = '';
      if (document.getElementById('node-' + btuMeterTempSensorNodeId)) {
        document.getElementById('node-' + btuMeterTempSensorNodeId).getElementsByClassName('drawflow_content_node')[0].classList.forEach(_class => {
          if (classArray.includes(_class)) {
            btuMeterChildPipeClass = _class;
          }
        });
      }
      this.duplicateNodesArr = this.duplicateNodesArr.map(_node => {
        if (_node.class == 'btuMeter') {
          _node.equipData.subEquips[0].pipeClass = this.setPipeClassByTags(_node.equipData.subEquips[0].parentEquipTags);
        }
        return _node;
      });
      this.editor.removeNodeId('node-' + btuMeterTempSensorNodeId);
      this.createConnectionOnSensorDeletion(btuMeterChildPipeClass, this.selectedItemNode.groupPipeId);
    }
  }

  onBtuMeterDeletion(pipeClass) {
    const restoreGroupPipeClass = this.editor.deletedNodeData.groupPipeId;
    this.createConnectionOnSensorDeletion(pipeClass, restoreGroupPipeClass);
  }

  removeOfGroupNode(id) {
    Object.keys(this.editor.drawflow.drawflow[this.editor.module].data).forEach(ele => {
      if (this.editor.drawflow.drawflow[this.editor.module].data[ele].class === "GROUP") {
        const findIndex = this.editor.drawflow.drawflow[this.editor.module].data[ele].data.elements.indexOf(id);
        if (findIndex !== -1) {
          this.editor.drawflow.drawflow[this.editor.module].data[ele].data.elements.splice(findIndex, 1);
        }
      }
    })
  }


  /**
   * Updates the position and dimensions of a group node based on the child nodes.
   * @param id - The ID of the group node.
   */
  /**
   * Updates the position and dimensions of a group node based on the child nodes.
   * @param id - The ID of the group node.
   */
  updateGroupPosition(id) {
    let movedNodeData = this.editor.getNodeFromId(id); 
    // If the moved node is not a group node.
    if (movedNodeData.class != "GROUP") {
      // Get the child nodes of the moved node.
      let childNode = document.querySelectorAll(`.parentEquipId-${movedNodeData.equipId}`);
      let childNodeId = Array.from(childNode, (element: any) => element.id.slice(5));

      // Get the position and dimensions of the child nodes.
      let nodeXPositionArr = childNodeId.map((element: any) => this.editor.getNodeFromId(element).pos_x);
      let nodeYPositionArr = childNodeId.map((element: any) => this.editor.getNodeFromId(element).pos_y);

      // Calculate the position and dimensions of the group node based on the child nodes.
      let groupNodePos_x = Math.min(...nodeXPositionArr);
      let groupNodePos_y = Math.min(...nodeYPositionArr);
      let groupNodeWidth = (Math.max(...nodeXPositionArr) - Math.min(...nodeXPositionArr)) + 60;
      let groupNodeHeight = (Math.max(...nodeYPositionArr) - Math.min(...nodeYPositionArr)) + 30;

      // Adjust the position and dimensions of the group node based on the child nodes.
      if (movedNodeData.childData.class == "coolingTowerSingleFan") {
        groupNodeWidth += 900;
      } else if (movedNodeData.childData.class == "coolingTowerTwoFan") {
        groupNodeWidth += 1350;
      } else if (movedNodeData.childData.class == "coolingTowerFourFan") {
        groupNodeWidth += 1400;
      } else if (movedNodeData.childData.class == "building2PipeHot" || movedNodeData.childData.class == "building2Pipe" || movedNodeData.childData.class == "dualTempBuilding2Pipe" || movedNodeData.childData.class == "building4Pipe") {
        groupNodeWidth += 800;
      } else if (movedNodeData.childData.class.includes("pump1Vertical")) {
        groupNodeWidth = 500;
        groupNodeHeight = 440;
      } else if (movedNodeData.childData.class.includes("connectorLbend")) {
        const equipGroupElement = document.querySelector(`.group-${movedNodeData.equipId}`);
        let firstElementData = this.editor.getNodeFromId(`${equipGroupElement.id.slice(5)}`);
        groupNodePos_x = firstElementData.pos_x - 145;
        groupNodeWidth = 200;
        groupNodeHeight = 200;
        groupNodePos_x -= 145;
        groupNodeWidth = 200;
        groupNodeHeight = 200;
      }

      let groupNodeId = document.getElementsByClassName(`group-${movedNodeData.equipId}`)?.[0]?.id;
      // Update the position and dimensions of the group node.
      if (groupNodeId) {
        let groupNodeElement: any = document.getElementById(groupNodeId);
        groupNodeElement.style.width = groupNodeWidth + "px";
        groupNodeElement.style.height = groupNodeHeight + "px";
        groupNodeElement.style.left = groupNodePos_x + "px";
        groupNodeElement.style.top = groupNodePos_y + "px";

        // Update the position and dimensions of the group node in the drawflow data.
        this.editor.drawflow.drawflow.Home.data[groupNodeId.slice(5)].pos_y = groupNodePos_y;
        this.editor.drawflow.drawflow.Home.data[groupNodeId.slice(5)].pos_x = groupNodePos_x;
        this.editor.drawflow.drawflow.Home.data[groupNodeId.slice(5)].groupNodeWidth = groupNodeWidth;
        this.editor.drawflow.drawflow.Home.data[groupNodeId.slice(5)].groupNodeHeight = groupNodeHeight;
      }
    } else { // If the moved node is a group node.
      let nodeXPositionArr = movedNodeData.data.elements.map((element: any) => this.editor.getNodeFromId(element).pos_x);
      let nodeYPositionArr = movedNodeData.data.elements.map((element: any) => this.editor.getNodeFromId(element).pos_y);

      // Calculate the position and dimensions of the group node based on the child nodes.
      let groupNodePos_x = Math.min(...nodeXPositionArr);
      let groupNodePos_y = Math.min(...nodeYPositionArr);
      let groupNodeWidth = (Math.max(...nodeXPositionArr) - Math.min(...nodeXPositionArr)) + 60;
      let groupNodeHeight = (Math.max(...nodeYPositionArr) - Math.min(...nodeYPositionArr)) + 30;

      let pipeType = '';
      // Adjust the position and dimensions of the group node based on the child nodes.
      if (movedNodeData.childData.class == "coolingTowerSingleFan") {
        groupNodeWidth += 900;
      } else if (movedNodeData.childData.class == "coolingTowerTwoFan") {
        groupNodeWidth += 1350;
      } else if (movedNodeData.childData.class == "coolingTowerFourFan") {
        groupNodeWidth += 1400;
      } else if (movedNodeData.childData.class == "building2Pipe" || movedNodeData.childData.class == "building2PipeHot" || movedNodeData.childData.class == "dualTempBuilding2Pipe" || movedNodeData.childData.class == "building4Pipe") {
        groupNodeWidth += 800;
      } else if (movedNodeData.childData.class.includes("connectorLbend")) {
        pipeType = movedNodeData.pipeType;
        let firstElementData = this.editor.getNodeFromId(movedNodeData.data.elements[0]);
        if (pipeType == 'forward') {
          groupNodePos_x = firstElementData.pos_x - 145;
          groupNodeWidth = 200;
          groupNodeHeight = 200;
        } else if (pipeType == 'backward') {
          groupNodePos_x = firstElementData.pos_x;
          groupNodePos_y = firstElementData.pos_y - 45;
          groupNodeWidth = 290;
          groupNodeHeight = 140;
        }
      } else if (movedNodeData.childData.class.includes("pump1Vertical")) {
        groupNodeWidth = 500;
        groupNodeHeight = 440;
      }

      // Update the position and dimensions of the group node.
      let groupNodeId = document.getElementsByClassName(`group-${movedNodeData.equipId}`)?.[0]?.id;
      if (groupNodeId) {
        let groupNodeElement: any = document.getElementById(groupNodeId);
        groupNodeElement.style.width = groupNodeWidth + "px";
        groupNodeElement.style.height = groupNodeHeight + "px";
        groupNodeElement.style.left = groupNodePos_x + "px";
        groupNodeElement.style.top = groupNodePos_y + "px";

        // Update the position and dimensions of the group node in the drawflow data.
        this.editor.drawflow.drawflow.Home.data[groupNodeId.slice(5)].pos_y = groupNodePos_y;
        this.editor.drawflow.drawflow.Home.data[groupNodeId.slice(5)].pos_x = groupNodePos_x;
        this.editor.drawflow.drawflow.Home.data[groupNodeId.slice(5)].groupNodeWidth = groupNodeWidth;
        this.editor.drawflow.drawflow.Home.data[groupNodeId.slice(5)].groupNodeHeight = groupNodeHeight;
      }
    }
  }


  checkEmptyEquipList(subEquipsData) {
    if (subEquipsData.equips.some(equip => equip.equip != "")) {
      return false;
    } else {
      return true;
    }
  }

  sortSubEquip(equiData) {
    let sortedArr = [];
    if (equiData.class == 'heatExchanger_backward' || equiData.class == 'heatExchanger_forward') {
      sortedArr = this.sortHeatExchangerSubEquips(equiData);
    } else {
      sortedArr = equiData?.equipData?.subEquips.sort((a, b) => {
        const posA = this.equipPipeSequence[equiData?.class].indexOf(a.equip[0]);
        const posB = this.equipPipeSequence[equiData?.class].indexOf(b.equip[0]);
        if (posA < posB) {
          return -1;
        } else if (posA > posB) {
          return 1;
        } else {
          return 0;
        }
      });
    }
    return sortedArr;
  }

  createNodesOnEquipDrop() {
    this.createdParentEquipId = this.editor.nodeId;
    let nodeId = this.editor.nodeId;
    let event: any = {};
    let pipeClass;
    let sensorNodeId;

    this.tempParentEquipData.equipData.subEquips = this.sortSubEquip(this.tempParentEquipData);

    let filteredData = JSON.parse(JSON.stringify(this.tempParentEquipData));

    filteredData.equipData.subEquips.forEach((subEquipsData, index) => {
      let toDeleteObj = [];
      subEquipsData.equips.forEach((subEquipObj) => {
        if (subEquipObj.hasOwnProperty("master")) {
          if (subEquipObj.master == false) {
            toDeleteObj.push(subEquipObj.pointId);
          }
        }
      });

      toDeleteObj.forEach((pointId) => {
        let findSensorIndex = filteredData.equipData.subEquips[index].equips.findIndex(subEquip => subEquip.pointId == pointId);
        filteredData.equipData.subEquips[index].equips.splice(findSensorIndex, 1);
      });
    });

    filteredData.equipData.subEquips.forEach((subEquipsData, index) => {
      index = index + 1;
      if (this.tempParentEquipData.class == 'coolingTowerTwoFan' || this.tempParentEquipData.class == 'coolingTowerSingleFan' || this.tempParentEquipData.class == 'coolingTowerFourFan' || this.tempParentEquipData.class == 'building2PipeHot' || this.tempParentEquipData.class == 'building2Pipe' || this.tempParentEquipData.class == 'dualTempBuilding2Pipe') {
        event = document.getElementById(`node-${nodeId}`).getElementsByClassName(`input input_${index}`)[0].getBoundingClientRect();
      } else {
        if ((index == 1 || index == 2) && this.tempParentEquipData.class == 'heatExchanger_backward') {
          event = document.getElementById(`node-${nodeId}`).getElementsByClassName(`input input_${index}`)[0].getBoundingClientRect();
        }
      }
      subEquipsData.equips.forEach((subEquip, subIndex) => {
        let subEquipName;
        if (this.equipConfiguration.rightsideEquipList.includes(this.tempParentEquipData.class) || this.tempParentEquipData.class == 'heatExchanger_forward') {
          subEquipName = `${subEquip.subEquipType}_forward`;
        } else{
          subEquipName = `${subEquip.subEquipType}_backward`;
        }
        this.selectedItem = ElementsToBeConfiguredNodes.find(sensorData => {
          return sensorData.name == subEquip.subEquipType;
        });
        this.selectedItem.class = subEquipName;
        sensorNodeId = this.editor.nodeId + 1;
        this.selectedItemID = sensorNodeId;
        pipeClass = this.classForComponentAndPort(this.tempParentEquipData.class, `output_${index}`);
        this.selectedItem.pipeClass = pipeClass;
        if (this.equipConfiguration.rightsideEquipList.includes(this.tempParentEquipData.class)) {
          this.createForwardEquipsOnCanvasDrop(event, index, subIndex, nodeId, sensorNodeId, subEquipsData, subEquip, subEquipName, pipeClass);
        } else if (this.tempParentEquipData.class == 'heatExchanger_backward' || this.tempParentEquipData.class == 'heatExchanger_forward') {
          pipeClass = this.getPipeForHeatExchanger(subEquipsData.tags);
          this.selectedItem.pipeClass = pipeClass;
          this.createHeatExchangerOnCanvasDrop(event, index, subIndex, nodeId, sensorNodeId, subEquipsData, subEquip, subEquipName, pipeClass);
        } else {
          this.createBackwardEquipsOnCanvasDrop(event, index, subIndex, subEquipsData, subEquip, nodeId, sensorNodeId, pipeClass);
        }
      });
      this.selectedItemID = this.editor.nodeId + 1;
      this.selectedItem = <NodeElement>{};
      this.selectedItem.displayName = 'Connector';
      this.selectedItem.name = 'connectTwo';
      this.selectedItem.inputs = 1;
      this.selectedItem.outputs = 1;
      this.selectedItem.class = 'connectTwo';
      this.selectedItem.pipeClass = pipeClass;

      //If no sensors in subEquip
      if (subEquipsData?.equips?.length == 0) {
        pipeClass = this.classForComponentAndPort(this.tempParentEquipData.class, `output_${index}`);
        this.selectedItem.pipeClass = pipeClass;
        if (this.equipConfiguration.rightsideEquipList.includes(this.tempParentEquipData.class)) {
          this.addNodeToDrawBoard(event.x + 70 + (index * 10), event.y + 70 + (index * 10), false, false, false, subEquipsData.equipId);
          document.getElementById(`node-${this.selectedItemID}`).style.left = 'auto';
          document.getElementById(`node-${this.selectedItemID}`).style.top = 'auto';
          const calcLeft = `${(document.getElementById(`node-${this.createdParentEquipId}`).getElementsByClassName(`input input_${index}`)[0].getBoundingClientRect().x - document.getElementById(`node-${this.selectedItemID}`).getElementsByClassName(`output output_${1}`)[0].getBoundingClientRect().x) / this.editor.zoom}`;
          const calcTop = `${(document.getElementById(`node-${this.createdParentEquipId}`).getElementsByClassName(`input input_${index}`)[0].getBoundingClientRect().y - document.getElementById(`node-${this.selectedItemID}`).getElementsByClassName(`output output_${1}`)[0].getBoundingClientRect().y) / this.editor.zoom}`;
          document.getElementById(`node-${this.selectedItemID}`).style.left = (Number(calcLeft) - 200) + 'px';
          document.getElementById(`node-${this.selectedItemID}`).style.top = (Number(calcTop) + 95) + 'px';
          this.editor.drawflow.drawflow.Home.data[this.selectedItemID].pos_x = (Number(calcLeft) - 200);
          this.editor.drawflow.drawflow.Home.data[this.selectedItemID].pos_y = (Number(calcTop) + 95);
          this.editor.addConnection(this.selectedItemID, this.createdParentEquipId, `output_1`, `input_${index}`, pipeClass, false, true, subEquipsData.equipId);
        } else if (this.tempParentEquipData.class.includes('heatExchanger')) {
          pipeClass = this.getPipeForHeatExchanger(subEquipsData.tags);
          this.selectedItem.pipeClass = pipeClass;
          if (this.tempParentEquipData.class.includes('heatExchanger_backward')) {
            this.createHeatExchangerWhenNoSubEquipsBackwardPosition(event, index, subEquipsData, pipeClass);
          } 
          if (this.tempParentEquipData.class.includes('heatExchanger_forward')) {
            this.createHeatExchangerWhenNoSubEquipsForwardPosition(event, index, subEquipsData, pipeClass);
          }
        } else {
          this.addNodeToDrawBoard(event.x + 70 + (index * 10), event.y + 30 + (index * 5), false, false, false, subEquipsData.equipId);
          document.getElementById(`node-${this.selectedItemID}`).style.left = 'auto';
          document.getElementById(`node-${this.selectedItemID}`).style.top = 'auto';
          const calcLeft = `${(document.getElementById(`node-${this.createdParentEquipId}`).getElementsByClassName(`output output_${index}`)[0].getBoundingClientRect().x - document.getElementById(`node-${this.selectedItemID}`).getElementsByClassName(`input input_${1}`)[0].getBoundingClientRect().x) / this.editor.zoom}`;
          const calcTop = `${(document.getElementById(`node-${this.createdParentEquipId}`).getElementsByClassName(`output output_${index}`)[0].getBoundingClientRect().y - document.getElementById(`node-${this.selectedItemID}`).getElementsByClassName(`input input_${1}`)[0].getBoundingClientRect().y) / this.editor.zoom}`;
          document.getElementById(`node-${this.selectedItemID}`).style.left = (Number(calcLeft) + 200) + 'px';
          document.getElementById(`node-${this.selectedItemID}`).style.top = (Number(calcTop) + 105) + 'px';
          this.editor.drawflow.drawflow.Home.data[this.selectedItemID].pos_x = (Number(calcLeft) + 200);
          this.editor.drawflow.drawflow.Home.data[this.selectedItemID].pos_y = (Number(calcTop) + 105);
          this.editor.addConnection(this.createdParentEquipId, this.selectedItemID, `output_${index}`, `input_1`, pipeClass, false, true, subEquipsData.equipId);
        }
      } else {
        if (this.equipConfiguration.rightsideEquipList.includes(this.tempParentEquipData.class)) {
          this.addNodeToDrawBoard(event.x + 70, event.y + 70, false, false, false, subEquipsData.equipId);
          document.getElementById(`node-${this.selectedItemID}`).style.left = 'auto';
          document.getElementById(`node-${this.selectedItemID}`).style.top = 'auto';
          const calcLeft = `${(document.getElementById(`node-${sensorNodeId}`).getElementsByClassName(`output output_${1}`)[0].getBoundingClientRect().x - document.getElementById(`node-${this.selectedItemID}`).getElementsByClassName(`output output_${1}`)[0].getBoundingClientRect().x) / this.editor.zoom}`;
          const calcTop = `${(document.getElementById(`node-${sensorNodeId}`).getElementsByClassName(`output output_${1}`)[0].getBoundingClientRect().y - document.getElementById(`node-${this.selectedItemID}`).getElementsByClassName(`output output_${1}`)[0].getBoundingClientRect().y) / this.editor.zoom}`;
          document.getElementById(`node-${this.selectedItemID}`).style.left = (Number(calcLeft) - 125) + 'px';
          document.getElementById(`node-${this.selectedItemID}`).style.top = (Number(calcTop) + 55) + 'px';
          this.editor.drawflow.drawflow.Home.data[this.selectedItemID].pos_x = (Number(calcLeft) - 125);
          this.editor.drawflow.drawflow.Home.data[this.selectedItemID].pos_y = (Number(calcTop) + 55);
          this.editor.addConnection(this.selectedItemID, sensorNodeId, `output_${1}`, `input_1`, pipeClass, false, true, subEquipsData.equipId);
        } else {
          if (this.tempParentEquipData.class.includes('heatExchanger_backward') && (index == 1 || index == 2)) {
            this.addNodeToDrawBoard(event.x - 100, event.y - 70, false, false, false, subEquipsData.equipId);
          } else {
            this.addNodeToDrawBoard(event.x + 100, event.y + 70, false, false, false, subEquipsData.equipId);
          }
          document.getElementById(`node-${this.selectedItemID}`).style.left = 'auto';
          document.getElementById(`node-${this.selectedItemID}`).style.top = 'auto';
          const calcLeft = `${(document.getElementById(`node-${sensorNodeId}`).getElementsByClassName(`output output_${1}`)[0].getBoundingClientRect().x - document.getElementById(`node-${this.selectedItemID}`).getElementsByClassName(`input input_${1}`)[0].getBoundingClientRect().x) / this.editor.zoom}`;
          const calcTop = `${(document.getElementById(`node-${sensorNodeId}`).getElementsByClassName(`output output_${1}`)[0].getBoundingClientRect().y - document.getElementById(`node-${this.selectedItemID}`).getElementsByClassName(`input input_${1}`)[0].getBoundingClientRect().y) / this.editor.zoom}`;
          let nodeLeftPos = (Number(calcLeft) + 100);
          let nodeTopPos = (Number(calcTop) + 50);
          if (this.tempParentEquipData.class == 'heatExchanger_backward' && (index == 1 || index == 2)) {
            nodeLeftPos = (Number(calcLeft) - 125);
            nodeTopPos = (Number(calcTop) - 65);
          }
          if (this.tempParentEquipData.class == 'heatExchanger_forward') {
            if ((index == 3 || index == 4)) {
              nodeLeftPos = (Number(calcLeft) - 100);
              nodeTopPos = (Number(calcTop) + 44);
            }
            if ((index == 1 || index == 2)) {
              nodeLeftPos = (Number(calcLeft) + 170);
              nodeTopPos = (Number(calcTop) - 95);
            }
          }
          document.getElementById(`node-${this.selectedItemID}`).style.left = nodeLeftPos + 'px';
          document.getElementById(`node-${this.selectedItemID}`).style.top = nodeTopPos + 'px';
          this.editor.drawflow.drawflow.Home.data[this.selectedItemID].pos_x = nodeLeftPos;
          this.editor.drawflow.drawflow.Home.data[this.selectedItemID].pos_y = nodeTopPos;
          this.editor.addConnection(sensorNodeId, this.selectedItemID, `output_${1}`, `input_1`, pipeClass, false, true, subEquipsData.equipId);
        }
      }
    });

    if (this.equipConfiguration.rightsideEquipList.includes(this.tempParentEquipData.class)) {
      const sensorNodeId = this.editor.nodeId + 1;
      this.selectedItemID = sensorNodeId;
      this.addNodeToDrawBoard(event.x - 180, event.y, false, false, true);
    } else {
      const sensorNodeId = this.editor.nodeId + 1;
      this.selectedItemID = sensorNodeId;
      this.addNodeToDrawBoard(event.x, event.y, false, false, true);
    }
  }

  checkIsCollide(id) {
    let allNodeData = this.editor.drawflow.drawflow.Home.data;

    let allnodeDataArr = Object.keys(allNodeData).map(key => {
      return allNodeData[key];
    })

    let allNodeIDs = allnodeDataArr.filter(nodeData => (nodeData.class == 'GROUP')).map(nodeData => nodeData.id);
    if (allNodeIDs.length > 1) {
      allNodeIDs = allNodeIDs.filter(item => item != id)
      allNodeIDs = allNodeIDs.map(id => 'node-' + id);
      let getAllNodeBoundary = [];
      getAllNodeBoundary = allNodeIDs.map(id => document.getElementById(id).getBoundingClientRect())

      let getSelectedBoundary = document.getElementById('node-' + id);
      let selectedNodeBoundary = getSelectedBoundary.getBoundingClientRect();
      let isCollide = [];
      getAllNodeBoundary.forEach((node, index) => {
        isCollide.push(this.collide(selectedNodeBoundary, node));
      });
      if (isCollide.includes(true)) {
        this.isCollide = true;
        return true;
      } else {
        this.isCollide = false;
        return false;
      }
    } else {
      return false;
    }

  }

  moveNode(id) {
    let nodeID = 'node-' + id;
    let selectedNode = this.editor.drawflow.drawflow.Home.data[id];
    this.editor.drawflow.drawflow.Home.data[id].pos_x = selectedNode.pos_x + 80;
    document.getElementById(nodeID).style.left = selectedNode.pos_x + 80 + 'px';

    // move group node with child node 
    const dragNodeInfo = this.editor.getNodeFromId(id);
    const elements = dragNodeInfo.data.elements;
    elements.forEach(childNodeId => {
      let selectedNode = this.editor.drawflow.drawflow.Home.data[childNodeId];
      let nodeID = 'node-' + childNodeId;
      this.editor.drawflow.drawflow.Home.data[childNodeId].pos_x = selectedNode.pos_x + 80;
      document.getElementById(nodeID).style.left = selectedNode.pos_x + 80 + 'px';
      this.editor.updateConnectionNodes(nodeID);
    });
    this.editor.dispatch('nodeMoved', { id: id });
  }

  encodeSVG(data) {
    data = data.replace(/"/g, `'`);
    data = data.replace(/>\s{1,}</g, `><`);
    data = data.replace(/\s{2,}/g, ` `);
    const symbols = /[\r\n%#()<>?[\\\]^`{|}]/g;
    return data.replace(symbols, encodeURIComponent);
  }

  removeConnection(connection) {
    this.editor.removeSingleConnection(connection.output_id, connection.input_id, connection.output_class, connection.input_class);
  }
  checkConnectionExists(connection) {
    // connection => { output_id: id_output, input_id: id_input, output_class:  output_class, input_class: input_class}
    if (this.editor.getNodeFromId(connection.input_id).inputs[connection.input_class].connections.length > 0) return false;
    // if(this.editor.getNodeFromId(connection.output_id).outputs[connection.output_class].connections.length > 0) return false;
    return true;
  }
  setLastMousePosition(position: any) {
    this.mousePositionEv = { x: 1, y: 1 }
    this.mousePositionEv.x = position.x;
    this.mousePositionEv.y = position.y;
  }
  addSplitNode(e) {
    this.selectedItem = <NodeElement>{};
    this.selectedItem.displayName = 'Connector';
    this.selectedItem.name = 'connectTwo';
    this.selectedItem.inputs = 1;
    this.selectedItem.outputs = 1;
    this.selectedItem.class = 'byPassLine-connectTwo';
    this.selectedItemID = this.editor.nodeId;
    this.createNodeBetweenSubEquips(e);
  }

  setSelectedNode(id) {
    this.selectedItemNode = this.editor.getNodeFromId(id);
    this.selectedItemNodeIsSensor = false;
    if (this.selectedItemNode && this.isSensorNode(this.selectedItemNode?.class)) {
      this.selectedItemNodeIsSensor = true;
      this.selectedItemNodeInputId = this.selectedItemNode.inputs.input_1?.connections[0]?.node;
      this.selectedItemNodeInputPort = this.selectedItemNode.inputs.input_1?.connections[0]?.input;
      this.selectedItemNodeOutputId = this.selectedItemNode.outputs.output_1?.connections[0]?.node;
      this.selectedItemNodeOutputPort = this.selectedItemNode.outputs.output_1?.connections[0]?.output;
    }
  }

  isSensorNode(equipName) {
    return this.equipConfiguration.sensorEquipList.indexOf(equipName) != -1;
  }

  isParentEquip(equipName) {
    return this.equipConfiguration.parentEquipList.indexOf(equipName) != -1;
  }

  isChiller_Or_Boiler(equipName) {
    return this.equipConfiguration.chiller_Or_Boiler.indexOf(equipName) != -1;
  }

  createConnectionOnSensorDeletion(pipeClass = '', groupPipeClass = '') {
    if (this.selectedItemNodeIsSensor) {
      this.editor.addConnection(this.selectedItemNodeInputId, this.selectedItemNodeOutputId, this.selectedItemNodeInputPort, this.selectedItemNodeOutputPort, pipeClass, false, true, groupPipeClass, false);
    }
  }

  ngOnInit(): void {
    this.loginUserTypeData = this.userService.getUserType();
    if (this.loginUserTypeData.isPublic) {
      this.toggleTheme = "light";
      this.masterViewPreference = "valueView";
    } else if (!this.loginUserTypeData.isPublic) {
      this.getUserPreferenceData();
      if (JSON.parse(localStorage.getItem("portal")) == 'facilisight') {
        this.isFac = true;

        if(JSON.parse(localStorage.getItem("certificateLevel")) == 'L3'){
          this.isBuilderUser = true;
        }
      }
      this.selectedPlantData = { lastModifiedBy: { userEmail: '', dateTime: '', userId: '' } };
    }
  }

  getSiteEquips(onShowEquipmentsClick = false) {
    this.hideEquipListLoader = false;
    const siteId = this.selectedBuilding.length ? this.selectedBuilding[0].id : this.selectedPlantData?.siteId;
    let getAllEquips;
    if (this.loginUserTypeData.isPublic) {
      getAllEquips = this.apiService.getAllSharedEquips(this.loginUserTypeData.linkShareUuid, this.selectedPlantData.chillerPlantId, this.selectedPlantData?.siteId);
    } else {
      getAllEquips = this.apiService.getAllEquips(siteId, this.selectedPlantData.chillerPlantId);
    }
    getAllEquips.subscribe((response) => {
      let coolingFanEquipIds = response?.filter(equip => equip.tags.includes("coolingTower") && equip.tags.includes("cell")).map(x => x.equipId);
      if (coolingFanEquipIds?.length) {
        this.apiService.getReadByIdMany(coolingFanEquipIds).subscribe((res: any) => {
          if (res && res.rows && res.rows.length) {
            res.rows.forEach(equipTags => {
              if (equipTags.cell == "2") {
                response?.filter(equip => equip.equipId == equipTags.id.split("r:")[1]).map(equip => equip.tags.push("cooling2Fan"));
              }
              if (equipTags.cell == "4") {
                response?.filter(equip => equip.equipId == equipTags.id.split("r:")[1]).map(equip => equip.tags.push("cooling4Fan"));
              }
            });
          }
          this.createEquipJSONData(response, onShowEquipmentsClick);
        });
      } else {
        this.createEquipJSONData(response, onShowEquipmentsClick);
      }
      if (document.getElementsByClassName('ng-tooltip-show')?.length) {
        Array.from(document.getElementsByClassName('ng-tooltip-show')).forEach(_r => _r.remove());
      }
    });
  }

  async createEquipJSONData(response, onShowEquipmentsClick = false) {
    let tempEquipData = [];
    let tempAllSubEquips = [];
    response?.forEach(equipData => {
      let tempEquipDatum = {};
      let tempSubEquipIds = [];
      tempEquipDatum["equipId"] = equipData.equipId;
      tempEquipDatum['parentEquipId'] = equipData.equipId;
      tempEquipDatum["equipName"] = equipData.equipName;
      tempEquipDatum["equip"] = [this.getEquipFromTags(equipData.tags)];
      tempEquipDatum["equipType"] = this.getEquipFromTags(equipData.tags);
      tempEquipDatum["tags"] = equipData.tags;
      tempEquipDatum["data"] = [];
      tempEquipDatum["equipData"] = {
        "displayName": equipData.equipName,
        "siteName": equipData.siteName,
        "name": equipData.equipName,
        "brand": equipData['brand'],
        "model": equipData['model'],
        "capacity": equipData['capacity'],
        "pointLimit": equipData['pointLimit'],
        "subEquips": this.getSubEquipData(equipData.subEquipList, tempSubEquipIds, tempAllSubEquips, tempEquipDatum)
      };
      tempEquipDatum[equipData.equipId] = tempSubEquipIds;
      if (tempEquipDatum["equip"] != '') {
        tempEquipData.push(tempEquipDatum);
      }
    });

    tempEquipData = await this.getSubEquipPoints(tempAllSubEquips, tempEquipData, onShowEquipmentsClick);
    this.nodes = [];
  }


  getSubEquipPoints(tempAllSubEquips, tempEquipData, onShowEquipmentsClick = false) {
    let subEquipApiCall = this.apiService.getPointsForEquipsSubequips(tempAllSubEquips);
    if (this.loginUserTypeData.isPublic) {
      subEquipApiCall = this.apiService.getSharedPointForSubEquips(this.loginUserTypeData.linkShareUuid, tempAllSubEquips);
    }
    subEquipApiCall.subscribe({
      next: (response) => {
        response.forEach((resEquip) => {
          tempEquipData.find((equip) => {
            let index = equip.equipData.subEquips.findIndex(subEquip => subEquip.equipId == resEquip.equipId);
            if (index > -1) {
              equip.equipData.subEquips[index].equips = ObjectUtil.deepCopy(this.getSensorData(resEquip.pointList));
            }
          });
        });
      },
      error: () => {
        this.nodes = [];
        this.filterEquipListWhichAlreadyPresentOnCanvas(onShowEquipmentsClick);
      },
      complete: () => {
        this.initializeList(tempEquipData);
        this.nodes = this.removeDuplicatesInArrayOfObject(this.nodes, 'equipId')
        this.duplicateNodesArr = ObjectUtil.deepClone(this.nodes);
        this.filterEquipListWhichAlreadyPresentOnCanvas(onShowEquipmentsClick);
      }
    });

    return tempEquipData
  }

  setPipeClassByTags(tagList) {
    let pipeClasses = [];
    if ((tagList.includes("meter") && tagList.includes("thermal")) || tagList.includes("pump")) {
      if (tagList.includes("chilled")) {
        pipeClasses = ["CWSupplyClass", "CWReturnClass", "CWBypassClass", "DTSupplyClass", "DTReturnClass", "DTBypassClass"];
      } else if (tagList.includes("hot")) {
        pipeClasses = ["boilerSupplyClass", "boilerReturnClass", "HotBypassClass", "DTSupplyClass", "DTReturnClass", "DTBypassClass"];
      } else if (tagList.includes("condenser")) {
        pipeClasses = ["CDWSupplyClass", "CDWReturnClass", "CDWBypassClass", "DTSupplyClass", "DTReturnClass", "DTBypassClass"];
      } else if (tagList.includes("dual") && tagList.includes("temp")) {
        pipeClasses = ["DTSupplyClass", "DTReturnClass", "DTBypassClass"];
      }
    }
    return pipeClasses;
  }

  getSubEquipData(subEquipList, tempSubEquipIds, tempAllSubEquips, equipData) {
    let isHeaderPipe = this.isHeaderPipe(equipData.equipType);
    const isBypassHeaderPipe = this.isBypassPipe(equipData.equipType);
    if (isHeaderPipe || isBypassHeaderPipe) {
      let tempSubEquipData = [];
      let tempSubEquipDatum = {};
      tempSubEquipIds.push(equipData.equipId);
      tempAllSubEquips.push(equipData.equipId);
      tempSubEquipDatum["equip"] = [equipData.equip[0]];
      tempSubEquipDatum["equips"] = [];
      tempSubEquipDatum["equipId"] = equipData.equipId;
      tempSubEquipDatum["parentEquipsTags"] = equipData.tags;
      if (equipData.equip[0] != '') {
        tempSubEquipData.push(tempSubEquipDatum);
      }
      return tempSubEquipData;
    }

    if (equipData.equipType.includes('pump1Vertical')) {
      let tempSubEquipData = [];
      let tempSubEquipDatum = {};
      tempSubEquipIds.push(equipData.equipId);
      tempAllSubEquips.push(equipData.equipId);
      tempSubEquipDatum["equip"] = [equipData.equip[0]];
      tempSubEquipDatum["equips"] = [];
      tempSubEquipDatum["pipeClass"] = this.setPipeClassByTags(equipData.tags);
      tempSubEquipDatum["parentEquipTags"] = equipData.tags;
      tempSubEquipDatum["equipId"] = equipData.equipId;
      if (equipData.equip[0] != '') {
        tempSubEquipData.push(tempSubEquipDatum);
      }
      return tempSubEquipData;
    }

    if (equipData.equipType == 'btuMeter') {
      let tempSubEquipData = [];
      let tempSubEquipDatum = {};
      tempSubEquipIds.push(equipData.equipId);
      tempAllSubEquips.push(equipData.equipId);
      tempSubEquipDatum["equip"] = [equipData.equip[0]];
      tempSubEquipDatum["equips"] = [];
      tempSubEquipDatum["pipeClass"] = this.setPipeClassByTags(equipData.tags);
      tempSubEquipDatum["parentEquipTags"] = equipData.tags;
      tempSubEquipDatum["equipId"] = equipData.equipId;
      if (equipData.equip[0] != '') {
        tempSubEquipData.push(tempSubEquipDatum);
      }
      return tempSubEquipData;
    }

    if (subEquipList) {
      let tempSubEquipData = [];
      subEquipList.forEach(equipData => {
        let tempSubEquipDatum = {};
        tempSubEquipDatum["equipId"] = equipData.equipId;
        tempSubEquipDatum['parentEquipId'] = equipData.equipId;
        tempSubEquipIds.push(equipData.equipId);
        tempAllSubEquips.push(equipData.equipId);
        tempSubEquipDatum["equip"] = [this.getEquipFromTags(equipData.tags)],
          tempSubEquipDatum["subEquipsName"] = equipData.equipName;
        tempSubEquipDatum["subEquipType"] = this.getEquipFromTags(equipData.tags);
        tempSubEquipDatum["tags"] = equipData.tags;
        tempSubEquipDatum["equipData"] = {
          "displayName": equipData.equipName,
          "name": equipData.equipName
        };
        tempSubEquipDatum["equips"] = [];
        if (tempSubEquipDatum["equip"] != '') {
          tempSubEquipData.push(tempSubEquipDatum);
        }
      });
      return tempSubEquipData;
    } else
      return [];
  }

  getSensorData(subEquipList) {
    if (subEquipList) {
      let tempSubEquipData = [];
      subEquipList.forEach(equipData => {
        if (equipData.tags.indexOf('sensor') > -1 || equipData.tags.indexOf('cmd') > -1 ) {
          let tempSubEquipDatum = {};
          tempSubEquipDatum["pointId"] = equipData.pointId;
          tempSubEquipDatum["equip"] = [this.getEquipFromTags(equipData.tags)],
            tempSubEquipDatum["pointName"] = equipData.shortName;
          tempSubEquipDatum["shortName"] = equipData.shortName;
          tempSubEquipDatum["subEquipType"] = this.getEquipFromTags(equipData.tags);
          if (preConfiguredNodes[tempSubEquipDatum["subEquipType"]] && preConfiguredNodes[tempSubEquipDatum["subEquipType"]].pointLimit) {
            if (tempSubEquipDatum["subEquipType"]?.includes('pump1Vertical')) {
              tempSubEquipDatum["pointLimit"] = 1;
            } else {
              tempSubEquipDatum["pointLimit"] = preConfiguredNodes[tempSubEquipDatum["subEquipType"]].pointLimit;
            }
          }
          tempSubEquipDatum["data"] = equipData.tags;
          if (tempSubEquipDatum["equip"] != "") {
            tempSubEquipData.push(tempSubEquipDatum);
          }
        }
      });
      return tempSubEquipData;
    } else
      return [];
  }

  // Show or Hide pipe Rotate Icon
  pipeArrowIconShowHide(inputConnectionData, outputConnectionData) {

    let pipeclassNodeData = inputConnectionData.class == 'pipeDemarkation' ? inputConnectionData : outputConnectionData;

    let grouPipeNodeId = document.getElementsByClassName(`parentEquipId-${pipeclassNodeData.equipId} GROUP`)[0]?.id.slice(5);
    if (grouPipeNodeId != undefined) {
      let pipeGroupNodeData = this.editor.drawflow.drawflow[this.editor.module].data[grouPipeNodeId];

      let groupElements = pipeGroupNodeData.data.groupNodeIds;
      let groupElementsData = [];

      groupElements.map(id => {
        groupElementsData.push(this.editor.getNodeFromId(id))
      });

      let isconnectedNode = 0;
      groupElementsData.forEach(equipData => {
        if (equipData.class == "pipeDemarkation") {
          let equipInput = [];
          let equipOutputs = [];
          Object.keys(equipData.inputs).forEach(key => equipInput.push(equipData.inputs[key]));
          Object.keys(equipData.outputs).forEach(key => equipOutputs.push(equipData.outputs[key]));

          if (equipInput[0].connections.length >= 1 && equipOutputs[0].connections.length >= 1) {
            isconnectedNode++;
          } else {
            if (Object.keys(equipData.inputs).length > 0) {
              equipInput.forEach(input => {
                if (input.connections.length == 0) {
                  equipOutputs.forEach(outputdata => {
                    if (outputdata.connections.length > 1) {
                      isconnectedNode++;
                    }
                  })
                }
              })
            }

            if (Object.keys(equipData.outputs).length > 0) {
              equipOutputs.forEach(output => {
                if (output.connections.length == 0) {
                  equipInput.forEach(input => {
                    if (input?.connections?.length > 1) {
                      isconnectedNode++;
                    }
                  })
                }
              })
            }
          }

        }
      });

      var els: any = document.querySelector(`#pipeRotateImg_${pipeclassNodeData?.equipId}`);

      if (isconnectedNode > 0) {
        if (els != null) {
          els.style.display = 'none';
        }
      } else {
        if (els != null) {
          els.style.display = 'block';
        }
      }
    }
  }

  getMasterViewColor(tagList) {
    if (tagList.includes("chilled") && (tagList.includes("entering") || tagList.includes("supply"))) {
      return "#9CD1FF";
    } else if (tagList.includes("chilled") && (tagList.includes("leaving") || tagList.includes("return"))) {
      return "#4EABFF";
    } else if (tagList.includes("chilled") && tagList.includes("water") && tagList.includes("flow")) {
      return "#FF5E55";
    } else if (tagList.includes("condenser") && tagList.includes("water") && tagList.includes("flow")) {
      return "#FF5E55";
    } else if (tagList.includes("condenser") && (tagList.includes("entering") || tagList.includes("supply"))) {
      return "#12D681";
    } else if (tagList.includes("condenser") && tagList.includes("leaving")) {
      return "#7EF3C2";
    } else if (tagList.includes("boiler") && (tagList.includes("entering") || tagList.includes("supply"))) {
      return "#FA8A8A";
    } else if (tagList.includes("boiler") && (tagList.includes("leaving") || tagList.includes("return"))) {
      return "#E53636";
    } else if (tagList.includes("chiller") && tagList.includes("efficiency")) {
      return "#12D681";
    } else if (tagList.includes("chiller") && tagList.includes("load")) {
      return "#6884FD";
    } else if ((tagList.includes("pump") || tagList.includes("coolingTower")) && tagList.includes("vfd")) {
      return "#B68AFF";
    } else if ((tagList.includes("pump") || tagList.includes("isolation")) && tagList.includes("valve")) {
      return "#FFB444";
    } else {
      return "#E55201"
    }
  }

  getEquipFromTags(tagList) {
    if (tagList.includes("chiller") && tagList.includes("waterCooling")) {
      return "waterCooledChiller";
    } else if (tagList.includes("chiller") && tagList.includes("airCooling")) {
      return "airCooledChiller";
    } else if (tagList.includes("pump")) {
      return "pump1Vertical";
    } else if (tagList.includes("coolingTower") && tagList.includes("cooling2Fan")) {
      return "coolingTowerTwoFan";
    } else if (tagList.includes("coolingTower") && tagList.includes("cooling4Fan")) {
      return "coolingTowerFourFan";
    } else if (tagList.includes("coolingTower")) {
      return "coolingTowerSingleFan";
    } else if (tagList.includes("boiler") && (tagList.includes("condensor") || tagList.includes("condensate"))) {
      return "condenserBoiler";
    } else if (tagList.includes("boiler") && tagList.includes("steam")) {
      return "steamBoiler";
    } else if (tagList.includes("header") && tagList.includes("pipe") && (tagList.includes("return") || tagList.includes("leaving")) && tagList.includes("hot")) {
      return "hotWaterReturnHeaderPipe";
    } else if (tagList.includes("header") && tagList.includes("pipe") && (tagList.includes("supply") || tagList.includes("entering")) && tagList.includes("hot")) {
      return "hotWaterSupplyHeaderPipe";
    } else if (tagList.includes("header") && tagList.includes("pipe") && (tagList.includes("return") || tagList.includes("leaving")) && tagList.includes("chilled")) {
      return "chilledWaterReturnHeaderPipe";
    } else if (tagList.includes("header") && tagList.includes("pipe") && (tagList.includes("supply") || tagList.includes("entering")) && tagList.includes("chilled")) {
      return "chilledWaterSupplyHeaderPipe";
    } else if (tagList.includes("header") && tagList.includes("pipe") && (tagList.includes("return") || tagList.includes("leaving")) && tagList.includes("condenser")) {
      return "condenserWaterReturnHeaderPipe";
    } else if (tagList.includes("header") && tagList.includes("pipe") && (tagList.includes("supply") || tagList.includes("entering")) && tagList.includes("condenser")) {
      return "condenserWaterSupplyHeaderPipe";
    } else if (tagList.includes("header") && tagList.includes("pipe") && (tagList.includes("return") || tagList.includes("leaving")) && (tagList.includes("dual") && tagList.includes("temp"))) {
      return "dualTempReturnHeaderPipe";
    } else if (tagList.includes("header") && tagList.includes("pipe") && (tagList.includes("supply") || tagList.includes("entering")) && (tagList.includes("dual") && tagList.includes("temp"))) {
      return "dualTempSupplyHeaderPipe";
    } else if (!tagList.includes("header") && tagList.includes("pipe") && (tagList.includes("return") || tagList.includes("leaving")) && tagList.includes("hot")) {
      return "hotWaterReturnHeaderPipe";
    } else if (!tagList.includes("header") && tagList.includes("pipe") && (tagList.includes("supply") || tagList.includes("entering")) && tagList.includes("hot")) {
      return "hotWaterSupplyHeaderPipe";
    } else if (!tagList.includes("header") && tagList.includes("pipe") && (tagList.includes("return") || tagList.includes("leaving")) && tagList.includes("chilled")) {
      return "chilledWaterReturnHeaderPipe";
    } else if (!tagList.includes("header") && tagList.includes("pipe") && (tagList.includes("supply") || tagList.includes("entering")) && tagList.includes("chilled")) {
      return "chilledWaterSupplyHeaderPipe";
    } else if (!tagList.includes("header") && tagList.includes("pipe") && (tagList.includes("return") || tagList.includes("leaving")) && tagList.includes("condenser")) {
      return "condenserWaterReturnHeaderPipe";
    } else if (!tagList.includes("header") && tagList.includes("pipe") && (tagList.includes("supply") || tagList.includes("entering")) && tagList.includes("condenser")) {
      return "condenserWaterSupplyHeaderPipe";
    } else if (tagList.includes("meter") && tagList.includes("thermal")) {
      return "btuMeter";
    } else if (tagList.includes("meter") && tagList.includes("flow")) {
      return "flowMeter";
    } else if (tagList.includes("sensor") && (tagList.includes("temperature") || tagList.includes("temp"))) {
      return "temperatureSensor";
    } else if (tagList.includes("well") && (tagList.includes("temperature") && tagList.includes("temp"))) {
      return "wellTemperature";
    } else if (tagList.includes("sensor") && tagList.includes("pressure")) {
      return "diffPressureSensor";
    } else if (tagList.includes("flow") && tagList.includes("sensor")) {
      return "flowSensor";
    } else if (tagList.includes("flow") && tagList.includes("switch")) {
      return "flowSwitch";
    } else if (tagList.includes("building") && tagList.includes("hot") && tagList.includes("chilled")) {
      return "building4Pipe";
    } else if (tagList.includes("building") && tagList.includes("hot")) {
      return "building2PipeHot";
    } else if (tagList.includes("building") && tagList.includes("chilled")) {
      return "building2Pipe";
    } else if (tagList.includes("building") && tagList.includes("chiller")) {
      return "dualTempBuilding2Pipe";
    } else if (tagList.includes("building") && tagList.includes("dual") && tagList.includes("temp") && tagList.includes("water") && tagList.includes("load") ) {
      return "dualTempBuilding2Pipe";
    } else if (tagList.includes("chilled") && tagList.includes("bypass") && tagList.includes("pipe")) {
      return "chilledWaterBypassHeaderPipe";
    } else if (tagList.includes("condenser") && tagList.includes("bypass") && tagList.includes("pipe")) {
      return "condenserWaterBypassHeaderPipe";
    } else if (tagList.includes("dual") && tagList.includes("temp") && tagList.includes("bypass") && tagList.includes("pipe")) {
      return "dualTempBypassHeaderPipe";
    } else if (tagList.includes("hot") && tagList.includes("bypass") && tagList.includes("pipe")) {
      return "hotWaterBypassHeaderPipe";
    } else if ((tagList.includes("sensor") || tagList.includes("cmd")) && tagList.includes("valve") && tagList.includes("diverting")) {
      return "threeWayValveDivert";
    } else if ((tagList.includes("sensor") || tagList.includes("cmd")) && tagList.includes("valve") && tagList.includes("mixing")) {
      return "threeWayValvemixing";
    } else if ((tagList.includes("sensor") || tagList.includes("cmd")) && tagList.includes("valve") && (!tagList.includes("diverting") || !tagList.includes("mixing"))) {
      return "valveActuator";
    } else if (!tagList.includes("header") && tagList.includes("pipe") && (tagList.includes("return") || tagList.includes("leaving"))) {
      return "dualTempReturnHeaderPipe";
    } else if (!tagList.includes("header") && tagList.includes("pipe") && (tagList.includes("supply") || tagList.includes("entering"))) {
      return "dualTempSupplyHeaderPipe";
    } else if (!tagList.includes("header") && tagList.includes("pipe") && (tagList.includes("supply") || tagList.includes("entering"))) {
      return "dualTempSupplyHeaderPipe";
    } else if (tagList.includes("heatExchanger")) {
      return "heatExchanger_backward";
    }
    return "";
  }

  checksubEquipValidation(pipeClass) {
    if (this.selectedItem.equipData['subEquips'][0].pipeClass.includes(pipeClass)) {
      return true;
    }
    return false;
  }

  addSensorNodeBetweenNodes(event) {
    let eventClassList = event.target.parentElement.classList;
    let connectionStart: any;
    let connectionEnd: any;
    let pipeClass = '';
    let groupPipeId = '';
    let eventClassListArr = Array.from(eventClassList)
    if (!eventClassListArr.includes("LbendConnector-pipe")) {
      eventClassList.forEach(_class => {
        if (_class.includes('node_in_node-')) {
          connectionEnd = Number(_class.split("node_in_node-")[1]);
        }
        const pipeClasses = ["CDWReturnClass", "CDWSupplyClass", "CWReturnClass", "CWSupplyClass", "boilerReturnClass", "boilerSupplyClass", "CDWBypassClass",
          "HotBypassClass", "CWBypassClass", "DTSupplyClass", "DTReturnClass", "DTBypassClass"];
        if (pipeClasses.includes(_class)) {
          pipeClass = _class;
        };
        if (_class.includes('node_out_node-')) {
          connectionStart = _class.split("node_out_node-")[1];
        }
        if (_class.includes('groupPipe-')) {
          groupPipeId = _class;
        }
      });
      if (this.checksubEquipValidation(pipeClass) && this.selectedItem.class == 'btuMeter' && !eventClassList.contains("lbend-connector-pipe")) {
        if (eventClassList.contains("connector-pipe")) {
          pipeClass = pipeClass + " connector-pipe";
        }
        this.addBtuMeterBetweenPipeConnections(event, groupPipeId, connectionStart, connectionEnd, pipeClass, eventClassList);
      } else if (this.selectedItem.class.includes('pump1Vertical')) {
        if (eventClassList.contains("connector-pipe")) {
          pipeClass = pipeClass + " connector-pipe";
        }
        if (eventClassList.contains("lbend-connector-pipe")) {
          pipeClass = pipeClass + " lbend-connector-pipe";
        }
        this.addPumpBetweenPipeConnections(event, pipeClass, connectionStart, connectionEnd, eventClassList, groupPipeId);
      } else {
        if (connectionEnd && connectionStart) {
          document.querySelector(`.connection.node_in_node-${connectionEnd}.node_out_node-${connectionStart}`).querySelector('.main-path').classList.remove('orangeClass');
        }
      }
    } else {
      const groupPipeElement = document.querySelectorAll(`.${eventClassListArr.join('.')} path.main-path`);
      groupPipeElement.forEach(pipeElement => {
        if (pipeElement.classList.contains("orangeClass")) {
          pipeElement.classList.remove("orangeClass");
        }
      });
    }
  }

  addBtuMeterBetweenPipeConnections(event, groupPipeId, connectionStart, connectionEnd, pipeClass, eventClassList) {
    let parentNodeData = this.editor.getNodeFromId(connectionEnd);
    let pumpPlacedOnConnectorPipe = false;
    if (!groupPipeId) {
      parentNodeData = this.pumpPlacedOnConnectorPipe(event, connectionStart, connectionEnd);
      pumpPlacedOnConnectorPipe = true;
    }
    if (parentNodeData && parentNodeData?.equipId) {
      let pipeType = 'forward';
      document.getElementsByClassName('parentEquipId-' + parentNodeData.equipId)[0].classList.forEach(cssClass => {
        if (cssClass.includes('parent')) {
          if (document.getElementsByClassName(cssClass)[0].getElementsByClassName('pipeArrowImg_backward') && document.getElementsByClassName(cssClass)[0].getElementsByClassName('pipeArrowImg_backward').length) {
            pipeType = 'backward';
          }
        }
      });

      if (!this.isHeaderPipe(parentNodeData.childData.class)) {
        pipeType = this.checkParentEquipsForwardOrBackwardType(parentNodeData.childData.class);
      }

      this.selectedItemID = this.editor.nodeId;
      const btuMeterEquipId = ObjectUtil.deepClone(this.tempParentEquipData['equipId']);
      this.tempParentEquipData['equipId'] = parentNodeData.equipId;
      this.addNodeToDrawBoard(event.clientX, event.clientY, false, false, false, "", this.selectedItem.equipData.subEquips[0].equipId);
      if (document.getElementsByClassName(`parentEquipId-${parentNodeData.equipId} GROUP`)?.length) {
        const groupNodeId = document.getElementsByClassName(`parentEquipId-${parentNodeData.equipId} GROUP`)[0].id.slice(5);
        if (this.editor.drawflow.drawflow.Home.data[Number(groupNodeId)]) {
          let nodesArray = ObjectUtil.deepCopy(this.editor.drawflow.drawflow.Home.data[Number(groupNodeId)].data.elements);
          const subEquipList = ObjectUtil.deepCopy(nodesArray);
          subEquipList.shift();
          subEquipList.pop();
          const subEquipIdx = subEquipList.findIndex(_node => _node == connectionEnd);
          const btuMeterObj = {
            equip: [this.selectedItem.equipData.subEquips[0].equip[0]],
            subEquipType: this.selectedItem.equipData.subEquips[0].equip[0],
            equips: [this.selectedItem.equipData.subEquips[0].equips[0]],
            equipId: btuMeterEquipId
          };
          const indx = this.editor.drawflow.drawflow.Home.data[Number(groupNodeId)].data.elements.findIndex(_r => _r == connectionEnd);
          nodesArray = this.insertAt(nodesArray, indx, this.selectedItemID);
          if (this.editor.drawflow.drawflow.Home.data[Number(groupNodeId)].childData.equipData.subEquips[0].equips.length) {
            this.editor.drawflow.drawflow.Home.data[Number(groupNodeId)].childData.equipData.subEquips[0].equips = this.insertAt(this.editor.drawflow.drawflow.Home.data[Number(groupNodeId)].childData.equipData.subEquips[0].equips, subEquipIdx, btuMeterObj);
          }
          if (pipeType == 'forward') {
            this.updatePositionOnBtuMeterDrop(this.selectedItemID, connectionEnd, pipeType, pumpPlacedOnConnectorPipe, connectionStart);
          } else {
            this.updatePositionOnBtuMeterDrop(this.selectedItemID, connectionEnd, pipeType, pumpPlacedOnConnectorPipe);
          }
        }
        let arrayNodeList = [];
        let idx = null;
        if (parentNodeData.childData.class == "waterCooledChiller" || parentNodeData.childData.class == "airCooledChiller" || parentNodeData.childData.class == "condenserBoiler" || parentNodeData.childData.class == "steamBoiler") {
          if (groupPipeId) {
            document.querySelectorAll('.' + groupPipeId).forEach((_node, i) => {
              if (i == 0) {
                idx = _node.classList[3].split('output_')[1];
                arrayNodeList.push(_node.classList[2].split('node_out_node-')[1]);
                arrayNodeList.push(_node.classList[1].split('node_in_node-')[1]);
              } else {
                arrayNodeList.push(_node.classList[1].split('node_in_node-')[1]);
              }
            });
            const insertPosition = arrayNodeList.findIndex(_node => _node == connectionEnd);
            arrayNodeList = arrayNodeList.splice(insertPosition, 0, this.selectedItemID);
          }
        }

        eventClassList = Array.from(eventClassList);
        let dualPipeClasses: any = ["DTSupplyClass", "DTReturnClass", "DTBypassClass"];
        this.editor.drawflow.drawflow.Home.data[Number(this.selectedItemID)].isDualTempEquip = dualPipeClasses.some(el => eventClassList.includes(el)) ? true : false;

        this.editor.drawflow.drawflow.Home.data[Number(this.selectedItemID)].nodePipeClass = pipeClass;
        this.editor.drawflow.drawflow.Home.data[Number(this.selectedItemID)].groupPipeId = groupPipeId.split('groupPipe-')[1];
        this.editor.removeSingleConnection(connectionStart, connectionEnd, eventClassList[3], eventClassList[4]);

        let connectionStartNodeData = this.editor.getNodeFromId(String(connectionStart));
        let connectionEndNodeData = this.editor.getNodeFromId(String(connectionEnd));

        /***
          Adding SubEquip Id, subEquip pipeClass, 
          Parent EquipId, SubEquip pipe class to the BTU Object from Header Pipe/Parent SubEquip
          to maintain the connection stack 
        ***/
        if (connectionStartNodeData.class == "connectTwo") {
          this.editor.drawflow.drawflow.Home.data[Number(this.selectedItemID)].parentPipeRef = connectionStartNodeData.groupPipeId;
          this.editor.drawflow.drawflow.Home.data[Number(this.selectedItemID)].connectedParentClass = connectionStartNodeData.childData.class;
          this.editor.drawflow.drawflow.Home.data[Number(this.selectedItemID)].connectedParentSubEquipPipeClass = connectionStartNodeData.nodePipeClass;
          this.editor.drawflow.drawflow.Home.data[Number(this.selectedItemID)].connectedParentSubEquipId = connectionStartNodeData.groupPipeId;
          this.editor.drawflow.drawflow.Home.data[Number(this.selectedItemID)].connectedParentEquipId = connectionStartNodeData.childData.equipId;
        }
        else if (connectionStartNodeData.class.includes('pump') || connectionStartNodeData.class.includes('btuMeter') || connectionStartNodeData.class.includes('temperatureSensor')) {
          this.editor.drawflow.drawflow.Home.data[Number(this.selectedItemID)].parentPipeRef = connectionStartNodeData.parentPipeRef;
          this.editor.drawflow.drawflow.Home.data[Number(this.selectedItemID)].connectedParentClass = connectionStartNodeData.connectedParentClass;
          this.editor.drawflow.drawflow.Home.data[Number(this.selectedItemID)].connectedParentSubEquipPipeClass = connectionStartNodeData.nodePipeClass;
          this.editor.drawflow.drawflow.Home.data[Number(this.selectedItemID)].connectedParentSubEquipId = connectionStartNodeData.groupPipeId;
          this.editor.drawflow.drawflow.Home.data[Number(this.selectedItemID)].connectedParentEquipId = connectionStartNodeData.childData.equipId;
        }

        if (this.editor.drawflow.drawflow.Home.data[Number(this.selectedItemID)]?.parentPipeRef == undefined) {
          if (connectionEndNodeData.class == "connectTwo") {
            this.editor.drawflow.drawflow.Home.data[Number(this.selectedItemID)].parentPipeRef = connectionEndNodeData.groupPipeId;
            this.editor.drawflow.drawflow.Home.data[Number(this.selectedItemID)].connectedParentClass = connectionEndNodeData.childData.class;
            this.editor.drawflow.drawflow.Home.data[Number(this.selectedItemID)].connectedParentSubEquipPipeClass = connectionEndNodeData.nodePipeClass;
            this.editor.drawflow.drawflow.Home.data[Number(this.selectedItemID)].connectedParentSubEquipId = connectionEndNodeData.groupPipeId;
            this.editor.drawflow.drawflow.Home.data[Number(this.selectedItemID)].connectedParentEquipId = connectionEndNodeData.childData.equipId;
          }
          else if (connectionEndNodeData.class.includes('pump') || connectionEndNodeData.class.includes('btuMeter') || connectionEndNodeData.class.includes('temperatureSensor')) {
            this.editor.drawflow.drawflow.Home.data[Number(this.selectedItemID)].parentPipeRef = connectionEndNodeData.parentPipeRef;
            this.editor.drawflow.drawflow.Home.data[Number(this.selectedItemID)].connectedParentClass = connectionEndNodeData.connectedParentClass;
            this.editor.drawflow.drawflow.Home.data[Number(this.selectedItemID)].connectedParentSubEquipPipeClass = connectionEndNodeData.nodePipeClass;
            this.editor.drawflow.drawflow.Home.data[Number(this.selectedItemID)].connectedParentSubEquipId = connectionEndNodeData.groupPipeId;
            this.editor.drawflow.drawflow.Home.data[Number(this.selectedItemID)].connectedParentEquipId = connectionEndNodeData.childData.equipId;
          }
        }


        this.editor.addConnection(Number(connectionStart), this.selectedItemID, eventClassList[3], 'input_1', pipeClass, false, true, groupPipeId.split('groupPipe-')[1]); // A(Equip_connection_out) -> B(selected_input)

        this.editor.addConnection(this.selectedItemID, Number(connectionEnd), 'output_1', eventClassList[4], pipeClass, false, true, groupPipeId.split('groupPipe-')[1]); // B(selected_ouput) -> A (Equip_connection_in)
        this.editor.drawflow.drawflow.Home.data[Number(groupNodeId)].data.elements.push(...[this.selectedItemID]);
        // Adding BTU Meter and sensor.
        if (this.tempParentEquipData.equipData.subEquips[0].equips.length) {
          this.tempParentEquipData.equipData.subEquips[0].equips.forEach((subEquipsData) => {
            let subEquipName = `${subEquipsData.subEquipType}_forward`;
            if (pipeType == 'backward') {
              subEquipName = `${subEquipsData.subEquipType}_backward`;
            }
            this.selectedItem = ElementsToBeConfiguredNodes.find(sensorData => {
              return sensorData.name == subEquipName;
            });
            this.selectedItemID = this.editor.nodeId;
          });
          this.addNodeToDrawBoard(event.clientX, event.clientY, false, false, false, "", this.selectedItem?.equipData?.subEquips[0]?.equipId);
          this.editor.drawflow.drawflow.Home.data[this.selectedItemID - 1].childData.equipData.subEquips[0]['childNodeId'] = this.selectedItemID;
          // Adding pipe connection from BTU Meter to Temp sensor.
          if (this.editor.drawflow.drawflow.Home.data[Number(this.selectedItemID - 1)].childData.equipData?.subEquips?.length) {
            const parentTags = this.editor.drawflow.drawflow.Home.data[Number(this.selectedItemID - 1)].childData.equipData?.subEquips[0]?.parentEquipTags;
            this.editor.drawflow.drawflow.Home.data[Number(this.selectedItemID - 1)].childData.equipData.subEquips[0].pipeClass = this.setPipeClassByTags(parentTags);
            this.editor.drawflow.drawflow.Home.data[Number(this.selectedItemID - 1)].childData.equipData.subEquips[0].pipeClass = ObjectUtil.deepCopy(this.editor.drawflow.drawflow.Home.data[Number(this.selectedItemID - 1)].childData.equipData.subEquips[0].pipeClass.filter(_class => _class != pipeClass));
          }
          this.editor.addConnection(this.selectedItemID - 1, this.selectedItemID, 'output_1', 'input_1', pipeClass, false, false, '', true);
        }
        this.nodes = this.nodes.filter((node: NodeElement) => node.equipId != this.tempParentEquipData.equipId);
      }
    }
    this.removeOrangeClass();
  }

  // Adding Pump inbetween the Pipe Connection
  addPumpBetweenPipeConnections(event, pipeClass, connectionStart, connectionEnd, eventClassList, groupPipeId) {
    const connectionStartDetails = this.editor.getNodeFromId(connectionStart);
    const connectionEndDetails = this.editor.getNodeFromId(connectionEnd);
    if (this.checksubEquipValidation(pipeClass.split(" ")[0]) && connectionStart && connectionEnd && ((pipeClass.includes('connector-pipe') && pipeClass.includes('lbend-connector-pipe')) || !pipeClass.includes('connector-pipe'))) {
      let parentNodeData = this.editor.getNodeFromId(connectionEnd);
      // when pump placed on connector pipe.
      let pumpPlacedOnConnectorPipe = false;
      if (!groupPipeId) {
        parentNodeData = this.pumpPlacedOnConnectorPipe(event, connectionStart, connectionEnd);
        pumpPlacedOnConnectorPipe = true;
      }
      if (parentNodeData && parentNodeData?.equipId) {
        let pipeType = 'forward';
        document.getElementsByClassName('parentEquipId-' + parentNodeData.equipId)[0].classList.forEach(cssClass => {
          if (cssClass.includes('parent')) {
            if (document.getElementsByClassName(cssClass)[0].getElementsByClassName('pipeArrowImg_backward') && document.getElementsByClassName(cssClass)[0].getElementsByClassName('pipeArrowImg_backward').length) {
              pipeType = 'backward';
            }
          }
        });
        if (!this.isHeaderPipe(parentNodeData.childData.class) && !this.isBypassPipe(parentNodeData.childData.class)) {
          pipeType = this.checkParentEquipsForwardOrBackwardType(parentNodeData.childData.class);
        }

        this.editor.removeSingleConnection(connectionStart, connectionEnd, eventClassList[3], eventClassList[4]);
        this.selectedItemID = this.editor.nodeId;
        this.selectedItem.pipeType = pipeType;
        if (this.selectedItem.class == 'pump1Vertical_forward' || this.selectedItem.class == 'pump1Vertical_backward') {
          this.selectedItem.class = 'pump1Vertical';
        }
        this.selectedItem.class = this.selectedItem.class + '_' + pipeType;
        if (parentNodeData.childData.class == "connectorLbendPipe") {
          this.tempParentEquipData['equipId'] = parentNodeData.connectedParentSubEquipId;
        } else {
          this.tempParentEquipData['equipId'] = parentNodeData.equipId;
        }
        if (pipeType == 'forward') {
          this.selectedItem.img = '06_Pump1vertical_forward.png';
          this.selectedItem.cutAwayImg = '06_Pump1_Cutaway-1.png';
        } else {
          this.selectedItem.img = '06_Pump1vertical_backward.png';
        }
        this.addNodeToDrawBoard(event.clientX, event.clientY, false, false, false, "", this.selectedItem.equipData.subEquips[0].equipId);
        if (document.getElementsByClassName(`parentEquipId-${parentNodeData.equipId} GROUP`)?.length) {
          const groupNodeId = document.getElementsByClassName(`parentEquipId-${parentNodeData.equipId} GROUP`)[0].id.slice(5);
          if (this.editor.drawflow.drawflow.Home.data[Number(groupNodeId)]) {
            let nodesArray = ObjectUtil.deepCopy(this.editor.drawflow.drawflow.Home.data[Number(groupNodeId)].data.elements);
            const subEquipList = ObjectUtil.deepCopy(nodesArray);
            subEquipList.shift();
            subEquipList.pop();
            const subEquipIdx = subEquipList.findIndex(_node => _node == connectionEnd);
            let btuMeterObj = this.selectedItem.equipData.subEquips[0].equips[0];
            if (!btuMeterObj) {
              btuMeterObj = {
                equipId: this.selectedItem.equipData.subEquips[0].equipId,
                subEquipType: this.selectedItem.equipData.subEquips[0].equip[0],
                equips: this.selectedItem.equipData.subEquips[0].equips
              }
            }
            const indx = this.editor.drawflow.drawflow.Home.data[Number(groupNodeId)].data.elements.findIndex(_r => _r == connectionEnd);
            if (pipeType == 'backward') {
              // update position of pump in a straight line.
              this.updatePositionOnPumpDrop(this.selectedItemID, connectionEnd, pipeType, pumpPlacedOnConnectorPipe);
              if (connectionStartDetails.class != "connectorLbend" && connectionEndDetails.class != "connectorLbend") {
                nodesArray = this.insertAt(nodesArray, indx, this.selectedItemID);
                this.insertAt(this.editor.drawflow.drawflow.Home.data[Number(groupNodeId)].childData?.equipData?.subEquips[0]?.equips, subEquipIdx, btuMeterObj);
              }
            } else {
              this.updatePositionOnPumpDrop(this.selectedItemID, connectionStart, pipeType, pumpPlacedOnConnectorPipe);
              if (connectionStartDetails.class != "connectorLbend" && connectionEndDetails.class != "connectorLbend") {
                nodesArray = this.insertAt(nodesArray, indx + 1, this.selectedItemID);
                this.insertAt(this.editor.drawflow.drawflow.Home.data[Number(groupNodeId)].childData?.equipData?.subEquips[0]?.equips, subEquipIdx + 1, btuMeterObj);
              }
            }
          }
          eventClassList = Array.from(eventClassList);

          this.editor.drawflow.drawflow.Home.data[Number(this.selectedItemID)].nodePipeClass = pipeClass;
          let dualPipeClasses: any = ["DTSupplyClass", "DTReturnClass", "DTBypassClass"];
          this.editor.drawflow.drawflow.Home.data[Number(this.selectedItemID)].isDualTempEquip = dualPipeClasses.some(el => eventClassList.includes(el)) ? true : false;
          this.editor.drawflow.drawflow.Home.data[Number(this.selectedItemID)].groupPipeId = groupPipeId.split('groupPipe-')[1];

          let connectionStartNodeData = this.editor.getNodeFromId(String(connectionStart));
          let connectionEndNodeData = this.editor.getNodeFromId(String(connectionEnd));

          /***
            Adding SubEquip Id, subEquip pipeClass, 
            Parent EquipId, SubEquip pipe class to the Pump Object from Header Pipe/Parent SubEquip
            to maintain the connection stack 
          ***/

          if (connectionStartNodeData.class == "connectTwo") {
            this.editor.drawflow.drawflow.Home.data[Number(this.selectedItemID)].parentPipeRef = connectionStartNodeData.groupPipeId;
            this.editor.drawflow.drawflow.Home.data[Number(this.selectedItemID)].connectedParentClass = connectionStartNodeData.childData.class;
            this.editor.drawflow.drawflow.Home.data[Number(this.selectedItemID)].connectedParentSubEquipPipeClass = connectionStartNodeData.nodePipeClass;
            this.editor.drawflow.drawflow.Home.data[Number(this.selectedItemID)].connectedParentSubEquipId = connectionStartNodeData.groupPipeId;
            this.editor.drawflow.drawflow.Home.data[Number(this.selectedItemID)].connectedParentEquipId = connectionStartNodeData.childData.equipId;
          }
          else if (connectionStartNodeData.class.includes('pump') || connectionStartNodeData.class.includes('btuMeter') || connectionStartNodeData.class.includes('temperatureSensor')) {
            this.editor.drawflow.drawflow.Home.data[Number(this.selectedItemID)].parentPipeRef = connectionStartNodeData.parentPipeRef;
            this.editor.drawflow.drawflow.Home.data[Number(this.selectedItemID)].connectedParentClass = connectionStartNodeData.connectedParentClass;
            this.editor.drawflow.drawflow.Home.data[Number(this.selectedItemID)].connectedParentSubEquipPipeClass = connectionStartNodeData.nodePipeClass;
            this.editor.drawflow.drawflow.Home.data[Number(this.selectedItemID)].connectedParentSubEquipId = connectionStartNodeData.groupPipeId;
            this.editor.drawflow.drawflow.Home.data[Number(this.selectedItemID)].connectedParentEquipId = connectionStartNodeData.connectedParentEquipId;
          }

          if ((connectionStartNodeData.class == "connectTwo" || connectionStartNodeData.class == "pipeDemarkation") && connectionEndNodeData.class == "connectorLbend") {
            this.editor.drawflow.drawflow.Home.data[Number(this.selectedItemID)].parentPipeRef = connectionEndNodeData.groupPipeId;
            this.editor.drawflow.drawflow.Home.data[Number(this.selectedItemID)].connectedParentClass = connectionEndNodeData.class;
            this.editor.drawflow.drawflow.Home.data[Number(this.selectedItemID)].connectedParentSubEquipPipeClass = connectionStartNodeData.nodePipeClass;
            this.editor.drawflow.drawflow.Home.data[Number(this.selectedItemID)].connectedParentSubEquipId = connectionStartNodeData.groupPipeId;
            this.editor.drawflow.drawflow.Home.data[Number(this.selectedItemID)].connectedParentEquipId = connectionStartNodeData.childData.equipId;
          }

          if ((connectionEndNodeData.class == "connectTwo" || connectionEndNodeData.class == "pipeDemarkation") && connectionStartNodeData.class == "connectorLbend") {
            this.editor.drawflow.drawflow.Home.data[Number(this.selectedItemID)].parentPipeRef = connectionStartNodeData.groupPipeId;
            this.editor.drawflow.drawflow.Home.data[Number(this.selectedItemID)].connectedParentClass = connectionStartNodeData.class;
            this.editor.drawflow.drawflow.Home.data[Number(this.selectedItemID)].connectedParentSubEquipPipeClass = connectionEndNodeData.nodePipeClass;
            this.editor.drawflow.drawflow.Home.data[Number(this.selectedItemID)].connectedParentSubEquipId = connectionEndNodeData.groupPipeId;
            this.editor.drawflow.drawflow.Home.data[Number(this.selectedItemID)].connectedParentEquipId = connectionEndNodeData.childData.equipId;
          }

          if (this.editor.drawflow.drawflow.Home.data[Number(this.selectedItemID)]?.parentPipeRef == undefined) {
            if (connectionEndNodeData.class == "connectTwo") {
              this.editor.drawflow.drawflow.Home.data[Number(this.selectedItemID)].parentPipeRef = connectionEndNodeData.groupPipeId;
              this.editor.drawflow.drawflow.Home.data[Number(this.selectedItemID)].connectedParentClass = connectionEndNodeData.class;
              this.editor.drawflow.drawflow.Home.data[Number(this.selectedItemID)].connectedParentSubEquipPipeClass = connectionEndNodeData.nodePipeClass;
              this.editor.drawflow.drawflow.Home.data[Number(this.selectedItemID)].connectedParentSubEquipId = connectionEndNodeData.groupPipeId;
              this.editor.drawflow.drawflow.Home.data[Number(this.selectedItemID)].connectedParentEquipId = connectionEndNodeData.childData.equipId;
            }
            else if (connectionEndNodeData.class.includes('pump') || connectionEndNodeData.class.includes('btuMeter') || connectionEndNodeData.class.includes('temperatureSensor')) {
              this.editor.drawflow.drawflow.Home.data[Number(this.selectedItemID)].parentPipeRef = connectionEndNodeData.connectedParentSubEquipId;
              this.editor.drawflow.drawflow.Home.data[Number(this.selectedItemID)].connectedParentClass = connectionEndNodeData.connectedParentClass;
              this.editor.drawflow.drawflow.Home.data[Number(this.selectedItemID)].connectedParentSubEquipPipeClass = connectionEndNodeData.nodePipeClass;
              this.editor.drawflow.drawflow.Home.data[Number(this.selectedItemID)].connectedParentSubEquipId = connectionEndNodeData.groupPipeId;
              this.editor.drawflow.drawflow.Home.data[Number(this.selectedItemID)].connectedParentEquipId = connectionEndNodeData.connectedParentEquipId;
            }
          }

          const extraClassArray = pipeClass.split(' ');
          for (let i = 0; i < extraClassArray.length; i++) {
            document.getElementById('node-' + this.selectedItemID).getElementsByClassName('drawflow_content_node')[0].classList.add(extraClassArray[i]);
          }

          if (!pumpPlacedOnConnectorPipe) {
            if (!this.isParentEquip(connectionStartDetails.class) && !this.isBypassPipe(connectionStartDetails.childData.class) && !this.isHeaderPipe(connectionStartDetails.childData.class) && (this.isSensorNode(connectionStartDetails.class) || this.isSensorNode(connectionEndDetails.class))) {
              this.editor.addConnection(Number(connectionStart), this.selectedItemID, 'output_1', 'input_1', pipeClass, false, true, groupPipeId.split('groupPipe-')[1], false, true); // A(Equip_connection_out) -> B(selected_input)
              this.editor.addConnection(this.selectedItemID, Number(connectionEnd), 'output_1', eventClassList[4], pipeClass, false, true, groupPipeId.split('groupPipe-')[1], false, true); // B(selected_ouput) -> A (Equip_connection_in)
            } else {
              if (this.isParentEquip(connectionStartDetails.class)) {
                this.editor.addConnection(Number(connectionStart), this.selectedItemID, eventClassList[3], 'input_1', pipeClass, false, true, groupPipeId.split('groupPipe-')[1], false, true); // A(Equip_connection_out) -> B(selected_input)
              } else {
                if (this.isParentEquip(connectionEndDetails.class) || ((this.isHeaderPipe(connectionStartDetails.childData.class) || this.isBypassPipe(connectionStartDetails.childData.class)) && pipeType == 'backward')) {
                  this.editor.addConnection(Number(connectionStart), this.selectedItemID, 'output_1', 'input_1', pipeClass, false, true, groupPipeId.split('groupPipe-')[1], false, true); // A(Equip_connection_out) -> B(selected_input)
                } else {
                  this.editor.addConnection(this.selectedItemID, Number(connectionStart), 'output_1', 'input_1', pipeClass, false, true, groupPipeId.split('groupPipe-')[1], false, true); // A(Equip_connection_out) -> B(selected_input)
                }
              }

              if (this.isParentEquip(connectionEndDetails.class)) {
                if (connectionEndDetails.class.includes('building2')) {
                  this.editor.addConnection(this.selectedItemID, Number(connectionEnd), 'output_1', eventClassList[4], pipeClass, false, true, groupPipeId.split('groupPipe-')[1], false, true); // B(selected_ouput) -> A (Equip_connection_in)
                } else {
                  this.editor.addConnection(this.selectedItemID, Number(connectionEnd), 'output_1', eventClassList[4], pipeClass, false, true, groupPipeId.split('groupPipe-')[1], false, true); // B(selected_ouput) -> A (Equip_connection_in)
                }
              } else {
                if (this.isParentEquip(connectionStartDetails.class) || ((this.isHeaderPipe(connectionStartDetails.childData.class) || this.isBypassPipe(connectionStartDetails.childData.class)) && pipeType == 'backward')) {
                  this.editor.addConnection(this.selectedItemID, Number(connectionEnd), 'output_1', eventClassList[4], pipeClass, false, true, groupPipeId.split('groupPipe-')[1], false, true); // B(selected_ouput) -> A (Equip_connection_in)
                } else {
                  this.editor.addConnection(Number(connectionEnd), this.selectedItemID, 'output_1', eventClassList[4], pipeClass, false, true, groupPipeId.split('groupPipe-')[1], false, true); // B(selected_ouput) -> A (Equip_connection_in)
                }
              }
            }
          } else {
            if (connectionStartNodeData?.groupPipeId != undefined && connectionStartNodeData?.groupPipeId != '') {
              pipeClass = pipeClass + ` groupPipe-${connectionStartNodeData.groupPipeId}`;
            }
            if (connectionEndDetails.equipType == '3wayValve') {
              this.editor.addConnection(Number(connectionStart), this.selectedItemID, 'output_1', 'input_1', pipeClass, false, true, groupPipeId.split('groupPipe-')[1], false, true); // A(Equip_connection_out) -> B(selected_input)
              this.editor.addConnection(this.selectedItemID, Number(connectionEnd), 'output_1', eventClassList[4], pipeClass, false, true, groupPipeId.split('groupPipe-')[1], false, true); // B(selected_ouput) -> A (Equip_connection_in)
            } else {
              this.editor.addConnection(this.selectedItemID, Number(connectionStart), 'output_1', 'input_1', pipeClass, false, true, groupPipeId.split('groupPipe-')[1], false, true); // A(Equip_connection_out) -> B(selected_input)
              this.editor.addConnection(Number(connectionEnd), this.selectedItemID, 'output_1', eventClassList[4], pipeClass, false, true, groupPipeId.split('groupPipe-')[1], false, true); // B(selected_ouput) -> A (Equip_connection_in)
            }
          }
          this.editor.drawflow.drawflow.Home.data[Number(groupNodeId)].data.elements.push(...[this.selectedItemID]);

          this.nodes = this.nodes.filter((node: NodeElement) => node.name != this.tempParentEquipData.name);
        }
      }
    } else {
      if (connectionEnd && connectionStart) {
        document.querySelector(`.connection.node_in_node-${connectionEnd}.node_out_node-${connectionStart}`).querySelector('.main-path').classList.remove('orangeClass');
      }
    }
    this.removeOrangeClass();
  }

  // Remove Orange Class  after pump and Btu Drop
  removeOrangeClass() {
    document.getElementsByClassName('orangeClass')
    let orangeClassElement = Array.from(document.querySelectorAll(".orangeClass"));
    if (orangeClassElement.length) {
      for (var i = 0; i < orangeClassElement.length; i++) {
        orangeClassElement[i].classList.remove('orangeClass');
      }
    }
  }

  getNearestValue(array, value) {
    return array.reduce((a, b) => {
      return Math.abs(b.value - value) < Math.abs(a.value - value) ? b : a;
    });
  }

  pumpPlacedOnConnectorPipe(event, connectionStart, connectionEnd) {
    const connectStartEventCoordinates = document.getElementById('node-' + connectionStart).getBoundingClientRect().x;
    const connectEndEventCoordinates = document.getElementById('node-' + connectionEnd).getBoundingClientRect().x;
    const array = [{ id: connectionStart, value: connectStartEventCoordinates }, { id: connectionEnd, value: connectEndEventCoordinates }];
    const nearestNode = this.getNearestValue(array, event.x);
    return this.editor.getParentNodeData(nearestNode.id);
  }

  updatePositionOnPumpDrop(pumpNodeId, nodeIdToUpdate, pipeType, pumpPlacedOnConnectorPipe: boolean = false) {
    let pumpNodeLeftPos = this.editor.drawflow.drawflow.Home.data[Number(nodeIdToUpdate)].pos_x;
    let pumpNodeTopPos = this.editor.drawflow.drawflow.Home.data[Number(nodeIdToUpdate)].pos_y;
    if (pipeType == 'backward') {
      let topVal = 250;
      let leftVal = 250;
      if (!pumpPlacedOnConnectorPipe) {
        if (!this.isSensorNode(this.editor.drawflow.drawflow.Home.data[Number(nodeIdToUpdate)].class)) {
          document.getElementById(`node-${pumpNodeId}`).style.left = (Number(pumpNodeLeftPos) - leftVal) + 'px';
          document.getElementById(`node-${pumpNodeId}`).style.top = (Number(pumpNodeTopPos) - topVal - 60) + 'px';
          this.editor.drawflow.drawflow.Home.data[Number(pumpNodeId)].pos_x = (Number(pumpNodeLeftPos) - leftVal);
          this.editor.drawflow.drawflow.Home.data[Number(pumpNodeId)].pos_y = (Number(pumpNodeTopPos) - topVal);

          document.getElementById(`node-${nodeIdToUpdate}`).style.left = (Number(pumpNodeLeftPos) + 100) + 'px';
          document.getElementById(`node-${nodeIdToUpdate}`).style.top = (Number(pumpNodeTopPos) + 60) + 'px';
          this.editor.drawflow.drawflow.Home.data[Number(nodeIdToUpdate)].pos_x = (Number(pumpNodeLeftPos) + 100);
          this.editor.drawflow.drawflow.Home.data[Number(nodeIdToUpdate)].pos_y = (Number(pumpNodeTopPos) + 60);
        } else {
          document.getElementById(`node-${pumpNodeId}`).style.left = (Number(pumpNodeLeftPos) - leftVal) + 'px';
          document.getElementById(`node-${pumpNodeId}`).style.top = (Number(pumpNodeTopPos) - topVal) + 'px';
          this.editor.drawflow.drawflow.Home.data[Number(pumpNodeId)].pos_x = (Number(pumpNodeLeftPos) - leftVal);
          this.editor.drawflow.drawflow.Home.data[Number(pumpNodeId)].pos_y = (Number(pumpNodeTopPos) - topVal);

          document.getElementById(`node-${nodeIdToUpdate}`).style.left = (Number(pumpNodeLeftPos) + 50) + 'px';
          document.getElementById(`node-${nodeIdToUpdate}`).style.top = (Number(pumpNodeTopPos) + 25) + 'px';
          this.editor.drawflow.drawflow.Home.data[Number(nodeIdToUpdate)].pos_x = (Number(pumpNodeLeftPos) + 50);
          this.editor.drawflow.drawflow.Home.data[Number(nodeIdToUpdate)].pos_y = (Number(pumpNodeTopPos) + 25);
        }
      } else {
        document.getElementById(`node-${pumpNodeId}`).style.left = (Number(pumpNodeLeftPos) + 405) + 'px';
        document.getElementById(`node-${pumpNodeId}`).style.top = (Number(pumpNodeTopPos) + 35) + 'px';
        this.editor.drawflow.drawflow.Home.data[Number(pumpNodeId)].pos_x = (Number(pumpNodeLeftPos) + leftVal);
        this.editor.drawflow.drawflow.Home.data[Number(pumpNodeId)].pos_y = (Number(pumpNodeTopPos) + topVal);
      }
    } else {
      if (!pumpPlacedOnConnectorPipe) {
        if (!this.isSensorNode(this.editor.drawflow.drawflow.Home.data[Number(nodeIdToUpdate)].class)) {
          document.getElementById(`node-${pumpNodeId}`).style.left = (Number(pumpNodeLeftPos) - 220) + 'px';
          document.getElementById(`node-${pumpNodeId}`).style.top = (Number(pumpNodeTopPos) - 220) + 'px';
          this.editor.drawflow.drawflow.Home.data[Number(pumpNodeId)].pos_x = (Number(pumpNodeLeftPos) - 220);
          this.editor.drawflow.drawflow.Home.data[Number(pumpNodeId)].pos_y = (Number(pumpNodeTopPos) - 220);

          document.getElementById(`node-${nodeIdToUpdate}`).style.left = (Number(pumpNodeLeftPos) - 210) + 'px';
          document.getElementById(`node-${nodeIdToUpdate}`).style.top = (Number(pumpNodeTopPos) + 120) + 'px';
          this.editor.drawflow.drawflow.Home.data[Number(nodeIdToUpdate)].pos_x = (Number(pumpNodeLeftPos) - 210);
          this.editor.drawflow.drawflow.Home.data[Number(nodeIdToUpdate)].pos_y = (Number(pumpNodeTopPos) + 120);
        } else {
          document.getElementById(`node-${pumpNodeId}`).style.left = (Number(pumpNodeLeftPos) + 55) + 'px';
          document.getElementById(`node-${pumpNodeId}`).style.top = (Number(pumpNodeTopPos) - 305) + 'px';
          this.editor.drawflow.drawflow.Home.data[Number(pumpNodeId)].pos_x = (Number(pumpNodeLeftPos) + 55);
          this.editor.drawflow.drawflow.Home.data[Number(pumpNodeId)].pos_y = (Number(pumpNodeTopPos) - 305);
        }
      } else {
        document.getElementById(`node-${pumpNodeId}`).style.left = (Number(pumpNodeLeftPos) - 400) + 'px';
        document.getElementById(`node-${pumpNodeId}`).style.top = (Number(pumpNodeTopPos) - 150) + 'px';
        this.editor.drawflow.drawflow.Home.data[Number(pumpNodeId)].pos_x = (Number(pumpNodeLeftPos) - 220);
        this.editor.drawflow.drawflow.Home.data[Number(pumpNodeId)].pos_y = (Number(pumpNodeTopPos) - 220);
      }
    }
  }

  updatePositionOnBtuMeterDrop(btuMeterNodeId, nodeIdToUpdate, pipeType, btuMeterPlacedOnConnectorPipe: boolean = false, nodeId?) {
    let btuNodeLeftPos = this.editor.drawflow.drawflow.Home.data[Number(nodeIdToUpdate)].pos_x;
    let btuNodeTopPos = this.editor.drawflow.drawflow.Home.data[Number(nodeIdToUpdate)].pos_y;
    let nextNodeLeftPos: any = '';
    let nextNodeTopPos: any = '';
    if (pipeType == 'backward') {
      if (['connectTwo', 'pipeDemarkation'].includes(this.editor.drawflow.drawflow.Home.data[Number(nodeIdToUpdate)].class)) {
        btuNodeLeftPos = (Number(btuNodeLeftPos) + 20);
        btuNodeTopPos = (Number(btuNodeTopPos) - 120);

        nextNodeLeftPos = (Number(btuNodeLeftPos) + 180);
        nextNodeTopPos = (Number(btuNodeTopPos) + 235);

      } else if (this.isSensorNode(this.editor.drawflow.drawflow.Home.data[Number(nodeIdToUpdate)].class)) {
        if (this.isHeaderPipe(this.editor.drawflow.drawflow.Home.data[Number(nodeIdToUpdate)].childData.class)) {
          btuNodeLeftPos = (Number(btuNodeLeftPos) - 120);
          btuNodeTopPos = (Number(btuNodeTopPos) - 180);

          nextNodeLeftPos = (Number(btuNodeLeftPos) + 95);
          nextNodeTopPos = (Number(btuNodeTopPos) + 95);
        } else {
          btuNodeLeftPos = (Number(btuNodeLeftPos) - 300);
          btuNodeTopPos = (Number(btuNodeTopPos) - 220);

          nextNodeLeftPos = (Number(btuNodeLeftPos) + 300);
          nextNodeTopPos = (Number(btuNodeTopPos) + 220);
        }
      } else {
        btuNodeLeftPos = (Number(btuNodeLeftPos) - 100);
        btuNodeTopPos = (Number(btuNodeTopPos) - 100);

        nextNodeLeftPos = (Number(btuNodeLeftPos) + 300);
        nextNodeTopPos = (Number(btuNodeTopPos) + 200);
      }
    } else {
      if (['connectTwo', 'pipeDemarkation'].includes(this.editor.drawflow.drawflow.Home.data[Number(nodeIdToUpdate)].class)) {
        btuNodeLeftPos = (Number(btuNodeLeftPos) - 20);
        btuNodeTopPos = (Number(btuNodeTopPos) - 150);

        nextNodeLeftPos = (Number(btuNodeLeftPos) - 80);
        nextNodeTopPos = (Number(btuNodeTopPos) + 200);

      } else if (this.isSensorNode(this.editor.drawflow.drawflow.Home.data[Number(nodeIdToUpdate)].class)) {
        btuNodeLeftPos = (Number(btuNodeLeftPos) + 100);
        btuNodeTopPos = (Number(btuNodeTopPos) - 80);

        nextNodeLeftPos = (Number(btuNodeLeftPos) - 100);
        nextNodeTopPos = (Number(btuNodeTopPos) + 80);
      } else {
        nodeIdToUpdate = nodeId;
        btuNodeLeftPos = (Number(btuNodeLeftPos) - 280);
        btuNodeTopPos = (Number(btuNodeTopPos) + 600);

        nextNodeLeftPos = (Number(btuNodeLeftPos) - 65);
        nextNodeTopPos = (Number(btuNodeTopPos) + 100);
      }
    }

    document.getElementById(`node-${btuMeterNodeId}`).style.left = btuNodeLeftPos + 'px';
    document.getElementById(`node-${btuMeterNodeId}`).style.top = btuNodeTopPos + 'px';
    this.editor.drawflow.drawflow.Home.data[Number(btuMeterNodeId)].pos_x = btuNodeLeftPos;
    this.editor.drawflow.drawflow.Home.data[Number(btuMeterNodeId)].pos_y = btuNodeTopPos;

    document.getElementById(`node-${nodeIdToUpdate}`).style.left = nextNodeLeftPos + 'px';
    document.getElementById(`node-${nodeIdToUpdate}`).style.top = nextNodeTopPos + 'px';
    this.editor.drawflow.drawflow.Home.data[Number(nodeIdToUpdate)].pos_x = nextNodeLeftPos;
    this.editor.drawflow.drawflow.Home.data[Number(nodeIdToUpdate)].pos_y = nextNodeTopPos;
  }

  checkParentEquipsForwardOrBackwardType(equipDataClass) {
      const found = this.equipConfiguration.rightsideEquipList.find(_equip => _equip.indexOf(equipDataClass) > -1);
      if (found) {
        return 'forward';
      } else {
        return 'backward';
      }
  }

  checkMultipleCollides(id) {
    let isNodeCollide = this.checkIsCollide(id);
    if (isNodeCollide) {
      this.moveNode(id);
    }
  }

  alignEquipsOnBtuMeterAddtion(groupPipeId, pipeClass, arrayNodeList, idx) {
    arrayNodeList.forEach((_node, i) => {
      if (i != 0) {
        if (document.getElementById(`node-${_node}`)) {
          document.getElementById(`node-${_node}`).style.left = 'auto';
          document.getElementById(`node-${_node}`).style.top = 'auto';
          let calcLeft = `${(document.getElementById(`node-${Number(arrayNodeList[i - 1])}`).getElementsByClassName(`output output_${1}`)[0].getBoundingClientRect().x - document.getElementById(`node-${_node}`).getElementsByClassName(`output output_${1}`)[0].getBoundingClientRect().x) / this.editor.zoom}`;
          let calcTop = `${(document.getElementById(`node-${Number(arrayNodeList[i - 1])}`).getElementsByClassName(`output output_${1}`)[0].getBoundingClientRect().y - document.getElementById(`node-${_node}`).getElementsByClassName(`output output_${1}`)[0].getBoundingClientRect().y) / this.editor.zoom}`;
          if (i == 1) {
            calcLeft = `${(document.getElementById(`node-${Number(arrayNodeList[i - 1])}`).getElementsByClassName(`output output_${idx}`)[0].getBoundingClientRect().x - document.getElementById(`node-${_node}`).getElementsByClassName(`output output_${1}`)[0].getBoundingClientRect().x) / this.editor.zoom}`;
            calcTop = `${(document.getElementById(`node-${Number(arrayNodeList[i - 1])}`).getElementsByClassName(`output output_${idx}`)[0].getBoundingClientRect().y - document.getElementById(`node-${_node}`).getElementsByClassName(`output output_${1}`)[0].getBoundingClientRect().y) / this.editor.zoom}`;
          }
          document.getElementById(`node-${_node}`).style.left = (Number(calcLeft) + 430 + (i * 10)) + 'px';
          document.getElementById(`node-${_node}`).style.top = (Number(calcTop) + 230 + (i * 5)) + 'px';
          this.editor.drawflow.drawflow.Home.data[_node].pos_x = (Number(calcLeft) + 430 + (i * 10));
          this.editor.drawflow.drawflow.Home.data[_node].pos_y = (Number(calcTop) + 230 + (i * 5));
          this.editor.updateConnectionNodes(`node-${_node}`);
        }
      }
    });
  }

  alignSensorOnBtuMeterAddition(nodesArray, pipeType) {
    nodesArray.forEach((node, i) => {
      if (i != 0) {
        if (document.getElementById(`node-${node}`)) {
          if (pipeType == 'forward') {
            document.getElementById(`node-${node}`).style.left = 'auto';
            document.getElementById(`node-${node}`).style.top = 'auto';
            const calcLeft = `${(document.getElementById(`node-${Number(nodesArray[i - 1])}`).getElementsByClassName(`input input_${1}`)[0].getBoundingClientRect().x - document.getElementById(`node-${node}`).getElementsByClassName(`input input_${1}`)[0].getBoundingClientRect().x) / this.editor.zoom}`;
            const calcTop = `${(document.getElementById(`node-${Number(nodesArray[i - 1])}`).getElementsByClassName(`input input_${1}`)[0].getBoundingClientRect().y - document.getElementById(`node-${node}`).getElementsByClassName(`input input_${1}`)[0].getBoundingClientRect().y) / this.editor.zoom}`;
            document.getElementById(`node-${node}`).style.left = (Number(calcLeft) - 232) + 'px';
            document.getElementById(`node-${node}`).style.top = (Number(calcTop) + 101) + 'px';
          } else {
            document.getElementById(`node-${node}`).style.left = 'auto';
            document.getElementById(`node-${node}`).style.top = 'auto';
            const calcLeft = `${(document.getElementById(`node-${Number(nodesArray[i - 1])}`).getElementsByClassName(`input input_${1}`)[0].getBoundingClientRect().x - document.getElementById(`node-${node}`).getElementsByClassName(`input input_${1}`)[0].getBoundingClientRect().x) / this.editor.zoom}`;
            const calcTop = `${(document.getElementById(`node-${Number(nodesArray[i - 1])}`).getElementsByClassName(`input input_${1}`)[0].getBoundingClientRect().y - document.getElementById(`node-${node}`).getElementsByClassName(`input input_${1}`)[0].getBoundingClientRect().y) / this.editor.zoom}`;
            document.getElementById(`node-${node}`).style.left = (Number(calcLeft) + 220) + 'px';
            document.getElementById(`node-${node}`).style.top = (Number(calcTop) + 110) + 'px';
          }
          this.editor.updateConnectionNodes(`node-${node}`);
        }
      }
    });
  }

  insertAt(array, index, ...elementsArray) {
    array.splice(index, 0, ...elementsArray);
    return array;
  }

  containsExtraClass(classListValue) {
    if (classListValue.indexOf("CDWReturnClass") > -1) {
      return "CDWReturnClass";
    } else if (classListValue.indexOf("CDWSupplyClass") > -1) {
      return "CDWSupplyClass";
    } else if (classListValue.indexOf("CWReturnClass") > -1) {
      return "CWReturnClass";
    } else if (classListValue.indexOf("CWSupplyClass") > -1) {
      return "CWSupplyClass";
    } else if (classListValue.indexOf("boilerReturnClass") > -1) {
      return "boilerReturnClass";
    } else if (classListValue.indexOf("boilerSupplyClass") > -1) {
      return "boilerSupplyClass";
    } else if (classListValue.indexOf("DTSupplyClass") > -1) {
      return "DTSupplyClass";
    } else if (classListValue.indexOf("DTReturnClass") > -1) {
      return "DTReturnClass";
    }
  }

  getConnectionCustomization(connection: any) {
    let outputNode = this.editor.getNodeFromId(connection.output_id);
    let inputNode = this.editor.getNodeFromId(connection.input_id);
    let toAssignClass = "";
    let equipId = undefined;
    let nodeId = undefined;

    // Adding GroupPipe Id based on the connection
    if (this.isParentEquip(inputNode.class) && outputNode.class == "connectTwo") {
      toAssignClass = `parentSubEquip-pipe  groupPipe-${outputNode.groupPipeId} `
    }

    if (this.isParentEquip(outputNode.class) && inputNode.class == "connectTwo") {
      toAssignClass = `parentSubEquip-pipe  groupPipe-${inputNode.groupPipeId} `
    }

    if (this.isParentEquip(outputNode.class) && inputNode.class == "connectTwo") {
      toAssignClass = `parentSubEquip-pipe  groupPipe-${inputNode.groupPipeId} `
    }

    if (outputNode.class === "pipeDemarkation" && inputNode.class === "connectTwo" && inputNode?.groupPipeId != undefined && inputNode?.groupPipeId != '') {
      toAssignClass = `connector-pipe  groupPipe-${inputNode.groupPipeId} `
    } else if (outputNode.class === "connectTwo" && inputNode.class === "pipeDemarkation" && outputNode?.groupPipeId != undefined) {
      toAssignClass = `connector-pipe groupPipe-${outputNode.groupPipeId} `
    } else if ((inputNode.class === "connectTwo" || inputNode.class === "pipeDemarkation") && outputNode.class === "connectorLbend" && outputNode?.groupPipeId != undefined && outputNode?.groupPipeId != '') {
      toAssignClass = `connector-pipe lbend-connector-pipe groupPipe-${outputNode.groupPipeId} `
    } else if ((outputNode.class === "connectTwo" || outputNode.class === "pipeDemarkation") && inputNode.class === "connectorLbend" && inputNode?.groupPipeId != undefined && inputNode?.groupPipeId != '') {
      toAssignClass = `connector-pipe lbend-connector-pipe groupPipe-${inputNode.groupPipeId} `
    } else if ((inputNode.class === "connectorLbend" && outputNode.class === "connectorLbend") && inputNode?.groupPipeId == outputNode?.groupPipeId && outputNode?.groupPipeId != undefined && outputNode?.groupPipeId != '') {
      toAssignClass = `LbendConnector-pipe groupPipe-${outputNode.groupPipeId} `
    } else if (inputNode.equipType === "3wayValve" && (outputNode.class === "pipeDemarkation" || outputNode.class === "connectTwo") && !(outputNode.childData.equipId == inputNode.childData.equipId) && outputNode?.groupPipeId != undefined && outputNode?.groupPipeId != '') {
      toAssignClass = `connector-pipe groupPipe-${outputNode.groupPipeId} `
    } else if ((inputNode.class === "pipeDemarkation" || inputNode.class === "connectTwo") && outputNode.equipType === "3wayValve" && !(outputNode.childData.equipId == inputNode.childData.equipId) && inputNode?.groupPipeId != undefined && inputNode?.groupPipeId != '') {
      toAssignClass = `connector-pipe groupPipe-${inputNode.groupPipeId} `
    } else if (inputNode.class === "subEquip-connectTwo" && outputNode.class === "subEquip-connectTwo" && inputNode.groupPipeId != outputNode.groupPipeId) {
      toAssignClass = 'connector-pipe  subEquip-connector-pipe  '
    } else if ((inputNode.class === "subEquip-connectTwo" && outputNode.class === "subEquip-connectTwo") && inputNode.groupPipeId == outputNode.groupPipeId) {
      toAssignClass = `groupPipe-${inputNode.groupPipeId} `
    } else if (inputNode.class === "subEquip-connectTwo" && outputNode.class != "subEquip-connectTwo") {
      toAssignClass = `groupPipe-${inputNode.groupPipeId} `
    } else if (outputNode.class === "subEquip-connectTwo" && inputNode.class != "subEquip-connectTwo") {
      toAssignClass = `groupPipe-${outputNode.groupPipeId} `
    } else if((outputNode.class == "connectTwo" && inputNode.class == "connectTwo") || (inputNode.class == "connectTwo" && outputNode.class == "connectTwo")){
      toAssignClass = `leadnLagPipe-${outputNode.groupPipeId}-${inputNode.groupPipeId} `
    }

    let connectionClassName = "node_out_node-" + connection.output_id + " " + "node_in_node-" + connection.input_id + " " + connection.output_class + " " + connection.input_class;

    // check for equipment and assign class based on equipment
    const classArray = ["CDWReturnClass", "CDWSupplyClass", "CWReturnClass", "CWSupplyClass", "boilerReturnClass", "boilerSupplyClass", "DTReturnClass", "DTSupplyClass"];
    if (classArray.includes(connection.parentComponentClass)) {
      toAssignClass = toAssignClass + connection.parentComponentClass;
    } else {
      if ((inputNode.class === "connectTwo" || inputNode.class === "pipeDemarkation") && outputNode.class === "connectorLbend") {
        let nodePipeClass = outputNode.connectedParentSubEquipPipeClass != undefined ? outputNode.connectedParentSubEquipPipeClass : outputNode.nodePipeClass;
        toAssignClass = toAssignClass + nodePipeClass;
      } else if ((outputNode.class === "connectTwo" || outputNode.class === "pipeDemarkation") && inputNode.class === "connectorLbend") {
        let nodePipeClass = inputNode.connectedParentSubEquipPipeClass != undefined ? inputNode.connectedParentSubEquipPipeClass : inputNode.nodePipeClass;
        toAssignClass = toAssignClass + nodePipeClass
      } else if (inputNode.class === "connectTwo" && outputNode.class === "connectTwo") {
        if ((inputNode.nodePipeClass === "CWReturnClass" && outputNode.nodePipeClass === "CWSupplyClass") || inputNode.nodePipeClass === "CWSupplyClass" && outputNode.nodePipeClass === "CWReturnClass") {
          toAssignClass = toAssignClass + "CWSupplyClass";
        }
        if ((inputNode.nodePipeClass === "boilerReturnClass" && outputNode.nodePipeClass === "boilerSupplyClass") || inputNode.nodePipeClass === "boilerSupplyClass" && outputNode.nodePipeClass === "boilerReturnClass") {
          toAssignClass = toAssignClass + "boilerSupplyClass";
        }
      } else if (this.isDualTempPipe(inputNode.childData.class) || this.isDualTempPipe(outputNode.childData.class)) {
        toAssignClass = this.isDualTempPipe(inputNode.childData.class) ? toAssignClass + outputNode.nodePipeClass : toAssignClass + inputNode.nodePipeClass;
      } else if (inputNode.class === "subEquip-connectTwo" && outputNode.class === "subEquip-connectTwo") {
        toAssignClass = toAssignClass + inputNode.nodePipeClass;
      } else {
          toAssignClass = toAssignClass + this.classForComponentAndPort(connection.parentComponentClass, connection.output_class);
      }
    }
    if (!toAssignClass) {
      toAssignClass = toAssignClass + this.classForComponentAndPort(inputNode.class, connection.input_class);
    }
    this.checkClassAndAssignClass(connectionClassName, toAssignClass);
  }

  checkClassAndAssignClass(className: string, assignClass: string) {
    if (!assignClass) return;
    const arr = assignClass.split(' ');
    for (let i = 0; i < arr.length; i++) {
      if (document.getElementsByClassName(className)[0].classList.value.indexOf(arr[i]) < 0)
        document.getElementsByClassName(className)[0].classList.add(arr[i]);
    }
  }

  checkForInputConnection(connectorNode) {
    let inputNodeConnectedId = connectorNode.inputs?.input_1?.connections[0]?.node;
    let inputNodeConnectedPort = connectorNode.inputs?.input_1?.connections[0]?.input;
    let checkClassName = "node_out_node-" + inputNodeConnectedId + " " + "node_in_node-" + connectorNode.id + " " + inputNodeConnectedPort + " input_1";
    if (inputNodeConnectedId && inputNodeConnectedPort) {
      return this.containsExtraClass(document.getElementsByClassName(checkClassName)[0].classList.value);
    }
    return "";
  }

  classForComponentAndPort(component, vertex) {
    // return the class needs to be added for particular equipment.
    if (component === "waterCooledChiller") {
      if (vertex === "output_1") {
        return 'CDWSupplyClass';
      }
      if (vertex === "output_2") {
        return 'CDWReturnClass';
      }
      if (vertex === "output_3") {
        return 'CWReturnClass';
      }
      if (vertex === "output_4") {
        return 'CWSupplyClass';
      }
    }
    if (component === "airCooledChiller") {
      if (vertex === "output_1") {
        return 'CWReturnClass';
      }
      if (vertex === "output_2") {
        return 'CWSupplyClass';
      }
    }
    if (component === "steamBoiler" || component === "condenserBoiler") {
      if (vertex === "output_1") {
        return 'boilerSupplyClass';
      }
      if (vertex === "output_2") {
        return 'boilerReturnClass';
      }
    }
    if (component === "supplyFlowArrow" || component === "supplyFlowArrow") {
      return "animate-forward";
    }
    if (component === "returnFlowArrow" || component === "returnFlowArrow") {
      return "animate-reverse";
    }
    if (component === 'coolingTowerTwoFan' || component == 'coolingTowerSingleFan' || component == 'coolingTowerFourFan') {
      if (vertex === "output_1") {
        return 'CDWSupplyClass';
      }
      if (vertex === "output_2" || vertex === "output_3" || vertex === "output_4" || vertex === "output_5") {
        return 'CDWReturnClass';
      }
    }
    if (component === 'building2Pipe') {
      if (vertex === "output_1") {
        return 'CWReturnClass';
      }
      if (vertex === "output_2") {
        return 'CWSupplyClass';
      }
    }
    if (component === 'building2PipeHot') {
      if (vertex === "output_1") {
        return 'boilerReturnClass';
      }
      if (vertex === "output_2") {
        return 'boilerSupplyClass';
      }
    }

    if (component === 'dualTempBuilding2Pipe') {
      if (vertex === "output_1") {
        return 'DTReturnClass';
      }
      if (vertex === "output_2") {
        return 'DTSupplyClass';
      }
    }

    if (component === 'building4Pipe') {
      if (vertex === "output_1") {
        return 'CWReturnClass';
      }
      if (vertex === "output_2") {
        return 'CWSupplyClass';
      }
      if (vertex === "output_3") {
        return 'boilerReturnClass';
      }
      if (vertex === "output_4") {
        return 'boilerSupplyClass';
      }
    }

    if (component === 'heatExchanger') {
      if (vertex === "output_1") {
        return 'CWReturnClass';
      }
      if (vertex === "output_2") {
        return 'CWSupplyClass';
      }
    }
    return this.getPipeClass(component);
  }
  // Drag Events
  onDragStart(e: any) {
    this.deletedNodeData = {};
    if (e.type === 'dragstart') {
      // console.log('onDragStart :>> e :>> ', e);
      this.selectedItem = <NodeElement>(
        this.nodes.find((node: NodeElement) => node.name === e.target.children[0].innerHTML)
        // this.nodes.find((node: NodeElement) => node.name === e.target.outerText)0
      );
    }
  }

  onDragEnter(e: any) {
    // console.log('onDragEnter :>> e :>> ', e);
  }

  onDragLeave(e: any) {
    // console.log('onDragLeave :>> e :>> ', e);
  }

  onDragOver(e: Event) {
    e.preventDefault();
    e.stopPropagation();
    this.lastMousePositionEv = e;
    // console.log('onDragOver :>> e :>> ', e);
  }

  onDragEnd(e: any) {
    // console.log('onDragend :>> e :>> ', e);
  }

  isHeaderPipe(equipName) {
    return this.equipConfiguration.headerPipeList.indexOf(equipName) != -1;
  }

  isDualTempPipe(equipName) {
    return this.equipConfiguration.dualTempPipeList.indexOf(equipName) != -1;
  }

  isBypassPipe(equipName) {
    return this.equipConfiguration.bypassPipeList.indexOf(equipName) != -1;
  }

  isMainEquip(equipName) {
    return this.equipConfiguration.mainEquipList.indexOf(equipName) != -1;
  }

  isConnector(equipName){
    return this.equipConfiguration.connectorList.indexOf(equipName) != -1;
  }

  getGroupEquipData(equipId){
    let groupNodeId = document.getElementsByClassName(`parentEquipId-${equipId} GROUP`)[0].id.slice(5);
    return this.editor.drawflow.drawflow.Home.data[Number(groupNodeId)];
  }

  // getting the subEquip node Object by passing the id
  getMainEquipBySubEquipID(equipId) {
    let drawflowArr = Object.values(this.editor.drawflow.drawflow.Home.data);
    let filteredEquipData = {};
    drawflowArr.forEach((element: any) => {
      if (element.class == "connectTwo" && element.groupPipeId == equipId) {
        filteredEquipData = element;
      }
    });
    return filteredEquipData;
  }

  /**
   * Checks if the given equipment name belongs to the building.
   * @param equipName - The name of the equipment to check.
   * @returns True if the equipment belongs to the building list, false otherwise.
   */
  isBuildingEquip(equipName) {
    return this.equipConfiguration.buildingList.indexOf(equipName) != -1;
  }

  /**
   * Checks if the given equipment name belongs to the dual temperature equipment group.
   * @param equipName - The name of the equipment to check.
   * @returns A boolean value indicating whether the equipment is dual temperature or not.
   */
  isDualTempEquip(equipName) {
    return this.equipConfiguration.dualTempEquip.indexOf(equipName) != -1;
  }

  /**
   * Checks if the given equipment name is a non-building parent equipment.
   * @param equipName - The name of the equipment to check.
   * @returns A boolean value indicating whether the equipment is a non-building parent equipment.
   */
  isNonBuidlingParentEquip(equipName) {
    return this.equipConfiguration.nonBuildingParentEquip.indexOf(equipName) != -1;
  }

  /**
   * Checks if the given equipment name is a heat exchanger.
   * @param equipName - The name of the equipment to check.
   * @returns `true` if the equipment is a heat exchanger, `false` otherwise.
   */
  isHeatExchangerEquip(equipName) {
    return this.equipConfiguration.heatExchangerList.indexOf(equipName) != -1;
  }

  setPointType(tags) {
    let pointType = '';
    if (tags.includes('his')) {
      pointType = 'HIS';
    } else if (pointType != 'HIS' && tags.includes('writable')) {
      pointType = 'WRITABLE';
    }
    return pointType == '' ? 'READABLE' : pointType;
  }

  updateDefaultSensorPipeMasterPoint() {
    let requestData = {
      "equipId": this.tempParentEquipData.equipId,
      "plantId": this.selectedPlantData.chillerPlantId,
      "equipMetaData": {},
      "tags": this.tempParentEquipData.tags,
      "alertConfigDtos": [],
      "pointList": [],
      "statusType": "DRAFT"
    }

    let sensorPointList = [];
    this.tempParentEquipData.equipData.subEquips[0].equips.forEach(sensorObj => {
      let sensorData = {
        pointMetaData: {
          "custom": false
        },
        pointName: sensorObj.shortName,
        shortName: sensorObj.shortName,
        click: false,
        hover: false,
        master: true,
        custom: false,
        equipRef: this.selectedItem.equipId,
        tags: sensorObj.data,
        pointId: sensorObj.pointId,
        pointType: this.setPointType(sensorObj.data)
      }
      sensorPointList.push(sensorData);
    });

    requestData.pointList = sensorPointList;
    this.apiService.saveEquipConfigs(requestData).subscribe();
    // this.apiService.saveEquipConfigs(requestData).subscribe((res) => {
    //   this.tempParentEquipData['configId'] = res.configId;
    //   this.createHeaderPipe(e, 'forward');
    // });
  }

  updateDefaultSensorMasterPoint(pumpData?) {
    let equipName = JSON.parse(JSON.stringify(this.selectedItem.class));
    if (equipName.includes('pump')) {
      equipName = 'pump1';
    }

    let requestData = {
      "equipId": equipName == 'pump1' ? pumpData.subEquips[0].equipId : this.selectedItem.equipId,
      "plantId": this.selectedPlantData.chillerPlantId,
      "equipMetaData": {},
      "tags": this.selectedItem.tags,
      "alertConfigDtos": [],
      "pointList": [],
      "statusType": "DRAFT"
    }

    let parentEquipId = this.selectedItem.equipId;
    let equipIds = [parentEquipId];

    this.selectedItem?.subEquips?.forEach(subEquip => {
      if (!equipIds.includes(subEquip.equipId)) {
        equipIds.push(subEquip.equipId);
      }
    });

    let allPointList = [];
    this.apiService.getPointsForEquipsSubequips(equipIds).subscribe((response) => {
      response.forEach(point => {
        if (point?.pointList) {
          allPointList = allPointList.concat(point.pointList);
        }
      });

      allPointList.forEach(point => {
        if (defaultClickViewPoint[equipName]?.length) {
          defaultClickViewPoint[equipName].forEach(pointTags => {
            if (pointTags.every(value => point.tags.includes(value))) {
              let sensorData = {
                pointMetaData: {
                  "custom": false
                },
                pointName: point.shortName,
                shortName: point.shortName,
                click: true,
                hover: false,
                master: equipName != 'pump1' ? point.equipRef == parentEquipId ? false : true : false,
                custom: false,
                equipRef: point.equipRef,
                tags: point.tags,
                pointId: point.pointId,
                pointType: this.setPointType(point.tags)
              }
              requestData.pointList.push(sensorData);
            }
          });
        }
        if (point.equipRef != parentEquipId) {
          let findIndex = requestData.pointList.findIndex(pointData => {
            return pointData.pointId == point.pointId;
          })

          if (findIndex == -1) {
            let sensorData = {
              pointMetaData: {
                "custom": false
              },
              pointName: point.shortName,
              shortName: point.shortName,
              click: false,
              hover: false,
              master: (point.tags.includes("scheduleType") || point.tags.includes("heartbeat")) ? false : true,
              custom: false,
              equipRef: point.equipRef,
              tags: point.tags,
              pointId: point.pointId,
              pointType: this.setPointType(point.tags)
            }
            requestData.pointList.push(sensorData);
          }
        }
      });
      this.apiService.saveEquipConfigs(requestData).subscribe();
    });
  }

  getPipeEquipConfig(equipId, e) {
    let status = this.editor.editor_mode == 'edit' ? 'DRAFT' : 'PUBLISHED';
    this.apiService.getEquipConfigs({ "plantId": this.selectedPlantData.chillerPlantId, "equipId": equipId, status: status }).subscribe((response) => {
      this.tempParentEquipData['configId'] = response.configId;
      if (Object.keys(response).length == 0) {
        this.updateDefaultSensorPipeMasterPoint();
      } else {
        this.tempParentEquipData.equipData.subEquips[0].equips.forEach((sensorObj: any) => {
          let subEquipObj;
          subEquipObj = response?.pointList?.filter(equipObj => {
            return equipObj?.pointId === sensorObj?.pointId;
          })

          if (subEquipObj?.length) {
            sensorObj['master'] = subEquipObj[0].master;
            sensorObj['click'] = subEquipObj[0].click;
            sensorObj['hover'] = subEquipObj[0].hover;
          } else {
            sensorObj['master'] = false;
            sensorObj['click'] = false;
            sensorObj['hover'] = false;
          }
        });
      }
      this.createHeaderPipe(e, 'forward');
    });
  }

  getEquipConfig(equipId, e) {
    let status = this.editor.editor_mode == 'edit' ? 'DRAFT' : 'PUBLISHED';
    this.apiService.getEquipConfigs({ "plantId": this.selectedPlantData.chillerPlantId, "equipId": equipId, status: status }).subscribe((response) => {
      if (Object.keys(response).length == 0) {
        this.updateDefaultSensorMasterPoint();
      } else {
        this.tempParentEquipData.equipData.subEquips.forEach((subEquipData, index) => {
          subEquipData.equips.forEach((sensorObj: any) => {
            let subEquipObj;
            subEquipObj = response?.pointList?.filter(equipObj => {
              return equipObj.pointId === sensorObj.pointId;
            })

            if (subEquipObj?.length) {
              sensorObj['master'] = subEquipObj[0].master;
              sensorObj['click'] = subEquipObj[0].click;
              sensorObj['hover'] = subEquipObj[0].hover;
            } else {
              sensorObj['master'] = false;
              sensorObj['click'] = false;
              sensorObj['hover'] = false;
            }
            // subEquipObj[0].points.forEach(subEquip => {
            //   if (sensorObj.pointId == subEquip.pointId) {
            //     sensorObj.master = subEquip.master;
            //     sensorObj.click = subEquip.click;
            //     sensorObj.hover = subEquip.hover;
            //   }
            // });
          });
        });
      }
      this.addNodeToDrawBoard(e.clientX, e.clientY);
    });
  }

  onDrop(e: any) {
    this.deletedNodeData = {};
    // After dropping the element, create a node
    if (e.type === 'drop' && this.selectedItem && !this.isConnector(this.selectedItem.class)) {

      if (document.getElementsByClassName('ng-tooltip-show')?.length) {
        Array.from(document.getElementsByClassName('ng-tooltip-show')).forEach(_r => _r.remove());
      }
      this.tempParentEquipData = this.selectedItem;
      if (this.selectedItem?.class === 'btuMeter' || this.selectedItem?.class.includes('pump1Vertical')) {
        e.preventDefault();
        this.addSensorNodeBetweenNodes(e);
        if (this.selectedItem.class.includes('pump1Vertical')) {
          this.updateDefaultSensorMasterPoint(this.selectedItem);
        }
      } else if (this.isHeaderPipe(this.selectedItem.class) || this.isBypassPipe(this.selectedItem.class)) {
        // this.updateDefaultSensorMasterPoint();
        this.getPipeEquipConfig(this.tempParentEquipData.equipId, e)
        this.nodes = this.nodes.filter((node: NodeElement) => node.name != this.tempParentEquipData.name);
      } else {
        e.preventDefault();
        this.getEquipConfig(this.tempParentEquipData.equipId, e);
        this.nodes = this.nodes.filter((node: NodeElement) => node.name != this.tempParentEquipData.name);
      }
    }
  }

  validateWaterFlow(e: any) {
    let listOfClasses = e.path[1].classList
    let equipId1, equipId2
    listOfClasses.forEach((element: any) => {
      if (element.startsWith("node_in_node-")) {
        equipId1 = element.split("node_in_node-")[1];
      }
      if (element.startsWith("node_out_node-")) {
        equipId2 = element.split("node_out_node-")[1];;
      }
    });

    let equipConfig = JSON.parse(localStorage.getItem(StorageKeys.EQUIPCONFIG))
    let keys = Object.keys(this.selectedPlantData.plantJsonStr.drawflow.Home.data)
    let equipsFromSelectedPlantData = [];
    keys.forEach(key => {
      equipsFromSelectedPlantData.push(this.selectedPlantData.plantJsonStr.drawflow.Home.data[key].data);
    })
    let temporaryEquipData = equipConfig === null || equipConfig.length == 0 ? equipsFromSelectedPlantData : equipConfig
    let equipData = temporaryEquipData.filter(value => Object.keys(value).length !== 0);  // to remove empty objects from the array
    return this.checkForRunningStatus(equipId1, equipId2, equipData) ? true : false
  }

  checkForRunningStatus(equipId1, equipId2, equipData) {
    let equipId1Boolean = false;
    let equipId2Boolean = false;
    let isSensorNode = false;
    let sensorsEquipment = ["valveActuator", "temperatureSensor", "wellTemperature", "pressureSensor", "diffPressureSensor", "flowSensor", "flowSwitch", "btuMeter", "building2PipeHot", "building2Pipe", "building4Pipe", "dualTempBuilding2Pipe"]

    equipData.forEach(equip => {
      if (sensorsEquipment.indexOf(equip.equipName) > -1) {
        isSensorNode = true;
      } else {
        isSensorNode = false;
      }
      if (equip.equipID == equipId1) {
        let points = isSensorNode ? equip.customPoint : equip.point;
        points?.forEach(pointObj => {
          if ((pointObj.isMaster || pointObj.isHover || pointObj.isClick)) {
            equipId1Boolean = true;
          }
        });
      } else if (equip.equipID == equipId2) {
        let points = isSensorNode ? equip.customPoint : equip.point;
        points?.forEach(pointObj => {
          if ((pointObj.isMaster || pointObj.isHover || pointObj.isClick)) {
            equipId2Boolean = true;
          }
        });
      }

    });
    return equipId1Boolean && equipId2Boolean ? true : false;
  }

  resetAllInputsOutputs() {
    this.nodes.forEach((node) => {
      node.inputs = 0;
      node.outputs = 0;
    });
  }

  // Drawflow Editor Operations
  addNodeToDrawBoard(pos_x: number, pos_y: number, equipCreated = true, isFirstHeaderPipe = false, isGroupEquip = false, groupPipeId = "", pointId = "") {
    if (this.editor.editor_mode === 'edit') {
      pos_x =
        pos_x *
        (this.editor.precanvas.clientWidth /
          (this.editor.precanvas.clientWidth * this.editor.zoom)) -
        this.editor.precanvas.getBoundingClientRect().x *
        (this.editor.precanvas.clientWidth /
          (this.editor.precanvas.clientWidth * this.editor.zoom));

      pos_y =
        pos_y *
        (this.editor.precanvas.clientHeight /
          (this.editor.precanvas.clientHeight * this.editor.zoom)) -
        this.editor.precanvas.getBoundingClientRect().y *
        (this.editor.precanvas.clientHeight /
          (this.editor.precanvas.clientHeight * this.editor.zoom));

      let drop_x = pos_x;
      let drop_y = pos_y;
      if (!isGroupEquip) {
        let htmlTemplate;
        let data = {}
        if (!this.selectedItem.class.includes('pump1Vertical') && (this.isSensorNode(this.selectedItem.class) || this.isBypassPipe(this.selectedItem.class) || this.selectedItem.class == "byPassLine-connectTwo" || this.selectedItem.class == "connectTwo" || this.selectedItem.class == "pipeDemarkation" || this.selectedItem.class == "subEquip-connectTwo")) {
          if (isFirstHeaderPipe || this.isBypassPipe(this.selectedItem.class)) {
            if (!this.selectedItem?.pipeType) {
              this.selectedItem.pipeType = 'forward';
            }
            data = this.tempParentEquipData;
           
            if (isFirstHeaderPipe) {
              htmlTemplate = `
              <div class="drag-drawflow"></div>
              <div class="equipName">${this.selectedItem.displayName}</div>
              <img id=pipeRotateImg_${this.tempParentEquipData.equipId} class="pipeArrowImg_${this.selectedItem.pipeType}" src="/assets/images/pipeArrow.svg">`;
            } else {
              htmlTemplate = `
              <div class="drag-drawflow"></div>
              <div class="equipName">${this.selectedItem.displayName}</div>`
            } 
          } else {
            htmlTemplate = `
          <div class="drag-drawflow"></div>
          <div class="equipName">${this.selectedItem.displayName}</div>
          `;
          }
        } else {
          htmlTemplate = `
          <div class="drag-drawflow"></div>`
          htmlTemplate += `<div class="equipName">${this.selectedItem.displayName}</div>`
          htmlTemplate += `<div><img style="${this.isBuildingEquip(this.selectedItem.class) ? 'height: 1400px':''}"  class="equipImg ${this.selectedItem.margin}" src=${this.setImgPath(this.selectedItem.img)}>`;
          if (this.selectedItem.class.includes('heatExchanger_backward')) {
            htmlTemplate += `<img class="heatExchangerPipeArrowImg_backward" src="/assets/images/pipeArrow.svg">`;
          } else if (this.selectedItem.class.includes('heatExchanger_forward')) {
            htmlTemplate += `<img class="heatExchangerPipeArrowImg_forward" src="/assets/images/pipeArrow.svg">`;
          }
          htmlTemplate +=`</div>`;
        }

        let parentNodeId = this.createdParentEquipId;
        if (this.isParentEquip(this.selectedItem.class)) {
          parentNodeId = null;
        }

        const nodeName = this.selectedItem.name;
        const nodeId = this.editor.addNode(
          this.selectedItem.name,
          this.selectedItem.inputs,
          this.selectedItem.outputs,
          pos_x,
          pos_y,
          this.selectedItem.class,
          data,
          htmlTemplate,
          false,
          this.selectedItem,
          equipCreated,
          this.selectedItemID,
          this.tempParentEquipData,
          this.selectedItem.pipeClass,
          "",
          "",
          parentNodeId,
          groupPipeId,
          pointId,
          drop_x,
          drop_y,
          ""
        );

        this.nodesDrawn.push({
          nodeId,
          nodeName,
        });
      } else {
        let childNode = document.querySelectorAll(`.parentEquipId-${this.tempParentEquipData.equipId}`);
        let parentNodeId = this.createdParentEquipId != '' ? this.createdParentEquipId : this.editor.nodeId + 1;
        let childNodeId = [];
        childNode.forEach((element: any) => {
          if (!element.classList.contains('GROUP')) {
            childNodeId.push(element.id.slice(5));
          }
        });

        let nodeXPositionArr = [];
        let nodeYPositionArr = [];

        childNodeId.forEach((element: any) => {
          nodeXPositionArr.push(this.editor.getNodeFromId(element).pos_x);
          nodeYPositionArr.push(this.editor.getNodeFromId(element).pos_y);
        });

        let groupNodePos_x = Math.min(...nodeXPositionArr);
        let groupNodePos_y = Math.min(...nodeYPositionArr);
        let groupNodeWidth = (Math.max(...nodeXPositionArr) - Math.min(...nodeXPositionArr)) + 60;
        let groupNodeHeight = (Math.max(...nodeYPositionArr) - Math.min(...nodeYPositionArr)) + 30;
        let pipeType = '';

        if (this.tempParentEquipData.class == "coolingTowerSingleFan") {
          groupNodeWidth = groupNodeWidth + 900;
        } else if (this.tempParentEquipData.class == "coolingTowerTwoFan") {
          groupNodeWidth = groupNodeWidth + 1350;
        } else if (this.tempParentEquipData.class == "coolingTowerFourFan") {
          groupNodeWidth = groupNodeWidth + 1400;
        } else if (this.tempParentEquipData.class == "building2Pipe" || this.tempParentEquipData.class == "building2PipeHot" || this.tempParentEquipData.class == "dualTempBuilding2Pipe" || this.tempParentEquipData.class == "building4Pipe") {
          groupNodeWidth = groupNodeWidth + 800;
        } else if (this.tempParentEquipData.class.includes("connectorLbend")) {
          pipeType = this.selectedItem.pipeType;
          let firstElementData = this.editor.getNodeFromId(childNodeId[0]);
          if (pipeType == 'forward') {
            groupNodePos_x = firstElementData.pos_x - 145;
            groupNodeWidth = 200;
            groupNodeHeight = 200;
          } else if (pipeType == 'backward') {
            groupNodePos_x = firstElementData.pos_x;
            groupNodePos_y = firstElementData.pos_y - 45;
            groupNodeWidth = 290;
            groupNodeHeight = 140;
          }
        } else if (this.tempParentEquipData.class.includes("pump1Vertical")) {
          groupNodeWidth = 500;
          groupNodeHeight = 440;
        }

        // this.editor.drawflow.drawflow.Home.data[parentNodeId].groupNodeId = this.editor.nodeId + 1;
        // this.editor.drawflow.drawflow.Home.data[parentNodeId].childNodeID = { elements: childNodeId };

      this.editor.addNode(
          'GROUP',
          0,
          0,
          groupNodePos_x,
          groupNodePos_y,
          "GROUP",
          { elements: childNodeId, groupNodeIds: ObjectUtil.deepCopy(childNodeId) },
          "",
          false,
          this.selectedItem,
          true,
          this.selectedItemID,
          this.tempParentEquipData,
          "",
          groupNodeWidth,
          groupNodeHeight,
          parentNodeId,
          "",
          pointId,
          drop_x,
          drop_y,
          pipeType
        );
      }
    }

  }

  onClear() {
    this.editor.clear();
  }

  /**
   * Handles the click event when a Click view equipment is clicked.
   * Opens a dialog to display the Click view equipment details.
   * @param nodeData - The data associated with the clicked equipment node.
   */
  onMasterViewEquipClicked(nodeData) {
    let checkIsLoader = document.getElementById(`pointLoader-${nodeData.equipId}`);
    if (checkIsLoader == null) {
      const dialogRef = this.dialog.open(CPMMasterViewEquipModalComponent, {
        width: '100%',
        height: '100%',
        backdropClass: 'cpm-masterview-equip-modal-overlay',
        panelClass: 'cpm-masterview-equip-modal-container',
        data: { "plantId": this.selectedPlantData.chillerPlantId, "equipId": nodeData.id, 'plantData': this.selectedPlantData, 'selectedEquipData': nodeData, userInfo: this.userPersonalInfo, siteName: this.siteName, plantJsonStr: this.editor.drawflow }
      });
    }
  }

  clickedOut(event): void {
    this.masterViewEquipModalOpened = false;
    this.masterViewEquipModalStyle = {
      display: 'none'
    };
  }


  checkEquipConnection(plantDataArr, data) {
    for (var i = 0; i < plantDataArr.length; i++) {
      if (plantDataArr[i].equipId == data.equipId) {
        if (plantDataArr[i].childData.class == 'btuMeter' || plantDataArr[i].childData.class == 'pump1Vertical_forward' || plantDataArr[i].childData.class == 'pump1Vertical_backward') {
          this.isEquipConnected = true;
          break;
        }

        if (this.isHeaderPipe(data.childData.class) || this.isBypassPipe(data.childData.class)) {
          if (plantDataArr[i].class == "pipeDemarkation" || plantDataArr[i].class == "byPassLine-connectTwo") {
            let inputLength = plantDataArr[i].inputs.input_1.connections.length;
            let outputLength = plantDataArr[i].outputs.output_1.connections.length;
            if (inputLength == 0 && outputLength == 1) {
              this.isEquipConnected = false;
            } else if (inputLength == 1 && outputLength == 0) {
              this.isEquipConnected = false;
            } else if (inputLength == 0 && outputLength > 1) {
              this.isEquipConnected = true;
              break;
            } else if (inputLength > 1 && outputLength == 0) {
              this.isEquipConnected = true;
              break;
            } else if (inputLength >= 1 && outputLength >= 1) {
              this.isEquipConnected = true;
              break;
            }
          }
        } else {
          if (plantDataArr[i].class == "connectTwo") {
            let inputLength = plantDataArr[i].inputs.input_1.connections.length;
            let outputLength = plantDataArr[i].outputs.output_1.connections.length;
            if (inputLength == 0 && outputLength == 1) {
              this.isEquipConnected = false;
            } else if (inputLength == 1 && outputLength == 0) {
              this.isEquipConnected = false;
            } else if (inputLength == 0 && outputLength > 1) {
              this.isEquipConnected = true;
              break;
            } else if (inputLength > 1 && outputLength == 0) {
              this.isEquipConnected = true;
              break;
            } else if (inputLength >= 1 && outputLength >= 1) {
              this.isEquipConnected = true;
              break;
            }
          }
        }
      }
    }
  }

  equipClicked(data) {
    this.isEquipConnected = true;
    const plantDataArr = [];
    Object.keys(this.editor.drawflow.drawflow.Home.data).forEach(key => plantDataArr.push(this.editor.drawflow.drawflow.Home.data[key]));
    this.checkEquipConnection(plantDataArr, data);
    data.building = this.selectedBuilding;
    this.isEquipDialogOpen = true;
    data.siteId = this.selectedBuilding[0].id;
    data.plantId = this.selectedPlantData.chillerPlantId;
    data.isEquipConnected = this.isEquipConnected;
    data.equipConfiguration = this.equipConfiguration;
    let modelWidth = data.isSensorNode || this.isBuildingEquip(data.class) || this.isHeatExchangerEquip(data.class)  ? "510px" : "750px";
    const dialogRef = this.dialog.open(EquipConfigModelComponent, {
      width: modelWidth,
      panelClass: 'equipConfig-model-container',
      data: data
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result != undefined) {
        if (result.action == 'save') {
          let equipGroupElement;
          let groupJson;
          if (this.isPump(data.class)) {
            equipGroupElement = document.querySelector(`.parentEquipId-${data.pointId}`);
            groupJson = this.editor.getNodeFromId(`${equipGroupElement.id.slice(5)}`);
          } else {
            equipGroupElement = document.querySelector(`.group-${data.equipId}`);
            groupJson = this.editor.getNodeFromId(`${equipGroupElement.id.slice(5)}`);
          }
         
          if (result.isReordered) {
            if(result.reorderedSequence.class.includes('heatExchanger')) {
              let x = document.getElementById(`${equipGroupElement.id}`).getBoundingClientRect().x;
              let y = document.getElementById(`${equipGroupElement.id}`).getBoundingClientRect().y;
               this.editor.removeNodeId(equipGroupElement.id);
               this.selectedItem = result.reorderedSequence;
               if (result.reorderedSequence.class.includes('heatExchanger')) {
                 this.duplicateNodesArr = this.duplicateNodesArr.map(_equip => {
                   if (_equip.equipId == result.reorderedSequence.equipId) {
                     _equip.subEquips = ObjectUtil.deepCopy(result.reorderedSequence.subEquips);
                     _equip.equipData.subEquips = ObjectUtil.deepCopy(result.reorderedSequence.equipData.subEquips);
                   }
                   return _equip;
                 });
               }
               this.tempParentEquipData = result.reorderedSequence;
               this.addNodeToDrawBoard(x, y);
             } else if (this.isHeaderPipe(data.childData.class) || this.isBypassPipe(data.childData.class)) {
              let posNode = Math.min(...groupJson.data.elements);
              if (posNode) {
                let findPos = this.editor.getNodeFromId(String(posNode));
                if (findPos) {
                  let nodePosition = {
                    x: document.getElementById(`node-${findPos.id}`).getBoundingClientRect().x,
                    y: document.getElementById(`node-${findPos.id}`).getBoundingClientRect().y
                  }
                  this.editor.removeNodeId(`node-${groupJson.id}`);
                  this.tempParentEquipData = result.reorderedSequence;
                  this.createHeaderPipe(nodePosition, 'forward');
                }
              }
            } else {
              let x = document.getElementById(`${equipGroupElement.id}`).getBoundingClientRect().x;
              let y = document.getElementById(`${equipGroupElement.id}`).getBoundingClientRect().y;
              this.editor.removeNodeId(equipGroupElement.id);
              this.selectedItem = result.reorderedSequence;
              this.tempParentEquipData = result.reorderedSequence;
              this.addNodeToDrawBoard(x, y);
            }
          }
          result.payload.plantId = this.selectedPlantData.chillerPlantId;
          if (this.pipeRefs[result.payload.equipId]) {
            result.payload.pipeRefs = this.pipeRefs[result.payload.equipId];
          }
          this.apiService.saveEquipConfigs(result.payload).subscribe();
        } else if (result.action == 'delete') {
          this.editor.nodeDeleteClicked = true;
          if ((this.isParentEquip(result.equipData.class) || this.isHeaderPipe(result.equipData.childData.class) || this.isBypassPipe(result.equipData.childData.class)) && !result.equipData.class.includes('pump1Vertical')) {
            const nodeData = this.editor.getParentNodeData(result.equipData.id);
            this.editor.removeNodeId("node-" + nodeData.id);
            const warningIcon = document.getElementsByClassName(`${result.equipData.equipId}_warning-icon`);
            if (Array.from(warningIcon).length) {
              Array.from(warningIcon).forEach(_ele => { _ele.remove() });
            }
            if (result?.equipData?.childData?.equipData?.subEquips?.length) {
              this.deleteEquipAndConfig(result?.equipData?.equipId);
              result?.equipData?.childData?.equipData?.subEquips.forEach(_subEquip => {
                _subEquip.equips.forEach(_equip => {
                  if (_equip.subEquipType.includes('btuMeter') || _equip.subEquipType.includes('pump1Vertical')) {
                    this.deleteEquipAndConfig(_equip.equipId);
                  }
                });
              });
            } else {
              this.deleteEquipAndConfig(result.equipData.equipId);
            }
          } else {
            this.editor.removeNodeId("node-" + result.equipData.id);
            if (result.equipData.class.includes('btuMeter') || result.equipData.class.includes('pump1Vertical')) {
              this.deleteEquipAndConfig(result?.equipData?.childData?.equipData?.subEquips[0]?.equipId);
            } else {
              this.deleteEquipAndConfig(result.equipData.equipId);
            }
          }
        }
        this.checkPipeConnection('equipConfigSave');
      }
      this.isEquipDialogOpen = false;
    });
  }

  deleteEquipAndConfig(equipId) {
    if (equipId) {
      this.apiService.deleteEquipAndConfig(this.selectedPlantData.chillerPlantId, equipId).subscribe({
        next: () => {
        }, error: (err) => {
          this.getSiteEquips(true);
          console.log(err);
        }, complete: () => {
        }
      });
    }
  }


  deleteNode(nodeData) {
    let equipList = this.storageService.equipConfigList;
    equipList?.splice(equipList.findIndex(equip => equip.equipName === nodeData), 1);
    this.storageService.setEquipConfigListData(equipList);
  }


  onZoomOut() {
    this.editor.zoom_out();
  }

  onZoomIn() {
    this.editor.zoom_in();
  }

  onZoomReset() {
    this.editor.zoom_reset();
  }

  onFullScreen() {
    this.isFullScreen = !this.isFullScreen;
    if (this.editor.editor_mode == 'edit') {
      this.hidePanels = !this.hidePanels;
      this.hideEquipBar = this.hidePanels;
      this.showTopBar = this.hidePanels;
      if (!this.hideEquipBar) {
        document.getElementById("parentDrawFlow").style.height = "calc(100vh - 10px)";
        document.getElementsByClassName('bar-zoom')[0]['style'].bottom = '80px'
        document.getElementById("parentDrawFlow").style.marginTop = '5px';
        document.getElementById('wrapper')['style'].height = "calc(100vh - 5px)";
      } else {
        document.getElementById("parentDrawFlow").style.height = "calc(100vh - 42px)";
        document.getElementsByClassName('bar-zoom')[0]['style'].bottom = '260px';
        document.getElementById("parentDrawFlow").style.marginTop = '0px';
        document.getElementById('wrapper')['style'].height = '92vh';
      }
    } else {
      if (this.isFullScreen) {
        this.showTopBar = false;
        document.getElementById("parentDrawFlow").style.height = "calc(100vh - 10px)";
        document.getElementsByClassName('bar-zoom')[0]['style'].bottom = '80px'
        document.getElementById("parentDrawFlow").style.marginTop = '5px';
        document.getElementById('wrapper')['style'].height = "calc(100vh - 5px)";
      } else {
        this.showTopBar = true;
        document.getElementById("parentDrawFlow").style.height = "calc(100vh - 42px)";
        document.getElementsByClassName('bar-zoom')[0]['style'].bottom = '80px'
        document.getElementById("parentDrawFlow").style.marginTop = '0px';
        document.getElementById('wrapper')['style'].height = "92vh";
      }
    }
  }

  onPublishedView() {
    this.hidePanels = !this.hidePanels;
    this.hideEquipBar = this.hidePanels;
    if (!this.hideEquipBar || this.editor.editor_mode == 'fixed') {
      document.getElementsByClassName('bar-zoom')[0]['style'].bottom = '80px'
      document.getElementById("parentDrawFlow").style.height = "calc(100vh - 42px)";
    } else {
      document.getElementById("parentDrawFlow").style.height = "calc(100vh - 42px)";
      document.getElementsByClassName('bar-zoom')[0]['style'].bottom = '250px'
    }
  }

  toggleEquipBar() {
    this.hideEquipBar = !this.hideEquipBar;
    if (!this.hideEquipBar) {
      document.getElementById("parentDrawFlow").style.height = "calc(92vh - 42px)";
      document.getElementsByClassName('bar-zoom')[0]['style'].bottom = '80px';
      document.getElementsByClassName('builder-bottom-container')[0]['style'].height = '70px';
    } else {
      document.getElementById("parentDrawFlow").style.height = "calc(100vh - 42px)";
      document.getElementsByClassName('bar-zoom')[0]['style'].bottom = '260px';
      let buidlingBottomContainer = document.getElementsByClassName('builder-bottom-container')
      if (buidlingBottomContainer.length) {
        buidlingBottomContainer[0]['style'].height = '230px';
      }
      this.getSiteEquips(true);
    }
  }

  //Publish Plant clicked
  publishPlantClicked() {
    let plantDataArr = Object.values(this.editor.drawflow.drawflow.Home.data);

    // remove the added stroke property
    const paths = document.querySelectorAll<SVGPathElement>('.main-path');
    paths.forEach(path => {
      if (path.style.stroke && path.style.stroke == '#FF5252') {
        path.style.removeProperty('stroke'); // Remove the extra stroke property
      }
    });

    // Check if any equipment is not connected
    let nonConnectedEquips = [];
    plantDataArr.forEach((equipData: any) => {
      let equipInput = [];
      let equipOutputs = [];
      Object.keys(equipData.inputs).forEach(key => equipInput.push(equipData.inputs[key]));
      Object.keys(equipData.outputs).forEach(key => equipOutputs.push(equipData.outputs[key]));
      if (equipData.class != "GROUP") {
        if (Object.keys(equipData.inputs).length > 0) {
          equipInput.forEach(input => {
            if (input.connections.length == 0) {
              equipOutputs.forEach(outputdata => {
                if (outputdata.connections.length < 2) {
                  nonConnectedEquips.push(equipData)
                }
              })
            }
          })
        }

        // Check if any equipment is not connected
        if (Object.keys(equipData.outputs).length > 0) {
          equipOutputs.forEach(output => {
            if (output.connections.length == 0) {
              equipInput.forEach(input => {
                if (input?.connections?.length < 2) {
                  nonConnectedEquips.push(equipData)
                }
              })
            }
          })
        }
      }
    });

    // If any equipment is not connected, show error message and highlight the pipes
    if (nonConnectedEquips.length) {
      // Highlight the pipes which are not connected
      nonConnectedEquips.forEach((equipData: any) => {
        if (equipData.groupPipeId) {
          const svgElement: any = document.querySelectorAll<SVGPathElement>(`.groupPipe-${equipData.groupPipeId} .main-path`);
          svgElement.forEach(path => {
            path.style.stroke = '#FF5252';
          });
        }
      });
      this.showToast('error', 'One or more connections are incomplete. Please complete all connections to publish.');
    }

    // If all the equipments are connected, proceed to publish the plant
    if (this.enablePublishButton && !this.disablePublishBtnIfAnyEquipsDeleted) {
      const self = this;
      const dialogRef = self.dialog.open(ConfirmationPopupComponent, {
        width: '450px',
        panelClass: 'confirmation-model-container',
        data: {
          title: 'Publish Plant',
          content: `<p>Are you sure you want to publish <span class="plantName">${this.selectedPlantData.chillerPlantName}</span> ? </p>
         <p>Proceeding with this will share <span class="plantName">${this.selectedPlantData.chillerPlantName}</span> with Facilisight users.</p>`,
          buttonText: 'PUBLISH',
          buttonType: 'cancel'
        },
      });

      dialogRef.componentInstance.isCancel = true;

      dialogRef.afterClosed().subscribe((result) => {
        if (result == 'confirm') {
          this.selectedPlantData.isPublished = true;
          this.selectedPlantData.isSaved = false;
          this.showBtnData = {
            showEditPlantBtn: false,
            showSaveDraftBtn: false,
            showDeleteDraftBtn: false,
            showPublishBtn: false,
            showExitPreviewBtn: false,
            showPreviewBtn: true,
            showExportBtn: false
          }
          this.onSubmit('publish');
        }
      });
    }
  }

  onSubmit(type) {
    const dataExport = this.editor.export();
    const pipeRefs = this.pipeRefs;
    JSON.stringify(dataExport).split('\\"').join("'").split('\\n').join("");
    Object.keys(dataExport.drawflow.Home.data).forEach(function (key) {
      if (dataExport.drawflow.Home.data[key].class != "GROUP") {
        dataExport.drawflow.Home.data[key].data = {};
      }
      // adding pipeRefs for headerpipes
      if (dataExport.drawflow.Home.data[key].class == "GROUP" && Object.keys(pipeRefs).includes(dataExport.drawflow.Home.data[key].equipId)) {
        dataExport.drawflow.Home.data[key]["pipeRefs"] = pipeRefs[dataExport.drawflow.Home.data[key].equipId];
      }
    });

    let equipConfigList = this.storageService.equipConfigList;
    if (equipConfigList != null && equipConfigList.length > 0) {
      Object.keys(dataExport.drawflow.Home.data).forEach(function (key) {
        equipConfigList.forEach((equip) => {
          if (equip.equipName === dataExport.drawflow.Home.data[key].class) {
            dataExport.drawflow.Home.data[key].data = equip;
          }
        });
      });
    }
    let _this = this;
    Object.keys(dataExport.drawflow.Home.data).forEach(function (key) {
      dataExport.drawflow.Home.data[key].zoomData = {
        "zoom": _this.editor.zoom,
        "canvas_x": _this.editor.canvas_x,
        "canvas_y": _this.editor.canvas_y,
      }
    });
    this.selectedPlantData.plantJsonStr = dataExport;
    this.selectedPlantData.sites = this.selectedBuilding;

    this.saveUpdatedPlantData(this.selectedPlantData, type);
  }

  onDiscardPopUpCrossClick() {
    this.showDiscardPopUp = !this.showDiscardPopUp
  }

  onDiscardClick() {
    this.saveUpdatedPlantData(this.selectedPlantData, 'discard')
  }

  editClicked() {
    if (!this.selectedPlantData.hasOwnProperty('lastModifiedBy')) {
      this.selectedPlantData['lastModifiedBy'] = this.selectedPlantData.createdBy
    }
    if (this.userPreferenceData.userId == this.selectedPlantData.lastModifiedBy.userId) {
      this.showBtnData.showEditPlantBtn = false;
      this.selectedTabIndex = 1;
      this.loadCanvasEquipData(this.selectedPlantData, true);
      this.disableMatTab = false;
      this.hidePanels = false;
      this.isPublished = false;
      this.nodes = [];
      this.duplicateNodesArr = [];
      this.showLoader();
    } else {
      this.showDiscardPopUp = true;
    }
  }

  showLoader() {
    if (document.getElementById("loader")?.style?.display) { document.getElementById("loader").style.display = "block"; }
    if (document.getElementById("top-bar")?.style?.pointerEvents) { document.getElementById("top-bar").style.pointerEvents = "none"; }
    if (document.getElementById("bottom-bar")?.style?.pointerEvents) { document.getElementById("bottom-bar").style.pointerEvents = "none"; }
    if (document.getElementById("drawflow")?.style?.pointerEvents) { document.getElementById("drawflow").style.pointerEvents = "none"; }
  }

  hideLoader() {
    if (document.getElementById("loader")?.style?.display) { document.getElementById("loader").style.display = "none"; }
    if (document.getElementById("top-bar")?.style?.pointerEvents) { document.getElementById("top-bar").style.pointerEvents = ""; }
    if (document.getElementById("bottom-bar")?.style?.pointerEvents) { document.getElementById("bottom-bar").style.pointerEvents = ""; }
    if (document.getElementById("drawflow")?.style?.pointerEvents) { document.getElementById("drawflow").style.pointerEvents = ""; }
  }

  checkisLbendPipe(equipId) {
    const groupId = document.getElementsByClassName(`parentEquipId-${equipId} GROUP`)[0]?.id.slice(5);
    if (groupId) {
      return this.editor.drawflow.drawflow[this.editor.module].data[groupId].childData.class == "connectorLbendPipe" ? true : false;
    }
    return false;
  }

  saveUpdatedPlantData(data, type) {
    if (type == 'discard') {
      this.disableDiscardBtn = true;
      this.selectedPlantData.plantJsonStr = {
        drawflow: {
          Home: {
            data: {}
          }
        }
      }
      this.apiService.discardDraft(this.selectedPlantData.chillerPlantId, this.selectedPlantData.plantJsonStr)
        .subscribe({
          next: (res) => {
            this.getPlant();
          }, error: (error) => {
            this.disableDiscardBtn = false;
          }, complete: () => {
          }
        })
    }
    // this method is used when user drags equips on canvas but does not configure any points and Published the dashboard.
    let self = this;
    let plantData = ObjectUtil.deepClone(data)
    // let configData = plantData.plantJsonStr.drawflow.Home.data;
    // let included = ["waterCooledChiller", "airCooledChiller", "pump1Vertical", "coolingTowerSingleFan", "coolingTowerTwoFan", "coolingTowerFourFan", "steamBoiler", "condenserBoiler"]
    // let defaultClickViewPoints = {
    //   'waterCooledChiller': ['Chiller Enable', 'Chiller Run Hours', 'Chiller Power', 'Chiller Energy Consumption', 'Chiller Command'],
    //   'airCooledChiller': ['Chiller Enable', 'Chiller Run Hours', 'Chiller Power', 'Chiller Energy Consumption', 'Chiller Command'],
    //   'pump1Vertical': ['Pump Enable', 'Pump Run Hours', 'Pump Power', 'Pump Energy Consumption', 'Pump Command'],
    //   'coolingTowerSingleFan': ['Cooling Tower Fan Enable', 'Cooling Tower Fan Run Hours', 'Cooling Tower Fan Power', 'Cooling Tower Fan Energy Consumption', 'Cooling Tower Fan Command'],
    //   'coolingTowerTwoFan': ['Cooling Tower Fan 1 Enable', 'Cooling Tower Fan 1 Run Hours', 'Cooling Tower Fan 1 Power', 'Cooling Tower Fan 1 Energy Consumption', 'Cooling Tower Fan 1 Command', 'Cooling Tower Fan 2 Enable', 'Cooling Tower Fan 2 Run Hours', 'Cooling Tower Fan 2 Power', 'Cooling Tower Fan 2 Energy Consumption', 'Cooling Tower Fan 2 Command'],
    //   'coolingTowerFourFan': ['Cooling Tower Fan 1 Enable', 'Cooling Tower Fan 1 Run Hours', 'Cooling Tower Fan 1 Power', 'Cooling Tower Fan 1 Energy Consumption', 'Cooling Tower Fan 1 Command', 'Cooling Tower Fan 2 Enable', 'Cooling Tower Fan 2 Run Hours', 'Cooling Tower Fan 2 Power', 'Cooling Tower Fan 2 Energy Consumption', 'Cooling Tower Fan 2 Command', 'Cooling Tower Fan 3 Enable', 'Cooling Tower Fan 3 Run Hours', 'Cooling Tower Fan 3 Power', 'Cooling Tower Fan 3 Energy Consumption', 'Cooling Tower Fan 3 Command', 'Cooling Tower Fan 4 Enable', 'Cooling Tower Fan 4 Run Hours', 'Cooling Tower Fan 4 Power', 'Cooling Tower Fan 4 Energy Consumption', 'Cooling Tower Fan 4 Command'],
    //   'condenserBoiler': ['Boiler Enable', 'Boiler Run Hours', 'Boiler Power', 'Boiler Energy Consumption', 'Boiler Command'],
    //   'steamBoiler': ['Boiler Enable', 'Boiler Run Hours', 'Boiler Power', 'Boiler Energy Consumption', 'Boiler Command']
    // };
    // self.apiService.getEquipConfigPoint().subscribe((response) => {
    // response = self.helperService.stripHaystackTypeMapping(response);

    // if (configData != undefined && Object.keys(configData).length > 0 && configData.class != "GROUP") {

    //   let pointInfo

    //   Object.keys(configData).forEach(function (key) {
    //     if(Object.keys(configData[key].data).length == 0 && included.indexOf(configData[key].class) > -1){
    //       let equip = plantData.plantJsonStr.drawflow.Home.data[key]
    //       let selectedData = {
    //         equipID: equip.id,
    //         equipName: equip.class,
    //         name:equip.name,
    //         customPoint: [],
    //         alert: [],
    //       }

    //         pointInfo = response.filter(equipData => equipData.equip.includes(equip.class))[0]; 
    //         let defaultClickViewPointsArr = defaultClickViewPoints[equip.class];
    //         defaultClickViewPointsArr.forEach(pointName => {
    //           let i = pointInfo.data.findIndex(e => e.dis === pointName);
    //           if (i > -1) {
    //             pointInfo.data[i].isClick = true;
    //           }
    //         });
    //         selectedData["point"] = pointInfo.data;
    //         selectedData["equipData"] = pointInfo.equipData
    //         plantData.plantJsonStr.drawflow.Home.data[key].data = ObjectUtil.deepClone(selectedData)
    //       }
    //     });
    //     this.selectedPlantData = plantData;
    //   }
    if (type == 'publish') {
      this.apiService.publishPlant(plantData.chillerPlantId, this.selectedPlantData.plantJsonStr)
        .subscribe({
          next: () => {
            localStorage.setItem(StorageKeys.TEMPPLANTDATA, JSON.stringify(this.selectedPlantData.plantJsonStr));
            this.showToast('success', 'Plant Published successfully');
            this.getPlantStatus();
          },
          error: () => {
            this.showToast('error', 'We were unable to Publish Plant');
            this.getPlantStatus();
          }
        });
    }
    // Saving Plant Draft
    if (type == 'saveDraft') {
      this.showLoader();
      const selectedPlantData = this.selectedPlantData;
      let _this = this;
      let headerPipeEquips = [];
      Object.keys(selectedPlantData?.plantJsonStr?.drawflow?.Home?.data).forEach(function (key) {
        let currentEquipDataObj = selectedPlantData.plantJsonStr.drawflow.Home.data[key];
        if (currentEquipDataObj.equipType == '3wayValve'){
          let isExistingEquipIndex = headerPipeEquips.findIndex(equip => equip.equipId === currentEquipDataObj.groupPipeId);
          let pipeRefObj;
          if (isExistingEquipIndex == -1) {
            pipeRefObj = {
              equipId: currentEquipDataObj.groupPipeId,
              pipeRefs: [
              ],
              nodeDataArr: [
              ]
            }
          } else {
            pipeRefObj = headerPipeEquips[isExistingEquipIndex];
          }

          if (currentEquipDataObj?.inputs?.input_2?.connections?.length) {
            let connectionNodeData = _this.editor.getNodeFromId(String(currentEquipDataObj?.inputs?.input_2?.connections[0].node));
            if (connectionNodeData?.groupPipeId) {
              if (!_this.checkisLbendPipe(connectionNodeData?.groupPipeId)) {
                pipeRefObj.pipeRefs.push(connectionNodeData.groupPipeId);
                pipeRefObj.nodeDataArr.push(connectionNodeData);
              }
            }

            if (connectionNodeData.class == "byPassLine-connectTwo" && connectionNodeData?.parentPipeRef) {
              if (!_this.checkisLbendPipe(connectionNodeData?.parentPipeRef)) {
                pipeRefObj.pipeRefs.push(connectionNodeData.parentPipeRef);
                pipeRefObj.nodeDataArr.push(connectionNodeData);
              }
            }

            pipeRefObj.pipeRefs = [...new Set(pipeRefObj.pipeRefs)]
            if (isExistingEquipIndex == -1) {
              headerPipeEquips.push(pipeRefObj);
            }
          }
        }

        if (currentEquipDataObj.class == 'GROUP' && (_this.isHeaderPipe(currentEquipDataObj.childData.class) || _this.isBypassPipe(currentEquipDataObj.childData.class))) {
          let isExistingEquipIndex =  headerPipeEquips.findIndex(equip => equip.equipId === currentEquipDataObj.equipId);
          let pipeRefObj;
          if (isExistingEquipIndex == -1) {
            pipeRefObj = {
              equipId: currentEquipDataObj.equipId,
              pipeRefs: [
              ],
              nodeDataArr: [
              ]
            }
          } else {
            pipeRefObj = headerPipeEquips[isExistingEquipIndex];
          }
          let groupElements = currentEquipDataObj.data.groupNodeIds;
          groupElements.forEach(childEquipId => {
            let equipData: any = _this.editor.getNodeFromId(String(childEquipId));
            if (equipData.class == "pipeDemarkation") {
              if (Object.keys(equipData.inputs).length) {
                Object.keys(equipData.inputs).forEach(inputKeys => {
                  equipData.inputs[inputKeys].connections.forEach(connectionObj => {
                    if (!groupElements.includes(connectionObj.node)) {
                      let connectionNodeData = _this.editor.getNodeFromId(String(connectionObj.node));
                      if (connectionNodeData.equipType == '3wayValve' && connectionNodeData.groupPipeId) {
                        if (!_this.checkisLbendPipe(connectionNodeData?.groupPipeId)) {
                          pipeRefObj.pipeRefs.push(connectionNodeData.groupPipeId);
                          pipeRefObj.nodeDataArr.push(connectionNodeData);
                        }
                      }
                      if (connectionNodeData.class == "connectorLbend" && connectionNodeData?.groupPipeId) {
                        pipeRefObj.nodeDataArr.push(connectionNodeData);
                      }
                      if (connectionNodeData.class == "connectTwo" && connectionNodeData?.groupPipeId) {
                        if (!_this.checkisLbendPipe(connectionNodeData?.groupPipeId)) {
                          pipeRefObj.pipeRefs.push(connectionNodeData.groupPipeId);
                          pipeRefObj.nodeDataArr.push(connectionNodeData);
                        }
                      }
                      if (_this.isBypassPipe(currentEquipDataObj.childData.class) && connectionNodeData.class == "byPassLine-connectTwo" && connectionNodeData?.parentPipeRef) {
                        if (!_this.checkisLbendPipe(connectionNodeData?.parentPipeRef)) {
                          pipeRefObj.pipeRefs.push(connectionNodeData.parentPipeRef);
                          pipeRefObj.nodeDataArr.push(connectionNodeData);
                        }
                      }
                      if (connectionNodeData.class.includes('pump') || connectionNodeData.class.includes('btuMeter') || connectionNodeData.class.includes('temperatureSensor')) {
                        if (connectionNodeData.parentPipeRef != undefined && !_this.checkisLbendPipe(connectionNodeData?.parentPipeRef)) {
                          pipeRefObj.pipeRefs.push(connectionNodeData.parentPipeRef);
                          pipeRefObj.nodeDataArr.push(connectionNodeData);
                        }
                      }
                    }
                  });
                });
              }

              if (Object.keys(equipData.outputs).length) {
                Object.keys(equipData.outputs).forEach(outputKeys => {
                  equipData.outputs[outputKeys].connections.forEach(connectionObj => {
                    if (!groupElements.includes(connectionObj.node)) {
                      let connectionNodeData = _this.editor.getNodeFromId(connectionObj.node);
                      if (connectionNodeData.equipType == '3wayValve' && connectionNodeData.groupPipeId) {
                        if (!_this.checkisLbendPipe(connectionNodeData?.groupPipeId)) {
                          pipeRefObj.pipeRefs.push(connectionNodeData.groupPipeId);
                          pipeRefObj.nodeDataArr.push(connectionNodeData);
                        }
                      }
                      if (connectionNodeData.class == "connectTwo" && connectionNodeData?.groupPipeId != undefined) {
                        if (!_this.checkisLbendPipe(connectionNodeData?.groupPipeId)) {
                          pipeRefObj.pipeRefs.push(connectionNodeData.groupPipeId);
                          pipeRefObj.nodeDataArr.push(connectionNodeData);
                        }
                      }
                      if (connectionNodeData.class == "connectorLbend" && connectionNodeData?.groupPipeId != undefined) {
                        pipeRefObj.nodeDataArr.push(connectionNodeData);
                      }
                      if (_this.isBypassPipe(currentEquipDataObj.childData.class) && connectionNodeData.class == "byPassLine-connectTwo" && connectionNodeData?.parentPipeRef) {
                        if (!_this.checkisLbendPipe(connectionNodeData?.parentPipeRef)) {
                          pipeRefObj.pipeRefs.push(connectionNodeData.parentPipeRef);
                          pipeRefObj.nodeDataArr.push(connectionNodeData);
                        }
                      }

                      if (connectionNodeData.class.includes('pump') || connectionNodeData.class.includes('btuMeter') || connectionNodeData.class.includes('temperatureSensor')) {
                        if (connectionNodeData.parentPipeRef != undefined) {
                          if (!_this.checkisLbendPipe(connectionNodeData?.parentPipeRef)) {
                            pipeRefObj.pipeRefs.push(connectionNodeData.parentPipeRef);
                            pipeRefObj.nodeDataArr.push(connectionNodeData);
                          }
                        }
                      }
                    }
                  });
                });
              }
            }
          });
          pipeRefObj.pipeRefs = [...new Set(pipeRefObj.pipeRefs)]
          if (isExistingEquipIndex == -1) {
            headerPipeEquips.push(pipeRefObj);
          }
        }
        // Adding PipeRef for SubEquip Connector- pipeRef of connected subequip will be mapped
        if(currentEquipDataObj.class== "subEquip-connectTwo"){
          let isExistingEquipIndex = headerPipeEquips.findIndex(equip => equip.equipId === currentEquipDataObj.groupPipeId);
          let pipeRefObj;
          if (isExistingEquipIndex == -1) {
            pipeRefObj = {
              equipId: currentEquipDataObj.groupPipeId,
              pipeRefs: [
              ],
              nodeDataArr: [
              ]
            }
          } else {
            pipeRefObj = headerPipeEquips[isExistingEquipIndex];
          }


          if (Object.keys(currentEquipDataObj.inputs).length) {
            Object.keys(currentEquipDataObj.inputs).forEach(inputKeys => {
              currentEquipDataObj.inputs[inputKeys].connections.forEach(connectionObj => {
                let connectionNodeData = _this.editor.getNodeFromId(String(connectionObj.node));
                if(connectionNodeData.class == "subEquip-connectTwo" && connectionNodeData.groupPipeId != currentEquipDataObj.groupPipeId){
                  pipeRefObj.pipeRefs.push(connectionNodeData.groupPipeId);
                  pipeRefObj.nodeDataArr.push(connectionNodeData);
                }
              });
            });
          }

          if (Object.keys(currentEquipDataObj.outputs).length) {
            Object.keys(currentEquipDataObj.outputs).forEach(outputKeys => {
              currentEquipDataObj.outputs[outputKeys].connections.forEach(connectionObj => {
                let connectionNodeData = _this.editor.getNodeFromId(String(connectionObj.node));
                if(connectionNodeData.class == "subEquip-connectTwo"  && connectionNodeData.groupPipeId != currentEquipDataObj.groupPipeId){
                  pipeRefObj.pipeRefs.push(connectionNodeData.groupPipeId);
                  pipeRefObj.nodeDataArr.push(connectionNodeData);
                }
              });
            });
          }

          pipeRefObj.pipeRefs = [...new Set(pipeRefObj.pipeRefs)]
          if (isExistingEquipIndex == -1) {
            headerPipeEquips.push(pipeRefObj);
          }
        }

// Adding PipeRef for Lead & Lag connection
        if (currentEquipDataObj.class == "connectTwo") {
          let isExistingEquipIndex = headerPipeEquips.findIndex(equip => equip.equipId === currentEquipDataObj.groupPipeId);
          let pipeRefObj;
          if (isExistingEquipIndex == -1) {
            pipeRefObj = {
              equipId: currentEquipDataObj.groupPipeId,
              pipeRefs: [
              ],
              nodeDataArr: [
              ]
            }
          } else {
            pipeRefObj = headerPipeEquips[isExistingEquipIndex];
          }

          if (Object.keys(currentEquipDataObj.inputs).length) {
            Object.keys(currentEquipDataObj.inputs).forEach(inputKeys => {
              currentEquipDataObj.inputs[inputKeys].connections.forEach(connectionObj => {
                let connectionNodeData = _this.editor.getNodeFromId(String(connectionObj.node));
                if (connectionNodeData.class == "connectTwo" && connectionNodeData.groupPipeId != currentEquipDataObj.groupPipeId) {
                  let pipeGroupId = document.getElementsByClassName(`parentEquipId-${currentEquipDataObj.equipId} GROUP`)[0]?.id.slice(5);
                  if (_this.selectedPlantData.plantJsonStr.drawflow.Home.data[pipeGroupId].leadnLagConnection != undefined) {
                    _this.selectedPlantData.plantJsonStr.drawflow.Home.data[pipeGroupId].leadnLagConnection.push(connectionNodeData.equipId);
                    _this.selectedPlantData.plantJsonStr.drawflow.Home.data[pipeGroupId].leadnLagConnection = [...new Set(_this.selectedPlantData.plantJsonStr.drawflow.Home.data[pipeGroupId].leadnLagConnection)];
                  } else {
                    _this.selectedPlantData.plantJsonStr.drawflow.Home.data[pipeGroupId].leadnLagConnection = [connectionNodeData.equipId];
                  }
                  pipeRefObj.pipeRefs.push(connectionNodeData.groupPipeId);
                  pipeRefObj.nodeDataArr.push(connectionNodeData);
                }
              });
            });
          }

          if (Object.keys(currentEquipDataObj.outputs).length) {
            Object.keys(currentEquipDataObj.outputs).forEach(outputKeys => {
              currentEquipDataObj.outputs[outputKeys].connections.forEach(connectionObj => {
                let connectionNodeData = _this.editor.getNodeFromId(String(connectionObj.node));
                if (connectionNodeData.class == "connectTwo" && connectionNodeData.groupPipeId != currentEquipDataObj.groupPipeId) {
                  let pipeGroupId = document.getElementsByClassName(`parentEquipId-${currentEquipDataObj.equipId} GROUP`)[0]?.id.slice(5);
                  if (_this.selectedPlantData.plantJsonStr.drawflow.Home.data[pipeGroupId].leadnLagConnection != undefined) {
                    _this.selectedPlantData.plantJsonStr.drawflow.Home.data[pipeGroupId].leadnLagConnection.push(connectionNodeData.equipId);
                    _this.selectedPlantData.plantJsonStr.drawflow.Home.data[pipeGroupId].leadnLagConnection = [...new Set(_this.selectedPlantData.plantJsonStr.drawflow.Home.data[pipeGroupId].leadnLagConnection)];
                  } else {
                    _this.selectedPlantData.plantJsonStr.drawflow.Home.data[pipeGroupId].leadnLagConnection = [connectionNodeData.equipId];
                  }
                  pipeRefObj.pipeRefs.push(connectionNodeData.groupPipeId);
                  pipeRefObj.nodeDataArr.push(connectionNodeData);
                }
              });
            });
          }

          pipeRefObj.pipeRefs = [...new Set(pipeRefObj.pipeRefs)]
          if (isExistingEquipIndex == -1 && pipeRefObj.pipeRefs.length) {
            headerPipeEquips.push(pipeRefObj);
          }
        }

        //Addding pipref for heatexchanger 
        if(currentEquipDataObj.childData.class.includes('heatExchanger') && currentEquipDataObj.class == "connectTwo") {
          let isExistingEquipIndex = headerPipeEquips.findIndex(equip => equip.equipId === currentEquipDataObj.groupPipeId);
          let pipeRefObj;
          if (isExistingEquipIndex == -1) {
            pipeRefObj = {
              equipId: currentEquipDataObj.groupPipeId,
              pipeRefs: [
              ],
              nodeDataArr: [
              ]
            }
          } else {
            pipeRefObj = headerPipeEquips[isExistingEquipIndex];
          }

          if (Object.keys(currentEquipDataObj.inputs).length) {
            Object.keys(currentEquipDataObj.inputs).forEach(inputKeys => {
              currentEquipDataObj.inputs[inputKeys].connections.forEach(connectionObj => {
                let connectionNodeData = _this.editor.getNodeFromId(String(connectionObj.node));
                if(connectionNodeData.class == "connectTwo"){
                  pipeRefObj.pipeRefs.push(connectionNodeData.groupPipeId);
                  pipeRefObj.nodeDataArr.push(connectionNodeData);
                }
              });
            });
          }

          if (Object.keys(currentEquipDataObj.outputs).length) {
            Object.keys(currentEquipDataObj.outputs).forEach(outputKeys => {
              currentEquipDataObj.outputs[outputKeys].connections.forEach(connectionObj => {
                let connectionNodeData = _this.editor.getNodeFromId(String(connectionObj.node));
                if(connectionNodeData.class == "connectTwo"){
                  pipeRefObj.pipeRefs.push(connectionNodeData.groupPipeId);
                  pipeRefObj.nodeDataArr.push(connectionNodeData);
                }
              });
            });
          }
          pipeRefObj.pipeRefs = [...new Set(pipeRefObj.pipeRefs)]
          if (isExistingEquipIndex == -1) {
            headerPipeEquips.push(pipeRefObj);
          }
        }
      });

      if (headerPipeEquips?.length) {
        headerPipeEquips.forEach(pipeRefData => {
          let pipeGroupId = document.getElementsByClassName(`parentEquipId-${pipeRefData.equipId} GROUP`)[0]?.id.slice(5);
          let groupPipeData = this.editor.getNodeFromId(pipeGroupId);
          if (this.isHeaderPipe(groupPipeData?.childData?.class) || groupPipeData?.childData?.class.includes('heatExchanger')) {
            this.selectedPlantData.plantJsonStr.drawflow.Home.data[pipeGroupId].groupPipeRef = pipeRefData.pipeRefs;
            this.selectedPlantData.plantJsonStr.drawflow.Home.data[pipeGroupId].connectedNodesData = [...new Set(pipeRefData.nodeDataArr)];
          }
        });
      }

      headerPipeEquips = headerPipeEquips.map(({ nodeDataArr, ...rest }) => {
        return rest;
      });
      let plantJson = {
        "headerPipeEquips": headerPipeEquips,
        "plantJson": JSON.stringify(this.selectedPlantData.plantJsonStr)
      }
      //Plant JSON for Reference 
      console.log("plantJsonStr", this.selectedPlantData.plantJsonStr);
      this.apiService.savePlantDraft(plantData.chillerPlantId, plantJson)
        .subscribe({
          next: () => {
            this.checkAnyEquipsOrSubEquipsAreDeleted();
            this.generatePublishTooltipText();
            this.hideLoader();
            this.showToast('success', 'Plant/Equip Data saved successfully!');
            this.getPlantStatus();
          },
          error: (err) => {
            this.getPlantStatus();
            this.showBtnData.showPublishBtn = false;
            this.showBtnData.showSaveDraftBtn = true;
            this.showBtnData.showDeleteDraftBtn = false;
            this.showBtnData.showPreviewBtn = false;
            this.hideLoader();
            this.showToast('error', 'We were unable to Save Plant');
          },
          complete: () => { }
        });
    }
  }

  getPlant() {
    let status = this.editor.editor_mode == 'edit' ? 'DRAFT' : 'PUBLISHED';
    this.apiService
      .getPlantData(this.selectedPlantData.chillerPlantId, status)
      .subscribe((plantDataResp) => {
        if (!plantDataResp.hasOwnProperty('plantJson')) {
          this.selectedPlantData.plantJsonStr = {
            drawflow: {
              Home: {
                data: {}
              }
            }
          };
        } else {
          this.selectedPlantData.plantJsonStr = JSON.parse(plantDataResp.plantJson);
        }
        this.siteName = plantDataResp?.siteName;
        this.storageService.setSelectedPlantData(this.selectedPlantData);
        this.nodes = [];
        this.duplicateNodesArr = [];
        this.loadCanvasEquipData(this.selectedPlantData, true);
        this.selectedTabIndex = 1;
        this.showDiscardPopUp = false;
        this.showBtnData.showEditPlantBtn = false;
        this.disableMatTab = false;
        this.disableDiscardBtn = false;
      })
  }

  setTheme() {
    const drawflowElement = document.querySelector('.drawflow');
    if (this.toggleTheme == 'light') {
      this.toggleChecked = false;
      (<HTMLElement>document.getElementsByClassName('wrapper')[0]).style.background = `#ffffff`;
      drawflowElement?.classList?.remove("darkMode")
      drawflowElement?.classList?.add('lightMode');
    } else {
      this.toggleChecked = true;
      (<HTMLElement>document.getElementsByClassName('wrapper')[0]).style.background = '#181818';
      drawflowElement?.classList?.remove("lightMode")
      drawflowElement?.classList?.add('darkMode');
    }
    this.editor.dispatch('zoom', this.editor.zoom.toFixed(1));
  }

  getUserPreferenceData() {
    this.userService.getUserPreferenceData().subscribe((res) => {
      this.userPreferenceData = res;
      this.localService.persistUserId(res['userId']);
      this.toggleTheme = this.userPreferenceData.heatmapPreferences['cpmTheme'];
      this.masterViewPreference = this.userPreferenceData.heatmapPreferences['cpmMasterViewPreference'];
      this.setTheme();
      if (this.userPreferenceData.heatmapPreferences.cpmMasterViewPreference) {
        this.CPMTopBarComponent.statusView(this.userPreferenceData.heatmapPreferences.cpmMasterViewPreference, false);
      } else {
        this.CPMTopBarComponent.statusView('valueView', false);
      }
    }, (error) => {
      this.setTheme();
      this.CPMTopBarComponent.statusView('valueView', false);
    })
  }

  themeChanged(themeData) {
    this.toggleTheme = themeData[0];
    this.toggleChecked = themeData[1];

    const drawflowElement = document.querySelector('.drawflow');
    if (this.toggleTheme == 'light') {
      this.toggleChecked = false;
      (<HTMLElement>document.getElementsByClassName('wrapper')[0]).style.background = `#ffffff`;
      drawflowElement.classList.remove("darkMode")
      drawflowElement.classList.add('lightMode');
    } else {
      this.toggleChecked = true;
      (<HTMLElement>document.getElementsByClassName('wrapper')[0]).style.background = '#181818';
      drawflowElement.classList.remove("lightMode")
      drawflowElement.classList.add('darkMode');
    }
    this.editor.dispatch('zoom', this.editor.zoom.toFixed(1));
    this.editor.dispatch('changeDualTempColor', this.editor.drawflow);
    if (!this.loginUserTypeData.isPublic) {
      this.setUserPreferenceData();
    }
  }

  async getUserPreferencesDatabeforeupdate() {
    return new Promise<void>((resolve, reject) => {
      this.userService.getUserPreferenceData().subscribe({
        next: (res) => {
          this.userPreferenceData = res;
        },
        error: (err) => {
          reject(err);
        },
        complete: () => {
          resolve();
        }
      });
    });
  }

  async setUserPreferenceData() {
    await this.getUserPreferencesDatabeforeupdate();
    this.userPreferenceData.userId = this.localService.getUserId();
    this.userPreferenceData.heatmapPreferences['cpmTheme'] = this.toggleTheme;
    this.userPreferenceData.heatmapPreferences['cpmMasterViewPreference'] = this.masterViewPreference;
    let payload = this.userPreferenceData;
    this.userService.setUserPreferenceData(payload).subscribe((res) => {
      this.userPreferenceData = res;
    })
  }

  masterViewPreferenceChanged(masterViewPreference) {
    this.masterViewPreference = masterViewPreference;
    if (!this.loginUserTypeData.isPublic) {
      this.setUserPreferenceData();
    }
    this.userPreferenceData.heatmapPreferences['cpmMasterViewPreference'] = masterViewPreference;
    this.loadMasterViewPoints(this.editor.drawflow);
  }

  /**
   * Displays the plant preview and enables the exit preview button.
   * Calls the `onFullScreen` method to enter full-screen mode.
   */
  plantPreview() {
    this.showBtnData.showExitPreviewBtn = true;
    this.onFullScreen();
  }

  /**
   * Exits the plant preview mode.
   */
  exitplantPreview() {
    this.showBtnData.showExitPreviewBtn = false;
    this.onFullScreen();
  }

  saveDraftBtnClicked() {
    this.selectedPlantData.isSaved = true;
    this.showBtnData = {
      showEditPlantBtn: false,
      showSaveDraftBtn: false,
      showDeleteDraftBtn: true,
      showPublishBtn: true,
      showExitPreviewBtn: false,
      showPreviewBtn: true,
      showExportBtn: false
    }
    this.onSubmit('saveDraft');
  }

  deleteDraftBtnClicked() {
    const self = this;
    const dialogRef = self.dialog.open(ConfirmationPopupComponent, {
      width: '450px',
      panelClass: 'confirmation-model-container',
      data: {
        title: 'Delete Draft',
        content: `<p>Are you sure you want to Delete Draft <span class="plantName">${this.selectedPlantData.chillerPlantName}</span> ? </p>`,
        buttonText: 'DELETE DRAFT',
        buttonType: 'cancel'
      },
    });
    dialogRef.componentInstance.isCancel = true;


    dialogRef.afterClosed().subscribe((result) => {
      if (result == 'confirm') {
        if (this.selectedPlantData.isPublished) {
          let getPlantData = JSON.parse(localStorage.getItem(StorageKeys.TEMPPLANTDATA));
          this.selectedPlantData.plantJsonStr = getPlantData;
          this.showBtnData.showDeleteDraftBtn = false;
        } else {
          this.showBtnData = {
            showEditPlantBtn: false,
            showSaveDraftBtn: false,
            showDeleteDraftBtn: false,
            showPublishBtn: false,
            showExitPreviewBtn: false,
            showPreviewBtn: false,
            showExportBtn: false
          }
        }
        this.deleteDraftAPICall();
      }
    });
  }

  deleteDraftAPICall() {
    this.selectedPlantData.plantJsonStr = { drawflow: { Home: { data: {} } } };
    this.apiService
      .discardDraft(this.selectedPlantData.chillerPlantId, this.selectedPlantData.plantJsonStr)
      .subscribe({
        next: () => {
          this.showToast('success', 'Draft deleted successfully');
          setTimeout(() => {
            this.showLoader();
            this.getPlant();
          }, 500);
        },
        error: () => {
          this.showToast('error', 'We were unable to Delete the draft');
          this.hideLoader();
        },
        complete: () => { }
      });
  }

  // Toast Message 
  showToast(type: string, message: string, duration = 5000) {
    this.toastMessage.openFromComponent(ToastMessageComponent, {
      duration: duration,
      data: {
        type: type,
        message: message
      },
    });
  }

  // Detect Node Collision 

  collide(ra, rb) {
    var a = this.norm(ra), b = this.norm(rb);
    var inx = this.isect(a.left, b.left, b.right)
      || this.isect(a.right, b.left, b.right)
      || this.inside(a.left, a.right, b.left, b.right)
      || this.inside(b.left, b.right, a.left, a.right)
      ;
    var iny = this.isect(a.top, b.top, b.bottom)
      || this.isect(a.bottom, b.top, b.bottom)
      || this.inside(a.top, a.bottom, b.top, b.bottom)
      || this.inside(b.top, b.bottom, a.top, a.bottom)
      ;
    return inx && iny;
  };

  isect(x, lower, upper) {
    return x >= lower && x <= upper;
  }

  inside(a0, a1, b0, b1) {
    return a0 >= b0 && a1 <= b1;
  }

  norm(q) {
    var p = {
      left: q.left,
      right: q.right,
      top: q.top,
      bottom: q.bottom
    };
    if (p.left === undefined && q.x !== undefined) p.left = q.x;
    if (p.top === undefined && q.y !== undefined) p.top = q.y;

    var w = q.width || 0, h = q.height || 0;

    if (p.right === undefined && q.x !== undefined) p.right = q.x + w;
    if (p.bottom === undefined && q.y !== undefined) p.bottom = q.y + h;
    return p;
  }

  calculateIntersectionPoint(line1StartX, line1StartY, line1EndX, line1EndY, line2StartX, line2StartY, line2EndX, line2EndY) {
    // if the lines intersect, the result contains the x and y of the intersection (treating the lines as infinite) and booleans for whether line segment 1 or line segment 2 contain the point
    var denominator, a, b, numerator1, numerator2, result = {
      x: null,
      y: null,
      onLine1: false,
      onLine2: false
    };
    denominator = ((line2EndY - line2StartY) * (line1EndX - line1StartX)) - ((line2EndX - line2StartX) * (line1EndY - line1StartY));
    if (denominator == 0) {
      return result;
    }
    a = line1StartY - line2StartY;
    b = line1StartX - line2StartX;
    numerator1 = ((line2EndX - line2StartX) * a) - ((line2EndY - line2StartY) * b);
    numerator2 = ((line1EndX - line1StartX) * a) - ((line1EndY - line1StartY) * b);
    a = numerator1 / denominator;
    b = numerator2 / denominator;

    // if we cast these lines infinitely in both directions, they intersect here:
    result.x = line1StartX + (a * (line1EndX - line1StartX));
    result.y = line1StartY + (a * (line1EndY - line1StartY));
    /*
      // it is worth noting that this should be the same as:
      x = line2StartX + (b * (line2EndX - line2StartX));
      y = line2StartX + (b * (line2EndY - line2StartY));
    */
    // if line1 is a segment and line2 is infinite, they intersect if:
    if (a > 0 && a < 1) {
      result.onLine1 = true;
    }
    // if line2 is a segment and line1 is infinite, they intersect if:
    if (b > 0 && b < 1) {
      result.onLine2 = true;
    }
    // if line1 and line2 are segments, they intersect if both of the above are true

    return result;
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  getUserDetails() {
    const userId = this.localService.getUserId();
    this.userService.getUserDetails(userId).subscribe(details => {
      this.userPersonalInfo = details['user'].personalInfo;
    })
  }

  // clearing the 5 mins interval. 
  clearAutoRefresh() {
    if (this.refreshFor5MinsMasterPointsData) {
      clearInterval(this.refreshFor5MinsMasterPointsData);
    }
  }

  set5minsIntervalRefresh() {
    this.clearAutoRefresh();
    this.refreshFor5MinsMasterPointsData = setInterval(() => {
      if (this.editor.editor_mode != 'edit') {
        this.loadMasterViewPoints(this.editor.drawflow);
      }
    }, 300000); // setting time interval for 300000 miliseconds (5 mins);
  }

  /**
   * Loads the master view points for the selected plant data.
   * 
   * @param selectedPlantData - The selected plant data containing the drawflow home data.
   */
  loadMasterViewPoints(selectedPlantData) {
    let plantData = selectedPlantData.drawflow.Home.data;
    if (Object.keys(plantData).length) {
      let equipArr = Object.values(plantData).filter((equipData:any) => 
        equipData.equipType == "equip" || 
        this.checkHeaderPipeGroup(equipData) || 
        this.checkBypassPipeGroup(equipData)
      );
      equipArr.forEach((equipObj: any, index) => {
        this.getMasterPoint(equipObj).subscribe((masterViewPoints) => {
          if (!plantData[equipObj.id]?.masterPoints) {
            plantData[equipObj.id]['masterPoints'] = '';
          }
          plantData[equipObj.id]['masterPoints'] = masterViewPoints;
          if (index == equipArr.length - 1) {
            setTimeout(() => {
              this.addPipeAnimation();
            }, 1000);
          }
        });
      });
    }
  }

  /**
   * Handles the animation flow change for the plant builder component.
   * This method updates the classList of connection elements based on their animation direction.
   */
  animationFlowChange() {
    let allConnectionElements: any = document.querySelectorAll('.connection');
    // Adding animation to the pipes based on the status of the equipment.
    if (allConnectionElements.length) {
      allConnectionElements.forEach((connectionElement: any) => {
        let classList = Array.from<string>(connectionElement.classList);
        let dualColorArr = ['DTSupplyClass', 'DTReturnClass','DTBypassClass'];
        if(classList.every(value => !dualColorArr.includes(value))){
        // Adding animation to the Parent pipes based on the status of the equipment.
        if (classList.includes('parentSubEquip-pipe')) {
          let parentConnectionClass = classList.find(item => item.startsWith("parentSubEquip-pipe-"));
          let equipName = parentConnectionClass.split("parentSubEquip-pipe-")[1];
          if (this.editor.isForwardEquipment(equipName)) {
            if (this.editor.isLeftToRightAnimationPipe(classList) != undefined) {
              connectionElement.classList.add("connection-right-to-left");
            } else {
              connectionElement.classList.add("connection-right-to-left");
            }
          } else {
            connectionElement.classList.add("connection-right-to-left");
          }
        }
        else if (classList.includes('bypassConnection-pipe')) { // Adding animation to the bypass pipes based on the status of the equipment.
          connectionElement.classList.add("connection-right-to-left");
        } else { // Adding animation to the pipes based on the status of the equipment.
          let fromNode = classList.find(item => item.startsWith("node_out_node-"));
          let toNode = classList.find(item => item.startsWith("node_in_node-"));

          let fromNodeId = fromNode.split('node_out_node-')[1];
          let toNodeId = toNode.split('node_in_node-')[1];

          const fromNodePosition = this.editor.getNodeFromId(fromNodeId);
          const toNodePosition = this.editor.getNodeFromId(toNodeId);

          let direction;
          if ((fromNodePosition.pos_x < toNodePosition.pos_x) && fromNodePosition.pos_y < toNodePosition.pos_y) { // Right to Left
            direction = "right-to-left";
          } else { // Left to Right
            if (classList.includes('headerPipe-pipe')) { // Adding animation to the Header pipes based on the status of the equipment.
              if (this.editor.isLeftToRightAnimationPipe(classList) != undefined && !classList.includes("CDWReturnClass")) {
                direction = "right-to-left";
              } else {
                direction = "left-to-right";
              }
            } else { //
              direction = "left-to-right";
            }
          }

          const connectionElement1 = document.querySelector(`.connection.node_in_node-${toNodeId}.node_out_node-${fromNodeId}`);
          // Adding animation direction to the connection element.
          if (connectionElement1) {
            if (direction === "left-to-right") {
              connectionElement1.classList.add("connection-left-to-right");
            } else if (direction === "right-to-left") {
              connectionElement1.classList.add("connection-right-to-left");
            }
          }
        }
      }
      });
    }
  }

  /**
   * Adds pipe animation to the Active Equipment.
   */
  addPipeAnimation() {
    let plantData = this.editor.drawflow.drawflow.Home.data;
    let mapSubEquipData = {};
    let plantDataArr = Object.values(plantData);
    let equipMetaData = {
      pump: [],
      pumpON: [],
      pumpOFF: [],
      chillerPumpON: [],
      chillerPumpOFF: [],
      boilerPumpON: [],
      boilerPumpOFF: [],
      condensorPumpON: [],
      condensorPumpOFF: []
    };

    // Adding animation to the pipes based on the status of the equipment.
    plantData = plantDataArr.forEach((equipObj: any) => {
      if (equipObj.equipType == 'equip') {
        if (this.isPump(equipObj.childData.class)) {
          equipMetaData.pump.push(equipObj);

          // check pump status and add to the array 
          if (equipObj.childData.tags.includes("chilled")) { // Chilled Water Pump
            if (equipObj.equipStatus == 'equipOn') {
              equipMetaData.chillerPumpON.push(equipObj.pointId);
              equipMetaData.pumpON.push(equipObj.pointId);
            } else {
              equipMetaData.chillerPumpOFF.push(equipObj.pointId);
              equipMetaData.pumpOFF.push(equipObj.pointId);
            }
          } else if (equipObj.childData.tags.includes("hot")) { // Hot Water Pump
            if (equipObj.equipStatus == 'equipOn') {
              equipMetaData.boilerPumpON.push(equipObj.pointId);
              equipMetaData.pumpON.push(equipObj.pointId);
            } else {
              equipMetaData.boilerPumpOFF.push(equipObj.pointId);
              equipMetaData.pumpOFF.push(equipObj.pointId);
            }
          } else if (equipObj.childData.tags.includes("condenser")) { // Condenser Water Pump
            if (equipObj.equipStatus == 'equipOn') {
              equipMetaData.condensorPumpON.push(equipObj.pointId);
              equipMetaData.pumpON.push(equipObj.pointId);
            } else {
              equipMetaData.condensorPumpOFF.push(equipObj.pointId);
              equipMetaData.pumpOFF.push(equipObj.pointId);
            }
          }

          // setting the animation class to the pipe based on the status of the equipment.
          mapSubEquipData[equipObj.pointId] = equipObj.equipStatus == 'equipOn' ? 'animate' : '';
        } else {
          mapSubEquipData[equipObj.equipId] = equipObj.equipStatus == 'equipOn' ? 'animate' : '';
          let childEquipData = equipObj.childData;
          childEquipData.equipData.subEquips.forEach((subEquip: any) => {
            // setting the animation class to the pipe based on the status of the equipment.
            if (equipObj.equipStatus)
              mapSubEquipData[subEquip.equipId] = equipObj.equipStatus == 'equipOn' ? 'animate' : '';
          });
        }
      }
    });

    this.pumpCatogoryData = equipMetaData;
    this.editor.dispatch('changeDualTempColor', this.editor.drawflow);

    // get all the connection elements.
    let allConnectionElements: any = document.querySelectorAll('.connection');

    // Loop through all the connection elements and add the animation class to the pipe.
    if (allConnectionElements.length && equipMetaData?.pumpON?.length) {
      allConnectionElements.forEach((connectionElement: any) => {
        let classList: any = Array.from<string>(connectionElement.classList);

        // Adding animation to the pipes based on the status of the equipment.
        if ((classList.includes('CWSupplyClass') || classList.includes('CWReturnClass') || classList.includes('CWBypassClass')) && equipMetaData?.chillerPumpON?.length) {
          let hasPumpOFF = false;
          if (equipMetaData?.chillerPumpOFF?.length) {
            equipMetaData.chillerPumpOFF.forEach((pumpId) => {
              if (classList.includes(`groupPipe-${pumpId}`)) {
                hasPumpOFF = true;
              }
            });
          }
          if (!hasPumpOFF) { // Adding the animation class to the pipe
            connectionElement.classList.add('animate');
          } else { // Removing the animation class from the pipe.
            if (classList.includes('animate')) {
              connectionElement.classList.remove('animate');
            }
          }
        }

        if ((classList.includes('CDWReturnClass') || classList.includes('CDWSupplyClass') || classList.includes('CDWBypassClass')) && equipMetaData?.condensorPumpON?.length) {
          let hasPumpOFF = false;
          if (equipMetaData?.condensorPumpOFF?.length) {
            equipMetaData.condensorPumpOFF.forEach((pumpId) => {
              if (classList.includes(`groupPipe-${pumpId}`)) {
                hasPumpOFF = true;
              }
            });
          }
          if (!hasPumpOFF) { // Adding the animation class to the pipe
            connectionElement.classList.add('animate');
          } else {  // Removing the animation class from the pipe.
            if (classList.includes('animate')) {
              connectionElement.classList.remove('animate');
            }
          }
        }

        // Adding the animation class to Boiler pipe
        if ((classList.includes('boilerReturnClass') || classList.includes('boilerSupplyClass') || classList.includes('HotBypassClass')) && equipMetaData?.boilerPumpON?.length) {
          let hasPumpOFF = false;
          if (equipMetaData?.boilerPumpOFF?.length) {
            equipMetaData.boilerPumpOFF.forEach((pumpId) => {
              if (classList.includes(`groupPipe-${pumpId}`)) {
                hasPumpOFF = true;
              }
            });
          }
          if (!hasPumpOFF) {  // Adding the animation class to the pipe
            connectionElement.classList.add('animate');
          } else { // Removing the animation class from the pipe.
            if (classList.includes('animate')) {
              connectionElement.classList.remove('animate');
            }
          }
        }




        if (equipMetaData.chillerPumpON?.length) {
          if (classList.includes("DTSupplyClass")) {
            if (classList.includes('headerPipe-pipe')) { // Adding animation to the Header pipes based on the status of the equipment.
              connectionElement.classList.add("connection-left-to-right");
            } else {
              connectionElement.classList.add("connection-right-to-left");
            }
          }
          if (classList.includes("DTReturnClass") || classList.includes("DTBypassClass")) {
            if (classList.includes('headerPipe-pipe')) { // Adding animation to the Header pipes based on the status of the equipment.
              connectionElement.classList.add("connection-left-to-right");
            } else {
              connectionElement.classList.add("connection-right-to-left")
            }
          }
        } else if (equipMetaData.boilerPumpON?.length || equipMetaData.condensorPumpON?.length) {
          if (classList.includes("DTSupplyClass")) {
            if (classList.includes('headerPipe-pipe')) { // Adding animation to the Header pipes based on the status of the equipment.
              connectionElement.classList.add("connection-right-to-left")
            } else {
              connectionElement.classList.add("connection-left-to-right");
            }
          }
          if (classList.includes("DTReturnClass") || classList.includes("DTBypassClass")) {
            if (classList.includes('headerPipe-pipe')) { // Adding animation to the Header pipes based on the status of the equipment.
              connectionElement.classList.add("connection-right-to-left");
            } else {
              connectionElement.classList.add("connection-left-to-right");
            }
          }
        }

        if ((classList.includes('DTReturnClass') || classList.includes('DTSupplyClass') || classList.includes('DTBypassClass')) && equipMetaData?.pumpON?.length) {
          connectionElement.classList.add('animate');
        }
      });
      // Add Animation direction
      this.animationFlowChange();
    }
  }

  setBottomBarHeightConditionally() {
    let buidlingBottomContainer = document.getElementsByClassName('builder-bottom-container');
    if (this.view == 'My Plant' || this.view == 'My Plant Preview') {
      if (buidlingBottomContainer.length) {
        buidlingBottomContainer[0]['style'].height = '32px';
      }
    } else {
      if (buidlingBottomContainer.length) {
        buidlingBottomContainer[0]['style'].removeProperty('height');
      }
    }
  }

  exportPlant() {
    const dialogRef = this.dialog.open(ExportModalComponent, {
      width: '600px',
      panelClass: 'builder-modal-container',
      data: {
        fromPlant: true,
        selectedPlantData: this.selectedPlantData
      }
    });
    dialogRef.afterClosed().subscribe();
  }

  bottomBarSaved(status) {
    this.showBtnData.showSaveDraftBtn = true;
  }

  createNodeBetweenSubEquips(event) {
    if (this.ctrlKeyPressed && event.target.parentElement.nodeName === "svg") {
      const connectionEnd = event.target.parentNode.classList[1].split("node_in_node-")[1]; // 16
      // console.log(connectionEnd);
      const connectionStart = event.target.parentNode.classList[2].split("node_out_node-")[1]; // 15
      // console.log(connectionStart);
      // let outputClass = event.target.parentNode.classList[3]; // output_1
      // console.log(outputClass);
      // let inputClass = event.target.parentNode.classList[4]; // input_1

      const parentNodeData = this.editor.getNodeFromId(connectionEnd);
      let elementT: any = event.target;
      let groupPipeId = '';
      event.target.parentNode?.classList.forEach(_class => {
        if (_class.includes("groupPipe-")) {
          groupPipeId = _class;
        }
      });
      const nodeData: any = this.editor.getParentNodeData(connectionStart);
      const isParentSubEquip:any = this.getMainEquipBySubEquipID(groupPipeId.slice(10));
      if (this.isHeaderPipe(parentNodeData.childData.class) && this.isHeaderPipe(nodeData.childData.class)) {
        this.tempParentEquipData = nodeData.childData;
        this.selectedItem.pipeClass = this.getPipeClass(this.tempParentEquipData.class)
        if (this.isHeaderPipe(parentNodeData.childData.class) && !this.isBypassPipe(parentNodeData.childData.class)) {
          const extraClass = this.containsExtraClass(event.target.parentNode.classList.value)
          this.addNodeToDrawBoard(event.clientX, event.clientY);
          if (document.getElementsByClassName(`parentEquipId-${parentNodeData.equipId} GROUP`)?.length) {
            const groupNodeId = document.getElementsByClassName(`parentEquipId-${parentNodeData.equipId} GROUP`)[0].id.slice(5);
            this.editor.drawflow.drawflow.Home.data[groupNodeId].data.elements.push(this.selectedItemID);

            let nodesArray = ObjectUtil.deepCopy(this.editor.drawflow.drawflow.Home.data[Number(groupNodeId)].data.elements);
            const subEquipList = ObjectUtil.deepCopy(nodesArray);
            subEquipList.shift();
            subEquipList.pop();
            const subEquipIdx = subEquipList.findIndex(_node => _node == connectionEnd);

            let connectTwoObj = {
              equip: [this.selectedItem.class],
              pointId: "",
              subEquipType: 'byPassLine-connectTwo'
            }

            const indx = this.editor.drawflow.drawflow.Home.data[Number(groupNodeId)].data.elements.findIndex(_r => _r == connectionEnd);
            this.insertAt(this.editor.drawflow.drawflow.Home.data[Number(groupNodeId)].childData.equipData.subEquips[0].equips, subEquipIdx, connectTwoObj);

          }
          // {output_id: '15', input_id: '16', output_class: 'output_1', input_class: 'input_1'}
          this.editor.removeSingleConnection(connectionStart, connectionEnd, event.target.parentNode.classList[3], event.target.parentNode.classList[4]);
          // {output_id: '15', input_id: '17', output_class: 'output_1', input_class: 'input_1'},,
          this.editor.addConnection(Number(connectionStart), this.selectedItemID, event.target.parentNode.classList[3], 'input_1', extraClass, false, true, this.tempParentEquipData.equipId); // A(Equip_connection_out) -> B(selected_input)
          // {output_id: '17', input_id: '16', output_class: 'output_1', input_class: 'input_1'}
          this.editor.addConnection(this.selectedItemID, Number(connectionEnd), 'output_1', event.target.parentNode.classList[4], extraClass, false, true, this.tempParentEquipData.equipId); // B(selected_ouput) -> A (Equip_connection_in)

          if (nodeData.class != 'GROUP') {
            this.editor.drawflow.drawflow.Home.data[Number(this.selectedItemID)].parentPipeRef = nodeData.equipId;
          } else if (parentNodeData.class != 'GROUP') {
            this.editor.drawflow.drawflow.Home.data[Number(this.selectedItemID)].parentPipeRef = nodeData.equipId;
          }

          if (groupPipeId) {
            const groupPipeElement = document.querySelectorAll(`.${groupPipeId}`);
            groupPipeElement.forEach(pipeElement => {
              if (pipeElement.classList.contains("orangeClass")) {
                pipeElement.classList.remove("orangeClass");
              }
            });
          }
        }

      } else if (Object.keys(isParentSubEquip).length && event.target.parentNode.classList.value.indexOf("connector-pipe") == -1 ) { //Adding connector between subEquips
        this.selectedItem.class = "subEquip-connectTwo";
        this.tempParentEquipData = isParentSubEquip.childData;
        this.selectedItem.pipeClass = isParentSubEquip.nodePipeClass;
        const extraClass =  isParentSubEquip.nodePipeClass;
        this.addNodeToDrawBoard(event.clientX, event.clientY, true, false, false, groupPipeId.slice(10));
        if (document.getElementsByClassName(`parentEquipId-${parentNodeData.equipId} GROUP`)?.length) {
          const groupNodeId = document.getElementsByClassName(`parentEquipId-${parentNodeData.equipId} GROUP`)[0].id.slice(5);
          this.editor.drawflow.drawflow.Home.data[groupNodeId].data.elements.push(this.selectedItemID);

          let nodesArray = ObjectUtil.deepCopy(this.editor.drawflow.drawflow.Home.data[Number(groupNodeId)].data.elements);
          const subEquipList = ObjectUtil.deepCopy(nodesArray);
          subEquipList.shift();
          subEquipList.pop();
          const subEquipIdx = subEquipList.findIndex(_node => _node == connectionEnd);

          let connectTwoObj = {
            equip: [this.selectedItem.class],
            pointId: "",
            subEquipType: 'byPassLine-connectTwo'
          }

          const indx = this.editor.drawflow.drawflow.Home.data[Number(groupNodeId)].data.elements.findIndex(_r => _r == connectionEnd);
          this.insertAt(this.editor.drawflow.drawflow.Home.data[Number(groupNodeId)].childData.equipData.subEquips[0].equips, subEquipIdx, connectTwoObj);

        }
        this.editor.removeSingleConnection(connectionStart, connectionEnd, event.target.parentNode.classList[3], event.target.parentNode.classList[4]);
        this.editor.addConnection(Number(connectionStart), this.selectedItemID, event.target.parentNode.classList[3], 'input_1', extraClass, false, true, this.tempParentEquipData.equipId); // A(Equip_connection_out) -> B(selected_input)
        this.editor.addConnection(this.selectedItemID, Number(connectionEnd), 'output_1', event.target.parentNode.classList[4], extraClass, false, true, this.tempParentEquipData.equipId); // B(selected_ouput) -> A (Equip_connection_in)

        if (nodeData.class != 'GROUP') {
          this.editor.drawflow.drawflow.Home.data[Number(this.selectedItemID)].parentPipeRef = nodeData.equipId;
        } else if (parentNodeData.class != 'GROUP') {
          this.editor.drawflow.drawflow.Home.data[Number(this.selectedItemID)].parentPipeRef = nodeData.equipId;
        }

        if (groupPipeId) {
          const groupPipeElement = document.querySelectorAll(`.${groupPipeId}`);
          groupPipeElement.forEach(pipeElement => {
            if (pipeElement.classList.contains("orangeClass")) {
              pipeElement.classList.remove("orangeClass");
            }
          });
        }
        }
      }
  }

  getPlantStatus() {
    if ((!this.isFac || this.isFac && this.isBuilderUser) && !this.loginUserTypeData.isPublic) {
      this.apiService.getPlantStatus(this.selectedPlantData.chillerPlantId).subscribe(
        {
          next: (_res) => {
            this.activeDraftState = _res.isActiveDraft;
            if (this.editor.editor_mode === 'edit') {
              if (this.activeDraftState && Object.keys(this.selectedPlantData.plantJsonStr.drawflow.Home.data).length > 0) {
                this.showBtnData = {
                  showEditPlantBtn: false,
                  showSaveDraftBtn: false,
                  showDeleteDraftBtn: true,
                  showPublishBtn: true,
                  showExitPreviewBtn: false,
                  showPreviewBtn: true,
                  showExportBtn: false
                }
              } else if (!this.activeDraftState && Object.keys(this.selectedPlantData.plantJsonStr.drawflow.Home.data).length > 0) {
                this.showBtnData = {
                  showEditPlantBtn: false,
                  showSaveDraftBtn: false,
                  showDeleteDraftBtn: false,
                  showPublishBtn: false,
                  showExitPreviewBtn: false,
                  showPreviewBtn: true,
                  showExportBtn: false
                }
              } else {
                this.showBtnData = {
                  showEditPlantBtn: false,
                  showSaveDraftBtn: false,
                  showDeleteDraftBtn: false,
                  showPublishBtn: false,
                  showExitPreviewBtn: false,
                  showPreviewBtn: false,
                  showExportBtn: false
                }
              }
            }
          },
          error: () => {
            this.showToast('error', 'We were unable to get plant status');
          }
        }
      );
    }
  }

  checkAnyEquipsOrSubEquipsAreDeleted() {
    let foundDeletedEquip = false;
    this.disablePublishBtnIfAnyEquipsDeleted = false;
    Object.keys(this.selectedPlantData.plantJsonStr?.drawflow?.Home?.data).find(_key => {
      if (this.selectedPlantData.plantJsonStr?.drawflow?.Home?.data[_key]?.equipPointDeleted || this.selectedPlantData.plantJsonStr?.drawflow?.Home?.data[_key]?.equipDeleted || this.editor.drawflow.drawflow.Home.data[_key]?.subEquipRefDeleted) {
        foundDeletedEquip = true;
        return true;
      }
    });
    if (foundDeletedEquip) {
      this.disablePublishBtnIfAnyEquipsDeleted = true;
    }
  }

  generatePublishTooltipText() {
    let text = '';
    if (!this.enablePublishButton && this.disablePublishBtnIfAnyEquipsDeleted) {
      text = 'One or more connections are incomplete. Please complete all connections to publish. \n \n One or more equips are deleted, Please update the central plant.';
    } else if (this.enablePublishButton && this.disablePublishBtnIfAnyEquipsDeleted) {
      text = 'One or more equips are deleted, Please update the central plant.';
    } else if (!this.enablePublishButton && !this.disablePublishBtnIfAnyEquipsDeleted) {
      text = 'One or more connections are incomplete. Please complete all connections to publish.';
    }
    return text;
  }


  checkDualTempEquip(nodeData) {
    let dualTempEquip = ["dualTempBypassHeaderPipe", "dualTempReturnHeaderPipe", "dualTempSupplyHeaderPipe", "dualTempBuilding2Pipe"];
    if (nodeData != undefined && dualTempEquip.includes(nodeData?.childData?.class)) {
      return true;
    }
    return false;
  }

  async changeNeutralPipeColor(dualTempHeaderPipeArr) {
    await this.setNeutralPipeColor(dualTempHeaderPipeArr);
  }

  /***
     Set Pipe color for the dual temp pipe based on Supply/Return and active equip
  ***/
  async setNeutralPipeColor(nodeDataArr) {
    if (nodeDataArr.length === 0) {
      return;
    }

    const currentNodeData = nodeDataArr[0];

    if (currentNodeData?.connectedNodesData?.length && currentNodeData.childData) {
      currentNodeData.childData.connectedParentEquipId = currentNodeData.childData.equipId;
      currentNodeData.childData.connectedParentSubEquipPipeClass = this.getPipeClass(currentNodeData.childData.class);
      currentNodeData.childData.connectedParentSubEquipId = currentNodeData.childData.equipId;
      currentNodeData.childData.connectedParentClass = currentNodeData.childData.class;
      currentNodeData.childData.childData = currentNodeData.childData;
      currentNodeData?.connectedNodesData.push(currentNodeData.childData);
    }

    let parentEquipArr = [];
    let buildingEquipArr = [];

    currentNodeData.connectedNodesData.forEach(connectedNodeData => {
      if (connectedNodeData.class == "connectTwo") {
        connectedNodeData.connectedParentEquipId = connectedNodeData.equipId;
        connectedNodeData.connectedParentSubEquipPipeClass = connectedNodeData.nodePipeClass;
        connectedNodeData.connectedParentSubEquipId = connectedNodeData.groupPipeId;
        connectedNodeData.connectedParentClass = connectedNodeData.childData.class;
      }

      if (this.isDualTempEquip(connectedNodeData.connectedParentClass)) {
        buildingEquipArr.push(connectedNodeData)
      }

      if (this.isNonBuidlingParentEquip(connectedNodeData.connectedParentClass)) {
        parentEquipArr.push(connectedNodeData);
      }
    });


    let nodeArr = Object.values(this.editor.drawflow.drawflow.Home.data);
    
    let leadnlagArr = [];

    nodeArr.forEach((equip: any) => {
      if (equip.class == "GROUP" && equip.leadnLagConnection?.length) {
        leadnlagArr =  [...leadnlagArr, ...equip.leadnLagConnection]
      }
    });

    leadnlagArr = [...new Set(leadnlagArr)];

    if (leadnlagArr?.length) {
      let leadnLagEquipDataArr = leadnlagArr.map(equipId => {
        return this.getGroupEquipData(equipId);
      });

      let equipStatusArr = [];
      let getEquipStatusList = [];
      leadnLagEquipDataArr.forEach(equipData => {
        if (this.loginUserTypeData.isPublic) {
          let queryString = this.getTagsByEquipClass(equipData.class);
          queryString += ` and equipRef==@${this.getEquipIdForMasterView(equipData)}`;
          getEquipStatusList.push(this.apiService.getSharedAlertStatusONOrOFF(this.loginUserTypeData.linkShareUuid, queryString).pipe(catchError(err => of(err)), map(this.cpmService.stripHaystackTypeMapping)));
        } else{
          getEquipStatusList.push(this.getEquipPointByQuery(equipData).pipe(catchError(err => of(err)), map(this.cpmService.stripHaystackTypeMapping)));
        }
      })

      forkJoin(getEquipStatusList).subscribe((response: any) => {
        if (this.loginUserTypeData.isPublic) {
          response = response.map(resp => resp[0]);
          response = _.uniq(response);

          let ids = response.map(_pt => _pt?.pointId);
          ids = ids.filter(id => id != undefined);
          let equipStatusList = [];
          this.apiService.getSharedBulkWritablePointData(ids, this.loginUserTypeData.linkShareUuid).pipe(map(this.cpmService.stripHaystackTypeMapping)).subscribe(_res => {
            if (_res.rows.length) {
              equipStatusList = _res.rows;
            }
            this.apiService.getSharedCurrenthisReadMany(ids, this.loginUserTypeData.linkShareUuid).pipe(map(this.cpmService.stripHaystackTypeMapping)).subscribe(_res => {
              if (equipStatusList.length) {
                _res.rows.forEach(pointData => {
                  equipStatusList.push(pointData);
                });
              } else {
                equipStatusList = _res.rows;
              }
              this.changeDualTempEquipColor(buildingEquipArr, equipStatusList, nodeDataArr);
            });
          });
        } else {
          response = response.map(resp => resp.rows[0]);
          response = _.uniq(response);

          let ids = response.map(_pt => _pt?.id);
          ids = ids.filter(id => id != undefined);
          let equipStatusList = [];
          this.cpmService.getBulkWritablePointData(ids).pipe(map(this.cpmService.stripHaystackTypeMapping)).subscribe(_res => {
            if (_res.rows.length) {
              equipStatusList = _res.rows;
            }
            this.cpmService.getCurrenthisReadMany(ids).pipe(map(this.cpmService.stripHaystackTypeMapping)).subscribe(_res => {
              if (equipStatusList.length) {
                _res.rows.forEach(pointData => {
                  equipStatusList.push(pointData);
                });
              } else {
                equipStatusList = _res.rows;
              }
              this.changeDualTempEquipColor(buildingEquipArr, equipStatusList, nodeDataArr);
            });
          });
        }
      });
      await this.setNeutralPipeColor(nodeDataArr.slice(1));
    } else {
      let parentEquipDataArr = [];
      parentEquipArr.forEach(nodeData => {
        let parentNode: any = _.filter(nodeArr, { 'class': "connectTwo", 'groupPipeId': nodeData.connectedParentSubEquipId });
        if (parentNode != -1) {
          parentEquipDataArr.push(parentNode[0]);
        }
      });
      let equipStatusArr = [];
      let getEquipStatusList = [];
      parentEquipDataArr.forEach(equipData => {
        if (this.loginUserTypeData.isPublic) {
          let queryString = this.getTagsByEquipClass(equipData.childData.class);
          queryString += ` and equipRef==@${this.getEquipIdForMasterView(equipData)}`;
          getEquipStatusList.push(this.apiService.getSharedAlertStatusONOrOFF(this.loginUserTypeData.linkShareUuid, queryString).pipe(catchError(err => of(err)), map(this.cpmService.stripHaystackTypeMapping)));
        } else {
          getEquipStatusList.push(this.getEquipPointByQuery(equipData).pipe(catchError(err => of(err)), map(this.cpmService.stripHaystackTypeMapping)));
        }
      })


      forkJoin(getEquipStatusList).subscribe((response: any) => {
        if (this.loginUserTypeData.isPublic) {
          response = response.map(resp => resp[0]);
          response = _.uniq(response);

          let ids = response.map(_pt => _pt?.pointId);
          ids = ids.filter(id => id != undefined);
          let equipStatusList = [];
          this.apiService.getSharedBulkWritablePointData(ids, this.loginUserTypeData.linkShareUuid).pipe(map(this.cpmService.stripHaystackTypeMapping)).subscribe(_res => {
            if (_res.rows.length) {
              equipStatusList = _res.rows;
            }
            this.apiService.getSharedCurrenthisReadMany(ids, this.loginUserTypeData.linkShareUuid).pipe(map(this.cpmService.stripHaystackTypeMapping)).subscribe(_res => {
              if (equipStatusList.length) {
                _res.rows.forEach(pointData => {
                  equipStatusList.push(pointData);
                });
              } else {
                equipStatusList = _res.rows;
              }
              this.changeDualTempEquipColor(buildingEquipArr, equipStatusList, nodeDataArr);
            });
          });
        } else {
          response = response.map(resp => resp.rows[0]);
          response = _.uniq(response);

          let ids = response.map(_pt => _pt?.id);
          ids = ids.filter(id => id != undefined);
          let equipStatusList = [];
          this.cpmService.getBulkWritablePointData(ids).pipe(map(this.cpmService.stripHaystackTypeMapping)).subscribe(_res => {
            if (_res.rows.length) {
              equipStatusList = _res.rows;
            }
            this.cpmService.getCurrenthisReadMany(ids).pipe(map(this.cpmService.stripHaystackTypeMapping)).subscribe(_res => {
              if (equipStatusList.length) {
                _res.rows.forEach(pointData => {
                  equipStatusList.push(pointData);
                });
              } else {
                equipStatusList = _res.rows;
              }
              this.changeDualTempEquipColor(buildingEquipArr, equipStatusList, nodeDataArr);
            });
          });
        }
      });
      await this.setNeutralPipeColor(nodeDataArr.slice(1));
    }
  }

  changeDualTempColor(plantJson) {
    let dualTempHeaderPipeArr: any = [];
    let heatExchangerArr: any = [];
    let _this = this;
    Object.keys(plantJson?.drawflow?.Home?.data).forEach(function (key) {
      if (plantJson.drawflow.Home.data[key].class == "GROUP" && _this.isDualTempPipe(plantJson.drawflow.Home.data[key].childData.class)) {
        dualTempHeaderPipeArr.push(plantJson.drawflow.Home.data[key]);
      }
      if (plantJson.drawflow.Home.data[key].childData.class.includes('heatExchanger') && plantJson.drawflow.Home.data[key].name != 'GROUP' && plantJson.drawflow.Home.data[key].name == 'connectTwo') {
        heatExchangerArr.push(plantJson.drawflow.Home.data[key]);
      }
    });
    this.changeNeutralPipeColorForHeatExchanger(heatExchangerArr, dualTempHeaderPipeArr);
  }

  /***
    Set Pipe color for the dual temp pipe based on Supply/Return and active equip
    isPathBorder (optional) - true/false for checking the element is pathBorder or mainPath
  ***/
  getpipeColorForDualTemp(elementList, activeEquipColor, isPathBorder?) {
    elementList = Array.from(elementList);

    if (elementList.length) {
      let pipeClasList: any = ["DTSupplyClass", "DTReturnClass", "DTBypassClass"];
      let pipeColorObj;
      // Assigning the corresponding colorObject (pipeColorObj or patternColorObj) for mainPath and pathBorder elements
      if (activeEquipColor == 'chiller') {
        if (this.pumpCatogoryData.chillerPumpON.length) {
          pipeColorObj = isPathBorder ? this.equipConfiguration.pipeColorObj : this.equipConfiguration.patternColorObj;
        } else {
          pipeColorObj = this.equipConfiguration.pipeColorObj;
        }
      } else if (activeEquipColor == 'boiler') {
        if (this.pumpCatogoryData.boilerPumpON.length) {
          pipeColorObj = isPathBorder ? this.equipConfiguration.pipeColorObj : this.equipConfiguration.patternColorObj;
        } else {
          pipeColorObj = this.equipConfiguration.pipeColorObj;
        }
      } else if (activeEquipColor == 'coolingTower') {
        if (this.pumpCatogoryData.condensorPumpON.length) {
          pipeColorObj = isPathBorder ? this.equipConfiguration.pipeColorObj : this.equipConfiguration.patternColorObj;
        } else {
          pipeColorObj = this.equipConfiguration.pipeColorObj;
        }
      }

      let htmlElementMainPathClassType = Array.from(elementList[0]?.classList);
      let currentClass: any = htmlElementMainPathClassType.filter(className => pipeClasList.includes(className));
      switch (activeEquipColor) {
        case 'chiller':
          if (currentClass[0] == 'DTSupplyClass') {
            return pipeColorObj[this.toggleTheme].chillerSupplyObj;
          } else if (currentClass[0] == 'DTReturnClass') {
            return pipeColorObj[this.toggleTheme].chillerReturnObj;
          }
        case "boiler":
          if (currentClass[0] == 'DTSupplyClass') {
            return pipeColorObj[this.toggleTheme].boilerSupplyObj;
          } else if (currentClass[0] == 'DTReturnClass') {
            return pipeColorObj[this.toggleTheme].boilerReturnObj;
          }
        case "coolingTower":
          if (currentClass[0] == 'DTSupplyClass') {
            return pipeColorObj[this.toggleTheme].condenserSupplyObj;
          } else if (currentClass[0] == 'DTReturnClass') {
            return pipeColorObj[this.toggleTheme].condenserReturnObj;
          }
        default:
          return "";
      }
    } else {
      return '';
    }
  }


  isBtuMeterSubEquip(dataNode) {
    if (dataNode?.childData?.class == "btuMeter" && dataNode?.class != "btuMeter") {
      return true;
    }
    return false;
  }

  /***
    Add pipe style to the group-pipe class based on the active equip
  ***/
  changeDualTempEquipColor(dualTempEquipArr, equipStatusList, nodeDataArr) {
    let activeEquip = equipStatusList.filter(equipData => equipData?.data[0]?.val == 1);
    let activeClass;
    let pipeClasList: any = ["DTSupplyClass", "DTReturnClass", "DTBypassClass"];
    let activeEquipType;
    if (activeEquip.length == 1) {
      let tags = Object.keys(activeEquip[0]);
      if (tags.includes('chiller')) {
        activeEquipType = 'chiller';
      } else if (tags.includes('boiler')) {
        activeEquipType = 'boiler';
      } else if (tags.includes('coolingTower')) {
        activeEquipType = 'coolingTower';
      }
      let groupPipeIdArr = [];
      dualTempEquipArr.forEach(equipData => {
        if (equipData.groupPipeId) {
          groupPipeIdArr.push(equipData.groupPipeId);
        } else if (equipData.parentPipeRef) {
          groupPipeIdArr.push(equipData.parentPipeRef);
        } else {
          groupPipeIdArr.push(equipData.equipId);
        }
      });
      groupPipeIdArr = _.uniq(groupPipeIdArr);

      groupPipeIdArr.forEach(equipData => {
        if (equipData != '') {
          let htmlElementMainPathElement: any = document.querySelectorAll(`.groupPipe-${equipData}`);
          let htmlElementMainPathColor = this.getpipeColorForDualTemp(htmlElementMainPathElement, activeEquipType);
          htmlElementMainPathElement.forEach(element => {
            let classList: any = Array.from<string>(element.classList);
            if (activeEquipType == 'chiller') {
              if (classList.includes("DTSupplyClass")) {
                if (classList.includes('headerPipe-pipe')) { // Adding animation to the Header pipes based on the status of the equipment.
                  element.classList.add("connection-left-to-right");
                } else {
                  element.classList.add("connection-right-to-left");
                }
              }
              if (classList.includes("DTReturnClass")) {
                if (classList.includes('headerPipe-pipe')) { // Adding animation to the Header pipes based on the status of the equipment.
                  element.classList.add("connection-left-to-right");
                } else {
                  element.classList.add("connection-right-to-left")
                }
              }
            } else if (activeEquipType == 'boiler' || activeEquipType == 'coolingTower') {
              if (classList.includes("DTSupplyClass")) {
                if (classList.includes('headerPipe-pipe')) { // Adding animation to the Header pipes based on the status of the equipment.
                  element.classList.add("connection-right-to-left")
                } else {
                  element.classList.add("connection-left-to-right");
                }
              }
              if (classList.includes("DTReturnClass")) {
                if (classList.includes('headerPipe-pipe')) { // Adding animation to the Header pipes based on the status of the equipment.
                  element.classList.add("connection-right-to-left");
                } else {
                  element.classList.add("connection-left-to-right");
                }
              }
            }
          });
          let htmlElementMainPath: any = document.querySelectorAll(`.groupPipe-${equipData} .main-path`);
          htmlElementMainPath = Array.from(htmlElementMainPath);
          if (htmlElementMainPath.length) {
            htmlElementMainPath.forEach(element => {
              element.style.stroke = htmlElementMainPathColor;
            });
          }

          let htmlElementMainPathBorderElement: any = document.querySelectorAll(`.groupPipe-${equipData}`);
          let htmlElementMainPathBorderColor = this.getpipeColorForDualTemp(htmlElementMainPathBorderElement, activeEquipType, true);
          let htmlElementPathBorder: any = document.querySelectorAll(`.groupPipe-${equipData} .pathBorder`);
          htmlElementPathBorder = Array.from(htmlElementPathBorder);
          if (htmlElementPathBorder.length) {
            htmlElementPathBorder.forEach(element => {
              element.style.stroke = htmlElementMainPathBorderColor;
            });
          }
        }
      });
      if (nodeDataArr.length == 1) {
        setTimeout(() => {
          document.getElementById("canvas").style.display = "block";
          document.getElementById("loader").style.display = "none";
        }, 100);
      }
    }
  }

  CheckEquipsOrSubEquipsDeleted(equipInCanvas) {
    const self = this;
    equipInCanvas.forEach(_data => {
      let notFoundEquipRef = false; // If any sensor is deleted.
      let isEquipPointDeletd = false; // If any equips is deleted.
      let isSensorEquipPointDeleted = false; // If any subequipRef is deleted.
      const plantJsonEachData = this.selectedPlantData.plantJsonStr?.drawflow?.Home?.data[_data.key];
      if (_data.type == 'equip' || (self.isHeaderPipe(_data.class))) {
        // If type is equip or headerpipe Equip and equipid not found on nodes array set notFoundEquipRef to true.
        notFoundEquipRef = !self.nodes.map(_node => _node.equipId).includes(_data.equipId);
      }
      if (!notFoundEquipRef) {
        // If notFoundEquipRef is false and type is not equip check for subequipPiperef or PointRef is not found, set isEquipPointDeletd to true.
        if (_data.type != 'equip') {
          const foundParentEquip = self.nodes.find(_node => _node.equipId == _data.equipId);
          if (foundParentEquip && _data.subEquipPipeRef) {
            isSensorEquipPointDeleted = !foundParentEquip.subEquips.map(_subEquip => _subEquip.equipId).includes(_data.subEquipPipeRef);
            if (_data?.equipPointId && !isSensorEquipPointDeleted) {
              isEquipPointDeletd = ![].concat.apply([], foundParentEquip.subEquips.map(_subEquip => _subEquip.equips.map(_equip => _equip.pointId))).includes(_data?.equipPointId);
            }
          }
        }
      }
      // Set subEquipRefDeleted || equipDeleted || equipPointDeleted true conditionally.
      if (isSensorEquipPointDeleted && plantJsonEachData.name != "GROUP" && plantJsonEachData.name != "connectTwo") {
        this.selectedPlantData.plantJsonStr.drawflow.Home.data[_data.key]['subEquipRefDeleted'] = true;
      }
      if (notFoundEquipRef) {
        if (this.isHeaderPipe(_data.class) && plantJsonEachData.html.includes("pipeArrow.svg")) {
          this.selectedPlantData.plantJsonStr.drawflow.Home.data[_data.key]['equipDeleted'] = true;
        } else {
          if (plantJsonEachData.name != "connectTwo" && plantJsonEachData.name != "GROUP") {
            this.selectedPlantData.plantJsonStr.drawflow.Home.data[_data.key]['equipDeleted'] = true;
          }
        }
      } else if (isEquipPointDeletd && plantJsonEachData.name != "GROUP" && plantJsonEachData.name != "connectTwo") {
        this.selectedPlantData.plantJsonStr.drawflow.Home.data[_data.key]['equipPointDeleted'] = true;
      }
      // Adding isNodeDeleted property key to node data to prevent moving the node on canvas.
      if (notFoundEquipRef || isEquipPointDeletd || isSensorEquipPointDeleted) {
        this.selectedPlantData.plantJsonStr.drawflow.Home.data[_data.key]['isNodeDeleted'] = true;
      }
    });
  }

  setWarningIconToAllTheEquipsIfEquipListIsEmpty(equipInCanvas) {
    equipInCanvas.forEach(_data => {
      const plantJsonEachData = this.selectedPlantData.plantJsonStr?.drawflow?.Home?.data[_data.key];
      if (this.isHeaderPipe(_data.class) && plantJsonEachData.html.includes("pipeArrow.svg")) {
        this.selectedPlantData.plantJsonStr.drawflow.Home.data[_data.key]['equipDeleted'] = true;
      } else {
        if (plantJsonEachData.name != "connectTwo" && plantJsonEachData.name != "GROUP") {
          this.selectedPlantData.plantJsonStr.drawflow.Home.data[_data.key]['equipDeleted'] = true;
        }
      }
      // Adding isNodeDeleted property key to node data to prevent moving the node on canvas.
      this.selectedPlantData.plantJsonStr.drawflow.Home.data[_data.key]['isNodeDeleted'] = true;
    });
  }

  sortHeatExchangerSubEquips(equiData) {
    let sortArr = [];
    if (equiData.class == 'heatExchanger_backward' || equiData.class == 'heatExchanger_forward') {
      sortArr = equiData?.equipData?.subEquips.map(_subEquip => {
        if (_subEquip.tags.includes('primaryLoop') && _subEquip.tags.includes('leaving')) {
          _subEquip['sort'] = 1;
        } else if (_subEquip.tags.includes('primaryLoop') && _subEquip.tags.includes('entering')) {
          _subEquip['sort'] = 2;
        } else if (_subEquip.tags.includes('secondaryLoop') && _subEquip.tags.includes('leaving')) {
          _subEquip['sort'] = 3;
        } else if (_subEquip.tags.includes('secondaryLoop') && _subEquip.tags.includes('entering')) {
          _subEquip['sort'] = 4;
        }
        return _subEquip;
      }).sort((a,b) => a.sort - b.sort);
    }
    return sortArr;
  }

  createBackwardEquipsOnCanvasDrop(event, index, subIndex, subEquipsData, subEquip, nodeId, sensorNodeId, pipeClass) {
    this.addNodeToDrawBoard(event.x + 70 + (index * 10), event.y + 30 + (index * 5), false, false, false, subEquipsData.equipId, subEquip.pointId);
    document.getElementById(`node-${sensorNodeId}`).style.left = 'auto';
    document.getElementById(`node-${sensorNodeId}`).style.top = 'auto';
    let calcLeft = `${(document.getElementById(`node-${nodeId}`).getElementsByClassName(`output output_${index}`)[0].getBoundingClientRect().x  - document.getElementById(`node-${sensorNodeId}`).getElementsByClassName(`input input_${1}`)[0].getBoundingClientRect().x) / this.editor.zoom }`;
    let calcTop = `${(document.getElementById(`node-${nodeId}`).getElementsByClassName(`output output_${index}`)[0].getBoundingClientRect().y  - document.getElementById(`node-${sensorNodeId}`).getElementsByClassName(`input input_${1}`)[0].getBoundingClientRect().y) / this.editor.zoom }`;
    if (subIndex != 0) {
      calcLeft = `${(document.getElementById(`node-${this.selectedItemID - 1}`).getElementsByClassName(`output output_${1}`)[0].getBoundingClientRect().x  - document.getElementById(`node-${sensorNodeId}`).getElementsByClassName(`input input_${1}`)[0].getBoundingClientRect().x) / this.editor.zoom }`;
      calcTop = `${(document.getElementById(`node-${this.selectedItemID - 1}`).getElementsByClassName(`output output_${1}`)[0].getBoundingClientRect().y  - document.getElementById(`node-${sensorNodeId}`).getElementsByClassName(`input input_${1}`)[0].getBoundingClientRect().y) / this.editor.zoom }`;
    }
    let offsetLeft;
    let offsetTop;
    if(subIndex == 0) {
      if(this.tempParentEquipData.class == 'airCooledChiller') {
        offsetLeft = 200;
        offsetTop = 106;
      } else {
        offsetLeft = 120;
        offsetTop = 64;
      }
    } else {
      offsetLeft = 430;
      offsetTop = 224;
    }
    document.getElementById(`node-${sensorNodeId}`).style.left = (Number(calcLeft) + offsetLeft + (index * 10)) + 'px';
    document.getElementById(`node-${sensorNodeId}`).style.top = (Number(calcTop) + offsetTop + (index * 5)) + 'px';
    this.editor.drawflow.drawflow.Home.data[sensorNodeId].pos_x = (Number(calcLeft) + offsetLeft + (index * 10));
    this.editor.drawflow.drawflow.Home.data[sensorNodeId].pos_y = (Number(calcTop) + offsetTop + (index * 5));
    if (subIndex == 0) {
      this.editor.addConnection(nodeId, sensorNodeId, `output_${index}`, `input_${1}`, pipeClass, false, true, subEquipsData.equipId);
    } else {
      this.editor.addConnection(this.selectedItemID - 1, sensorNodeId, `output_${1}`, `input_${1}`, pipeClass, false, true, subEquipsData.equipId);
    }
  }

  createForwardEquipsOnCanvasDrop(event, index, subIndex, nodeId, sensorNodeId, subEquipsData, subEquip, subEquipName, pipeClass) {
    this.addNodeToDrawBoard(event.x + 70 + (index * 10), event.y + 70 + (index * 10), false, false, false, subEquipsData.equipId, subEquip.pointId );
    document.getElementById(`node-${sensorNodeId}`).style.left = 'auto';
    document.getElementById(`node-${sensorNodeId}`).style.top = 'auto';
    let calcLeft = `${(document.getElementById(`node-${nodeId}`).getElementsByClassName(`input input_${index}`)[0].getBoundingClientRect().x  - document.getElementById(`node-${sensorNodeId}`).getElementsByClassName(`input input_${1}`)[0].getBoundingClientRect().x) / this.editor.zoom }`;
    let calcTop = `${(document.getElementById(`node-${nodeId}`).getElementsByClassName(`input input_${index}`)[0].getBoundingClientRect().y  - document.getElementById(`node-${sensorNodeId}`).getElementsByClassName(`input input_${1}`)[0].getBoundingClientRect().y) / this.editor.zoom }`;
    if (subIndex != 0) {
      calcLeft = `${(document.getElementById(`node-${this.selectedItemID - 1}`).getElementsByClassName(`input input_${1}`)[0].getBoundingClientRect().x  - document.getElementById(`node-${sensorNodeId}`).getElementsByClassName(`input input_${1}`)[0].getBoundingClientRect().x) / this.editor.zoom }`;
      calcTop = `${(document.getElementById(`node-${this.selectedItemID - 1}`).getElementsByClassName(`input input_${1}`)[0].getBoundingClientRect().y  - document.getElementById(`node-${sensorNodeId}`).getElementsByClassName(`input input_${1}`)[0].getBoundingClientRect().y) / this.editor.zoom }`;
    }
    this.updateForwardSensorsPostions(subEquipName, sensorNodeId, calcLeft, calcTop, subIndex);
    if (subIndex == 0) {
      this.editor.addConnection(sensorNodeId, nodeId, `output_1`, `input_${index}`, pipeClass, false, true, subEquipsData.equipId);
    } else {
      this.editor.addConnection(this.selectedItemID - 1, sensorNodeId, `output_1`, `input_${1}`, pipeClass, false, true, subEquipsData.equipId);
    }
  }

  /**
   * Updates the positions of the forward sensors based on the provided parameters.
   */
  updateForwardSensorsPostions(subEquipName, sensorNodeId, calcLeft, calcTop, subIndex) {

    let offsetLeft;
    let offsetTop;
    if (subIndex == 0) {
      if(this.tempParentEquipData.class == 'coolingTowerFourFan') {
        offsetLeft = 380;
        offsetTop = 210;
      } else if (this.tempParentEquipData.class == 'heatExchanger_forward') {
        offsetLeft = 230;
        offsetTop = 126;
      } else {
        offsetLeft = 230;
        offsetTop = 129;
      }
    } else {
      if (subEquipName == 'valveActuator_forward') {
        offsetLeft = 400;
        offsetTop = 220;
      } else if ((subEquipName == 'temperatureSensor_forward' || subEquipName == 'wellTemperature_forward') && this.tempParentEquipData.class == 'coolingTowerFourFan') {
        offsetLeft = 410;
        offsetTop = 222;
      } else if ((subEquipName == 'temperatureSensor_forward' || subEquipName == 'wellTemperature_forward') && this.tempParentEquipData.class != 'coolingTowerFourFan') {
        offsetLeft = 360;
        offsetTop = 182;
      } else if (subEquipName == 'pressureSensor_forward' || subEquipName == 'diffPressureSensor_forward') {
        offsetLeft = 470;
        offsetTop = 246;
      } else if (subEquipName == 'threeWayValveDivert_forward' || subEquipName == 'threeWayValvemixing_forward') {
        offsetLeft = 470;
        offsetTop = 236;
      } else if (subEquipName == 'flowSensor_forward') {
        offsetLeft = 400;
        offsetTop = 220;
      } else {
        offsetLeft = 511;
        offsetTop = 270;
      }
    }
    document.getElementById(`node-${sensorNodeId}`).style.left = (Number(calcLeft) - offsetLeft) + 'px';
    document.getElementById(`node-${sensorNodeId}`).style.top = (Number(calcTop) + offsetTop) + 'px';
    this.editor.drawflow.drawflow.Home.data[sensorNodeId].pos_x = (Number(calcLeft) - offsetLeft);
    this.editor.drawflow.drawflow.Home.data[sensorNodeId].pos_y = (Number(calcTop) + offsetTop);
  }

  /**
   * Creates a heat exchanger on canvas drop.
   */
  createHeatExchangerOnCanvasDrop(event, index, subIndex, nodeId, sensorNodeId, subEquipsData, subEquip, subEquipName, pipeClass) {
    if (this.tempParentEquipData.class == 'heatExchanger_backward') {
      // Heat Exchanger has 2 inputs and 2 outputs.
      // Index 1 and 2 will be inputs and 3 and 4 will be ouputs.
      this.createHeatExchangerBackWardPosition(event, index, subIndex, nodeId, sensorNodeId, subEquipsData, subEquip, subEquipName, pipeClass);
    } else {
      this.createHeatExchangerForWardPosition(event, index, subIndex, nodeId, sensorNodeId, subEquipsData, subEquip, subEquipName, pipeClass)
    }
  }

  /**
   * Creates a heat exchanger in a backward position.
   */
  createHeatExchangerBackWardPosition(event, index, subIndex, nodeId, sensorNodeId, subEquipsData, subEquip, subEquipName, pipeClass) {
    if (index == 1 || index == 2) {
      this.addNodeToDrawBoard(event.x - 70 - (index * 10), event.y - 30 - (index * 5), false, false, false, subEquipsData.equipId, subEquip.pointId);
      document.getElementById(`node-${sensorNodeId}`).style.left = 'auto';
      document.getElementById(`node-${sensorNodeId}`).style.top = 'auto';
      let calcLeft = `${(document.getElementById(`node-${nodeId}`).getElementsByClassName(`input input_${index}`)[0].getBoundingClientRect().x  - document.getElementById(`node-${sensorNodeId}`).getElementsByClassName(`input input_${1}`)[0].getBoundingClientRect().x) / this.editor.zoom }`;
      let calcTop = `${(document.getElementById(`node-${nodeId}`).getElementsByClassName(`input input_${index}`)[0].getBoundingClientRect().y  - document.getElementById(`node-${sensorNodeId}`).getElementsByClassName(`input input_${1}`)[0].getBoundingClientRect().y) / this.editor.zoom }`;
      if (subIndex != 0) {
        calcLeft = `${(document.getElementById(`node-${this.selectedItemID - 1}`).getElementsByClassName(`input input_${1}`)[0].getBoundingClientRect().x  - document.getElementById(`node-${sensorNodeId}`).getElementsByClassName(`input input_${1}`)[0].getBoundingClientRect().x) / this.editor.zoom }`;
        calcTop = `${(document.getElementById(`node-${this.selectedItemID - 1}`).getElementsByClassName(`input input_${1}`)[0].getBoundingClientRect().y  - document.getElementById(`node-${sensorNodeId}`).getElementsByClassName(`input input_${1}`)[0].getBoundingClientRect().y) / this.editor.zoom }`;
      }
      let offsetLeft;
      let offsetTop;
      if(subIndex == 0) {
        offsetLeft = 215;
        offsetTop = 110;
      } else {
        offsetLeft = 430;
        offsetTop = 224;
      }
      document.getElementById(`node-${sensorNodeId}`).style.left = (Number(calcLeft) - offsetLeft - (index * 10)) + 'px';
      document.getElementById(`node-${sensorNodeId}`).style.top = (Number(calcTop) - offsetTop - (index * 5)) + 'px';
      this.editor.drawflow.drawflow.Home.data[sensorNodeId].pos_x = (Number(calcLeft) - offsetLeft - (index * 10));
      this.editor.drawflow.drawflow.Home.data[sensorNodeId].pos_y = (Number(calcTop) - offsetTop - (index * 5));
      if (subIndex == 0) {
        this.editor.addConnection(sensorNodeId, nodeId, `output_${1}`, `input_${index}`, pipeClass, false, true, subEquipsData.equipId);
      } else {
        this.editor.addConnection(sensorNodeId, this.selectedItemID - 1, `output_${1}`, `input_${1}`, pipeClass, false, true, subEquipsData.equipId);
      }
    } else {
      // we are doing minus 2 in index. because outputs will be output_1 and output_2.
      index = index - 2;
      this.addNodeToDrawBoard(event.x + 70 + (index * 10), event.y + 70 + (index * 10), false, false, false, subEquipsData.equipId, subEquip.pointId );
      document.getElementById(`node-${sensorNodeId}`).style.left = 'auto';
      document.getElementById(`node-${sensorNodeId}`).style.top = 'auto';
      let calcLeft = `${(document.getElementById(`node-${nodeId}`).getElementsByClassName(`output output_${index}`)[0].getBoundingClientRect().x  - document.getElementById(`node-${sensorNodeId}`).getElementsByClassName(`output output_${1}`)[0].getBoundingClientRect().x) / this.editor.zoom }`;
      let calcTop = `${(document.getElementById(`node-${nodeId}`).getElementsByClassName(`output output_${index}`)[0].getBoundingClientRect().y  - document.getElementById(`node-${sensorNodeId}`).getElementsByClassName(`output output_${1}`)[0].getBoundingClientRect().y) / this.editor.zoom }`;
      if (subIndex != 0) {
        calcLeft = `${(document.getElementById(`node-${this.selectedItemID - 1}`).getElementsByClassName(`output output_${1}`)[0].getBoundingClientRect().x  - document.getElementById(`node-${sensorNodeId}`).getElementsByClassName(`output output_${1}`)[0].getBoundingClientRect().x) / this.editor.zoom }`;
        calcTop = `${(document.getElementById(`node-${this.selectedItemID - 1}`).getElementsByClassName(`output output_${1}`)[0].getBoundingClientRect().y  - document.getElementById(`node-${sensorNodeId}`).getElementsByClassName(`output output_${1}`)[0].getBoundingClientRect().y) / this.editor.zoom }`;
      }
      let offsetLeft;
      let offsetTop;
      if(subIndex == 0) {
        offsetLeft = 215;
        offsetTop = 115;
      } else {
        offsetLeft = 430;
        offsetTop = 224;
      }
      document.getElementById(`node-${sensorNodeId}`).style.left = (Number(calcLeft) + offsetLeft + (index * 10)) + 'px';
      document.getElementById(`node-${sensorNodeId}`).style.top = (Number(calcTop) + offsetTop + (index * 5)) + 'px';
      this.editor.drawflow.drawflow.Home.data[sensorNodeId].pos_x = (Number(calcLeft) + offsetLeft + (index * 10));
      this.editor.drawflow.drawflow.Home.data[sensorNodeId].pos_y = (Number(calcTop) + offsetTop + (index * 5));      
      if (subIndex == 0) {
        this.editor.addConnection(nodeId, sensorNodeId, `output_${index}`, `input_1`, pipeClass, false, true, subEquipsData.equipId);
      } else {
        this.editor.addConnection(sensorNodeId, this.selectedItemID - 1, `output_${1}`, `input_1`, pipeClass, false, true, subEquipsData.equipId);
      }
    }
  }

  /**
   * Creates a heat exchanger in a forward position.
   */
  createHeatExchangerForWardPosition(event, index, subIndex, nodeId, sensorNodeId, subEquipsData, subEquip, subEquipName, pipeClass) {
    if (index == 1 || index == 2) {
      this.addNodeToDrawBoard(event.x - 70 - (index * 10), event.y - 70 - (index * 10), false, false, false, subEquipsData.equipId, subEquip.pointId );
      document.getElementById(`node-${sensorNodeId}`).style.left = 'auto';
      document.getElementById(`node-${sensorNodeId}`).style.top = 'auto';
      let calcLeft = `${(document.getElementById(`node-${nodeId}`).getElementsByClassName(`output output_${index}`)[0].getBoundingClientRect().x  - document.getElementById(`node-${sensorNodeId}`).getElementsByClassName(`output output_${1}`)[0].getBoundingClientRect().x) / this.editor.zoom }`;
      let calcTop = `${(document.getElementById(`node-${nodeId}`).getElementsByClassName(`output output_${index}`)[0].getBoundingClientRect().y  - document.getElementById(`node-${sensorNodeId}`).getElementsByClassName(`output output_${1}`)[0].getBoundingClientRect().y) / this.editor.zoom }`;
      if (subIndex != 0) {
        calcLeft = `${(document.getElementById(`node-${this.selectedItemID - 1}`).getElementsByClassName(`output output_${1}`)[0].getBoundingClientRect().x  - document.getElementById(`node-${sensorNodeId}`).getElementsByClassName(`output output_${1}`)[0].getBoundingClientRect().x) / this.editor.zoom }`;
        calcTop = `${(document.getElementById(`node-${this.selectedItemID - 1}`).getElementsByClassName(`output output_${1}`)[0].getBoundingClientRect().y  - document.getElementById(`node-${sensorNodeId}`).getElementsByClassName(`output output_${1}`)[0].getBoundingClientRect().y) / this.editor.zoom }`;
      }
      let offsetLeft;
      let offsetTop;
      if(subIndex == 0) {
        offsetLeft = 190;
        offsetTop = 90;
      } else {
        offsetLeft = 220;
        offsetTop = 112;
      }
      document.getElementById(`node-${sensorNodeId}`).style.left = (Number(calcLeft) + offsetLeft) + 'px';
      document.getElementById(`node-${sensorNodeId}`).style.top = (Number(calcTop) - offsetTop) + 'px';
      this.editor.drawflow.drawflow.Home.data[sensorNodeId].pos_x = (Number(calcLeft) + offsetLeft);
      this.editor.drawflow.drawflow.Home.data[sensorNodeId].pos_y = (Number(calcTop) - offsetTop);
      if (subIndex == 0) {
        this.editor.addConnection(nodeId, sensorNodeId, `output_${index}`, `input_${1}`, pipeClass, false, true, subEquipsData.equipId);
      } else {
        this.editor.addConnection(this.selectedItemID - 1, sensorNodeId, `output_1`, `input_${1}`, pipeClass, false, true, subEquipsData.equipId);
      }
    } else {
      // we are doing minus 2 in index. because outputs will be output_1 and output_2.
      index = index - 2;
      this.addNodeToDrawBoard(event.x + 70 + (index * 10), event.y + 70 + (index * 10), false, false, false, subEquipsData.equipId, subEquip.pointId );
      let calcLeft = `${(document.getElementById(`node-${nodeId}`).getElementsByClassName(`input input_${index}`)[0].getBoundingClientRect().x  - document.getElementById(`node-${sensorNodeId}`).getElementsByClassName(`input input_${1}`)[0].getBoundingClientRect().x) / this.editor.zoom }`;
      let calcTop = `${(document.getElementById(`node-${nodeId}`).getElementsByClassName(`input input_${index}`)[0].getBoundingClientRect().y  - document.getElementById(`node-${sensorNodeId}`).getElementsByClassName(`input input_${1}`)[0].getBoundingClientRect().y) / this.editor.zoom }`;
      if (subIndex != 0) {
        calcLeft = `${(document.getElementById(`node-${this.selectedItemID - 1}`).getElementsByClassName(`input input_${1}`)[0].getBoundingClientRect().x  - document.getElementById(`node-${sensorNodeId}`).getElementsByClassName(`input input_${1}`)[0].getBoundingClientRect().x) / this.editor.zoom }`;
        calcTop = `${(document.getElementById(`node-${this.selectedItemID - 1}`).getElementsByClassName(`input input_${1}`)[0].getBoundingClientRect().y  - document.getElementById(`node-${sensorNodeId}`).getElementsByClassName(`input input_${1}`)[0].getBoundingClientRect().y) / this.editor.zoom }`;
      }
      this.updateForwardSensorsPostions(subEquipName, sensorNodeId, calcLeft, calcTop, subIndex);
      if (subIndex == 0) {
        this.editor.addConnection(sensorNodeId, nodeId, `output_${1}`, `input_${index}`, pipeClass, false, true, subEquipsData.equipId);
      } else {
        this.editor.addConnection(this.selectedItemID - 1, sensorNodeId, `output_1`, `input_${1}`, pipeClass, false, true, subEquipsData.equipId);
      }
    }
  }

  // Creates a heat exchanger in a forward position without subequips.
  createHeatExchangerWhenNoSubEquipsForwardPosition(event, index, subEquipsData, pipeClass) {
    if (index == 1 || index == 2) {
      this.addNodeToDrawBoard(event.x - 70 - (index * 10), event.y - 70 - (index * 10), false, false, false, subEquipsData.equipId);
      document.getElementById(`node-${this.selectedItemID}`).style.left = 'auto';
      document.getElementById(`node-${this.selectedItemID}`).style.top = 'auto';
      let calcLeft = `${(document.getElementById(`node-${this.createdParentEquipId}`).getElementsByClassName(`output output_${index}`)[0].getBoundingClientRect().x  - document.getElementById(`node-${this.selectedItemID}`).getElementsByClassName(`output output_${1}`)[0].getBoundingClientRect().x) / this.editor.zoom }`;
      let calcTop = `${(document.getElementById(`node-${this.createdParentEquipId}`).getElementsByClassName(`output output_${index}`)[0].getBoundingClientRect().y  - document.getElementById(`node-${this.selectedItemID}`).getElementsByClassName(`output output_${1}`)[0].getBoundingClientRect().y) / this.editor.zoom }`;
      document.getElementById(`node-${this.selectedItemID}`).style.left = (Number(calcLeft) + 220) + 'px';
      document.getElementById(`node-${this.selectedItemID}`).style.top = (Number(calcTop) - 114) + 'px';
      this.editor.drawflow.drawflow.Home.data[this.selectedItemID].pos_x = (Number(calcLeft) + 220);
      this.editor.drawflow.drawflow.Home.data[this.selectedItemID].pos_y = (Number(calcTop) - 114);
      this.editor.addConnection(this.createdParentEquipId, this.selectedItemID, `output_${index}`, `input_${1}`, pipeClass, false, true, subEquipsData.equipId);
    } else {
      index = index - 2;
      this.addNodeToDrawBoard(event.x + 70 + (index * 10), event.y + 70 + (index * 10), false, false, false, subEquipsData.equipId);
      document.getElementById(`node-${this.selectedItemID}`).style.left = 'auto';
      document.getElementById(`node-${this.selectedItemID}`).style.top = 'auto';
      let calcLeft = `${(document.getElementById(`node-${this.createdParentEquipId}`).getElementsByClassName(`input input_${index}`)[0].getBoundingClientRect().x  - document.getElementById(`node-${this.selectedItemID}`).getElementsByClassName(`input input_${1}`)[0].getBoundingClientRect().x) / this.editor.zoom }`;
      let calcTop = `${(document.getElementById(`node-${this.createdParentEquipId}`).getElementsByClassName(`input input_${index}`)[0].getBoundingClientRect().y  - document.getElementById(`node-${this.selectedItemID}`).getElementsByClassName(`input input_${1}`)[0].getBoundingClientRect().y) / this.editor.zoom }`;
      document.getElementById(`node-${this.selectedItemID}`).style.left = (Number(calcLeft) - 200) + 'px';
      document.getElementById(`node-${this.selectedItemID}`).style.top = (Number(calcTop) + 104) + 'px';
      this.editor.drawflow.drawflow.Home.data[this.selectedItemID].pos_x = (Number(calcLeft) - 200);
      this.editor.drawflow.drawflow.Home.data[this.selectedItemID].pos_y = (Number(calcTop) + 104);
      this.editor.addConnection(this.selectedItemID, this.createdParentEquipId, `output_${1}`, `input_${index}`, pipeClass, false, true, subEquipsData.equipId);
    }
  }

  // Creates a heat exchanger in a backward position without subequips.
  createHeatExchangerWhenNoSubEquipsBackwardPosition(event, index, subEquipsData, pipeClass) {
    if (index == 1 || index == 2) {
    this.addNodeToDrawBoard(event.x - 70 - (index * 10), event.y - 30 - (index * 5), false, false, false, subEquipsData.equipId);
    document.getElementById(`node-${this.selectedItemID}`).style.left = 'auto';
    document.getElementById(`node-${this.selectedItemID}`).style.top = 'auto';
    const calcLeft = `${(document.getElementById(`node-${this.createdParentEquipId}`).getElementsByClassName(`output output_${index}`)[0].getBoundingClientRect().x - document.getElementById(`node-${this.selectedItemID}`).getElementsByClassName(`input input_${1}`)[0].getBoundingClientRect().x) / this.editor.zoom}`;
    const calcTop = `${(document.getElementById(`node-${this.createdParentEquipId}`).getElementsByClassName(`output output_${index}`)[0].getBoundingClientRect().y - document.getElementById(`node-${this.selectedItemID}`).getElementsByClassName(`input input_${1}`)[0].getBoundingClientRect().y) / this.editor.zoom}`;
    let offsetLeft;
    let offsetTop;
    if (index == 1) {
      offsetLeft = 500;
      offsetTop = 296;
    } else {
      offsetLeft = 500;
      offsetTop = 305;
    }
    document.getElementById(`node-${this.selectedItemID}`).style.left = (Number(calcLeft) - offsetLeft) + 'px';
    document.getElementById(`node-${this.selectedItemID}`).style.top = (Number(calcTop) - offsetTop) + 'px';
    this.editor.drawflow.drawflow.Home.data[this.selectedItemID].pos_x = (Number(calcLeft) - offsetLeft);
    this.editor.drawflow.drawflow.Home.data[this.selectedItemID].pos_y = (Number(calcTop) - offsetTop);
    this.editor.addConnection( this.selectedItemID, this.createdParentEquipId, `output_${1}`, `input_${index}`, pipeClass, false, true, subEquipsData.equipId);
    } else {
    index = index - 2;
    this.addNodeToDrawBoard(event.x + 70 + (index * 10), event.y + 30 + (index * 5), false, false, false, subEquipsData.equipId);
    document.getElementById(`node-${this.selectedItemID}`).style.left = 'auto';
    document.getElementById(`node-${this.selectedItemID}`).style.top = 'auto';
    const calcLeft = `${(document.getElementById(`node-${this.createdParentEquipId}`).getElementsByClassName(`output output_${index}`)[0].getBoundingClientRect().x - document.getElementById(`node-${this.selectedItemID}`).getElementsByClassName(`input input_${1}`)[0].getBoundingClientRect().x) / this.editor.zoom}`;
    const calcTop = `${(document.getElementById(`node-${this.createdParentEquipId}`).getElementsByClassName(`output output_${index}`)[0].getBoundingClientRect().y - document.getElementById(`node-${this.selectedItemID}`).getElementsByClassName(`input input_${1}`)[0].getBoundingClientRect().y) / this.editor.zoom}`;
    document.getElementById(`node-${this.selectedItemID}`).style.left = (Number(calcLeft) + 200) + 'px';
    document.getElementById(`node-${this.selectedItemID}`).style.top = (Number(calcTop) + 105) + 'px';
    this.editor.drawflow.drawflow.Home.data[this.selectedItemID].pos_x = (Number(calcLeft) + 200);
    this.editor.drawflow.drawflow.Home.data[this.selectedItemID].pos_y = (Number(calcTop) + 105);
    this.editor.addConnection(this.createdParentEquipId, this.selectedItemID, `output_${index}`, `input_1`, pipeClass, false, true, subEquipsData.equipId);
    }
  }

  getPipeForHeatExchanger(tags) {
    // Getting pipeClass based on tags for heatexchanger.
    let pipeClass;
    if (tags.includes('dual') && tags.includes('temp') && tags.includes('entering')) {
        pipeClass = 'DTReturnClass';
    } else if (tags.includes('dual') && tags.includes('temp') && tags.includes('leaving')) {
      pipeClass = 'DTSupplyClass';
    } else if(tags.includes('entering') && (tags.includes('condenser'))) {
      pipeClass = 'CDWSupplyClass';
    } else if(tags.includes('leaving') && (tags.includes('condenser'))) {
      pipeClass = 'CDWReturnClass';
    } else if (tags.includes('entering')) {
      if (tags.includes('chilled')) {
        pipeClass = 'CWReturnClass';
      } else if (tags.includes('hot')) {
        pipeClass = 'boilerReturnClass';
      }
    } else if(tags.includes('leaving')) {
      if (tags.includes('chilled')) {
        pipeClass = 'CWSupplyClass';
      } else if (tags.includes('hot')) {
        pipeClass = 'boilerSupplyClass';
      }
    }
    return pipeClass;
  }

  async changeNeutralPipeColorForHeatExchanger(heatExchangerArr: any, dualTempHeaderPipeArr) {
    await this.getStatusOfEquipWhichConnectedToHeatExchanger(heatExchangerArr, dualTempHeaderPipeArr);
  }

  getStatusOfEquipWhichConnectedToHeatExchanger(heatExchangerArr, dualTempHeaderPipeArr) {
    const list = [];
    const equipIds = [];
    if (heatExchangerArr?.length) {
      heatExchangerArr.forEach(_eachNode => {
        const equipId = _eachNode.connectedParentEquipId;
        if (this.isDualTempPipe(_eachNode?.connectedParentClass)) {
          const htmlEle = document.getElementsByClassName(`GROUP parentEquipId-${equipId}`)[0];
          const nodeId = htmlEle.id.split('node-')[1];
          const nodeData = this.editor.getNodeFromId(nodeId);
          nodeData?.connectedNodesData.forEach(_connectednodes => {
            if (this.equipConfiguration.nonBuildingParentEquip.includes(_connectednodes?.connectedParentClass)) {
              let queryString = this.getTagsByEquipClass(_eachNode?.connectedParentClass);
              queryString += ` and equipRef==@${_connectednodes?.connectedParentEquipId}`;
              const queryStr = this.cpmService.findByQuery(queryString);
              list.push(queryStr.pipe(catchError(err => of(err)), map(this.cpmService.stripHaystackTypeMapping)));
            } else if (this.equipConfiguration.nonBuildingParentEquip.includes(_connectednodes?.childData?.class)) {
              let queryString = this.getTagsByEquipClass(_connectednodes?.childData?.class);
              queryString += ` and equipRef==@${_connectednodes?.childData?.equipId}`;
              const queryStr = this.cpmService.findByQuery(queryString);
              list.push(queryStr.pipe(catchError(err => of(err)), map(this.cpmService.stripHaystackTypeMapping)));
            }
          });
        }
        if (equipId && !equipIds.includes(equipId) && this.isNonBuidlingParentEquip(_eachNode?.connectedParentClass)) {
          let queryString = this.getTagsByEquipClass(_eachNode?.connectedParentClass);
          queryString += ` and equipRef==@${equipId}`;
          const queryStr = this.cpmService.findByQuery(queryString);
          list.push(queryStr.pipe(catchError(err => of(err)), map(this.cpmService.stripHaystackTypeMapping)));
        }
  
        if (_eachNode?.outputs?.output_1?.connections?.length) {
          _eachNode?.outputs?.output_1?.connections?.forEach(_node => {
            const nodeData = this.editor.getNodeFromId(_node.node);
            if (nodeData.equipId != _eachNode.equipId) {
              if (this.isDualTempPipe(nodeData?.childData?.class)) {
                const ele = document.getElementsByClassName(`pipeDemarkation parentEquipId-${nodeData?.childData?.equipId}`)
                const checkIds = [];
                Array.from(ele).forEach(_data => {
                  const data = this.editor.getNodeFromId(_data.id.split('node-')[1]);
                  if(this.isNonBuidlingParentEquip(data.connectedParentClass) && !checkIds.includes(data?.connectedParentEquipId)) {
                    let queryString = this.getTagsByEquipClass(data?.connectedParentClass);
                    queryString += ` and equipRef==@${data?.connectedParentEquipId}`;
                    const queryStr = this.cpmService.findByQuery(queryString);
                    list.push(queryStr.pipe(catchError(err => of(err)), map(this.cpmService.stripHaystackTypeMapping)));
                  }
                  checkIds.push(data?.connectedParentEquipId);
                })
              }
            }
          });
        }
        equipIds.push(equipId);
      });
      forkJoin(list).subscribe(res => {
        let response = res.map(resp => resp.rows[0]);
        response = _.uniq(response);
  
        let ids = response.map(_pt => _pt?.id);
        ids = ids.filter(id => id != undefined);
        let equipStatusList = [];
        this.cpmService.getBulkWritablePointData(ids).pipe(map(this.cpmService.stripHaystackTypeMapping)).subscribe(_res => {
          if (_res.rows.length) {
            equipStatusList = _res.rows;
          }
          this.cpmService.getCurrenthisReadMany(ids).pipe(map(this.cpmService.stripHaystackTypeMapping)).subscribe(_res => {
            if (equipStatusList.length) {
              _res.rows.forEach(pointData => {
                equipStatusList.push(pointData);
              });
            } else {
              equipStatusList = _res.rows;
            }
            this.changeDualTempOfHeatExchanger(equipStatusList, heatExchangerArr);
          });
        });
      });
    } else {
      this.changeNeutralPipeColor(dualTempHeaderPipeArr);
    }

    
  }

  changeDualTempOfHeatExchanger(equipStatusList, heatExchangerArr) {
    const equipIds = [];
    const activeEquip = equipStatusList.filter(_data => _data?.data?.[0]?.val == 1);
    let activeEquipType;
    if (activeEquip?.length > 1) {
      return;
    } else {
      activeEquip.forEach(_data => {
        if (_data.hasOwnProperty('coolingTower') || _data.hasOwnProperty('chiller')) {
          activeEquipType = 'chiller';
        } else if (_data.hasOwnProperty('boiler')) {
          activeEquipType = 'boiler';
        }
      })
    }
    heatExchangerArr.forEach(_eachNode => {
      const equipId = _eachNode.connectedParentEquipId; 
      this.setPipeColorBasedOnParentEquip({equipId: _eachNode.groupPipeId}, activeEquipType);
      if (_eachNode?.inputs?.input_1?.connections?.length) {
        _eachNode?.inputs?.input_1?.connections.forEach(_node => {
          const nodeData = this.editor.getNodeFromId(_node.node);
          if (nodeData.equipId != _eachNode.equipId) {
            this.setPipeColorBasedOnParentEquip(nodeData, activeEquipType);
            const dualBuildingBlockData = this.editor.getNodeFromId(nodeData.parentNodeId);
            if (this.isDualTempEquip(dualBuildingBlockData?.class)) {
              if (dualBuildingBlockData?.inputs?.input_1?.connections?.length) {
                dualBuildingBlockData?.inputs?.input_1?.connections.forEach(_node => {
                  const dualBuildingBlockSubEquipData = this.editor.getNodeFromId(_node.node);
                  this.setPipeColorBasedOnParentEquip({equipId: dualBuildingBlockSubEquipData.groupPipeId}, activeEquipType);
                });
              }
              if (dualBuildingBlockData?.inputs?.input_2?.connections?.length) {
                dualBuildingBlockData?.inputs?.input_2?.connections.forEach(_node => { 
                  const dualBuildingBlockSubEquipData = this.editor.getNodeFromId(_node.node);
                  this.setPipeColorBasedOnParentEquip({equipId: dualBuildingBlockSubEquipData.groupPipeId}, activeEquipType);
                });
              }
            }
          }
        });
      }
      if (_eachNode?.outputs?.output_1?.connections?.length) {
        _eachNode?.outputs?.output_1?.connections?.forEach(_node => {
          const nodeData = this.editor.getNodeFromId(_node.node);
          if (nodeData.equipId != _eachNode.equipId) {
            this.setPipeColorBasedOnParentEquip(nodeData, activeEquipType);
            if (this.isDualTempPipe(nodeData?.childData?.class)) {
              const ele = document.getElementsByClassName(`pipeDemarkation parentEquipId-${nodeData?.childData?.equipId}`)
              Array.from(ele).forEach(_data => {
                const data = this.editor.getNodeFromId(_data.id.split('node-')[1]);
                if (this.isDualTempEquip(data?.connectedParentClass)) {
                  const nodeId = document.getElementsByClassName(`dualTempBuilding2Pipe parentEquipId-${data?.connectedParentEquipId}`)?.[0].id?.split('node-')?.[1];
                  const dualBuildingBlockData = this.editor.getNodeFromId(nodeId);
                  if (dualBuildingBlockData?.inputs?.input_1?.connections?.length) {
                    dualBuildingBlockData?.inputs?.input_1?.connections.forEach(_node => {
                      const dualBuildingBlockSubEquipData = this.editor.getNodeFromId(_node.node);
                      this.setPipeColorBasedOnParentEquip({equipId: dualBuildingBlockSubEquipData.groupPipeId}, activeEquipType);
                    });
                  }
                  if (dualBuildingBlockData?.inputs?.input_2?.connections?.length) {
                    dualBuildingBlockData?.inputs?.input_2?.connections.forEach(_node => {
                      const dualBuildingBlockSubEquipData = this.editor.getNodeFromId(_node.node);
                      this.setPipeColorBasedOnParentEquip({equipId: dualBuildingBlockSubEquipData.groupPipeId}, activeEquipType);
                    });
                  }
                }
              });
            }
          }
        });
      }
    });
  }

  setPipeColorBasedOnParentEquip(nodeData, activeEquipType) {
    let htmlElementMainPathElement: any = document.querySelectorAll(`.groupPipe-${nodeData.equipId}`);

    htmlElementMainPathElement.forEach(element => {
      let classList: any = Array.from<string>(element.classList);
      if (activeEquipType == 'chiller') {
        if (classList.includes("DTSupplyClass")) {
          if (classList.includes('headerPipe-pipe')) { // Adding animation to the Header pipes based on the status of the equipment.
            element.classList.add("connection-left-to-right");
          } else if (classList.includes('parentSubEquip-pipe')) {
            element.classList.add("connection-right-to-left");
          } else {
            element.classList.add("connection-right-to-left");
          }
        }
        if (classList.includes("DTReturnClass")) {
          if (classList.includes('headerPipe-pipe')) { // Adding animation to the Header pipes based on the status of the equipment.
            element.classList.add("connection-left-to-right");
          } else if (classList.includes('parentSubEquip-pipe')) {
            element.classList.add("connection-right-to-left");
          } else {
            element.classList.add("connection-left-to-right");
          }
        }
      } else if (activeEquipType == 'boiler' || activeEquipType == 'coolingTower') {
        if (classList.includes("DTSupplyClass")) {
          if (classList.includes('headerPipe-pipe')) { // Adding animation to the Header pipes based on the status of the equipment.
            element.classList.add("connection-right-to-left")
          } else {
            element.classList.add("connection-left-to-right");
          }
        }
        if (classList.includes("DTReturnClass")) {
          if (classList.includes('headerPipe-pipe')) { // Adding animation to the Header pipes based on the status of the equipment.
            element.classList.add("connection-right-to-left");
          } else {
            element.classList.add("connection-left-to-right");
          }
        }
      }
    });

    let htmlElementMainPathColor = this.getpipeColorForDualTemp(htmlElementMainPathElement, activeEquipType);
    let htmlElementMainPath: any = document.querySelectorAll(`.groupPipe-${nodeData.equipId} .main-path`);
    htmlElementMainPath = Array.from(htmlElementMainPath);
    if (htmlElementMainPath.length) {
      htmlElementMainPath.forEach(element => {
        element.style.stroke = htmlElementMainPathColor;
      });
    }

    let htmlElementMainPathBorderElement: any = document.querySelectorAll(`.groupPipe-${nodeData.equipId}`);
    let htmlElementMainPathBorderColor = this.getpipeColorForDualTemp(htmlElementMainPathBorderElement, activeEquipType, true);
    let htmlElementPathBorder: any = document.querySelectorAll(`.groupPipe-${nodeData.equipId} .pathBorder`);
    htmlElementPathBorder = Array.from(htmlElementPathBorder);
    if (htmlElementPathBorder.length) {
      htmlElementPathBorder.forEach(element => {
        element.style.stroke = htmlElementMainPathBorderColor;
      });
    }

    // Adding animation to the pipes.
    Array.from(htmlElementMainPathElement).forEach((element: any) => {
      if (Array.from(element.classList).length && htmlElementMainPathColor.length) {
        element.classList.add('animate');
      }
    });
  }

  /**
   * Fits the nodes in the editor to the view.
   */
  fitToScreen() {
    this.editor.fitNodesToView(this.editor);
  }
}

