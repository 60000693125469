<form [formGroup]="scopeSelectorForm" class="filter-nodes-select-field d-block" autocomplete="off">
  <mat-form-field appearance="standard" class="siteSelectorFormField allsiteSelector">
    <mat-select #siteSelector (focusout)="clearSearch()" formControlName="scopeSelection" [placeholder]="placeholder"
      (openedChange)="openedChange($event)" [disableOptionCentering]="true" [disableRipple]="true"
      panelClass="filter-nodes-select headerSelector scopeSelector" multiple>
      <mat-select-trigger>
        <span *ngIf="allScopeSelected">All Buildings</span>
        <span *ngIf="!allScopeSelected">
          {{scopeSelectedList[0]}}
          <span *ngIf="scopeSelectedList.length > 1" class="example-additional-selection"> &
            + {{scopeSelectedList.length - 1}}
          </span>
        </span>
      </mat-select-trigger>

      <!-- Show search only if more than 4 elements -->
      <div class="searchArea" [ngClass]="{'hide' : this.scope?.length < 4}">
        <mat-form-field appearance="standard" class="w-100 searchFormField mat-elevation-z4">
          <input matInput #siteSearchInput autocomplete="off" placeholder="Site Search" class="w-100"
            (keydown)="$event.stopPropagation()" (keyup)="handleInput($event, siteSearchInput.value)" ng-aria-disable>
          <div matSuffix>
            <i class="fas fa-times appIcon" (click)="siteSearchInput.value = ''; clearSearch()"
              *ngIf="siteSearchInput.value.length > 0"></i>
          </div>
        </mat-form-field>
      </div>
      <div class="selectionOptions">
        <mat-option #allSelected (click)="toggleAllSelection()" [value]="0"  [ngClass]="{ 'hidden': !(checkSelectAll(siteSearchInput?.value?.length))}"
        class="selectorOption"> Select All
        </mat-option>
        <mat-option *ngFor="let filters of filteredScope" [value]="filters.id" class="group"
          (click)="siteSelectToggle()"
          [ngClass]="{'zeroSize' : selectorKeys.parent == 'sites' ? !filters?.dis?.toLowerCase().includes(siteSearchInput?.value?.toLowerCase()) : false || filters.hide}">
          {{filters.dis}}
        </mat-option>

      </div>

    </mat-select>
  </mat-form-field>
</form>